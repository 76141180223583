import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import { DataView, DrawerComponent, InternalHeader, Stats } from '../../Components'
import { getAccountStats } from '../../Services/stats'
import { getAllAccounts } from '../../Services/Sales/account'
import { resetAccount } from '../../Store/Actions/accounts'
import SingleAccount from './SingleAccount'
import { AccountForm } from '../../Components/Forms'

const Accounts = () => {
	const location = useLocation()
	const accountsData = useSelector(state => state.accountsData)
	const { id } = useSelector(state => state.userProfile)
	const { role, token } = useSelector(state => state.userSession)
	const [stats, setStats] = useState(null)

	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		sortOrder: 'desc',
		sortField: 'created_at',
		total: ''
	})
	const [addAccountDrawer, setAddAccountDrawer] = useState(false)
	const [search, setSearch] = useState('')

	const handleSearch = e => {
		setSearch(e.target.value)
	}

	const dispatch = useDispatch()

	const fetchData = async (current, pageSize, sortOrder, sortField, query) => {
		try {
			const { data } = await getAllAccounts(token, current, pageSize, sortOrder, sortField, query)
			if (!data.data) {
				dispatch(resetAccount([]))
			} else {
				dispatch(resetAccount(data.data))
			}
			setPagination({
				current: current + 1,
				pageSize,
				sortOrder,
				sortField,
				total: data.count
			})
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const getStats = async () => {
		try {
			const { data } = await getAccountStats(token)
			setStats(data.data)
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const handleChange = (page, filters, sorter) => {
		const accountFilter = filters.account_owner ? filters.account_owner[0] : undefined
		const sortOrder = {
			ascend: 'asc',
			descend: 'desc'
		}
		fetchData(
			page.current - 1,
			page.pageSize,
			sortOrder[sorter.order] || pagination.sortOrder,
			sorter.field || pagination.sortField,
			accountFilter || search
		)
	}

	useEffect(() => {
		fetchData(0, pagination.pageSize, pagination.sortOrder, pagination.sortField, search)
		// eslint-disable-next-line
	}, [search])

	useEffect(() => {
		getStats()
		// eslint-disable-next-line
	}, [accountsData])

	const accountsStats = {
		title: 'Accounts',
		total: stats?.accounts,
		data: []
	}
	const columns = [
		{
			title: 'Account Name',
			key: 'account_name',
			sorter: true,
			dataIndex: 'account_name'
		},
		{
			title: 'Parent Account',
			key: 'parent_account',
			sorter: true,
			dataIndex: 'parent_account_name'
		},
		{
			title: 'Email',
			dataIndex: 'email',
			sorter: true,
			key: 'email'
		},

		{
			title: 'Account Owner',
			key: 'account_owner',
			dataIndex: 'owner_name',
			filters:
				role !== 'Admin'
					? [
							{
								text: 'Assigned to Me',
								value: id
							}
					  ]
					: null
		}
	]

	return !location.search ? (
		<div className='bg-bell-background'>
			<InternalHeader title='Sales' selected='Accounts' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Accounts </div>
				<div>
					<DrawerComponent
						form={<AccountForm handleClose={() => setAddAccountDrawer(false)} />}
						visible={addAccountDrawer}
						onOpen={() => setAddAccountDrawer(true)}
						onClose={() => setAddAccountDrawer(false)}
						buttonTitle='Add Account'
					/>
				</div>
			</div>
			<div className='grid grid-flow-col px-10'>
				<div className='col-span-2'>
					<Stats data={accountsStats} nograph />
				</div>
			</div>
			<div>
				<DataView
					columns={columns}
					data={accountsData}
					type='accounts'
					rowClickable
					pagination={pagination}
					handleChange={handleChange}
					handleSearch={handleSearch}
				/>
			</div>
		</div>
	) : (
		<SingleAccount />
	)
}

export default Accounts
