import * as Colors from '../styles/colors'

// eslint-disable-next-line import/prefer-default-export
// eslint-disable-next-line import/prefer-default-export
export const renderStatus = (record, id, role) => ({
	Draft: {
		color: Colors.LIGHT_GREY
	},
	Pending: {
		color: id === record?.approver_id || role === 'Admin' ? Colors.RED : Colors.LIGHT_GREY,
		title: id === record?.approver_id || role === 'Admin' ? 'Requested For Approval' : 'Approval Pending'
	},
	Approved: {
		color: Colors.GREEN
	},
	Declined: {
		color: Colors.RED
	},
	Prepared: {
		color: Colors.BLUE
	},
	Submitted: {
		color: Colors.GREEN
	},
	Paid: {
		color: Colors.GREEN
	},
	Overdue: {
		color: Colors.RED
	},
	Rejected: {
		color: Colors.RED
	}
})
