import React from 'react'

const StatisticCard = ({ title, stat, description, urgent }) =>
	urgent ? (
		<div className='p-4 rounded-md bg-bell-red/10  hover:bg-bell-red/20 text-bell-red'>
			<div className=' text-base capitalize'>{title}</div>
			<div className='font-bold  text-3xl pt-4 pb-2 '>{stat}</div>
			<div className=' text-sm  font-light'>{description}</div>
		</div>
	) : (
		<div className='p-4 rounded-md bg-bell-blue/10  hover:bg-bell-blue/20 text-bell-blue'>
			<div className='text-base capitalize'>{title}</div>
			<div className='font-bold  text-3xl pt-4 pb-2 '>{stat}</div>
			<div className='text-sm  font-light'>{description}</div>
		</div>
	)

export default StatisticCard
