/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react'
import { Breadcrumb, Spin, message } from 'antd'
import { useSelector } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import { Link } from 'react-router-dom'

import { InfoCircleOutlined } from '@ant-design/icons'
import { InternalHeader, SelectDebounce } from '../../Components'
import { getProjectDurationReport } from '../../Services/reports'
import { fetchProjectsList } from '../../Controllers/fetchLists'
import { DownloadExcel } from '../../Components/DownloadExcel'
import NoData from '../../Components/NoData'

const ProjectDurationReport = () => {
	// states
	const { token } = useSelector(state => state.userSession)
	const [loading, setLoading] = useState(false)
	const [selectedProject, setSelectedProject] = useState(null)
	const [reportData, setReportData] = useState(null)
	const [originalData, setOriginalData] = useState(null)
	const [currentView, setCurrentView] = useState({ type: 'all' })

	// fetch timesheet by report by user id, start time, and end time

	const configureData = (type, data) => {
		switch (type) {
			case 'deliverable':
				const thisDeliverable = originalData.filter(x => x.ps_item_id === currentView.deliverable.id)[0]
				setReportData(
					thisDeliverable.billable_tasks.map(item => {
						let time = 0
						item.tasks.forEach(task => {
							time += task.time_spent
						})
						return {
							name: item.billable_task_name,
							id: item.billable_task_id,
							time
						}
					})
				)
				break
			case 'activity':
				const thisActivity = originalData
					.filter(x => x.ps_item_id === currentView.deliverable.id)[0]
					.billable_tasks.filter(y => y.billable_task_id === currentView.activity.id)[0]
				setReportData(
					thisActivity.tasks.map(item => ({
						name: item.task_name,
						id: item.task_id,
						time: item.time_spent
					}))
				)
				break
			default:
				const w = data.map(item => {
					let time = 0
					item.billable_tasks.forEach(billableTask => {
						billableTask.tasks.forEach(task => {
							time += task.time_spent
						})
					})
					return {
						name: item.ps_item_name,
						id: item.ps_item_id,
						time
					}
				})
				setReportData(w)
		}
	}

	useEffect(() => {
		if (originalData) {
			configureData(currentView.type, originalData)
		}
	}, [currentView.type])

	const fetchData = async projectId => {
		setLoading(true)
		try {
			const { data } = await getProjectDurationReport(token, projectId)
			if (!data.data) {
				setReportData(null)
			} else {
				configureData('all', data.data)
				setOriginalData(data.data)
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		} finally {
			setLoading(false)
		}
	}

	const barData = reportData
		? {
				datasets: [
					{
						label: 'Hours',
						data: reportData.map(a => ({
							x: a.name,
							y: (a.time || 0) / 3600,
							id: a.id,
							name: a.name,
							type: a.type
						})),
						backgroundColor: 'rgba(54, 162, 235, 0.4)',
						borderColor: 'rgb(54, 162, 235)',
						borderWidth: 1
					}
				]
		  }
		: null

	const chartOptions = {
		scales: {
			x: {
				offset: true
			}
		},
		plugins: {
			legend: {
				labels: {
					font: {
						family: 'Fira Sans'
					}
				}
			}
		},
		onClick: (e, w) => {
			const { datasetIndex } = w[0]
			const dataIndex = w[0].index
			const value = e.chart.data.datasets[datasetIndex].data[dataIndex]
			switch (currentView.type) {
				case 'all':
					setCurrentView(prev => ({ ...prev, type: 'deliverable', deliverable: { id: value.id, name: value.x } }))
					break
				case 'deliverable':
					setCurrentView(prev => ({ ...prev, type: 'activity', activity: { id: value.id, name: value.x } }))
					break
				default:
					break
			}
		}
	}

	// export as excel sheet
	const excelData = reportData?.length
		? {
				Timesheet: reportData.map(a => ({
					Name: a.name,
					Hours: (a.time || 0) / 3600
				}))
		  }
		: null

	return (
		<div className='bg-bell-background'>
			<InternalHeader title='Reports' selected='All Reports' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Project Duration Report </div>
			</div>
			<div className='px-10'>
				<div className='flex flex-row-reverse justify-between items-center'>
					<div className='flex space-x-4'>
						<DownloadExcel data={excelData} fileName={`Timesheet Report for ${selectedProject?.name}.xlsx`} />
					</div>
					<div className='flex space-x-4 text-xs text-gray-600'>
						<SelectDebounce
							showSearch
							placeholder='Search for Projects'
							fetchOptions={e => fetchProjectsList(e, token)}
							onChange={e => {
								setSelectedProject({
									name: e.label,
									id: e.value
								})
								fetchData(e.value)
							}}
							style={{ width: 400 }}
						/>
					</div>
				</div>
				<div>
					{selectedProject?.name && (
						<section className='text-2xl font-semibold py-4'>
							Project Duration Report: <span className='font-medium text-bell-blue'>{selectedProject?.name}</span>
						</section>
					)}
					{reportData ? (
						<div>
							<div className='w-1/2 m-auto'>
								<Breadcrumb>
									<Breadcrumb.Item onClick={() => setCurrentView({ type: 'all' })} className='cursor-pointer'>
										{selectedProject?.name}
									</Breadcrumb.Item>
									<Breadcrumb.Item
										onClick={() =>
											setCurrentView(prev => ({
												...prev,
												type: 'deliverable',
												activity: null
											}))
										}
										className='cursor-pointer'
									>
										{currentView?.deliverable?.name}
									</Breadcrumb.Item>
									<Breadcrumb.Item
										onClick={() => setCurrentView(prev => ({ ...prev, type: 'activity' }))}
										className='cursor-pointer'
									>
										{currentView?.activity?.name}
									</Breadcrumb.Item>
								</Breadcrumb>
							</div>
							<div className='w-1/2 m-auto'>
								<Bar data={barData} options={chartOptions} className='my-4' />
							</div>
							<div className='w-1/2 m-auto mt-10'>
								{currentView.type !== 'activity' && (
									<div className='text-gray-600 pr-10 flex items-center gap-2' hidden={currentView.task}>
										<InfoCircleOutlined />
										Click on the blue bar of any item to know in-depth details.
									</div>
								)}
								<div className='text-gray-600'>
									To know a particular user&apos;s in-depth time utilization for all projects, go to
									<Link to='./timesheet-report'> Timesheet Report</Link>.
								</div>
							</div>
						</div>
					) : loading ? (
						<Spin />
					) : (
						<NoData title={selectedProject ? 'No Timesheet available for this project.' : 'Please select a project!'} />
					)}
				</div>
			</div>
		</div>
	)
}

export default ProjectDurationReport
