/* eslint-disable react/no-unstable-nested-components */
import React, { useRef, useState, useEffect } from 'react'
import ReactToPrint from 'react-to-print'
import { PrinterOutlined } from '@ant-design/icons'
import { Button, Table, Spin, message, Select, Divider, Tag } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { InternalHeader } from '../../Components'
import { addReportCountry } from '../../Store/Actions/reports'
import { DownloadExcel } from '../../Components/DownloadExcel'
import { BASE_URL } from '../../config'
import { getCountriesList, getLeadsAndAccountsByCountryReport } from '../../Services/reports'

const LeadsAndAccountsReport = () => {
	const componentRef = useRef()

	const { token } = useSelector(state => state.userSession)
	const { country } = useSelector(state => state.reportsData)

	const dispatch = useDispatch()

	const [loading, setLoading] = useState(true)
	const [reportData, setReportData] = useState(null)
	const [countriesList, setCountriesList] = useState([])

	const fetchCountriesList = async () => {
		try {
			const { data } = await getCountriesList(token)
			if (!data.data) {
				setCountriesList([])
			} else {
				setCountriesList(data.data)
				if (!country) {
					dispatch(addReportCountry(data.data[0]))
				}
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const fetchReport = async () => {
		setLoading(true)
		try {
			const { data } = await getLeadsAndAccountsByCountryReport(token, country)
			if (!data.data) {
				setReportData(null)
			} else {
				setReportData(data.data)
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchCountriesList()
	}, [])

	useEffect(() => {
		if (country) {
			fetchReport()
		}
	}, [country])

	const excelData = reportData?.length
		? {
				Leads: reportData
					.filter(a => a.type === 'lead')
					.map(one => ({
						Name: one?.name,
						'Account Name': one?.account_name,
						'Mobile Phone': one?.mobile_phone,
						'Office Phone': one?.office_phone,
						'Personal Email': one?.personal_email,
						'Official Email': one?.official_email_address,
						'Billing Address': one?.billing_address,
						'Shipping Address': one?.shipping_address
					})),
				Accounts: reportData
					.filter(a => a.type === 'account')
					.map(one => ({
						Name: one?.name,
						Phone: one?.phone,
						Email: one?.email,
						'Billing Address': one?.billing_address,
						'Shipping Address': one?.shipping_address
					}))
		  }
		: null

	return (
		<div className='bg-bell-background'>
			<InternalHeader title='Reports' selected='All Reports' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Leads and Accounts By Country </div>
			</div>
			<div className='px-10'>
				<div className='flex flex-row-reverse justify-between items-center'>
					<div className='flex space-x-4'>
						<DownloadExcel data={excelData} fileName={`${country} Leads and Accounts Report.xlsx`} />
						<ReactToPrint
							trigger={() => (
								<Button type='primary' icon={<PrinterOutlined />}>
									Print Report
								</Button>
							)}
							content={() => componentRef.current}
						/>
					</div>
					<div className='flex space-x-4 text-xs text-gray-600'>
						<Select
							showSearch
							placeholder='Select Country'
							onChange={e => dispatch(addReportCountry(e))}
							options={countriesList.map(a => ({ value: a, label: a }))}
							value={country}
							style={{ width: 200 }}
						/>
					</div>
				</div>
				<ComponentToPrint ref={componentRef} componentData={reportData} country={country} loading={loading} />
			</div>
		</div>
	)
}

const ComponentToPrint = React.forwardRef(({ componentData, country, loading }, ref) =>
	loading ? (
		<div className='pt-32 text-center'>
			{' '}
			<Spin size='large' />
		</div>
	) : (
		<div ref={ref} className='print:p-10 py-10'>
			<section className='text-2xl font-semibold py-2'>
				Leads & Accounts Report: <span className='font-medium text-bell-blue'>{country} </span>
			</section>
			<section className='text-xl font-semibold py-2'>Leads </section>
			<Table
				columns={[
					{ title: 'Name', dataIndex: 'name' },
					{ title: 'Account Name', dataIndex: 'account_name' },
					{
						title: 'Contact',
						render: (_, record) => (
							<div className='space-y-2'>
								{record.mobile_phone && (
									<div className='flex justify-between'>
										<Tag> Mobile </Tag>
										<div className='w-24'>{record.mobile_phone}</div>
									</div>
								)}
								{record.office_phone && (
									<div className='flex justify-between'>
										<Tag> Office </Tag>
										<div className='w-24'>{record.office_phone}</div>
									</div>
								)}
							</div>
						)
					},
					{
						title: 'Email',
						render: (_, record) => (
							<div className='space-y-2'>
								{record.personal_email && (
									<div>
										<Tag> Personal Email </Tag>
										<div className=''>{record.personal_email}</div>
									</div>
								)}
								{record.official_email_address && (
									<div>
										<Tag> Official Email </Tag>
										<div className=''>{record.official_email_address}</div>
									</div>
								)}
							</div>
						)
					},
					{
						title: 'Address',
						render: (_, record) => (
							<div className='space-y-2'>
								{record.billing_address && (
									<div>
										<Tag> Billing Address </Tag>
										<div className=''>{record.billing_address}</div>
									</div>
								)}
								{record.shipping_address && (
									<div>
										<Tag> Shipping Address </Tag>
										<div className=''>{record.shipping_address}</div>
									</div>
								)}
							</div>
						)
					},
					{
						title: 'Actions',
						key: 'actions',
						render: (_, record) => (
							<div className='z-10'>
								<a href={record.id && `${BASE_URL}/sales/leads?id=${record.id}`}>View Lead</a>
							</div>
						)
					}
				]}
				dataSource={componentData?.filter(a => a?.type === 'lead')}
				pagination={false}
			/>
			<Divider />
			<section className='text-xl font-semibold py-2'>Accounts </section>
			<Table
				columns={[
					{ title: 'Name', dataIndex: 'name' },
					{ title: 'Email', dataIndex: 'email' },
					{ title: 'Phone', dataIndex: 'phone', render: phone => <div>{phone}</div> },
					{
						title: 'Address',
						render: (_, record) => (
							<div className='space-y-2'>
								{record.billing_address && (
									<div>
										<Tag> Billing Address </Tag>
										<div className=''>{record.billing_address}</div>
									</div>
								)}
								{record.shipping_address && (
									<div>
										<Tag> Shipping Address </Tag>
										<div className=''>{record.shipping_address}</div>
									</div>
								)}
							</div>
						)
					},
					{
						title: 'Actions',
						key: 'actions',
						render: (_, record) => (
							<div className='z-10'>
								<a href={record.id && `${BASE_URL}/sales/accounts?id=${record.id}`}>View Account</a>
							</div>
						)
					}
				]}
				dataSource={componentData?.filter(a => a?.type === 'account')}
				pagination={false}
			/>
		</div>
	)
)

export default LeadsAndAccountsReport
