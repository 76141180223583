import React, { useState } from 'react'
import { Button, Checkbox, DatePicker, Drawer, Form, Input, message, Select, Space, Switch, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'

import AccountForm from './AccountForm'
import { formItemLayout, tailFormItemLayout } from '../../Controllers/form'
import EmployeeForm from './EmployeeForm'
import { objectDifference } from '../../Controllers/objectDifference'
import { form_options, leadSources } from '../../Content/leads'
import SelectDebounce from '../SelectDebounce'
import { URL } from '../../config'
import { createContact, getContactById, updateContact } from '../../Services/Sales/contacts'
import { fetchAccountList } from '../../Controllers/fetchLists'
import { addContact, editContact } from '../../Store/Actions/contacts'
import dateFormat from '../../Content/dateFormat'
import ReporteeSelect from '../ReporteeSelect'

const { Option } = Select

const ContactForm = ({ edit, handleClose, data, type, handleContactValues }) => {
	const id = data ? data.id : null
	const formData = data
		? {
				...data,
				date_of_birth: data.date_of_birth !== 0 ? dayjs.unix(data.date_of_birth) : undefined,
				contact_owner: {
					key: data.contact_owner,
					label: data.contact_owner_name,
					value: data.contact_owner
				}
		  }
		: false

	const userSession = useSelector(state => state.userSession)

	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const [visible, setVisible] = useState('')
	const [loading, setLoading] = useState(false)
	const [checked, setChecked] = useState(false)
	const [uploadedFiles, setUploadedFiles] = useState([])

	const selectWidth = 300
	const showDrawer = (e, type) => {
		setVisible(type)
	}
	const onClose = () => {
		setVisible('')
	}

	const props = {
		name: 'file',
		action: `${URL}/assets/upload`,
		headers: {
			authorization: `Bearer ${userSession.token}`
		},
		multiple: true,
		onChange(info) {
			if (info.file.status === 'done') {
				message.success(`${info.file.name} file uploaded successfully`)
				setUploadedFiles([...uploadedFiles, info.file.response.data.name])
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`)
			}
		}
	}

	const handleChange = () => {
		if (checked) {
			form.setFieldsValue({
				shipping_address: form.getFieldValue('billing_address'),
				shipping_city: form.getFieldValue('billing_city'),
				shipping_state: form.getFieldValue('billing_state'),
				shipping_code: form.getFieldValue('billing_code'),
				shipping_country: form.getFieldValue('billing_country')
			})
		}
	}
	const handleCheck = e => {
		setChecked(e.target.checked)
		if (e.target.checked) {
			form.setFieldsValue({
				shipping_address: form.getFieldValue('billing_address'),
				shipping_city: form.getFieldValue('billing_city'),
				shipping_state: form.getFieldValue('billing_state'),
				shipping_code: form.getFieldValue('billing_code'),
				shipping_country: form.getFieldValue('billing_country')
			})
		}
	}
	const onAdd = async values => {
		setLoading(true)
		const dataObject = {
			...values,
			date_of_birth: values.date_of_birth ? dayjs(values.date_of_birth).unix() : 0,
			contact_owner: values?.contact_owner?.value,
			account_name: values.account_name?.value || '',
			attachments: uploadedFiles
		}
		try {
			const { data } = await createContact(userSession.token, dataObject)
			const { id } = data.data
			const res = await getContactById(userSession.token, id)
			dispatch(addContact(res.data.data))
			if (handleContactValues) {
				handleContactValues(type || '', {
					id,
					...dataObject
				})
			}
			message.success('Contact added successfully!')
			setLoading(false)
			form.resetFields()
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error.response?.data?.message || 'Something went wrong.')
		}
	}
	const onEdit = async values => {
		setLoading(true)
		const dataObject = {
			...values,
			date_of_birth: values.date_of_birth ? dayjs(values.date_of_birth).unix() : 0,
			contact_owner: values.contact_owner.value || data.contact_owner,
			account_name: values.account_name?.value || data.account_name,
			attachments: data.attachments ? [...data.attachments, ...uploadedFiles] : uploadedFiles
		}
		try {
			const updatedData = objectDifference(data, dataObject)
			await updateContact(userSession.token, { id, ...updatedData })
			dispatch(
				editContact({
					id: data.id,
					data: { id: data.id, ...dataObject }
				})
			)
			message.success('Contact Information updated successfully!')
			setLoading(false)
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const getDrawerComponent = onClose => {
		switch (visible) {
			case 'Create an Account':
				return (
					<AccountForm
						handleClose={onClose}
						handleAccountValues={data => {
							form.setFieldsValue({
								account_name: {
									label: `${data.account_name}`,
									value: data.id
								}
							})
						}}
					/>
				)
			case 'Add Employee':
				return (
					<EmployeeForm
						handleClose={onClose}
						handleEmployeeValues={data => {
							form.setFieldsValue({
								contact_owner: {
									label: `${data.first_name} ${data.last_name}`,
									value: data.id
								}
							})
						}}
					/>
				)
			default:
				return null
		}
	}
	const getDrawerWidth = () => {
		switch (visible) {
			case 'Create Lead Source':
				return 500
			case 'Create an Account':
				return 750
			case 'Create a Contact':
				return 770
			case 'Add Employee':
				return 700
			default:
				return 700
		}
	}
	return (
		<div className='space-y-20'>
			{/* REGISTER FORM */}
			<Form
				name='control-hooks'
				form={form}
				onFinish={edit ? onEdit : onAdd}
				{...formItemLayout}
				initialValues={formData}
				scrollToFirstError
				onChange={handleChange}
			>
				<div className='py-2 italic text-bell-gray'>Contact Information</div>
				<Form.Item label='Contact Owner'>
					<Space>
						<Form.Item name='contact_owner' noStyle rules={[{ required: true, message: 'Please input lead owner!' }]}>
							<ReporteeSelect
								form={form}
								fieldName='contact_owner'
								currentValue={formData ? formData?.contact_owner?.label : null}
							/>
						</Form.Item>
					</Space>
				</Form.Item>

				<Form.Item label='Contact Source' name='contact_source'>
					<Select placeholder='How did you find this contact?'>
						{leadSources.map((option, i) => (
							<Option value={option} key={i}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item
					label='Salutation'
					name='salutation'
					rules={[
						{
							required: true,
							message: 'Please input salutation'
						}
					]}
				>
					<Select placeholder='Select a Salutation'>
						{form_options.salutation.map((option, i) => (
							<Option value={option} key={i}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item
					label='First Name'
					name='first_name'
					rules={[
						{
							required: true,
							message: 'Please input first name'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item label='Middle Name' name='middle_name'>
					<Input />
				</Form.Item>
				<Form.Item
					label='Last Name'
					name='last_name'
					rules={[
						{
							required: true,
							message: 'Please input last name'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item label='Suffix' name='suffix'>
					<Input />
				</Form.Item>
				<Form.Item label='Account/Company'>
					<Space>
						<Form.Item name='account_name' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Select Account'
								fetchOptions={e => fetchAccountList(e, userSession.token)}
								style={{
									width: selectWidth
								}}
								currentSearchValue={data?.contact_account_name}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, 'Create an Account')} type='link'>
							Create an Account
						</Button>
					</Space>
				</Form.Item>
				<Form.Item label='Title' name='title'>
					<Input />
				</Form.Item>

				<Form.Item
					label='Official Email Address'
					name='official_email_address'
					rules={[
						{
							type: 'email',
							message: 'Please input a valid email address!'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item label='Department' name='department'>
					<Input />
				</Form.Item>
				<Form.Item
					label='Personal Email Address'
					name='personal_email_address'
					rules={[
						{
							type: 'email',
							message: 'Please input a valid email address!'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item label='Mobile Phone' name='mobile_phone'>
					<Input />
				</Form.Item>
				<Form.Item label='Office Phone' name='office_phone'>
					<Input />
				</Form.Item>
				<Form.Item label='Office Phone Extension' name='office_phone_extension'>
					<Input />
				</Form.Item>
				<Form.Item label='Home Phone' name='home_phone'>
					<Input />
				</Form.Item>
				<Form.Item label='Other Phone' name='other_phone'>
					<Input />
				</Form.Item>
				<Form.Item label='Fax' name='fax'>
					<Input />
				</Form.Item>
				<Form.Item label='Date of Birth' name='date_of_birth'>
					<DatePicker format={dateFormat} />
				</Form.Item>
				{/* <Form.Item label='Assistant Name' name='assistant_name'>
					<Select placeholder='Select Assistant Name'>
						{form_options.assistant_name.map(option => (
							<Option value={option}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item> */}

				{/* <Form.Item label='Reports To' name='reports_to'>
					<Select placeholder='Select reports to' allowClear>
						{form_options.reports_to.map(option => (
							<Option value={option}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item> */}
				<div className='py-2 italic text-bell-gray'>Social Media Information</div>
				<Form.Item label='Email Opt Out' name='email_opt_out'>
					<Switch />
				</Form.Item>
				<Form.Item label='Skype Id' name='skype_id'>
					<Input />
				</Form.Item>
				<Form.Item label='Twitter Id' name='twitter_id'>
					<Input />
				</Form.Item>
				<Form.Item label='Linkedin' name='linkedin'>
					<Input />
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Address Information</div>
				<Form.Item label='Billing Address' name='billing_address'>
					<Input />
				</Form.Item>
				<Form.Item label='Billing City' name='billing_city'>
					<Input />
				</Form.Item>
				<Form.Item label='Billing State' name='billing_state'>
					<Input />
				</Form.Item>
				<Form.Item label='Billing Code' name='billing_code'>
					<Input />
				</Form.Item>
				<Form.Item label='Billing Country' name='billing_country'>
					<Input />
				</Form.Item>
				<div className='text-xs w-full text-bell-gray text-right pb-4'>
					<Checkbox onClick={handleCheck} /> Same as Billing Address
				</div>
				<Form.Item label='Shipping Address' name='shipping_address'>
					<Input />
				</Form.Item>
				<Form.Item label='Shipping City' name='shipping_city'>
					<Input />
				</Form.Item>
				<Form.Item label='Shipping State' name='shipping_state'>
					<Input />
				</Form.Item>
				<Form.Item label='Shipping Code' name='shipping_code'>
					<Input />
				</Form.Item>
				<Form.Item label='Shipping Country' name='shipping_country'>
					<Input />
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Descriptive Information</div>
				<Form.Item label='Description' name='description'>
					<Input.TextArea rows={4} />
				</Form.Item>
				<Form.Item label='Insert Attachments' name='attachments'>
					<Upload {...props}>
						<Button icon={<UploadOutlined />}>Upload</Button>
					</Upload>
				</Form.Item>
				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
			<Drawer
				title={visible}
				width={getDrawerWidth()}
				onClose={onClose}
				open={!!visible}
				bodyStyle={{ paddingBottom: 80 }}
				footer={null}
			>
				{getDrawerComponent(onClose)}
			</Drawer>
		</div>
	)
}
export default ContactForm
