/* eslint-disable default-param-last */
import {
	ADD_CONTRACT,
	ADD_VARIATION,
	EDIT_CONTRACT,
	RESET_CONTRACT,
	RESET_VARIATION,
	SINGLE_CONTRACT,
	UPDATE_SINGLE_CONTRACT
} from '../types'

const contracts = []
const singleContractsState = []
const variationsState = []

export const contractsData = (state = contracts, { type, payload }) => {
	switch (type) {
		case ADD_CONTRACT:
			return [payload, ...state]
		case EDIT_CONTRACT:
			return state.map(x => {
				if (x.id === payload.id) {
					return payload.data
				}
				return x
			})
		case RESET_CONTRACT:
			return payload

		default:
			return state
	}
}

export const singleContractsData = (state = singleContractsState, { type, payload }) => {
	switch (type) {
		case SINGLE_CONTRACT:
			return payload
		case UPDATE_SINGLE_CONTRACT:
			return {
				...state,
				...payload
			}
		default:
			return state
	}
}

export const variationsData = (state = variationsState, { type, payload }) => {
	switch (type) {
		case ADD_VARIATION:
			return [payload, ...state]

		case RESET_VARIATION:
			return payload
		default:
			return state
	}
}
