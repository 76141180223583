import React, { useEffect, useState } from 'react'
import { message, Popconfirm, Steps } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { form_options } from '../../Content/opportunities'
import { updateOpportunity } from '../../Services/Sales/opportunity'
import { createActivity } from '../../Services/activity'
import { addActivity } from '../../Store/Actions/activity'
import DrawerComponent from '../DrawerComponent'
import { RegisterEnquiryForm } from '../Forms'
import { refreshComponent } from '../../Store/Actions/userSession'

const OpportunityStepper = ({ data, accessType }) => {
	const current = data.stage
	const { id } = data
	const { token } = useSelector(state => state.userSession)
	const { first_name, last_name } = useSelector(state => state.userProfile)
	const [currentStep, setCurrentStep] = useState(null)
	const [enquiryDrawer, setEnquiryDrawer] = useState(false)
	const dispatch = useDispatch()
	const { stages } = form_options
	useEffect(() => {
		setCurrentStep(stages.indexOf(current))
		// eslint-disable-next-line
	}, [current])

	const confirm = async (e, i) => {
		try {
			await updateOpportunity(token, { id, stage: stages[i] })
			const activityObject = {
				association_id: id,
				activity_type: 'Stage Update',
				subject: `Stage updated to "${stages[i]}"`,
				created_at: dayjs().unix(),
				created_by_name: `${first_name} ${last_name}`
			}
			const { data } = await createActivity(token, activityObject)
			dispatch(
				addActivity({
					id: data.data.id,
					...activityObject
				})
			)
			dispatch(refreshComponent())
			message.success('Opportunity stage updated successfully.')
			setCurrentStep(i)
		} catch (error) {
			message.error('Something went wrong.')
		}
	}

	const handleConversionToEnquiry = async (enquiryID, enquiryName) => {
		try {
			await updateOpportunity(token, { id, converted_to: enquiryID })
			const activityObject = {
				association_id: id,
				activity_type: 'Convert',
				subject: `Opportunity Converted`,
				description: `Converted to Enquiry "${enquiryName}"`,
				created_at: dayjs().unix(),
				created_by_name: `${first_name} ${last_name}`
			}
			const { data } = await createActivity(token, activityObject)
			dispatch(
				addActivity({
					id: data.data.id,
					...activityObject
				})
			)
			dispatch(refreshComponent())
			message.success('Opportunity successfully converted.')
		} catch (error) {
			message.error('Something went wrong.')
		}
	}

	const record = {
		account_id: {
			key: data.account_id,
			value: data.account_id,
			label: data.account_name
		},
		lead_account_name: data.account_name,
		job_description: data.name,
		description: data.description,
		enquiry_date: 0,
		enquiry_owner_id: data.owner,
		enquiry_owner_name: data.owner_name,
		enquiry_stage: 'Expression of Interest',
		probability: 15,
		submission_date: 0,
		converted_from: {
			key: data.id,
			value: data.id,
			label: data.name
		}
	}
	const items = stages.map((stage, i) => ({
		title: (
			<Popconfirm
				title='Update opportunity stage?'
				onConfirm={e => confirm(e, i)}
				okText='Yes'
				cancelText='No'
				disabled={accessType !== 'Public'}
			>
				<div className={accessType === 'Public' ? 'cursor-pointer' : 'cursor-not-allowed'}>{stage}</div>
			</Popconfirm>
		),
		key: i
	}))

	return (
		<div className='flex space-x-10 items-center'>
			<Steps current={currentStep} size='small' items={items} />
			{data.converted_to ? (
				false
			) : (
				<DrawerComponent
					form={
						<RegisterEnquiryForm
							handleClose={() => setEnquiryDrawer(false)}
							data={record}
							handleValue={handleConversionToEnquiry}
							opportunityId={data.id}
						/>
					}
					visible={enquiryDrawer}
					onOpen={() => setEnquiryDrawer(true)}
					onClose={() => setEnquiryDrawer(false)}
					buttonTitle='Convert to Enquiry'
					buttonType='primary'
					drawerWidth={900}
				/>
			)}
		</div>
	)
}

export default OpportunityStepper
