import React, { useEffect, useState } from 'react'
import { Badge, Space, Tabs, Tag, message } from 'antd'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'

import { DrawerComponent, InternalHeader, TableComponent } from '../../Components'
import dateFormat from '../../Content/dateFormat'
import accounting from '../../Controllers/accountingNumbers'
import { defaultPagination } from '../../Content/general'
import PettyCashForm from '../../Components/Forms/PettyCashForm'
import { getAllEmployeesPettyCashReport, getAllPettyCash } from '../../Services/pettycash'
import { ERROR_MESSAGE } from '../../Content/messages'
import PettyCashByEmployee from './PettyCashByEmployee'
import { refreshComponent } from '../../Store/Actions/userSession'
import * as Colors from '../../styles/colors'
import SingleExpense from '../Expenses/SingleExpense'
import { getAllExpenses } from '../../Services/expenses'
import MINIOLink from '../../Controllers/minioLink'

const PettyCashManagement = () => {
	const { token } = useSelector(state => state.userSession)
	const refresh = useSelector(state => state.refreshComponent)
	const [pettyData, setPettyData] = useState([])
	const [expenseData, setExpenseData] = useState([])
	const [employeesData, setEmployeesData] = useState([])
	const [addDrawer, setAddDrawer] = useState(false)
	const [editDrawer, setEditDrawer] = useState(false)
	const [viewDrawer, setViewDrawer] = useState(false)
	const [pagination, setPagination] = useState(defaultPagination)
	const [tableLoader, setTableLoader] = useState(false)
	const [badgeCount, setBadgeCount] = useState(null)
	const [tabView, setTabView] = useState('1')
	const dispatch = useDispatch()

	const pettyColumns = [
		{
			title: 'Issued Date',
			dataIndex: 'issued_on',
			key: 'issued_on',
			render: (_, record) => dayjs.unix(record.issued_on).format(dateFormat)
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: amount => accounting(amount)
		},
		{
			title: 'Issued to',
			key: 'employee_name',
			dataIndex: 'employee_name'
		},
		{
			title: 'Remarks',
			dataIndex: 'remarks',
			key: 'issued_on'
		},
		{
			title: 'Attachments',
			dataIndex: 'attachments',
			key: 'attachments',
			render: att => (
				<div className='flex flex-col'>
					{att.map(q => (
						<MINIOLink item={q} />
					))}
				</div>
			)
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (_, record) => (
				<DrawerComponent
					form={
						<PettyCashForm
							edit
							data={record}
							handleClose={e => {
								setPettyData(prev => prev.map(entry => (e.id === entry.id ? e : entry)))
								setEditDrawer(false)
							}}
						/>
					}
					visible={editDrawer[record.id]}
					onOpen={() => setEditDrawer({ [record.id]: true })}
					onClose={() => setEditDrawer(false)}
					drawerTitle='Expense Details'
					buttonTitle='Edit'
					drawerWidth={600}
					buttonType='link'
				/>
			)
		}
	]

	const expensesColumn = [
		{
			title: 'Date',
			dataIndex: 'expense_date',
			key: 'expense_date',
			render: (_, record) => dayjs.unix(record.expense_date).format(dateFormat)
		},
		{
			title: 'Expense Name',
			key: 'title',
			dataIndex: 'title'
		},
		{
			title: 'Category',
			dataIndex: 'category',
			key: 'category'
		},
		{
			title: 'Reimbursement',
			key: 'tags',
			render: (_, record) => <Space>{record.client_claim && <Tag color={Colors.GREEN}> From Client</Tag>}</Space>
		},
		{
			title: 'Amount',
			dataIndex: 'amount',
			key: 'amount',
			render: amount => accounting(amount)
		},

		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<Space size='middle'>
					<DrawerComponent
						visible={viewDrawer[record.id]}
						onOpen={() => setViewDrawer({ [record.id]: true })}
						onClose={() => setViewDrawer(false)}
						drawerTitle='Expense Details'
						buttonTitle='View'
						drawerWidth={900}
						buttonType='link'
						form={<SingleExpense id={record.id} closeDrawer={() => setViewDrawer(false)} financeView />}
					/>
				</Space>
			)
		}
	]

	const employeesColumns = [
		{
			title: 'Employee Name',
			key: 'employee_name',
			dataIndex: 'employee_name'
		},
		{
			title: 'Total Disbursement',
			dataIndex: 'cash',
			key: 'cash',
			render: amount => accounting(amount)
		},
		{
			title: 'Total Expense',
			dataIndex: 'expense',
			key: 'expense',
			render: amount => accounting(amount)
		},
		{
			title: 'Balance',
			render: (_, rec) =>
				rec.cash > rec.expense ? (
					<div className='text-bell-green'> {accounting(rec.cash - rec.expense)}</div>
				) : (
					accounting(rec.cash - rec.expense)
				)
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (_, record) => (
				<DrawerComponent
					form={<PettyCashByEmployee employeeId={record.id} employeeName={record.employee_name} />}
					visible={viewDrawer[record.id]}
					onOpen={() => setViewDrawer({ [record.id]: true })}
					onClose={() => {
						dispatch(refreshComponent())
						setViewDrawer(false)
					}}
					drawerTitle={record.employee_name}
					buttonTitle='View Details'
					drawerWidth={800}
					buttonType='link'
				/>
			)
		}
	]

	const fetchBadgeCount = async () => {
		try {
			const { data } = await getAllExpenses(token, 0, 100, pagination.sortOrder, pagination.sortField, '', 'Approved')
			setBadgeCount(data?.count)
		} catch (error) {
			return null
		}
		return true
	}
	useEffect(() => {
		fetchBadgeCount()
	}, [])

	const fetchData = async (current, pageSize) => {
		setTableLoader(true)
		try {
			if (tabView === '1') {
				const { data: r } = await getAllEmployeesPettyCashReport(token, current, pageSize, '')
				if (r.data) {
					setEmployeesData(r.data)
					setPagination({
						current: current + 1,
						pageSize,
						total: r.count
					})
				}
			}
			if (tabView === '2') {
				const { data: q } = await getAllPettyCash(token, current, pageSize)
				if (q.data) {
					setPettyData(q.data)
					setPagination({
						current: current + 1,
						pageSize,
						total: q.count
					})
				}
			}
			if (tabView === '3') {
				const { data: w } = await getAllExpenses(
					token,
					current,
					pageSize,
					pagination.sortOrder,
					pagination.sortField,
					'',
					'Approved'
				)
				if (w.data) {
					setExpenseData(w.data)
					setPagination({
						current: current + 1,
						pageSize,
						total: w.count
					})
				}
			}
		} catch (error) {
			message.error(error?.response?.data?.message || ERROR_MESSAGE)
		} finally {
			setTableLoader(false)
		}
	}

	const handleChange = page => {
		fetchData(page.current - 1, page.pageSize)
	}

	useEffect(() => {
		fetchData(pagination.current - 1, pagination.pageSize)
	}, [refresh, tabView])

	return (
		<div className='bg-bell-background'>
			<InternalHeader title='Finance' selected='Disbursement Management' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Disbursement </div>
				<div>
					<DrawerComponent
						form={
							<PettyCashForm
								handleClose={entry => {
									setAddDrawer(false)
									setPettyData(prev => [entry, ...prev])
									dispatch(refreshComponent())
								}}
							/>
						}
						visible={addDrawer}
						onOpen={() => setAddDrawer(true)}
						onClose={() => setAddDrawer(false)}
						buttonTitle='Issue Disbursement'
						drawerWidth={600}
					/>
				</div>
			</div>
			<div className='px-10'>
				<Tabs
					defaultActiveKey={tabView}
					items={[
						{
							key: '1',
							label: `Overview`,
							children: (
								<TableComponent
									columns={employeesColumns}
									data={employeesData}
									pagination={pagination}
									handleChange={handleChange}
									loading={tableLoader}
								/>
							)
						},
						{
							key: '2',
							label: `Disbursements`,
							children: (
								<TableComponent
									columns={pettyColumns}
									data={pettyData}
									pagination={pagination}
									handleChange={handleChange}
									loading={tableLoader}
								/>
							)
						},
						{
							key: '3',
							label: (
								<Badge count={badgeCount} overflowCount={10} offset={[9, 2]}>
									Approved Expenses
								</Badge>
							),
							children: (
								<TableComponent
									columns={expensesColumn}
									data={expenseData}
									pagination={pagination}
									handleChange={handleChange}
									loading={tableLoader}
								/>
							)
						}
					]}
					onChange={e => {
						setTabView(e)
						setPagination(defaultPagination)
					}}
				/>
			</div>
		</div>
	)
}

export default PettyCashManagement
