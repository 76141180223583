import React from 'react'

const Title = ({ title, name, overview, editButton, shareButton, hasSubtitle, extraDetail }) => (
	<div>
		<div className='space-y-1'>
			<div className='text-sm text-bell-gray'> {title} </div>
			<div className='flex space-x-4 items-center'>
				<div className='text-2xl font-medium text-bell-text'>{name}</div>
				<div className='flex -space-x-4'>
					{editButton}
					{shareButton}
				</div>
			</div>
		</div>
		{hasSubtitle && <div>{extraDetail}</div>}
		<div className='flex space-x-6 mt-10'>
			{overview
				.filter(x => x.value)
				.map((x, i) => (
					<div key={i}>
						<div className='text-bell-gray text-sm font-light min-w-max'> {x.title}</div>
						<div className='text-bell-text text-sm'> {x.value} </div>
					</div>
				))}
		</div>
	</div>
)

export default Title
