/* eslint-disable default-param-last */
import {
	ADD_REPORT_USER,
	ADD_REPORT_FILTER,
	ADD_REPORT_COUNTRY,
	ADD_ENQUIRY_REPORT_DATA,
	ADD_ACTIVE_AND_LOST_REPORT,
	ADD_CLIENT_ENGAGEMENT_REPORT,
	ADD_TIMESHEET_REPORT
} from '../types'

const reportsState = {
	user: {
		name: '',
		id: ''
	},
	filter: [604800],
	country: '',
	enquiryReport: {
		country: null,
		account: null
	},
	activeAndLostReport: {
		type: 'active'
	},
	clientEngagementReport: {
		duration: [604800]
	},
	timesheetReport: {
		user: {
			name: '',
			id: ''
		}
	}
}

// eslint-disable-next-line import/prefer-default-export
export const reportsData = (state = reportsState, { type, payload }) => {
	switch (type) {
		case ADD_REPORT_USER:
			return { ...state, user: payload }
		case ADD_REPORT_FILTER:
			return { ...state, filter: payload }
		case ADD_REPORT_COUNTRY:
			return { ...state, country: payload }
		case ADD_ENQUIRY_REPORT_DATA:
			return { ...state, enquiryReport: payload }
		case ADD_ACTIVE_AND_LOST_REPORT:
			return { ...state, activeAndLostReport: payload }
		case ADD_CLIENT_ENGAGEMENT_REPORT:
			return { ...state, clientEngagementReport: payload }
		case ADD_TIMESHEET_REPORT:
			return { ...state, timesheetReport: payload }
		default:
			return state
	}
}
