import {
	ADD_ACCOUNT,
	DELETE_ACCOUNT,
	EDIT_ACCOUNT,
	RESET_ACCOUNT,
	SINGLE_ACCOUNT,
	UPDATE_SINGLE_ACCOUNT
} from '../types'

const accounts = []

export const accountsData = (state = accounts, { type, payload }) => {
	switch (type) {
		case ADD_ACCOUNT:
			return [payload, ...state]
		case EDIT_ACCOUNT:
			return state.map(x => {
				if (x.id === payload.id) {
					return payload.data
				}
				return x
			})
		case DELETE_ACCOUNT:
			return state.filter(acc => acc.id !== payload)
		case RESET_ACCOUNT:
			return payload
		default:
			return state
	}
}

const singleAccount = {}

export const singleAccountsData = (state = singleAccount, { type, payload }) => {
	switch (type) {
		case SINGLE_ACCOUNT:
			return payload
		case UPDATE_SINGLE_ACCOUNT:
			return { ...state, payload }
		default:
			return state
	}
}
