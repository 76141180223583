import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { message, Tag, Tooltip } from 'antd'
import { AiFillChrome } from 'react-icons/ai'
import { DataView, InternalHeader, Stats, DrawerComponent } from '../../../Components'

// eslint-disable-next-line import/no-named-as-default
import SingleLead from './SingleLead'
import * as Colors from '../../../styles/colors'

import { getAllLeads } from '../../../Services/Sales/leads'
import { resetLead } from '../../../Store/Actions/leads'
import { getLeadStats } from '../../../Services/stats'
import { LeadForm } from '../../../Components/Forms'

const Leads = () => {
	const location = useLocation()
	const leadsData = useSelector(state => state.leadsData)
	const { id } = useSelector(state => state.userProfile)
	const { role, token } = useSelector(state => state.userSession)
	const [stats, setStats] = useState(null)
	const dispatch = useDispatch()
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		sortOrder: 'desc',
		sortField: 'created_at',
		total: ''
	})
	const [addLeadDrawer, setAddLeadDrawer] = useState(false)
	const [search, setSearch] = useState('')

	// functions
	const handleSearch = e => {
		setSearch(e.target.value)
	}

	const fetchData = async (current, pageSize, sortOrder, sortField, query) => {
		try {
			const { data } = await getAllLeads(token, current, pageSize, sortOrder, sortField, query)

			if (!data.data) {
				dispatch(resetLead([]))
			} else {
				dispatch(resetLead(data.data))
			}
			setPagination({
				current: current + 1,
				pageSize,
				sortOrder,
				sortField,
				total: data.count
			})
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const getStats = async () => {
		try {
			const { data } = await getLeadStats(token)
			setStats(data.data)
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const handleChange = (page, filters, sorter) => {
		const leadFilter = filters.lead_owner ? filters.lead_owner[0] : undefined
		const sortOrder = {
			ascend: 'asc',
			descend: 'desc'
		}
		fetchData(
			page.current - 1,
			page.pageSize,
			sortOrder[sorter.order],
			sorter.order ? sorter.columnKey : '',
			leadFilter || search
		)
	}

	useEffect(() => {
		fetchData(0, pagination.pageSize, pagination.sortOrder, pagination.sortField, search)
		// eslint-disable-next-line
	}, [search])

	useEffect(() => {
		getStats()
		// eslint-disable-next-line
	}, [leadsData])

	const leadsStats = {
		title: 'Leads',
		total: stats?.leads,
		data: [
			{
				name: 'New',
				color: Colors.RED,
				number: stats?.new_leads
			},
			{
				name: 'Working',
				color: Colors.BLUE,
				number: stats?.working_leads
			},
			{
				name: 'Nurturing',
				color: Colors.YELLOW,
				number: stats?.nurturing_leads
			},
			{
				name: 'Closed',
				color: Colors.GREEN,
				number: stats?.closed_leads
			}
		]
	}
	const columns = [
		{
			title: 'Name',
			key: 'name',
			sorter: true,
			render: (_, record) => (
				<div className='flex items-center gap-2'>
					{record?.lead_source === 'Website' && (
						<Tooltip title='Converted from a web inquiry' placement='topLeft'>
							<span className='cursor-default'>
								<AiFillChrome color={Colors.BLUE} size={18} />
							</span>
						</Tooltip>
					)}
					<span>{`${record.first_name} ${record.last_name}`}</span>
				</div>
			)
		},
		{
			title: 'Account',
			key: 'account',
			dataIndex: 'lead_account_name'
		},
		{
			title: 'Lead Owner',
			key: 'lead_owner',
			dataIndex: 'lead_owner_name',
			filters:
				role !== 'Admin'
					? [
							{
								text: 'Assigned to Me',
								value: id
							}
					  ]
					: null
		},
		{
			title: 'Lead Status',
			dataIndex: 'lead_status',
			sorter: true,
			key: 'lead_status',
			render: status => {
				let color
				if (status === 'New') {
					color = Colors.RED
				}
				if (status === 'Working') {
					color = Colors.BLUE
				}
				if (status === 'Nurturing') {
					color = Colors.YELLOW
				}
				if (status === 'Closed') {
					color = Colors.GREEN
				}
				return (
					<Tag color={color} key={status}>
						{status?.toUpperCase()}
					</Tag>
				)
			}
		}
	]

	return !location.search ? (
		<div className='bg-bell-background'>
			<InternalHeader title='Sales' selected='Leads' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Leads </div>
				<div>
					<DrawerComponent
						form={<LeadForm handleClose={() => setAddLeadDrawer(false)} />}
						visible={addLeadDrawer}
						onOpen={() => setAddLeadDrawer(true)}
						onClose={() => setAddLeadDrawer(false)}
						buttonTitle='Add Lead'
					/>
				</div>
			</div>
			<div className='grid grid-flow-col px-10'>
				<div className='col-span-2'>
					<Stats data={leadsStats} />
				</div>
			</div>
			<div>
				<DataView
					columns={columns}
					data={leadsData}
					type='leads'
					rowClickable
					pagination={pagination}
					handleChange={handleChange}
					handleSearch={handleSearch}
				/>
			</div>
		</div>
	) : (
		<SingleLead />
	)
}

export default Leads
