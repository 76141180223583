/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { message, Popover, Space, Spin, Statistic, Tooltip } from 'antd'
import { AiFillPushpin, AiOutlineInfoCircle, AiOutlinePushpin } from 'react-icons/ai'
import { DataView, DrawerComponent, InternalHeader, Stats } from '../../../Components'

import SingleEnquiry from './SingleEnquiry'
import {
	getAllEnquiries,
	getAllEnquiriesForKanban,
	getEnquiryById,
	getEnquiryStatsByEnquiryStage
} from '../../../Services/Sales/enquiries'
import { editEnquiry, resetEnquiry } from '../../../Store/Actions/enquiries'
import { getEnquiryStats } from '../../../Services/stats'

import * as Colors from '../../../styles/colors'
import SuggestedSection from '../../../Components/SuggestedSection'
import { pinItem, unpinItem } from '../../../Services/pin'
import { RegisterEnquiryForm } from '../../../Components/Forms'
import accounting from '../../../Controllers/accountingNumbers'
import dateFormat from '../../../Content/dateFormat'
import tableFilter from '../../../Controllers/tableFilter'

const Enquiries = () => {
	const [search, setSearch] = useState('')
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		sortOrder: '',
		sortField: '',
		total: '',
		filters: []
	})
	const dispatch = useDispatch()
	const [addEnquiryDrawer, setAddEnquiryDrawer] = useState(false)
	const enquiriesData = useSelector(state => state.enquiriesData)
	const enquiryStageData = useSelector(state => state.enquiryStageData)
	const refreshComponent = useSelector(state => state.refreshComponent)
	const { token, role } = useSelector(state => state.userSession)
	const { id } = useSelector(state => state.userProfile)
	const [stats, setStats] = useState(null)
	const [stageStats, setStageStats] = useState(null)
	const [suggestedEnquiries, setSuggestedEnquiries] = useState([])
	const [kanbanData, setKanbanData] = useState([])

	const handleSearch = e => {
		setSearch(e.target.value)
	}
	const [pinLoader, setPinLoader] = useState(false)

	const fetchData = async (token, current, pageSize, sortOrder, sortField, query, filters) => {
		try {
			const { data } = await getAllEnquiries(token, current, pageSize, sortOrder, sortField, query, filters || [])
			if (!data.data) {
				dispatch(resetEnquiry([]))
			} else {
				dispatch(resetEnquiry(data.data))
			}
			setPagination({
				current: current + 1,
				pageSize,
				sortOrder,
				sortField,
				total: data.count,
				filters
			})
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const fetchKanbanData = async () => {
		try {
			const { data } = await getAllEnquiriesForKanban(token, search)
			setKanbanData(data.data || [])
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const handleKanbanData = async data => {
		setKanbanData(prev => prev.map(one => (one.id === data.id ? { ...one, enquiry_stage: data.enquiry_stage } : one)))
		const res = await getEnquiryById(token, data.id)
		dispatch(
			editEnquiry({
				id: data.id,
				data: { enquiry_stage: data.enquiry_stage, ...res.data.data }
			})
		)
	}

	const fetchSuggestedEnquiries = async () => {
		try {
			// fetch enquiries with submission date in one year, sorted by the closest to today's date
			const { data } = await getAllEnquiries(token, 0, 3, 'asc', 'submission_date', id, [], {
				start: dayjs().unix(),
				end: dayjs().add(1, 'y').unix()
			})
			if (data.data) {
				setSuggestedEnquiries(data)
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const handlePin = async (id, pin) => {
		setPinLoader(() => ({ [id]: true }))
		try {
			pin ? await pinItem(token, 'enquiry', id) : await unpinItem(token, 'enquiry', id)
			const res = await getEnquiryById(token, id)
			dispatch(
				editEnquiry({
					id,
					data: { ...res.data.data, user_pinned: pin }
				})
			)
			setPinLoader(false)
		} catch (error) {
			setPinLoader(false)
			message.error(error?.response?.data?.message || 'The enquiry could not be pinned. Please try again.')
		}
	}

	useEffect(() => {
		fetchData(token, 0, pagination.pageSize, pagination.sortOrder, pagination.sortField, search, pagination.filters)
		// eslint-disable-next-line
	}, [search, refreshComponent])

	useEffect(() => {
		fetchSuggestedEnquiries()
		fetchKanbanData()
		// eslint-disable-next-line
	}, [enquiriesData, search])

	const columns = [
		{
			title: '',
			key: 'pinned',
			render: (text, record) => (
				<Space size='middle'>
					{pinLoader[record.id] ? (
						<Spin />
					) : record?.user_pinned ? (
						<AiFillPushpin
							color={Colors.BLUE}
							fontSize={20}
							onClick={e => {
								e.stopPropagation()
								handlePin(record.id, false)
							}}
						/>
					) : (
						<Tooltip title='Pin this enquiry' placement='topRight'>
							<AiOutlinePushpin
								fontSize={20}
								onClick={e => {
									e.stopPropagation()
									handlePin(record.id, true)
								}}
							/>
						</Tooltip>
					)}
				</Space>
			)
		},
		{
			title: 'Enquiry Number',
			dataIndex: 'enquiry_number',
			key: 'enquiry_number'
		},
		{
			title: 'Job Description',
			dataIndex: 'job_description',
			key: 'job_description',
			sorter: true
		},
		{
			title: 'Account',
			dataIndex: 'lead_account_name',
			key: 'account'
			// ...getColumnSearchProps('Account')
		},
		{
			title: 'Enquiry Owner',
			dataIndex: 'enquiry_owner_name',
			key: 'enquiry_owner',
			...tableFilter('Owner', id, setSearch)
		},
		{
			title: 'Enquiry Stage',
			dataIndex: 'enquiry_stage',
			key: 'enquiry_stage',
			filters: enquiryStageData.map(one => ({ value: one, text: one })),
			filterMultiple: true
		},
		{
			title: 'Submission Date',
			key: 'submission_date',
			sorter: true,
			render: (text, record) => <span> {dayjs.unix(record.submission_date).format(dateFormat)} </span>
		}
	]

	const getStats = async () => {
		try {
			const { data } = await getEnquiryStats(token)
			setStats(data)
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	// stats based on enquiry stages
	const getStageBasedStats = async () => {
		try {
			const { data } = await getEnquiryStatsByEnquiryStage(token)
			setStageStats(data.data)
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	useEffect(() => {
		getStats()
		getStageBasedStats()
		// eslint-disable-next-line
	}, [enquiriesData])

	const enquiryStats = {
		title: 'Enquiries',
		total: stats?.count,
		data: [
			{
				name: 'Registration',
				color: Colors.RED,
				number: stats?.data?.registration
			},
			{
				name: 'Scope & Evaluation',
				color: Colors.YELLOW,
				number: stats?.data?.scope_and_evaluation
			},
			{
				name: 'Costing',
				color: Colors.BLUE,
				number: stats?.data?.costing
			},
			{
				name: 'Submission',
				color: Colors.GREEN,
				number: stats?.data?.submission
			}
		]
	}

	const firmArray = [
		'Expression of Interest',
		'Technical Only',
		'Technical & Commercial',
		'Technical Evaluation',
		'Negotiation / Review'
	]

	const stageStatsObject = [
		{
			title: 'Budgetary',
			value: stageStats && stageStats.filter(one => one.s === 'Budgetary')[0]?.c
		},
		{
			title: 'Firm',
			value:
				stageStats &&
				stageStats
					.filter(one => firmArray.indexOf(one.s) !== -1)
					?.map(one => one?.c)
					?.reduce((a, b) => a + b, 0)
		},
		{
			title: 'Won',
			value: stageStats && stageStats.filter(one => one.s === 'Closed (Won)')[0]?.c
		},
		{
			title: 'Lost',
			value: stageStats && stageStats.filter(one => one.s === 'Closed (Lost)')[0]?.c
		},
		{
			title: 'Cancelled',
			value: stageStats && stageStats.filter(one => one.s === 'Cancelled')[0]?.c
		}
	]
	const FirmContent = () =>
		stageStats
			.filter(one => firmArray.indexOf(one.s) !== -1)
			.map(one => (
				<div className='grid grid-cols-2 gap-10'>
					<div className='text-bell-gray'> {one?.s}</div>
					<div className='font-medium'> $ {accounting(one?.c)}</div>
				</div>
			))

	const handleChange = (page, filters, sorter) => {
		const enquiryStageFilter = filters.enquiry_stage ? filters.enquiry_stage : undefined
		const sortOrder = {
			ascend: 'asc',
			descend: 'desc'
		}
		fetchData(
			token,
			page.current - 1,
			page.pageSize,
			sortOrder[sorter.order] || pagination.sortOrder,
			sorter.columnKey || pagination.sortField,
			search,
			enquiryStageFilter
		)
	}
	const location = useLocation()

	return !location.search ? (
		<div className='bg-bell-background'>
			<InternalHeader title='Sales' selected='Enquiries' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Enquiries </div>
				<div>
					<DrawerComponent
						form={
							<RegisterEnquiryForm
								handleClose={() => {
									setAddEnquiryDrawer(false)
								}}
							/>
						}
						visible={addEnquiryDrawer}
						drawerWidth={900}
						onOpen={() => setAddEnquiryDrawer(true)}
						onClose={() => setAddEnquiryDrawer(false)}
						buttonTitle='Add Enquiry'
					/>
				</div>
			</div>
			<div className='grid grid-cols-4 px-10'>
				<div className='col-span-3 grid grid-cols-3 place-items-center'>
					<div className='col-span-1'>
						<Stats data={enquiryStats} />
					</div>
					{stageStats && role !== 'Employee' && (
						<div className='col-span-2 flex flex-wrap gap-5 items-center px-4'>
							{stageStatsObject.map(item =>
								item.title === 'Firm' ? (
									<div className='px-2'>
										<Statistic
											title={
												<div className='text-bell-gray flex items-center space-x-2'>
													<div> Firm</div>
													<div className='cursor-pointer'>
														<Popover content={<FirmContent />} title='Firm'>
															<AiOutlineInfoCircle fontSize={18} />
														</Popover>
													</div>
												</div>
											}
											value={item?.value}
											precision={2}
											prefix='$'
										/>
									</div>
								) : (
									<div className='px-2'>
										<Statistic title={item?.title} value={item?.value} precision={2} prefix='$' />
									</div>
								)
							)}
						</div>
					)}
				</div>
				{suggestedEnquiries?.data?.length > 0 && (
					<div>
						<SuggestedSection data={suggestedEnquiries} />
					</div>
				)}
			</div>
			<div>
				<DataView
					columns={columns}
					data={enquiriesData.sort((a, b) => {
						if (a.user_pinned && !b.user_pinned) {
							return -1
						}
						if (!a.user_pinned && b.user_pinned) {
							return 1
						}
						return 0
					})}
					pagination={pagination}
					handleChange={handleChange}
					handleSearch={handleSearch}
					rowClickable
					type='enquiries'
					showKanban
					kanbanData={kanbanData}
					handleKanbanData={handleKanbanData}
				/>
			</div>
		</div>
	) : (
		<SingleEnquiry />
	)
}

export default Enquiries
