import { ADD_BG, EDIT_BG, RESET_BG } from '../types'

export const addBG = payload => ({
	type: ADD_BG,
	payload
})

export const editBG = payload => ({
	type: EDIT_BG,
	payload
})

export const resetBG = payload => ({
	type: RESET_BG,
	payload
})
