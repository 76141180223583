/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react'
import { Button, Divider, Empty, Tabs, Tag } from 'antd'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { FiExternalLink } from 'react-icons/fi'
import { CgMenuGridO } from 'react-icons/cg'
import Greetings from '../Content/greetings'
import StatisticCard from '../Components/Home/StatisticCard'
import { getDashboardStats } from '../Services/stats'
import Spinner from '../Components/Spinner'
import accounting from '../Controllers/accountingNumbers'
import { whatsNew } from '../Content/news'
import dateFormat from '../Content/dateFormat'
import { taskPriorityColor, taskStatusColor } from '../Content/tasks'
import { DrawerComponent } from '../Components'
import SingleExpense from './Expenses/SingleExpense'
import InvoiceExport from '../Components/Internal/InvoiceExport'
import { moduleItems } from '../Utils/PrivateRoute'

const InformationBanner = ({ title = 'News Feed', description, sideButton, children }) => (
	<div className='bg-white rounded-xl p-5'>
		<div className='flex justify-between'>
			<div>
				<div className='text-lg font-medium py-1'>{title}</div>
				<div className='text-xs text-bell-gray'>{description}</div>
			</div>
			<div>{sideButton}</div>
		</div>
		<div>{children}</div>
	</div>
)
const Home = () => {
	const { first_name, last_name } = useSelector(state => state.userProfile)
	const { token, role } = useSelector(state => state.userSession)
	const [data, setData] = useState(null)

	const [error, setError] = useState(false)
	const [viewDrawer, setViewDrawer] = useState(false)

	const fetchDashboardData = async () => {
		try {
			const res = await getDashboardStats(token)
			setData(res.data.data)
		} catch {
			setError(404)
		}
	}

	useEffect(() => {
		fetchDashboardData()
	}, [viewDrawer])

	const approvalsArray = data &&
		data.dashboard_data &&
		data.dashboard_data.pending_approvals && [
			...data.dashboard_data.pending_approvals?.expenses?.map(q =>
				q
					? {
							title: q.title,
							link: '/expenses',
							description: q.date && `Added on ${dayjs.unix(q.date).format(dateFormat)}`,
							tag: 'Expenses',
							id: q.id
					  }
					: null
			),
			...data.dashboard_data.pending_approvals?.invoices?.map(q =>
				q
					? {
							title: `${q.contract_number} - ${q.subject}`,
							link: '/contracts/invoices',
							description: q.date && `Due by ${dayjs.unix(q.date).format(dateFormat)}`,
							tag: 'Invoices',
							id: q.id
					  }
					: null
			)
		]

	const stats = data
		? {
				Overview: {
					'Expenses To Be Approved': {
						value: data.dashboard_data?.expenses_to_be_approved,
						description: 'Expenses that are pending and need your approval.'
					},
					'Invoices To Be Approved': {
						value: data.dashboard_data?.invoices_to_be_approved,
						description: 'Invoices that are pending and need your approval.'
					},
					'Leaves To Be Approved': {
						value: 'NA',
						description: 'This stat is currently unavailable.'
					},
					'Critical Tasks': {
						value: data.task_stats?.critical_tasks,
						description: 'Critical tasks that are assigned to you.',
						urgent: true
					}
				},
				Finance: {
					'Expenses To Be Approved': {
						value: data.dashboard_data?.expenses_to_be_approved,
						description: 'Expenses that are pending and need your approval.',
						urgent: true
					},
					'Total Disbursement': {
						value: accounting(data.finance_stats?.total_disbursement_issued),
						description: 'Your total disbursement'
					},
					'Total Expenses': {
						value: accounting(data.finance_stats?.total_expense),
						description: 'Your total expenses'
					},
					'Approved Expenses': {
						value: accounting(data.finance_stats?.total_expense_approved),
						description: 'Total amount of approved expenses'
					},
					'Closed Expenses': {
						value: accounting(data.finance_stats?.total_expense_close_out),
						description: 'Total amount of expenses that are disbursed and closed'
					}
				},
				Sales: {
					'Active Leads': {
						value: data.sales_stats?.active_leads,
						description: 'Your Leads that are currently active.'
					},
					'Active Opportunities': {
						value: data.sales_stats?.active_opportunity,
						description: 'Your Opportunities that are currently active.'
					},
					'Urgent Enquiries': {
						value: data.sales_stats?.enquries_approaching,
						description: 'Enquiries that are approaching or have passed the submission date.',
						urgent: true
					},
					'Urgent Opportunities': {
						value: data.sales_stats?.opportunities_approaching,
						description: 'Opportunities that are approaching or have passed the closing date.',
						urgent: true
					}
				},
				Tasks: {
					'Critical Tasks': {
						value: data.task_stats?.critical_tasks,
						description: 'Critical tasks that are assigned to you.',
						urgent: true
					},
					'Urgent Tasks': {
						value: data.task_stats?.tasks_approaching,
						description: 'Your tasks that are approaching or have passed the deadline date.',
						urgent: true
					},
					'Tasks Not Started': {
						value: data.task_stats?.not_started,
						description: 'Tasks not started so far.'
					}
				}
		  }
		: null

	const otherContent = data
		? {
				Overview: (
					<div className='grid grid-cols-1 md:grid-cols-4 gap-5'>
						<div className='col-span-1 md:col-span-2'>
							<InformationBanner
								title='Upcoming Tasks'
								description='Critical Tasks that need your attention'
								sideButton={
									<Link to='/tasks' className='flex items-center'>
										<Button type='link'>Go to Tasks</Button>
										<FiExternalLink />
									</Link>
								}
							>
								{data?.dashboard_data?.upcoming_tasks?.length ? (
									[...data?.dashboard_data?.upcoming_tasks]?.map(a => (
										<div className='py-4 border-b-[0.5px] last:border-0 flex flex-col gap-2'>
											<div>
												<Tag color={taskPriorityColor[a.priority]}>{a.priority}</Tag>
												<Tag color={taskStatusColor[a.status]}>{a.status}</Tag>
											</div>
											<div className='text-sm font-medium flex space-x-2 items-center'>
												<span>{a.name}</span>
											</div>
											<div className='text-xs text-bell-gray'>{a.description}</div>
											{a.end_date && (
												<div className='text-xs text-bell-gray'>Due by {dayjs.unix(a.end_date).format(dateFormat)}</div>
											)}
										</div>
									))
								) : (
									<Empty description='No critical tasks to follow up' className='py-8' />
								)}
							</InformationBanner>
						</div>
						<div className='col-start-1 md:col-start-3'>
							<InformationBanner title='Pending Approvals'>
								{approvalsArray?.length > 0 ? (
									approvalsArray?.map(a => (
										<div className='py-4 border-b-[0.5px] last:border-0 flex flex-col gap-2'>
											<div>
												<Tag color={a.tag === 'Expenses' ? 'red' : 'blue'}>{a.tag}</Tag>
											</div>
											<div className='text-sm font-medium flex space-x-2 items-center'>
												<span>{a.title}</span>
												{a.tag === 'Expenses' ? (
													<DrawerComponent
														visible={viewDrawer[a.id]}
														onOpen={() => setViewDrawer({ [a.id]: true })}
														onClose={() => setViewDrawer(false)}
														drawerTitle='Expense Details'
														buttonTitle={<FiExternalLink />}
														drawerWidth={900}
														buttonType='link'
														form={
															<SingleExpense
																id={a.id}
																closeDrawer={() => setViewDrawer(false)}
																currentView='Requested'
															/>
														}
													/>
												) : (
													<DrawerComponent
														form={<InvoiceExport invoiceId={a.id} closeDrawer={() => setViewDrawer(false)} />}
														visible={viewDrawer[a.id]}
														onOpen={() => setViewDrawer({ [a.id]: true })}
														onClose={() => setViewDrawer(false)}
														drawerTitle='Invoice Details'
														buttonTitle={<FiExternalLink />}
														buttonType='link'
														drawerWidth={1100}
													/>
												)}
											</div>
											<div className='text-xs text-bell-gray'>{a.description}</div>
										</div>
									))
								) : (
									<Empty description='Yay! No pending approvals.' className='py-10' />
								)}
							</InformationBanner>
						</div>
						<div className='col-start-1 md:col-start-4'>
							<InformationBanner title='Paradigm Update' description={`Version ${whatsNew.version} | ${whatsNew.date}`}>
								{whatsNew.content.map(a => (
									<div className='py-4 border-b-[0.5px] last:border-0'>
										<div className='text-sm font-medium flex space-x-2 items-center'>
											<span>{a.title}</span>
											{a.tagName && (
												<Tag
													bordered={false}
													color={a.tagName === 'Important' ? 'red' : 'blue'}
													className='text-xs font-normal'
												>
													{a.tagName}
												</Tag>
											)}
											{a.link && (
												<Link to={a.link}>
													<FiExternalLink />
												</Link>
											)}
										</div>
										<div className='text-xs text-bell-gray'>{a.description}</div>
									</div>
								))}
							</InformationBanner>
						</div>
					</div>
				)
		  }
		: null

	const k = moduleItems(role).map(a => a)

	return (
		<div className='border border-bell-background'>
			<div className='m-5 md:m-10 space-y-10'>
				<div className='text-xl font-light'>
					<Greetings />,{' '}
					<span className='font-medium'>
						{first_name} {last_name}
					</span>
				</div>
				{!error && data ? (
					<Tabs
						defaultActiveKey={0}
						centered
						items={[
							{
								label: <CgMenuGridO />,
								key: 'menu',
								children: (
									<section className='flex flex-wrap justify-center'>
										{k.map((a, i) => (
											<Link to={a.url} key={i}>
												<div className='group w-24 flex-shrink-0 flex-grow-0 flex flex-col items-center justify-between py-2 rounded-md cursor-pointer'>
													<div className='flex items-center flex-col space-y-2'>
														<div className='text-2xl group-hover:-translate-y-1 duration-200 transition-all'>
															{' '}
															{a.icon}
														</div>
														<div className='text-bell-text group-hover:text-bell-blue border-solid border-0 border-b-0 group-hover:border-b border-bell-blue'>
															{' '}
															{a.label}
														</div>
													</div>
												</div>
											</Link>
										))}
									</section>
								)
							},
							...Object.keys(stats).map((a, i) => ({
								label: a,
								key: i,
								children: (
									<section className='flex flex-col gap-5'>
										<div className='grid grid-cols-1 md:grid-cols-4 gap-5'>
											{Object.keys(stats[a]).map(q => (
												<StatisticCard
													title={q}
													stat={stats[a][q].value}
													description={stats[a][q].description}
													urgent={stats[a][q].urgent}
												/>
											))}
										</div>
										<div>{otherContent[a]}</div>
									</section>
								)
							}))
						]}
					/>
				) : (
					<Spinner text='Loading..' errorCode={error} />
				)}
			</div>
			<section className='pb-4 px-10 text-gray-400'>
				<Divider />
				<div className='text-xs text-bell-gray text-center'>
					We appreciate any suggestions you may have to improve our product and make it more useful. Please feel free to{' '}
					<a
						href='https://teams.microsoft.com/l/chat/0/0?users=junaid.anjum@bell-energy.net'
						target='_blank'
						className='underline hover:underline'
						rel='noreferrer'
					>
						contact us directly on Microsoft Teams.
					</a>
				</div>
			</section>
		</div>
	)
}

export default Home
