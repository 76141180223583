import {
	ADD_ACTIVE_AND_LOST_REPORT,
	ADD_CLIENT_ENGAGEMENT_REPORT,
	ADD_ENQUIRY_REPORT_DATA,
	ADD_REPORT_COUNTRY,
	ADD_REPORT_FILTER,
	ADD_REPORT_USER,
	ADD_TIMESHEET_REPORT
} from '../types'

export const addReportUser = payload => ({
	type: ADD_REPORT_USER,
	payload
})

export const addReportFilter = payload => ({
	type: ADD_REPORT_FILTER,
	payload
})

export const addReportCountry = payload => ({
	type: ADD_REPORT_COUNTRY,
	payload
})

export const addEnquiryReportData = payload => ({
	type: ADD_ENQUIRY_REPORT_DATA,
	payload
})

export const addActiveAndLostReportStatus = payload => ({
	type: ADD_ACTIVE_AND_LOST_REPORT,
	payload
})

export const addClientEngagementReport = payload => ({
	type: ADD_CLIENT_ENGAGEMENT_REPORT,
	payload
})

export const addTimesheetReport = payload => ({
	type: ADD_TIMESHEET_REPORT,
	payload
})
