/* eslint-disable camelcase */
// eslint-disable-next-line import/prefer-default-export
export const form_options = {
	type: ['Existing Business', 'New Business'],
	stages: ['Prequalifications', 'Needs Analysis', 'Value Proposition', 'Identify Decision Makers']
}

export const opportunity_stage_probability = [
	{
		name: 'Prequalifications',
		percentage: 10
	},
	{
		name: 'Needs Analysis',
		percentage: 10
	},
	{
		name: 'Value Proposition',
		percentage: 10
	},
	{
		name: 'Identify Decision Makers',
		percentage: 13
	}
]
