import axios from 'axios'
import { URL } from '../../config'

export const getAllContacts = (
	token,
	offset = 0,
	limit = 20,
	sort_order = 'desc',
	sort_by = 'created_at',
	query = ''
) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/contact/all?offset=${offset}&limit=${limit}&sort_order=${sort_order}&sort_by=${sort_by}&query=${query}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const createContact = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'post',
				url: `${URL}/contact/add`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const updateContact = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'put',
				url: `${URL}/contact/edit`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getContactById = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/contact/get/${id}`,

				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
