import { ADD_TIME, DELETE_TIME, EDIT_TIME, RESET_TIME, CURRENT_TIME } from '../types'

const timeSheets = []
const currentEntry = null

// eslint-disable-next-line import/prefer-default-export
const timeSheetsData = (state = timeSheets, { type, payload }) => {
	switch (type) {
		case ADD_TIME:
			return [payload, ...state]
		case EDIT_TIME:
			return state.map(x => {
				if (x.id === payload.id) {
					return payload.data
				}
				return x
			})
		case DELETE_TIME:
			return state.filter(acc => acc.id !== payload)
		case RESET_TIME:
			return payload
		default:
			return state
	}
}

const currentTimeEntry = (state = currentEntry, { type, payload }) => {
	switch (type) {
		case CURRENT_TIME:
			return payload
		default:
			return state
	}
}

export { timeSheetsData, currentTimeEntry }
