import { message, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getLeadsByAccountID } from '../../../Services/Sales/account'

const LeadsView = ({ id }) => {
	const { token } = useSelector(state => state.userSession)
	const [loading, setLoading] = useState(true)
	const [leadData, setLeadData] = useState([])
	// GET ENQUIRIES BY ACCOUNT ID
	const fetchData = async () => {
		try {
			const { data } = await getLeadsByAccountID(token, id)
			setLeadData(data?.data)
			setLoading(false)
		} catch (error) {
			setLeadData([])
			setLoading(false)
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const enquiryColumn = [
		{
			title: 'Lead Name',
			key: 'account_name',
			render: (_, record) => (
				<div>
					{record?.first_name} {record?.last_name}
				</div>
			)
		},
		{
			title: 'Lead Status',
			key: 'lead_status',
			dataIndex: 'lead_status'
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<div className='z-10'>
					<Space size='middle'>
						{/* eslint-disable-next-line */}
						<Link to={`/sales/leads?id=${record.id}`}>View Lead</Link>
					</Space>
				</div>
			)
		}
	]

	// FETCH DATA ON PAGE LOAD
	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, [])
	return <Table columns={enquiryColumn} dataSource={leadData} loading={loading} />
}

export default LeadsView
