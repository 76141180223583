import {
	ADD_LEAD,
	CLOSED_LEAD,
	DELETE_LEAD,
	EDIT_LEAD,
	NURTURING_LEAD,
	RESET_LEAD,
	SINGLE_LEAD,
	UPDATE_STAGE,
	WORKING_LEAD,
	UPDATE_SINGLE_LEAD
} from '../types'

export const addLead = payload => ({
	type: ADD_LEAD,
	payload
})

export const editLead = payload => ({
	type: EDIT_LEAD,
	payload
})

export const deleteLead = payload => ({
	type: DELETE_LEAD,
	payload
})

export const resetLead = payload => ({
	type: RESET_LEAD,
	payload
})

export const updateStage = payload => ({
	type: UPDATE_STAGE,
	payload
})

export const addSingleLead = payload => ({
	type: SINGLE_LEAD,
	payload
})
export const addWorkingLead = payload => ({
	type: WORKING_LEAD,
	payload
})

export const addNurturingLead = payload => ({
	type: NURTURING_LEAD,
	payload
})
export const addClosedLead = payload => ({
	type: CLOSED_LEAD,
	payload
})

export const updateSingleLead = payload => ({
	type: UPDATE_SINGLE_LEAD,
	payload
})
