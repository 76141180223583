/* eslint-disable no-shadow */
import React, { useState } from 'react'
import { Form, Input, Button, message } from 'antd'
import { useSelector } from 'react-redux'
import { formItemLayout, tailFormItemLayout } from '../../Controllers/form'
import { createOpportunitySource } from '../../Services/Sales/opportunity'

const OpportunitiesSourceForm = ({ handleClose, handleSourceValues }) => {
	const { token } = useSelector(state => state.userSession)
	const [loading, setLoading] = useState(false)
	const [form] = Form.useForm()

	const onFinish = async values => {
		setLoading(true)
		try {
			const { data } = await createOpportunitySource(token, values)
			handleSourceValues({ id: data.data.id, ...values })
			setLoading(false)
			message.success('Opportunity source added successfully!')
			form.resetFields()
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error.response?.data?.message || 'Something went wrong!')
		}
	}

	return (
		<div className='space-y-20'>
			<Form name='control-hooks' form={form} onFinish={onFinish} {...formItemLayout} scrollToFirstError>
				<Form.Item
					label='Source Name'
					name='name'
					rules={[
						{
							required: true,
							message: 'Please input opportunity source name!'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item label='Webpage' name='webpage'>
					<Input />
				</Form.Item>
				<Form.Item label='Username' name='username'>
					<Input />
				</Form.Item>
				<Form.Item label='Password' name='password'>
					<Input />
				</Form.Item>
				<Form.Item label='Remarks' name='remarks'>
					<Input.TextArea rows={4} />
				</Form.Item>
				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}

export default OpportunitiesSourceForm
