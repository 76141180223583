import React, { useEffect, useState } from 'react'
import { AiOutlineDown, AiOutlineRight } from 'react-icons/ai'
import _ from 'lodash'
import ScopeAndEvaluation from './ScopeAndEvaluation'
import Costing from './Costing'
import Submission from './Submission'
import Registration from './Registration'

const EnquiryStage = ({ stages, data, revisionData, accessType }) => {
	const showButton = accessType === 'Public'
	const revision = revisionData || data.current_revision_data
	const currentStage = revisionData.revision_stage || data?.current_revision_data?.revision_stage
	const isCurrent = () => {
		if (!revisionData) {
			return true
		}
		if (revisionData?.id === data?.current_revision_data?.id) {
			return true
		}
		return false
	}
	const [selectedStage, setSelectedStage] = useState('')

	useEffect(() => {
		setSelectedStage(currentStage)
	}, [currentStage])

	const [displayDescription, setDisplayDescription] = useState(false)
	const selectStage = w => {
		setSelectedStage(w)
	}

	const stageStyle = (stage, i) => {
		if (stage === currentStage) {
			return 'h-12 w-48 grid place-items-center border-solid border border-l-8 border-bell-green rounded-sm bg-white cursor-pointer'
		}
		if (stage === selectedStage) {
			return 'h-12 w-48 grid place-items-center border-solid border border-bell-blue rounded-sm bg-white cursor-pointer'
		}
		if (stages.indexOf(currentStage) > i) {
			return 'h-12 w-48 grid place-items-center border-solid border  border-bell-lightgreen rounded-sm bg-white cursor-pointer '
		}
		return 'h-12 w-48 grid place-items-center border-solid border-gray-200 rounded-sm bg-white cursor-pointer'
	}

	const getDetails = () => {
		switch (selectedStage) {
			case 'Registration':
				return (
					<Registration
						data={_.omit(data, [
							'current_revision_data',
							'revisions',
							'pinned',
							'current_revision_id',
							'enquiry_owner_id',
							'id',
							'attachments',
							'contact_person',
							'converted_from'
						])}
						isCurrent={isCurrent()}
					/>
				)
			case 'Scope and Evaluation':
				return (
					<ScopeAndEvaluation
						data={_.omit(revision.scope_and_evaluation, [
							'attachments',
							'decision_makers_procurement',
							'decision_makers_registration',
							'decision_makers_technical',
							// 'proposal_engineer',
							'competitors',
							'products_services'
						])}
						isFilled={
							data?.current_revision_data?.revision_number === 1
								? stages.indexOf(currentStage) >= stages.indexOf('Scope and Evaluation')
								: true
						}
						isCurrent={isCurrent()}
						currentStage={currentStage}
						showButton={showButton}
					/>
				)
			case 'Costing':
				return (
					<Costing
						data={_.omit(revision.costing, ['attachments'])}
						submission_schema={revision?.submission?.costing_schema}
						isFilled={
							data?.current_revision_data?.revision_number === 1
								? stages.indexOf(currentStage) >= stages.indexOf('Costing')
								: true
						}
						isCurrent={isCurrent()}
						currentStage={currentStage}
						showButton={showButton}
					/>
				)
			case 'Submission':
				return (
					<Submission
						data={revision.submission}
						isFilled={stages.indexOf(currentStage) >= stages.indexOf('Submission')}
						isCurrent={isCurrent()}
						showButton={showButton}
						otherData={data}
					/>
				)
			default:
				return null
		}
	}

	return (
		<div>
			<div className='w-full flex justify-between'>
				<div className='flex'>
					<div
						className='h-12 w-12 grid place-items-center border rounded-sm bg-white cursor-pointer'
						onClick={() => setDisplayDescription(!displayDescription)}
					>
						{!displayDescription ? <AiOutlineRight /> : <AiOutlineDown />}
					</div>
					<div className='flex space-x-4 items-center'>
						{stages.map((stage, i) => (
							<div
								className={stageStyle(stage, i)}
								onClick={() => {
									selectStage(stage)
									setDisplayDescription(true)
								}}
							>
								{stage}
							</div>
						))}
					</div>
				</div>
			</div>
			{displayDescription ? <div className='bg-white p-5 mt-5'>{getDetails()}</div> : null}
		</div>
	)
}

export default EnquiryStage
