/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import { PrinterOutlined } from '@ant-design/icons'
import {
	Alert,
	Button,
	DatePicker,
	Divider,
	Form,
	Input,
	message,
	Modal,
	Radio,
	Result,
	Steps,
	Table,
	Tag,
	Tooltip,
	Typography
} from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineCopy } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactToPrint from 'react-to-print'
import { MINIO } from '../../config'
import dateFormat from '../../Content/dateFormat'
import accounting from '../../Controllers/accountingNumbers'
import { fetchUserList } from '../../Controllers/fetchLists'
import { formItemLayout } from '../../Controllers/form'
import {
	deleteInvoiceById,
	generateInvoiceNumber,
	getInvoiceById,
	updateInvoice
} from '../../Services/Contracts/invoices'
import SelectDebounce from '../SelectDebounce'
import Overview from '../Views/Information/Overview'
import { DrawerComponent, Spinner, DeleteItem } from '../index'
import { InvoiceForm } from '../Forms'
import * as Colors from '../../styles/colors'
import renderShareTag from '../../Controllers/shareTag'
import MINIOLink from '../../Controllers/minioLink'

const { Text } = Typography
const ComponentToPrint = React.forwardRef(({ data }, ref) => {
	const displayArray = [
		{
			name: 'Invoice Number',
			value: data?.invoice_number && (
				<div className='font-semibold text-bell-text flex items-center'>
					{data?.invoice_number}
					<span
						className='text-bell-gray pl-4 cursor-pointer print:hidden block'
						onClick={() => {
							navigator.clipboard.writeText(data.invoice_number)
							message.success('Copied!')
						}}
					>
						<AiOutlineCopy />
					</span>
				</div>
			)
		},
		{
			name: 'Invoice Date',
			value: dayjs.unix(data.invoice_date).format(dateFormat)
		},
		{
			name: 'Payment Terms',
			value: data?.payment_terms
		},
		{
			name: 'Work Order Ref.',
			value: data?.contract_data?.po_number || null
		}
	]
	return (
		<div ref={ref}>
			<div className='p-4 bg-white rounded-lg shadow-md print:p-10 print:shadow-none text-xs'>
				{/* Entity Details */}
				<div className='grid grid-cols-4'>
					<div className='col-span-3 items-center'>
						<div className='text-sm font-bold'>{data?.entity_data?.name}</div>
						<div> {data?.entity_data?.address} </div>
						<div> {data?.entity_data?.country} </div>
						<div className='grid grid-cols-2'>
							{[
								{
									title: 'Tel',
									value: data?.entity_data?.tel && (
										<a href={`tel:${data?.entity_data?.tel}`}>{data?.entity_data?.tel}</a>
									)
								},
								{
									title: 'Fax',
									value: data?.entity_data?.fax && (
										<a href={`tel:${data?.entity_data?.fax}`}>{data?.entity_data?.fax}</a>
									)
								},
								{
									title: 'Email',
									value: data?.entity_data?.email && (
										<a href={`mailto:${data?.entity_data?.email}`}>{data?.entity_data?.email}</a>
									)
								},
								{
									title: 'Website',
									value: data?.entity_data?.website && (
										<a href={data?.entity_data?.website} target='_blank' rel='noreferrer'>
											{data?.entity_data?.website}
										</a>
									)
								},
								{
									title: 'TRN',
									value: data?.entity_data?.trn
								}
							]
								.filter(one => one.value)
								.map(one => (
									<div className='text-bell-gray flex'>
										<div className='w-14'>{one.title}</div>
										<div className='text-bell-text'>{one.value}</div>
									</div>
								))}
						</div>
					</div>
					{data?.entity_data?.logo_file && (
						<div className='col-span-1 grid place-items-center'>
							<img
								src={`${MINIO}/${data?.entity_data?.logo_file}`}
								alt='Entity Stamp File'
								className='object-contain w-40'
							/>
						</div>
					)}
				</div>
				<Divider style={{ margin: 5 }} />
				<div className='text-bell-text text-xl font-semibold text-center'> Tax Invoice</div>
				<Divider style={{ margin: 5 }} />
				<div className='grid grid-cols-2 gap-10'>
					<div>
						<div className='text-bell-gray text-center pb-1'>Invoice To</div>
						<div>
							<Link to={`../sales/contacts?id=${data.kind_attention}`} className='text-sm font-medium print:hidden'>
								{data.kind_attention_name}
							</Link>
						</div>
						<div className='text-sm font-medium hidden print:block'>{data.kind_attention_name}</div>
						<div>
							<Link to={`../sales/accounts?id=${data.company_id}`} className='text-sm font-bold print:hidden'>
								{data.company_name}
							</Link>
						</div>
						<div className='text-sm font-bold hidden print:block'>{data.company_name}</div>
						<div>
							<div>{data.address}</div>
							<div>{data.country}</div>
						</div>
						{data?.contract_data?.trn && <div className='text-bell-gray'> TRN: {data?.contract_data?.trn} </div>}
					</div>
					<div>
						<div className='text-bell-gray text-center pb-1'> Terms of Reference</div>
						<div className='space-y-1'>
							{displayArray
								.filter(x => x.value)
								.map((item, i) => (
									<div className='grid grid-cols-7 gap-4' key={i}>
										<div className='capitalize col-span-3 text-bell-gray'>{item.name}</div>
										<div className='col-span-4'> {item.value}</div>
									</div>
								))}
						</div>
					</div>
				</div>
				<Divider style={{ margin: 5 }} />
				<div>
					<div className='text-bell-gray text-center pb-1'> Project Details</div>
					<div>
						<div className='grid grid-cols-5'>
							<div className='col-span-1 text-bell-gray'>Project Name</div>
							<div className='print:hidden col-span-4'>
								{data?.contract_data?.project_number} -&nbsp;
								<Link to={`../contracts/contracts?id=${data?.contract_data?.id}`} className='font-medium pb-1'>
									{data?.contract_data?.project_name}
								</Link>
							</div>
							<div className='font-medium hidden print:block col-span-4'>
								{data?.contract_data?.project_number} - {data?.contract_data?.project_name}
							</div>
						</div>
						{data?.subject && (
							<div className='grid grid-cols-5'>
								<div className='col-span-1 text-bell-gray'>Invoice Subject</div>
								<div className='col-span-4'>{data?.subject}</div>
							</div>
						)}
					</div>
				</div>
				<div className='py-2'>
					<Table
						size='small'
						columns={[
							{ title: 'Description', dataIndex: 'description' },
							{ title: 'Quantity', dataIndex: 'quantity', render: (_, record) => record?.quantity || '-' },
							{
								title: 'Percentage',
								render: (_, record) => (record?.percentage ? `${record.percentage}%` : '-')
							},
							{ title: 'Rate', render: (_, record) => `${accounting(record.rate)}` },
							{ title: 'Net Amount', render: (_, record) => `${accounting(record.amount)}` }
						]}
						dataSource={data?.item_details?.data?.filter(x => x.amount)}
						pagination={false}
						summary={pageData => {
							let totalAmount = 0
							pageData.forEach(({ amount }) => {
								totalAmount += amount
							})
							return (
								<>
									<Table.Summary.Row>
										<Table.Summary.Cell />
										<Table.Summary.Cell />
										<Table.Summary.Cell />
										<Table.Summary.Cell>Total</Table.Summary.Cell>
										<Table.Summary.Cell colSpan={2}>
											<Text type='primary' className='font-normal'>
												USD {accounting(totalAmount)}
											</Text>
										</Table.Summary.Cell>
									</Table.Summary.Row>
									<Table.Summary.Row>
										<Table.Summary.Cell />
										<Table.Summary.Cell />
										<Table.Summary.Cell />
										<Table.Summary.Cell>{data?.item_details?.tax?.tax_name || 'Tax'}</Table.Summary.Cell>
										<Table.Summary.Cell colSpan={2}>
											<Text type='primary' className='font-normal'>
												{data?.item_details?.tax?.tax || 0}%
											</Text>
										</Table.Summary.Cell>
									</Table.Summary.Row>
									<Table.Summary.Row>
										<Table.Summary.Cell />
										<Table.Summary.Cell />
										<Table.Summary.Cell />
										<Table.Summary.Cell>Net Amount</Table.Summary.Cell>
										<Table.Summary.Cell colSpan={2}>
											<Text type='primary' className='font-medium'>
												USD {accounting(totalAmount + (totalAmount * (data?.item_details?.tax?.tax || 0)) / 100)}
											</Text>
										</Table.Summary.Cell>
									</Table.Summary.Row>
								</>
							)
						}}
					/>
				</div>
				<div className='flex justify-between text-xs'>
					<div>
						<div className='text-bell-gray'> Banking Particulars</div>
						<div>
							<div className='font-semibold text-sm'>{data?.bank_account?.account_name}</div>
							<div>
								Account No: <strong>{data?.bank_account?.account_number}</strong>
							</div>
							<div>{data?.bank_account?.bank_name}</div>
							<div>{data?.bank_account?.street_address}</div>
							<div>
								SWIFT Code: <strong>{data?.bank_account?.code}</strong>
							</div>
							<div>
								IBAN: <strong> {data?.bank_account?.iban}</strong>
							</div>
						</div>
					</div>
					{data?.entity_data?.signatory_name && (
						<div className='text-right'>
							<div className='text-bell-gray text-center pb-1'> Authorized Signatory </div>
							<div className='flex space-x-4'>
								{data?.entity_data?.signature_file && (
									<div className='flex flex-row-reverse py-2'>
										<img
											src={`${MINIO}/${data?.entity_data?.signature_file}`}
											className='w-20 object-contain'
											alt='Authorized Signature'
										/>
									</div>
								)}
								{data?.entity_data?.stamp_file && (
									<div className='flex flex-row-reverse py-2'>
										<img
											src={`${MINIO}/${data?.entity_data?.stamp_file}`}
											className='w-20 object-contain'
											alt='Authorized Signature'
										/>
									</div>
								)}
							</div>
							<div className='font-medium text-center'>{data?.entity_data?.signatory_name}</div>
						</div>
					)}
				</div>
			</div>
		</div>
	)
})

const InvoiceExport = ({ handleValues, invoiceId, closeDrawer }) => {
	const { id, role } = useSelector(state => state.userProfile)
	const { token, id: userId } = useSelector(state => state.userSession)
	const [editDrawer, setEditDrawer] = useState(false)
	const [data, setData] = useState(null)
	const [errorCode, setErrorCode] = useState(null)
	const { status, approver_id } = data || { status: null, approver_id: null }

	const accessType = data?.access_specifier
	const shareType = data?.access_type

	const fetchInvoiceData = async () => {
		try {
			const { data } = await getInvoiceById(token, invoiceId)
			if (data?.data?.access_specifier === 'Private') {
				setErrorCode(403)
			} else {
				setData(data.data)
			}
		} catch (error) {
			setErrorCode(error?.response?.data?.status || 401)
			message.error('The invoice could not be loaded.')
		}
	}

	useEffect(() => {
		fetchInvoiceData()
	}, [])

	const statusStep = {
		Draft: {
			number: 0,
			status: 'finish',
			disabled: status !== 'Draft',
			approver_button: id === approver_id || role === 'Admin' ? 'Approve Invoice' : 'Request for Approval'
		},
		Pending: {
			number: 1,
			status: id === approver_id || role === 'Admin' ? 'process' : 'wait',
			approver_button: id === approver_id || role === 'Admin' ? 'Approve Invoice' : 'Approval Pending',
			disabled: id !== approver_id && role !== 'Admin'
		},
		Approved: {
			number: 1,
			status: 'finish',
			approver_button: 'Invoice Approved',
			disabled: id !== approver_id && role !== 'Admin'
		},
		Declined: {
			number: 1,
			status: 'error',
			approver_button: 'Invoice Declined',
			disabled: false
		},
		Prepared: {
			number: 2,
			status: 'finish',
			disabled: status !== 'Approved',
			approver_button: 'Invoice Approved'
		},
		Submitted: {
			number: 3,
			status: 'finish',
			disabled: status !== 'Prepared',
			approver_button: 'Invoice Approved'
		},
		Paid: {
			number: 4,
			status: 'finish',
			approver_button: 'Invoice Approved',
			title: 'Paid'
		},
		Overdue: {
			number: 4,
			status: 'error',
			approver_button: 'Invoice Approved',
			title: 'Overdue'
		},
		Rejected: {
			number: 4,
			status: 'error',
			approver_button: 'Invoice Approved',
			title: 'Rejected'
		}
	}
	const [isModalVisible, setIsModalVisible] = useState(false)
	const [stageValue, setStageValue] = useState('')
	const [loading, setLoading] = useState(false)
	const [invoiceNumber, setInvoiceNumber] = useState(null)
	const [form] = Form.useForm()

	const showModal = step => {
		switch (step) {
			case 1:
				if (id === approver_id || role === 'Admin') {
					setIsModalVisible({ approval: true })
				} else {
					setIsModalVisible({ request: true })
				}
				break
			case 2:
				setIsModalVisible({ prepare: true })
				break
			case 3:
				setIsModalVisible({ submit: true })
				break
			case 4:
				setIsModalVisible({ update: true })
				break
			default:
				break
		}
	}

	const handleStep = async (values, isSubmitted) => {
		const dataObject = {
			...values,
			id: data.id,
			submitted: data.submitted || isSubmitted,
			approver_id: values?.approver_id?.value || data.approver_id,
			payment_date: values.payment_date ? dayjs(values.payment_date).unix() : 0
		}
		try {
			await updateInvoice(token, dataObject)
			const res = await getInvoiceById(token, data.id)
			setData(res.data.data)
			if (handleValues) {
				handleValues(res.data.data)
			}
		} catch (e) {
			message.error('Invoice could not be updated. Please try again! ')
		}
	}

	const handleSubmit = () => {
		handleStep({ status: 'Submitted', submission_date: dayjs().unix() }, true)
		setIsModalVisible(false)
	}

	const handleOk = async () => {
		const values = await form.validateFields()
		if (values?.approver_id) {
			if (values?.approver_id?.value === id) {
				message.error('Obviously, you cannot set yourself as approver!')
				form.resetFields()
			} else {
				handleStep({ ...values, status: 'Pending' }, false)
				message.success('Request for Approval sent!')
				setIsModalVisible(false)
			}
		} else {
			handleStep(values)
			setIsModalVisible(false)
		}
	}

	const generateInvoice = async () => {
		setLoading(true)

		try {
			const res = await generateInvoiceNumber(token, data.id)
			setInvoiceNumber(res.data.data)
			handleStep({ status: 'Prepared', invoice_number: res.data.data }, true)
		} catch (error) {
			message.error('Something wrong happened! Please try again.')
		} finally {
			setLoading(false)
		}
	}

	const handleCancel = () => {
		setIsModalVisible(false)
	}

	const deleteInvoice = async () => {
		try {
			await deleteInvoiceById(token, invoiceId)
			message.success('Item deleted successfully!')
			closeDrawer()
		} catch (error) {
			message.error(error?.response?.data?.message || 'Item could not be deleted. Please try again!')
		}
	}

	const overviewData = {
		notes: data?.notes,
		terms_and_conditions: data?.terms_and_conditions,
		attachment:
			data?.attachments?.length &&
			data.attachments.map(item => (
				<div>
					<MINIOLink item={item} />
				</div>
			))
	}

	const componentRef = useRef()

	const items = data
		? [
				{ title: 'Draft', disabled: statusStep.Draft.disabled || accessType !== 'Public' },
				{
					title: (
						<div>
							<div>{statusStep[status].approver_button}</div>
							<Tooltip title={data?.approval_description} placement='bottom'>
								<div className='text-xs text-ellipsis overflow-hidden w-32'>{data?.approval_description}</div>
							</Tooltip>
						</div>
					),
					disabled: statusStep[status].disabled || statusStep[status].number >= 2 || accessType !== 'Public',
					status: status === 'Draft' && 'process'
				},
				{
					title: 'Prepare For Submission',
					disabled: statusStep.Prepared.disabled || accessType !== 'Public',
					status: status === 'Approved' && 'process'
				},

				{
					title: (
						<div>
							<div>Submit</div>
							{data?.submission_date !== 0 && (
								<Tooltip title='Submission Date' placement='bottom'>
									<div className='text-xs text-ellipsis overflow-hidden w-32'>
										{dayjs.unix(data?.submission_date).format(dateFormat)}
									</div>
								</Tooltip>
							)}
						</div>
					),

					disabled: statusStep.Submitted.disabled || accessType !== 'Public',
					status: status === 'Prepared' && 'process'
				},
				{
					title: (
						<div>
							{statusStep[status].number === 4 ? (
								<div>
									<div>{statusStep[status].title}</div>
									{status === 'Paid' && data?.payment_date !== 0 && (
										<Tooltip title='Date of Payment' placement='bottom'>
											<div className='text-xs text-ellipsis overflow-hidden w-32'>
												{dayjs.unix(data?.payment_date).format(dateFormat)}
											</div>
										</Tooltip>
									)}
									{status === 'Rejected' && data?.rejection_reason && (
										<Tooltip title={`Reason of Rejection: ${data?.rejection_reason}`} placement='bottom'>
											<div className='text-xs text-ellipsis overflow-hidden w-32'>{data?.rejection_reason}</div>
										</Tooltip>
									)}
								</div>
							) : (
								'Update'
							)}
						</div>
					),
					disabled: (status !== 'Submitted' && statusStep[status].number !== 4) || accessType !== 'Public',
					status: status === 'Submitted' && 'process'
				}
		  ]
		: []

	return data ? (
		<div className='bg-bell-background p-5 rounded-lg'>
			<div>
				<div className='w-full'>
					<Steps
						size='small'
						current={statusStep[status]?.number}
						status={statusStep[status]?.status}
						items={items}
						onChange={showModal}
					/>
				</div>

				{/* request for approval modal */}
				<Modal
					title='Request Approval for Submission'
					open={isModalVisible?.request}
					onOk={handleOk}
					onCancel={handleCancel}
				>
					<Form
						name='control-hooks'
						form={form}
						initialValues={{
							approver_id:
								data?.contract_data?.project_manager_name || data?.contract_data?.deputy_name
									? data?.contract_data?.project_manager_name
										? {
												key: data?.contract_data?.project_manager_id,
												value: data?.contract_data?.project_manager_id,
												label: data?.contract_data?.project_manager_name
										  }
										: {
												key: data?.contract_data?.deputy_id,
												value: data?.contract_data?.deputy_id,
												label: data?.contract_data?.deputy_name
										  }
									: null
						}}
					>
						<Form.Item
							label='Approver'
							name='approver_id'
							rules={[{ required: true, message: 'Please select approver!' }]}
						>
							<SelectDebounce showSearch placeholder='Search Users' fetchOptions={e => fetchUserList(e, token)} />
						</Form.Item>
					</Form>
				</Modal>

				{/* approve / decline invoice modal */}
				<Modal
					title='Approval / Decline Invoice'
					open={isModalVisible?.approval}
					onOk={handleOk}
					onCancel={handleCancel}
				>
					<Form name='control-hooks' form={form} {...formItemLayout}>
						<Form.Item
							name='status'
							label='Invoice Status'
							rules={[{ required: true, message: 'Please pick an item!' }]}
						>
							<Radio.Group>
								<Radio.Button value='Approved'>Approved</Radio.Button>
								<Radio.Button value='Declined'>Declined</Radio.Button>
							</Radio.Group>
						</Form.Item>
						<Form.Item label='Description' name='approval_description'>
							<Input.TextArea rows={4} placeholder='Descriptive Information about your decision!' />
						</Form.Item>
					</Form>
				</Modal>

				{/* paid / rejected modal */}
				<Modal title='Update Stage' open={isModalVisible?.update} onOk={handleOk} onCancel={handleCancel}>
					<Form name='control-hooks' form={form} {...formItemLayout}>
						<Form.Item
							name='status'
							label='Invoice Status'
							rules={[{ required: true, message: 'Please pick an item!' }]}
						>
							<Radio.Group onChange={e => setStageValue(e.target.value)}>
								<Radio.Button value='Paid'>Paid</Radio.Button>
								<Radio.Button value='Rejected'>Rejected</Radio.Button>
							</Radio.Group>
						</Form.Item>
						{stageValue === 'Paid' && (
							<Form.Item name='payment_date' label='Date of Payment'>
								<DatePicker disabledDate={currentDate => currentDate > dayjs()} format={dateFormat} />
							</Form.Item>
						)}
						{stageValue === 'Rejected' && (
							<Form.Item name='rejection_reason' label='Reason'>
								<Input.TextArea rows={4} placeholder='Why has the invoice been rejected?' />
							</Form.Item>
						)}
					</Form>
				</Modal>

				{/* submit invoice modal */}
				<Modal
					title='Submit Invoice'
					open={isModalVisible?.submit}
					onOk={handleSubmit}
					onCancel={handleCancel}
					okText='Yes'
				>
					Are you sure that you have submitted this invoice?
				</Modal>

				{/* prepare for submission modal */}
				<Modal
					title='Prepare for Submission'
					open={isModalVisible?.prepare}
					width={600}
					onOk={generateInvoice}
					onCancel={handleCancel}
					okText='Generate Invoice Number'
					cancelText={invoiceNumber ? 'Close' : 'Cancel'}
					confirmLoading={loading}
					okButtonProps={{
						disabled: invoiceNumber
					}}
				>
					{invoiceNumber ? (
						<Result
							status='success'
							title={`The invoice number is ${invoiceNumber}`}
							subTitle='This invoice number will automatically reflect in your invoice details. You can close this window now!'
						/>
					) : (
						<Alert
							message='Generate Invoice Number'
							description='Please note that the invoice cannot be updated further once an invoice number is generated.'
							type='warning'
							showIcon
						/>
					)}
				</Modal>

				<div className='pt-10 pb-4 flex justify-between'>
					<div>
						<Tag color={userId === data?.contract_data?.owner ? Colors.GREEN : Colors.LIGHT_GREY}>
							{renderShareTag({
								shareType,
								isOwner: userId === data?.contract_data?.owner,
								accessType
							})}
						</Tag>
					</div>
					<div className='flex flex-row-reverse'>
						{statusStep[status].number >= 2 && (
							<ReactToPrint
								trigger={() => (
									<Button type='primary' icon={<PrinterOutlined />}>
										Print Invoice
									</Button>
								)}
								content={() => componentRef.current}
							/>
						)}
						{!data.submitted && (
							<>
								<DeleteItem onDelete={deleteInvoice} popTitle='Are you sure to delete this invoice permanently?'>
									Delete Invoice
								</DeleteItem>
								<DrawerComponent
									form={
										<InvoiceForm
											edit
											handleClose={() => setEditDrawer(false)}
											data={data}
											handleValues={e => setData(e)}
										/>
									}
									visible={editDrawer[data?.id]}
									onOpen={() => setEditDrawer({ [data?.id]: true })}
									onClose={() => setEditDrawer(false)}
									buttonTitle='Edit Invoice'
									buttonType='primary'
									drawerWidth={700}
									isHidden={accessType !== 'Public'}
								/>
							</>
						)}
					</div>
				</div>
			</div>
			<ComponentToPrint ref={componentRef} data={data} />
			<div className='pt-10 text-sm'>
				<div className='py-2 text-sm font-medium'>Additional Information</div>
				<Overview data={overviewData} />
			</div>
		</div>
	) : (
		<Spinner text='Loading Invoice' errorCode={errorCode} />
	)
}

export default InvoiceExport
