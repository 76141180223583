import React, { useState } from 'react'
import { Form, Input, Button, Select, message, Divider, Space, InputNumber } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { v4 } from 'uuid'
import { useSelector } from 'react-redux'
import { tailFormItemLayout, formItemLayout } from '../../../Controllers/form'
import { worldCurrencies } from '../../../Content/worldCurrencies'
import { Files } from '../../Views'
import { createEntity, updateEntity, getEntityById } from '../../../Services/Contracts/entity'
import SelectDebounce from '../../SelectDebounce'
import { fetchUserList } from '../../../Controllers/fetchLists'

const { Option } = Select

const InvoiceEntityForm = ({ data, handleClose, handleValues }) => {
	const { token } = useSelector(state => state.userSession)
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)
	const [uploadedFiles, setUploadedFiles] = useState({
		signature_file: data?.signature_file ? [data?.signature_file] : [],
		stamp_file: data?.stamp_file ? [data?.stamp_file] : [],
		logo_file: data?.logo_file ? [data?.logo_file] : []
	})
	const formData = data
		? {
				...data,
				signatory_id: {
					key: data?.signatory_id,
					value: data?.signatory_id,
					label: data?.signatory_name || data?.signatory_id
				}
		  }
		: null

	const dataObject = values => ({
		...values,
		signature_file: uploadedFiles.signature_file[0],
		stamp_file: uploadedFiles.stamp_file[0],
		logo_file: uploadedFiles.logo_file[0],
		bank_accounts: values?.bank_accounts?.length ? values.bank_accounts.map(one => ({ ...one, key: v4() })) : [],
		tax_details: values?.tax_details?.length ? values.tax_details.map(one => ({ ...one, id: v4() })) : [],
		signatory_id: values?.signatory_id?.value
	})

	const onAdd = async values => {
		setLoading(true)
		try {
			const { data } = await createEntity(token, dataObject(values))
			const { id: entityId } = data.data
			const res = await getEntityById(token, entityId)
			handleValues(res.data.data)
			message.success('Entity added successfully!')
			form.resetFields()
			setUploadedFiles({
				signature_file: [],
				stamp_file: [],
				logo_file: []
			})
			handleClose()
		} catch (error) {
			message.error(error.response?.data?.message || 'Something went wrong.')
		} finally {
			setLoading(false)
		}
	}

	const onEdit = async values => {
		setLoading(true)
		try {
			await updateEntity(token, { id: data.id, ...dataObject(values) })
			const res = await getEntityById(token, data.id)
			handleValues(res.data.data)
			message.success('Entity updated successfully!')
			form.resetFields()
			setUploadedFiles({
				signature_file: [],
				stamp_file: [],
				logo_file: []
			})
			handleClose()
		} catch (error) {
			message.error(error.response?.data?.message || 'Something went wrong.')
		} finally {
			setLoading(false)
		}
	}

	const handleFiles = async (currentAttachment, fileName, attachmentType) => {
		setUploadedFiles(prev => ({
			...prev,
			[attachmentType]: [currentAttachment]
		}))
		message.success(`${fileName} uploaded successfully`)
	}
	const handleRemove = async (fileName, attachmentType) => {
		try {
			setUploadedFiles(prev => ({
				...prev,
				[attachmentType]: []
			}))
			message.success(`${fileName} removed successfully`)
		} catch (error) {
			message.error(`Deleting ${fileName} failed. Try again.`)
		}
	}

	return (
		<div className='space-y-20'>
			<Form
				name='control-hooks'
				form={form}
				onFinish={data ? onEdit : onAdd}
				{...formItemLayout}
				scrollToFirstError
				initialValues={formData}
			>
				<div className='py-2 italic text-bell-gray'>Basic Information</div>
				<Form.Item label='Entity Legal Name' name='name' rules={[{ required: true, message: 'Name is required' }]}>
					<Input placeholder='Weißensteiner Transporte' />
				</Form.Item>
				<Form.Item label='Entity Address' name='address'>
					<Input placeholder='Hauptstraße 1, 4581 Rosenau am Hengstpaß, Austria' />
				</Form.Item>
				<Form.Item label='Country' name='country'>
					<Input placeholder='Austria' />
				</Form.Item>
				<Form.Item label='Tel' name='tel'>
					<Input placeholder='+123-456-7890' />
				</Form.Item>
				<Form.Item label='Fax' name='fax'>
					<Input placeholder='+123-456-7890' />
				</Form.Item>
				<Form.Item
					label='Email'
					name='email'
					rules={[
						{
							type: 'email',
							message: 'Please input valid email address.'
						}
					]}
				>
					<Input placeholder='office@example.com' />
				</Form.Item>
				<Form.Item
					label='Website'
					name='website'
					rules={[
						{
							type: 'url',
							message: 'Please input valid web address. Example: https://www.example.com'
						}
					]}
				>
					<Input type='url' placeholder='https://www.example.com' />
				</Form.Item>
				<Form.Item label='Tax Details'>
					<Form.List name='tax_details'>
						{(fields, { add, remove }) => (
							<>
								{fields.map(field => (
									<Space key={field.key} align='baseline'>
										<Form.Item
											{...field}
											label='Name'
											name={[field.name, 'tax_name']}
											rules={[{ required: true, message: 'Name is required!' }]}
										>
											<Input />
										</Form.Item>
										<Form.Item
											{...field}
											label='Tax'
											name={[field.name, 'tax']}
											rules={[{ required: true, message: 'Tax percentage is required!' }]}
										>
											<InputNumber
												placeholder='%'
												formatter={value => `${value}%`}
												parser={value => value.replace('%', '')}
											/>
										</Form.Item>
										<MinusCircleOutlined onClick={() => remove(field.name)} />
									</Space>
								))}
								<Form.Item>
									<Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
										Add Tax
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>
				</Form.Item>
				<Form.Item label='TRN' name='trn'>
					<Input placeholder='Tax Registration Number' />
				</Form.Item>
				<Form.Item label='Entity Logo' name='logo_file'>
					<Files
						attachments={uploadedFiles.logo_file}
						handleFiles={(a, b) => handleFiles(a, b, 'logo_file')}
						handleRemove={a => handleRemove(a, 'logo_file')}
						singleUpload
						disabled={uploadedFiles.logo_file[0]}
					/>
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Signatory Information</div>
				<Form.Item label='Authorized Signatory' name='signatory_id'>
					<SelectDebounce
						showSearch
						placeholder='Search from Employees List'
						fetchOptions={e => fetchUserList(e, token)}
					/>
				</Form.Item>

				<Form.Item label='Electronic Signature File' name='signature_file'>
					<Files
						attachments={uploadedFiles.signature_file}
						handleFiles={(a, b) => handleFiles(a, b, 'signature_file')}
						handleRemove={a => handleRemove(a, 'signature_file')}
						singleUpload
						disabled={uploadedFiles.signature_file[0]}
					/>
				</Form.Item>
				<Form.Item label='Electronic Stamp File' name='stamp_file'>
					<Files
						attachments={uploadedFiles.stamp_file}
						handleFiles={(a, b) => handleFiles(a, b, 'stamp_file')}
						handleRemove={a => handleRemove(a, 'stamp_file')}
						singleUpload
						disabled={uploadedFiles.stamp_file[0]}
					/>
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Banking Details</div>
				<Form.List name='bank_accounts'>
					{(fields, { add, remove }) => (
						<>
							{fields.map(field => (
								<div key={field.key} className='w-full flex'>
									<div style={{ width: '100%' }}>
										<Form.Item
											{...field}
											label='Bank Account Name'
											name={[field.name, 'account_name']}
											rules={[{ required: true, message: 'Name is required!' }]}
										>
											<Input />
										</Form.Item>
										<Form.Item {...field} label='Account Number' name={[field.name, 'account_number']}>
											<Input />
										</Form.Item>
										<Form.Item {...field} label='IBAN' name={[field.name, 'iban']}>
											<Input />
										</Form.Item>
										<Form.Item {...field} label='Bank Name' name={[field.name, 'bank_name']}>
											<Input />
										</Form.Item>
										<Form.Item {...field} label='Street Address' name={[field.name, 'street_address']}>
											<Input />
										</Form.Item>
										<Form.Item {...field} label='SWIFT / IFSC Code' name={[field.name, 'code']}>
											<Input />
										</Form.Item>
										<Form.Item {...field} label='Currency' name={[field.name, 'currency']}>
											<Select placeholder='Select Currency'>
												{worldCurrencies.map(option => (
													<Option value={option.code} key={option.code}>
														<p className='capitalize'>
															{option.name} ({option.symbol})
														</p>
													</Option>
												))}
											</Select>
										</Form.Item>
										<Divider />
									</div>
									<div>
										<MinusCircleOutlined onClick={() => remove(field.name)} style={{ width: 50 }} />
									</div>
								</div>
							))}
							<Form.Item {...tailFormItemLayout}>
								<Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
									Add Bank Account
								</Button>
							</Form.Item>
						</>
					)}
				</Form.List>

				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}
export default InvoiceEntityForm
