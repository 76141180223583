import {
	ADD_LEAD,
	CLOSED_LEAD,
	DELETE_LEAD,
	EDIT_LEAD,
	NURTURING_LEAD,
	RESET_LEAD,
	SINGLE_LEAD,
	UPDATE_SINGLE_LEAD,
	WORKING_LEAD
} from '../types'

const leads = []
const singleLeadsState = []

export const leadsData = (state = leads, { type, payload }) => {
	switch (type) {
		case ADD_LEAD:
			return [payload, ...state]
		case EDIT_LEAD:
			return state.map(x => {
				if (x.id === payload.id) {
					return payload.data
				}
				return x
			})
		case RESET_LEAD:
			return payload
		case DELETE_LEAD:
			return state.filter(lead => lead.id !== payload)
		default:
			return state
	}
}

export const singleLeadsData = (state = singleLeadsState, { type, payload }) => {
	switch (type) {
		case SINGLE_LEAD:
			return payload
		case UPDATE_SINGLE_LEAD:
			return { ...state, ...payload }
		case WORKING_LEAD:
			return {
				...state,
				...payload
			}
		case NURTURING_LEAD:
			return {
				...state,
				...payload
			}
		case CLOSED_LEAD:
			return {
				...state,
				...payload
			}
		default:
			return state
	}
}
