/* eslint-disable default-param-last */
import { ADD_PROJECT, EDIT_PROJECT, RESET_PROJECT, SINGLE_PROJECT, UPDATE_SINGLE_PROJECT } from '../types'

const projects = []
const singleProjectsState = []

export const projectsData = (state = projects, { type, payload }) => {
	switch (type) {
		case ADD_PROJECT:
			return [payload, ...state]
		case EDIT_PROJECT:
			return state.map(x => {
				if (x.id === payload.id) {
					return payload.data
				}
				return x
			})
		case RESET_PROJECT:
			return payload

		default:
			return state
	}
}

export const singleProjectsData = (state = singleProjectsState, { type, payload }) => {
	switch (type) {
		case SINGLE_PROJECT:
			return payload
		case UPDATE_SINGLE_PROJECT:
			return {
				...state,
				...payload
			}
		default:
			return state
	}
}
