import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { message, Tag } from 'antd'
import dayjs from 'dayjs'
import { DataView, DrawerComponent, InternalHeader, Stats } from '../../../Components'
import { OpportunitiesForm } from '../../../Components/Forms'
import SingleOpportunity from './SingleOpportunity'
import dateFormat from '../../../Content/dateFormat'
import { getAllOpportunities } from '../../../Services/Sales/opportunity'
import { resetOpportunity } from '../../../Store/Actions/opportunities'
import { getOpportunityStats } from '../../../Services/stats'
import * as Colors from '../../../styles/colors'
import { form_options } from '../../../Content/opportunities'

const Opportunities = () => {
	const location = useLocation()
	const opportunitiesData = useSelector(state => state.opportunitiesData)
	const { id } = useSelector(state => state.userProfile)
	const { role, token } = useSelector(state => state.userSession)
	const refreshComponent = useSelector(state => state.refreshComponent)

	const [stats, setStats] = useState(null)
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		sortOrder: 'desc',
		sortField: 'created_at',
		total: ''
	})
	const [addDrawer, setAddDrawer] = useState(false)
	const [search, setSearch] = useState('')

	const handleSearch = e => {
		setSearch(e.target.value)
	}

	const dispatch = useDispatch()
	const fetchData = async (_, current, pageSize, sortOrder, sortField, query, filters = []) => {
		try {
			const { data } = await getAllOpportunities(token, current, pageSize, sortOrder, sortField, query, filters)
			if (!data.data) {
				dispatch(resetOpportunity([]))
			} else {
				dispatch(resetOpportunity(data.data))
			}
			setPagination({
				current: current + 1,
				pageSize,
				sortOrder,
				sortField,
				total: data.count
			})
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const getStats = async token => {
		try {
			const { data } = await getOpportunityStats(token)
			setStats(data.data)
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	useEffect(() => {
		getStats(token)
		// eslint-disable-next-line
	}, [opportunitiesData])

	const handleChange = (page, filters, sorter) => {
		const assignedToMe = filters.owner ? filters.owner[0] : undefined
		const stageFilter = filters.stage ? filters.stage : undefined
		const sortOrder = {
			ascend: 'asc',
			descend: 'desc'
		}
		fetchData(
			token,
			page.current - 1,
			page.pageSize,
			sortOrder[sorter.order] || pagination.sortOrder,
			sorter.columnKey || pagination.sortField,
			assignedToMe || stageFilter || search
		)
	}

	useEffect(() => {
		fetchData(token, 0, pagination.pageSize, pagination.sortOrder, pagination.sortField, search)
		// eslint-disable-next-line
	}, [search, refreshComponent])

	const colorSelector = {
		'': Colors.LIGHT_GREY,
		Prequalifications: Colors.RED,
		'Needs Analysis': Colors.YELLOW,
		'Value Proposition': Colors.BLUE,
		'Identify Decision Makers': Colors.GREEN
	}
	const statSortOrder = ['Prequalifications', 'Needs Analysis', 'Value Proposition', 'Identify Decision Makers']

	const opportunityStats = {
		title: 'Opportunities',
		total: stats ? stats.map(one => one.num).reduce((a, b) => a + b, 0) : null,
		data: stats
			? stats
					.sort((a, b) => statSortOrder.indexOf(a.stage) - statSortOrder.indexOf(b.stage))
					.map(one => ({
						name: one.stage || 'Unassigned',
						number: one.num,
						color: colorSelector[one.stage]
					}))
			: []
	}

	const columns = [
		{
			title: 'Name',
			key: 'name',
			sorter: true,
			dataIndex: 'name'
		},
		{
			title: 'Account',
			key: 'account',
			dataIndex: 'account_name'
		},
		{
			title: 'Closing Date',
			key: 'closing_date',
			render: (_, record) => (record.closing_date !== 0 ? dayjs.unix(record.closing_date).format(dateFormat) : '_'),
			sorter: true
		},
		{
			title: 'Stage',
			key: 'stage',
			dataIndex: 'stage',
			filters: form_options.stages.map(one => ({ value: one, text: one })),
			filterMultiple: false
		},
		{
			title: 'Owner',
			key: 'owner',
			dataIndex: 'owner_name',
			filters:
				role !== 'Admin'
					? [
							{
								text: 'Assigned to Me',
								value: id
							}
					  ]
					: null
		},
		{
			title: 'Status',
			key: 'status',
			render: (_, record) =>
				record.converted_to ? <Tag color={Colors.LIGHT_GREEN}>Converted</Tag> : <div className='text-center'>-</div>
		}
	]

	return !location.search ? (
		<div className='bg-bell-background'>
			<InternalHeader title='Sales' selected='Opportunities' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Opportunities</div>
				<div>
					<DrawerComponent
						form={<OpportunitiesForm handleClose={() => setAddDrawer(false)} />}
						visible={addDrawer}
						onOpen={() => setAddDrawer(true)}
						onClose={() => setAddDrawer(false)}
						buttonTitle='Add Opportunity'
						drawerWidth={900}
					/>
				</div>
			</div>
			<div className='grid grid-flow-col px-10'>
				<div className='col-span-2'>
					<Stats data={opportunityStats} />
				</div>
			</div>
			<div>
				<DataView
					columns={columns}
					data={opportunitiesData}
					type='opportunities'
					rowClickable
					pagination={pagination}
					handleChange={handleChange}
					handleSearch={handleSearch}
				/>
			</div>
		</div>
	) : (
		<SingleOpportunity />
	)
}

export default Opportunities
