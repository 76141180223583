/* eslint-disable default-param-last */
import { LOGGED_IN, LOGGED_OUT, REFRESH_COMPONENT } from '../types'

const userSessionState = {
	token: null,
	role: null,
	id: null
}

export const userSession = (state = userSessionState, { payload, type }) => {
	switch (type) {
		case LOGGED_IN:
			return {
				token: payload.token,
				role: payload.role,
				id: payload.id
			}
		case LOGGED_OUT:
			return {
				token: null,
				role: null,
				id: null
			}
		default:
			return state
	}
}

const refreshState = 0

export const refreshComponent = (state = refreshState, { type }) => {
	switch (type) {
		case REFRESH_COMPONENT:
			return state + 1
		default:
			return state
	}
}
