/* eslint-disable import/prefer-default-export */
export const associationData = [
	{ name: 'Accounts', link: '/sales/accounts' },
	{ name: 'Contacts', link: '/sales/contacts' },
	{ name: 'Leads', link: '/sales/leads' },
	{ name: 'Enquiries', link: '/sales/enquiries' },
	{ name: 'Opportunities', link: '/sales/opportunities' },
	{ name: 'Contracts', link: '/contracts/contracts' },
	{ name: 'Projects', link: '/projects/projects' }
]
