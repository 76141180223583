/* eslint-disable no-shadow */
import React, { useState } from 'react'
import { Form, Input, Button, Select, message, Drawer, Space, DatePicker, InputNumber } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { form_options, opportunity_stage_probability } from '../../Content/opportunities'
import { formItemLayout, tailFormItemLayout } from '../../Controllers/form'
import SelectDebounce from '../SelectDebounce'
import { fetchAccountList, fetchLeadsList, fetchOpportunitySources } from '../../Controllers/fetchLists'
import { EmployeeForm, AccountForm, LeadForm } from '.'
import { Files } from '../Views'
import { addOpportunity, editOpportunity } from '../../Store/Actions/opportunities'
import OpportunitiesSourceForm from './OpportunitySourceForm'
import { createOpportunity, getOpportunityByID, updateOpportunity } from '../../Services/Sales/opportunity'
import { objectDifference } from '../../Controllers/objectDifference'
import dateFormat from '../../Content/dateFormat'
import ReporteeSelect from '../ReporteeSelect'

const { Option } = Select

const OpportunitiesForm = ({ edit, data, handleClose, handleValues }) => {
	const { token } = useSelector(state => state.userSession)
	const dispatch = useDispatch()
	const [visible, setVisible] = useState('')
	const [loading, setLoading] = useState(false)
	const [uploadedFiles, setUploadedFiles] = useState(data?.attachments || [])
	const [form] = Form.useForm()

	const formData = data
		? {
				...data,
				date: data.date && data.date !== 0 ? dayjs.unix(data.date) : null,
				closing_date: data.closing_date && data.closing_date !== 0 ? dayjs.unix(data.closing_date) : null,
				owner: {
					key: data.owner,
					label: data.owner_name,
					value: data.owner
				},
				account_id: {
					key: data.account_id,
					label: data.account_name,
					value: data.account_id
				},
				related_accounts: data?.related_accounts_data?.map(one => ({
					key: one.id,
					label: one.name,
					value: one.id
				})),
				decision_makers_registration: data?.decision_makers_registration_data?.map(one => ({
					key: one.id,
					label: one.name,
					value: one.id
				})),
				decision_makers_technical: data?.decision_makers_technical_data?.map(one => ({
					key: one.id,
					label: one.name,
					value: one.id
				})),
				decision_makers_procurement: data?.decision_makers_procurement_data?.map(one => ({
					key: one.id,
					label: one.name,
					value: one.id
				})),
				source: {
					key: data.source,
					label: data.source_data.name,
					value: data.source
				}
		  }
		: null

	const selectWidth = 500
	const showDrawer = (e, type) => {
		setVisible(type)
	}
	const onClose = () => {
		setVisible('')
	}
	const handleOpportunityStage = e => {
		form.setFieldsValue({
			probability: opportunity_stage_probability.filter(x => x.name === e)[0].percentage
		})
	}

	const onAdd = async values => {
		setLoading(true)
		const dataObject = {
			...values,
			owner: values?.owner?.value,
			account_id: values?.account_id?.value,
			source: values?.source?.value,
			closing_date: values.closing_date ? dayjs(values.closing_date).unix() : 0,
			date: values.date ? dayjs(values.date).unix() : 0,
			decision_makers_procurement:
				(values.decision_makers_procurement && values.decision_makers_procurement.map(x => x.value)) || [],
			decision_makers_registration:
				(values.decision_makers_registration && values.decision_makers_registration.map(x => x.value)) || [],
			decision_makers_technical:
				(values.decision_makers_technical && values.decision_makers_technical.map(x => x.value)) || [],
			related_accounts: (values.related_accounts && values.related_accounts.map(x => x.value)) || [],
			attachments: uploadedFiles,
			expected_budget: Number(values.expected_budget)
		}
		try {
			const { data } = await createOpportunity(token, dataObject)
			const res = await getOpportunityByID(token, data.data.id)
			dispatch(addOpportunity(res.data.data))
			if (handleValues) {
				handleValues(res.data.data)
			}
			setLoading(false)
			message.success('Opportunity added successfully!')
			form.resetFields()
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error.response?.data?.message || 'Something went wrong!')
		}
	}

	const onEdit = async values => {
		setLoading(true)
		const dataObject = {
			...values,
			owner: values?.owner?.value,
			account_id: values?.account_id?.value,
			source: values?.source?.value,
			closing_date: values.closing_date ? dayjs(values.closing_date).unix() : 0,
			date: values.date ? dayjs(values.date).unix() : 0,
			decision_makers_procurement:
				values.decision_makers_procurement && values.decision_makers_procurement.map(x => x.value),
			decision_makers_registration:
				values.decision_makers_registration && values.decision_makers_registration.map(x => x.value),
			decision_makers_technical: values.decision_makers_technical && values.decision_makers_technical.map(x => x.value),
			related_accounts: values.related_accounts && values.related_accounts.map(x => x.value),
			attachments: uploadedFiles,
			budget: Number(values.budget)
		}
		try {
			const updatedData = objectDifference(data, dataObject)
			await updateOpportunity(token, { id: data.id, ...updatedData })
			const res = await getOpportunityByID(token, data.id)
			dispatch(
				editOpportunity({
					id: data.id,
					data: res.data.data
				})
			)
			message.success('Opportunity updated successfully!')
			setLoading(false)
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const drawerNames = {
		employee: 'Add Employee',
		account: 'Add Account',
		related_account: 'Add Related Account',
		decision_makers_registration: 'Add Decision Maker (Registration / Prequel)',
		decision_makers_technical: 'Add Decision Maker (Technical)',
		decision_makers_procurement: 'Add Decision Maker (Procurement)',
		op_source: 'Add Opportunity Source'
	}
	const getDrawerComponent = () => {
		switch (visible) {
			case drawerNames.employee:
				return (
					<EmployeeForm
						handleClose={onClose}
						handleEmployeeValues={data => {
							form.setFieldsValue({
								owner: {
									label: `${data.first_name} ${data.last_name}`,
									value: data.id
								}
							})
						}}
					/>
				)
			case drawerNames.account:
				return (
					<AccountForm
						handleClose={onClose}
						handleAccountValues={data => {
							form.setFieldsValue({
								account_id: {
									label: `${data.account_name}`,
									value: data.id
								}
							})
						}}
					/>
				)
			case drawerNames.related_account:
				return (
					<AccountForm
						handleClose={onClose}
						handleAccountValues={data => {
							form.setFieldsValue({
								related_accounts: [
									...(form.getFieldValue('related_accounts') || []),
									{
										label: `${data.account_name}`,
										value: data.id,
										key: data.id
									}
								]
							})
						}}
					/>
				)
			case drawerNames.decision_makers_registration:
				return (
					<LeadForm
						handleClose={onClose}
						handleLeadValues={data => {
							form.setFieldsValue({
								decision_makers_registration: [
									...(form.getFieldValue('decision_makers_registration') || []),
									{
										label: `${data.first_name} ${data.last_name}`,
										value: data.id,
										key: data.id
									}
								]
							})
						}}
					/>
				)
			case drawerNames.decision_makers_technical:
				return (
					<LeadForm
						handleClose={onClose}
						handleLeadValues={data => {
							form.setFieldsValue({
								decision_makers_technical: [
									...(form.getFieldValue('decision_makers_technical') || []),
									{
										label: `${data.first_name} ${data.last_name}`,
										value: data.id,
										key: data.id
									}
								]
							})
						}}
					/>
				)
			case drawerNames.decision_makers_procurement:
				return (
					<LeadForm
						handleClose={onClose}
						handleLeadValues={data => {
							form.setFieldsValue({
								decision_makers_procurement: [
									...(form.getFieldValue('decision_makers_procurement') || []),
									{
										label: `${data.first_name} ${data.last_name}`,
										value: data.id,
										key: data.id
									}
								]
							})
						}}
					/>
				)
			case drawerNames.op_source:
				return (
					<OpportunitiesSourceForm
						handleClose={onClose}
						handleSourceValues={data => {
							form.setFieldsValue({
								source: {
									label: `${data.name}`,
									value: data.id
								}
							})
						}}
					/>
				)
			default:
				return null
		}
	}
	const getDrawerWidth = () => {
		switch (visible) {
			case 'Add Employee':
				return 700
			default:
				return 700
		}
	}

	const handleFiles = async (currentAttachment, fileName) => {
		setUploadedFiles(prev => [currentAttachment, ...prev])
		message.success(`${fileName} uploaded successfully`)
	}

	const handleRemove = async fileName => {
		try {
			if (edit) {
				await updateOpportunity(token, {
					id: formData.id,
					attachments: uploadedFiles.filter(x => x !== fileName)
				})
			}
			setUploadedFiles(prev => prev.filter(x => x !== fileName))
			message.success(`${fileName} removed successfully`)
		} catch (error) {
			message.error(`Deleting ${fileName} failed. Try again.`)
		}
	}

	return (
		<div className='space-y-20'>
			<Form
				name='control-hooks'
				form={form}
				onFinish={edit ? onEdit : onAdd}
				{...formItemLayout}
				scrollToFirstError
				initialValues={formData}
			>
				<Form.Item
					label='Opportunity Owner'
					name='owner'
					rules={[{ required: true, message: 'Please select opportunity owner!' }]}
				>
					<ReporteeSelect form={form} fieldName='owner' currentValue={formData ? formData?.owner?.label : null} />
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Opportunity Information</div>
				<Form.Item label='Account Name'>
					<Space>
						<Form.Item name='account_id' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search Accounts'
								fetchOptions={e => fetchAccountList(e, token)}
								style={{
									width: selectWidth
								}}
								// currentSearchValue={data?.owner}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, drawerNames.account)} type='link'>
							Add Account
						</Button>
					</Space>
				</Form.Item>
				<Form.Item label='Related Account(s)'>
					<Space>
						<Form.Item name='related_accounts' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search Accounts'
								fetchOptions={e => fetchAccountList(e, token)}
								style={{
									width: selectWidth
								}}
								isMultiple
								// currentSearchValue={data?.owner}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, drawerNames.related_account)} type='link'>
							Add Account
						</Button>
					</Space>
				</Form.Item>
				<Form.Item
					label='Opportunity Name'
					name='name'
					rules={[
						{
							required: true,
							message: 'Please input opportunity name!'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item label='Opportunity Type' name='type'>
					<Select placeholder='Select Type of Opportunity'>
						{form_options.type.map(option => (
							<Option value={option}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label='Opportunity Source'>
					<Space>
						<Form.Item name='source' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search Opportunity Source'
								fetchOptions={e => fetchOpportunitySources(e, token)}
								style={{
									width: selectWidth
								}}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, drawerNames.op_source)} type='link'>
							Add Source
						</Button>
					</Space>
				</Form.Item>
				<Form.Item label='Description' name='description'>
					<Input.TextArea rows={4} />
				</Form.Item>
				<Form.Item label='Opportunity Date' name='date'>
					<DatePicker format={dateFormat} />
				</Form.Item>
				<Form.Item label='Closing Date' name='closing_date'>
					<DatePicker format={dateFormat} />
				</Form.Item>
				<Form.Item label='Opportunity Stage' name='stage'>
					<Select placeholder='Select Opportunity Stage' onChange={handleOpportunityStage}>
						{form_options.stages.map(option => (
							<Option value={option}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label='Probability [%]' name='probability'>
					<InputNumber disabled />
				</Form.Item>
				<Form.Item
					label={
						<div className='my-2'>
							<div> Decision Makers</div>
							<div> (Registration / Prequel)</div>
						</div>
					}
				>
					<Space>
						<Form.Item name='decision_makers_registration' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search Leads'
								fetchOptions={e => fetchLeadsList(e, token)}
								style={{
									width: selectWidth
								}}
								isMultiple
								// currentSearchValue={data?.owner}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, drawerNames.decision_makers_registration)} type='link'>
							Add Lead
						</Button>
					</Space>
				</Form.Item>
				<Form.Item label='Decision Makers (Technical)'>
					<Space>
						<Form.Item name='decision_makers_technical' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search Leads'
								fetchOptions={e => fetchLeadsList(e, token)}
								style={{
									width: selectWidth
								}}
								isMultiple
								// currentSearchValue={data?.owner}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, drawerNames.decision_makers_technical)} type='link'>
							Add Lead
						</Button>
					</Space>
				</Form.Item>
				<Form.Item label='Decision Makers (Procurement)'>
					<Space>
						<Form.Item name='decision_makers_procurement' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search Leads'
								fetchOptions={e => fetchLeadsList(e, token)}
								style={{
									width: selectWidth
								}}
								isMultiple
								// currentSearchValue={data?.owner}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, drawerNames.decision_makers_procurement)} type='link'>
							Add Lead
						</Button>
					</Space>
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Descriptive Information</div>
				<Form.Item label='Explain what is the opportunity' name='explanation'>
					<Input.TextArea rows={4} />
				</Form.Item>
				<Form.Item label='Products & Services' name='ps'>
					<Input />
				</Form.Item>
				<Form.Item label='Expected Budget' name='expected_budget'>
					<Input addonBefore='USD' />
				</Form.Item>
				<Form.Item label='Insert Attachments' name='attachments'>
					<Files attachments={uploadedFiles} handleFiles={handleFiles} handleRemove={handleRemove} />
				</Form.Item>
				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
			<Drawer
				title={visible}
				width={getDrawerWidth()}
				onClose={onClose}
				open={!!visible}
				bodyStyle={{ paddingBottom: 80 }}
			>
				{getDrawerComponent(onClose)}
			</Drawer>
		</div>
	)
}

export default OpportunitiesForm
