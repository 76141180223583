import { Button, Select, Space } from 'antd'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getReportees } from '../Services/user'
import { addReportUser } from '../Store/Actions/reports'

const ReporteeSelect = ({ form, fieldName, currentValue, onChange }) => {
	const [reporteesList, setReporteesList] = useState([])
	const [reporteeListLoading, setReporteeListLoading] = useState(false)
	const [value, setValue] = useState(currentValue)
	const { first_name, last_name, id: profileId } = useSelector(state => state.userProfile)
	const userSession = useSelector(state => state.userSession)
	const dispatch = useDispatch()

	useEffect(() => {
		if (!form) {
			setValue(currentValue)
		}
	}, [currentValue])

	const getReporteeList = async () => {
		try {
			setReporteeListLoading(true)
			const response = await getReportees(userSession.token, userSession.id)
			setReporteesList(response.data.data.map(x => ({ label: x.name, value: x.name, id: x.id })))
		} catch (error) {
			return []
		} finally {
			setReporteeListLoading(false)
		}
	}

	useEffect(() => {
		getReporteeList()
	}, [])

	return (
		<Space>
			<Select
				showSearch
				placeholder='Select Assignee'
				options={reporteesList}
				loading={reporteeListLoading}
				value={value}
				onChange={(e, w) => {
					if (form) {
						form.setFieldsValue({
							[fieldName]: {
								label: w.label,
								value: w.id,
								key: w.id
							}
						})
						setValue(w.label)
					} else {
						if (onChange) {
							onChange(w)
						} else {
							dispatch(addReportUser({ name: w.label, id: w.id }))
						}
						setValue(w.label)
					}
				}}
				style={{ width: 300 }}
			/>
			{form && (
				<Button
					type='link'
					onClick={() => {
						form.setFieldsValue({
							[fieldName]: {
								label: `${first_name} ${last_name}`,
								value: profileId,
								key: profileId
							}
						})
						setValue(`${first_name} ${last_name}`)
					}}
				>
					Assign me
				</Button>
			)}
		</Space>
	)
}

export default ReporteeSelect
