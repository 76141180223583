/* eslint-disable react/no-unstable-nested-components */
import React, { useContext, useState, useRef } from 'react'
import { Table, Input, Button, Form, Divider } from 'antd'
import { PlusOutlined, MenuOutlined } from '@ant-design/icons'
import { v4 } from 'uuid'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'

const EditableContext = React.createContext(null)

const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />)

const BillableTasksForm = ({ data, handleData, handleClose }) => {
	const [dataSource, setDataSource] = useState(data?.length ? data?.map((x, i) => ({ ...x, index: i })) : [])

	const handleSave = row => {
		setDataSource(prev =>
			prev.map(x => {
				if (x.key === row.key) {
					return { ...x, ...row, updated: true }
				}
				return x
			})
		)
	}

	const handleAdd = async () => {
		const newData = {
			key: v4(),
			id: v4(),
			name: `Task`,
			description: `Task Description`,
			updated: false,
			index: dataSource.length
		}
		setDataSource(prev => [...prev, newData])
	}

	const handleRemove = async row => {
		if (dataSource?.length > 1) {
			setDataSource(prev => prev.filter(x => x.key !== row.key).map((x, i) => ({ ...x, index: i })))
		} else {
			setDataSource([
				{
					key: v4(),
					name: `Task`,
					description: `Task Description`,
					updated: false,
					id: v4(),
					index: 0
				}
			])
		}
	}

	const SortableItem = SortableElement(props => <tr {...props} />)
	const SortableBody = SortableContainer(props => <tbody {...props} />)

	const onSortEnd = ({ oldIndex, newIndex }) => {
		if (oldIndex !== newIndex) {
			const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter(el => !!el)
			setDataSource(newData.map((x, i) => ({ ...x, index: i })))
		}
	}

	const DraggableContainer = props => (
		<SortableBody useDragHandle disableAutoscroll helperClass='row-dragging' onSortEnd={onSortEnd} {...props} />
	)

	const DraggableBodyRow = ({ index, ...props }) => <SortableItem index={index} {...props} />

	const EditableRow = ({ ...props }) => {
		const index = props['data-row-key']
		const [form] = Form.useForm()
		return (
			<Form form={form} component={false}>
				<EditableContext.Provider value={form}>
					<DraggableBodyRow index={index} {...props} />
				</EditableContext.Provider>
			</Form>
		)
	}

	const EditableCell = ({ title, editable, children, dataIndex, record, ...restProps }) => {
		const inputRef = useRef(null)
		const form = useContext(EditableContext)

		const save = async () => {
			const values = await form.validateFields()
			handleSave({ ...record, ...values })
		}
		return record ? (
			<td {...restProps}>
				{title === 'action' ? (
					<Button type='link' onClick={() => handleRemove(record)}>
						Remove
					</Button>
				) : (
					<Form.Item
						style={{
							margin: 0
						}}
						name={dataIndex}
						initialValue={record.updated ? record[dataIndex] : ''}
					>
						<Input ref={inputRef} onPressEnter={save} onBlur={save} placeholder={`Task ${title}`} />
					</Form.Item>
				)}
			</td>
		) : (
			<td>
				<DragHandle />
			</td>
		)
	}
	const columns = [
		{
			title: 'Sort',
			dataIndex: 'sort',
			width: 30,
			className: 'drag-visible'
		},
		{
			title: 'Name',
			dataIndex: 'name',
			width: '30%',
			onCell: record => ({
				record,
				editable: true,
				dataIndex: 'name',
				title: 'Name',
				handleSave
			})
		},
		{
			title: 'Description',
			dataIndex: 'description',
			editable: true,
			onCell: record => ({
				record,
				editable: true,
				dataIndex: 'description',
				title: 'Description',
				handleSave
			})
		},
		{
			title: 'Action',
			width: '10%',
			onCell: record => ({
				record,
				title: 'action',
				handleRemove
			})
		}
	]

	const components = {
		body: {
			wrapper: DraggableContainer,
			row: EditableRow,
			cell: EditableCell
		}
	}
	return (
		<div className='space-y-4'>
			<Table
				components={components}
				rowClassName={() => 'editable-row'}
				bordered
				rowKey='index'
				dataSource={dataSource}
				columns={columns}
				pagination={false}
			/>
			<Button type='dashed' onClick={handleAdd} block icon={<PlusOutlined />}>
				Add Task
			</Button>
			<Divider />
			<div className='grid place-items-center pt-10'>
				<Button
					onClick={() => {
						handleData(dataSource)
						handleClose()
					}}
					type='primary'
				>
					Save & Close
				</Button>
			</div>
		</div>
	)
}

export default BillableTasksForm
