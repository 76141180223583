export const worldCurrencies = [
	{
		symbol: '$',
		name: 'US Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'USD',
		name_plural: 'US dollars'
	},
	{
		symbol: 'AED',
		name: 'United Arab Emirates Dirham',
		symbol_native: 'د.إ.‏',
		decimal_digits: 2,
		rounding: 0,
		code: 'AED',
		name_plural: 'UAE dirhams'
	},
	{
		symbol: '€',
		name: 'Euro',
		symbol_native: '€',
		decimal_digits: 2,
		rounding: 0,
		code: 'EUR',
		name_plural: 'euros'
	},
	{
		symbol: 'Rs',
		name: 'Indian Rupee',
		symbol_native: '₹',
		decimal_digits: 2,
		rounding: 0,
		code: 'INR',
		name_plural: 'Indian rupees'
	},
	{
		symbol: 'CA$',
		name: 'Canadian Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'CAD',
		name_plural: 'Canadian dollars'
	},
	{
		symbol: 'AU$',
		name: 'Australian Dollar',
		symbol_native: '$',
		decimal_digits: 2,
		rounding: 0,
		code: 'AUD',
		name_plural: 'Australian dollars'
	}
]
