import { message, Table, Tag } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import dateFormat from '../../../Content/dateFormat'
import accounting from '../../../Controllers/accountingNumbers'
import SingleExpense from '../../../Pages/Expenses/SingleExpense'
import { getExpenseByAssociationId } from '../../../Services/expenses'
import DrawerComponent from '../../DrawerComponent'
import * as Colors from '../../../styles/colors'

const ExpensesView = ({ id }) => {
	const { token } = useSelector(state => state.userSession)
	const [loading, setLoading] = useState(true)
	const [data, setData] = useState([])
	const [viewDrawer, setViewDrawer] = useState(false)

	const fetchData = async () => {
		setLoading(true)
		try {
			const { data } = await getExpenseByAssociationId(token, id)
			setData(data?.data)
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchData()
	}, [viewDrawer])

	const enquiryColumn = [
		{
			title: 'Expense Name',
			key: 'title',
			sorter: true,
			dataIndex: 'title'
		},
		{
			title: 'Date',
			dataIndex: 'expense_date',
			sorter: true,
			key: 'expense_date',
			render: (_, record) => dayjs.unix(record.expense_date).format(dateFormat)
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			sorter: true,
			render: text => <Tag color={Colors.LIGHT_GREY}> {text}</Tag>
		},
		{
			title: 'Amount (USD)',
			dataIndex: 'amount',
			key: 'amount',
			sorter: true,
			render: amount => accounting(amount)
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<div className='z-10'>
					<DrawerComponent
						visible={viewDrawer[record.id]}
						onOpen={() => setViewDrawer({ [record.id]: true })}
						onClose={() => setViewDrawer(false)}
						drawerTitle='Expense Details'
						buttonTitle='View'
						drawerWidth={900}
						buttonType='link'
						form={<SingleExpense id={record.id} closeDrawer={() => setViewDrawer(false)} />}
					/>
				</div>
			)
		}
	]

	// FETCH DATA ON PAGE LOAD
	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, [])
	return <Table columns={enquiryColumn} dataSource={data} loading={loading} />
}

export default ExpensesView
