import React, { useState } from 'react'
import { Button, Form, Input, message } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import resetPasswordSVG from '../../Assets/Images/reset-password.svg'
import logo from '../../Assets/Images/paradigm_logo.png'
import { resetPassword } from '../../Services/access'

const ResetPassword = () => {
	const [loading, setLoading] = useState(false)
	const location = useLocation()
	const history = useHistory()
	const params = new URLSearchParams(location.search)
	const token = params.get('token')
	const [form] = Form.useForm()
	const onFinish = async values => {
		setLoading(true)
		try {
			await resetPassword(token, { password: values.password })

			message.success('Your password has been successfully updated. We will redirect you to the login now.')
			setTimeout(() => {
				history.push('/login')
			}, 3000)
			setLoading(false)
		} catch (error) {
			if (error?.response?.status === 401) {
				message.error('We could not verify you. Please click on the reset link we sent you on your email address.', 4)
			} else {
				message.error(error.response?.data?.message || 'Something went wrong')
			}
			setLoading(false)
		}
	}

	return (
		<div className='grid grid-cols-5 gap-10 px-20 bg-white'>
			<div className='xl:col-span-3 md:col-span-2 hidden md:grid place-items-center'>
				<img src={resetPasswordSVG} alt='Login Illustration' className='w-2/3' />
			</div>
			<div className='flex flex-col justify-center items-center h-screen space-y-5 xl:col-span-2 md:col-span-3 col-span-5'>
				<div className='flex items-center space-x-10'>
					<div className='flex items-center'>
						<span>
							<img src={logo} alt='Paradigm Logo' className='w-16' />
						</span>
						<div className='flex flex-col'>
							<span className=' text-bell-text text-xl font-bold'> Paradigm </span>
						</div>
					</div>
				</div>
				<div className='font-bold text-bell-text text-3xl'> Reset Password</div>

				<div className='w-full'>
					<Form name='control-hooks' form={form} onFinish={onFinish} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
						<Form.Item
							label='Password'
							name='password'
							rules={[
								{
									required: true,
									message: 'Please input your password!'
								},
								() => ({
									validator(_, value) {
										if (!value || value.length > 7) {
											return Promise.resolve()
										}
										return Promise.reject(new Error('Password should be 8 characters long'))
									}
								})
							]}
						>
							<Input.Password />
						</Form.Item>
						<Form.Item
							label='Confirm Password'
							name='confirm_password'
							dependencies={['password']}
							hasFeedback
							rules={[
								{
									required: true,
									message: 'Please confirm your password!'
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (!value || getFieldValue('password') === value) {
											return Promise.resolve()
										}
										return Promise.reject(new Error('The two passwords that you entered do not match!'))
									}
								})
							]}
						>
							<Input.Password />
						</Form.Item>

						<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
							<Button type='primary' htmlType='submit' loading={loading}>
								Change Password
							</Button>
						</Form.Item>
					</Form>
				</div>
			</div>
		</div>
	)
}

export default ResetPassword
