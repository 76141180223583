import { ADD_NOTE, DELETE_NOTE, RESET_NOTE } from '../types'

export const addNote = payload => ({
	type: ADD_NOTE,
	payload
})

export const deleteNote = payload => ({
	type: DELETE_NOTE,
	payload
})

export const resetNote = payload => ({
	type: RESET_NOTE,
	payload
})
