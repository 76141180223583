/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// eslint-disable-next-line jsx-a11y/no-static-element-interactions

// eslint-disable-next-line jsx-a11y/no-static-element-interactions

import React, { useState } from 'react'
import { Form, Input, Button, message, Radio, Divider, Space } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons'
import _ from 'lodash'
import { v4 } from 'uuid'
import { tailFormItemLayout, formItemLayout } from '../../Controllers/form'
import { objectDifference } from '../../Controllers/objectDifference'
import { addPSAPI, editPSAPI } from '../../Services/products-services'
import { addPS, editPS } from '../../Store/Actions/products-services'
import { Files } from '../Views'
import AvatarUploader from '../AvatarUploader'
import { MINIO } from '../../config'
import DrawerComponent from '../DrawerComponent'
import BillableTasksForm from './BillableTasksForm'

const ProductServicesForm = ({ handleClose, edit, data, handlePS }) => {
	const dispatch = useDispatch()
	const formData = data
		? {
				...data,
				billable_tasks: data?.billable_tasks?.length
					? data?.billable_tasks?.map(one => ({
							key: v4(),
							name: one.name,
							description: one.description,
							updated: true,
							id: one.id
					  }))
					: [
							{
								key: v4(),
								name: `Task`,
								description: `Task Description`,
								updated: false,
								id: v4()
							}
					  ]
		  }
		: null
	// SELECTORS
	const { token } = useSelector(state => state.userSession)
	const [uploadedFiles, setUploadedFiles] = useState(formData?.attachments || [])
	const [image, setImage] = useState(formData?.image || '')
	const [form] = Form.useForm()
	const [loading, setLoading] = useState(false)
	const [addDrawer, setAddDrawer] = useState(false)
	const [tableData, setTableData] = useState(
		formData?.billable_tasks || [
			{
				key: v4(),
				name: `Task`,
				description: `Task Description`,
				updated: false,
				id: v4()
			}
		]
	)
	const handleImage = e => {
		setImage(e)
	}

	const onAdd = async values => {
		setLoading(true)
		const dataObject = {
			..._.omit(values, ['task_description', 'task_name']),
			name: values.name,
			description: values.description,
			billable_tasks: tableData
				.filter(x => x.updated)
				?.map(x => ({ name: x.name, description: x.description, id: x.id })),
			attachments: uploadedFiles,
			image
		}
		try {
			// eslint-disable-next-line no-shadow
			const { data } = await addPSAPI(token, dataObject)
			if (handlePS) {
				handlePS({ id: data.data.id, ...dataObject })
			}
			dispatch(addPS({ id: data.data.id, ...dataObject }))
			message.success('Product / Service added successfully!')
			setLoading(false)
			form.resetFields()
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error.response?.data?.message || 'Something went wrong.')
		}
	}

	const onEdit = async values => {
		setLoading(true)
		const dataObject = {
			..._.omit(values, ['task_description', 'task_name']),
			name: values.name,
			description: values.description,
			billable_tasks: tableData
				.filter(x => x.updated)
				?.map(x => ({ name: x.name, description: x.description, id: x.id })),
			attachments: uploadedFiles,
			image
		}
		const difference = objectDifference(formData, dataObject)
		try {
			await editPSAPI(token, { id: formData.id, ...difference })
			dispatch(editPS({ id: formData.id, data: { id: formData.id, ...dataObject } }))
			if (handlePS) {
				handlePS({ id: formData.id, ...dataObject })
			}
			setLoading(false)
			message.success('Product / Service updated successfully!')
			form.resetFields()
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error.response?.data?.message || 'Something went wrong.')
		}
	}

	const handleFiles = async (currentAttachment, fileName) => {
		setUploadedFiles(prev => [currentAttachment, ...prev])
		message.success(`${fileName} uploaded successfully`)
	}

	const handleRemove = async fileName => {
		try {
			if (edit) {
				await editPSAPI(token, {
					id: formData.id,
					attachments: uploadedFiles.filter(x => x !== fileName)
				})
			}
			setUploadedFiles(prev => prev.filter(x => x !== fileName))
			message.success(`${fileName} removed successfully`)
		} catch (error) {
			message.error(`Deleting ${fileName} failed. Try again.`)
		}
	}

	const handleRemoveImage = async () => {
		try {
			await editPSAPI(token, {
				id: formData.id,
				image: ''
			})
			setImage(false)
			message.success(`Image removed successfully!`)
		} catch (error) {
			message.error(`Deleting image failed. Try again.`)
		}
	}

	return (
		<div className='space-y-20'>
			<Form
				name='control-hooks'
				form={form}
				{...formItemLayout}
				onFinish={edit ? onEdit : onAdd}
				initialValues={formData}
				scrollToFirstError
			>
				<div className='py-2 italic text-bell-gray'>Product / Service Information</div>
				<Form.Item name='type' label='Type' rules={[{ required: true, message: 'Please pick an item!' }]}>
					<Radio.Group>
						<Radio.Button value='Goods'>Goods</Radio.Button>
						<Radio.Button value='Service'>Service</Radio.Button>
						<Radio.Button value='Internal'>Internal</Radio.Button>
					</Radio.Group>
				</Form.Item>
				<Form.Item label='Product / Service Code' name='code'>
					<Input />
				</Form.Item>
				<Form.Item
					label='Product / Service Name'
					name='name'
					rules={[{ required: true, message: 'Please input name!' }]}
				>
					<Input />
				</Form.Item>
				<Form.Item label='SKU' name='sku'>
					<Input />
				</Form.Item>
				<Form.Item label='Unit' name='unit'>
					<Input />
				</Form.Item>
				<Form.Item label='Description' name='description'>
					<Input.TextArea rows={4} />
				</Form.Item>
				<Divider />
				<div className='py-2 italic text-bell-gray'>Billable Tasks</div>
				<Form.Item label='Billable Tasks' name='billable_tasks'>
					<Space>
						<DrawerComponent
							form={
								<BillableTasksForm
									handleClose={() => setAddDrawer(false)}
									data={tableData}
									handleData={w => setTableData(w)}
								/>
							}
							visible={addDrawer}
							onOpen={() => setAddDrawer(true)}
							onClose={() => setAddDrawer(false)}
							buttonTitle='Update Billable Tasks'
							drawerWidth={1000}
							buttonType='default'
						/>
						<Divider type='vertical' />
						<div className='text-gray-600 font-normal'>
							<span className='text-bell-blue text-2xl font-medium'>
								{tableData?.filter(x => x.updated)?.length || 'No'}
							</span>{' '}
							Task(s) added.
						</div>
					</Space>
				</Form.Item>
				<Divider />
				<div className='py-2 italic text-bell-gray'>Attachments</div>

				<Form.Item label='Image' name='image'>
					<div className='flex items-center justify-start'>
						<div>
							<AvatarUploader image={image} handleImage={handleImage} />
						</div>
						{image && (
							<div className='flex space-x-2 items-center'>
								<a href={`${MINIO}/${image}`}>
									<DownloadOutlined />
								</a>
								<div onClick={handleRemoveImage} className='text-bell-blue cursor-pointer'>
									<DeleteOutlined />
								</div>
							</div>
						)}
					</div>
				</Form.Item>
				<Form.Item label='Attachments' name='attachments'>
					<Files attachments={uploadedFiles} handleFiles={handleFiles} handleRemove={handleRemove} />
				</Form.Item>

				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' loading={loading} htmlType='submit'>
						Save and Close
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}
export default ProductServicesForm
