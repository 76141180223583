import { ADD_ACTIVITY, DELETE_ACTIVITY, RESET_ACTIVITY } from '../types'

const activities = []

// eslint-disable-next-line default-param-last,import/prefer-default-export
export const activitiesData = (state = activities, { type, payload }) => {
	switch (type) {
		case ADD_ACTIVITY:
			return [payload, ...state]

		case RESET_ACTIVITY:
			return payload

		case DELETE_ACTIVITY:
			return state.filter(activity => activity.id !== payload)

		default:
			return state
	}
}
