import React, { useState } from 'react'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import DrawerComponent from '../../../DrawerComponent'
import ClosingLeadForm from '../../../Forms/Leads/ClosingLeadForm'

const Closed = ({ data, isFilled, editable }) => {
	const [form, setForm] = useState(false)
	const refactor = data
		? {
				...data,
				lost_to: data.lost_to && <Link to={`./accounts?id=${data.lost_to}`}>{data.lost_to_account_name} </Link>
		  }
		: null

	return (
		<div>
			<div className='flex items-center'>
				<div className='font-bold text-base text-bell-text py-2'> Closed</div>
			</div>

			{isFilled ? (
				<div className='p-4 space-y-4'>
					{Object.keys(_.omit(refactor, ['lost_to_account_name']))
						.filter(item => refactor[item])
						.map((item, i) => (
							<div className='grid grid-cols-3 lg:grid-cols-5 gap-4' key={i}>
								<div className='capitalize col-span-1 lg:col-span-1 text-bell-gray'>{item.replaceAll('_', ' ')}</div>
								<div className='col-span-2 lg:col-span-2'> {refactor[item]}</div>
							</div>
						))}
				</div>
			) : (
				<div>
					<div className='text-bell-gray text-sm py-2'>No Information Available</div>
					{editable && (
						<DrawerComponent
							form={<ClosingLeadForm handleClose={() => setForm(false)} />}
							visible={form}
							onOpen={() => setForm(true)}
							onClose={() => setForm(false)}
							buttonTitle='Add Information'
							drawerTitle='Closing'
							drawerWidth='1000'
						/>
					)}
				</div>
			)}
		</div>
	)
}

export default Closed
