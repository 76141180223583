/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react'
import { Button, Form, Input, message, Select, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'

import { v4 } from 'uuid'
import { formItemLayout, tailFormItemLayout } from '../../../Controllers/form'
import { getAllRoles } from '../../../Services/roles'
import { resetRole } from '../../../Store/Actions/employees'

const CostingSchema = ({ currency, handleData, data }) => {
	const dispatch = useDispatch()
	const { current_revision_data } = useSelector(state => state.singleEnquiriesData)
	const userSession = useSelector(state => state.userSession)
	const rolesData = useSelector(state => state.rolesData)
	const [costingObject, setCostingObject] = useState([])

	useEffect(() => {
		if (data) {
			setCostingObject(data?.deliverables)
		}
		// eslint-disable-next-line
	}, [])
	const [selectLoading, setSelectLoading] = useState(true)
	const [resources, setResources] = useState(data?.resources || [])
	const products = current_revision_data?.scope_and_evaluation?.products_services_data
	const fetchRolesData = async (token, current) => {
		try {
			const res = await getAllRoles(token, current)
			const { count } = res.data
			const { data: roles } = await getAllRoles(token, current, count)
			if (!roles.data) {
				dispatch(resetRole([]))
			} else {
				dispatch(resetRole(roles.data))
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		} finally {
			setSelectLoading(false)
		}
	}
	useEffect(() => {
		fetchRolesData(userSession.token, 0)
		setSelectLoading(false)
		// eslint-disable-next-line
	}, [])

	const formRefactor = data
		? {
				costing_name: data?.name,
				costing_description: data?.description,
				roles: data.resources,
				insideForm: costingObject
					?.map(product => ({
						[`${product.product_id}_travel_cost`]: product?.travel?.value,
						[`${product.product_id}_travel_desc`]: product?.travel?.description,
						[`${product.product_id}_subcontracting_cost`]: product?.subcontracting?.value,
						[`${product.product_id}_subcontracting_desc`]: product?.subcontracting?.description,
						[`${product.product_id}_accomodation_cost`]: product?.accomodation?.value,
						[`${product.product_id}_accomodation_desc`]: product?.accomodation?.description,
						[`${product.product_id}_other_costs`]: product?.other_costs
					}))
					?.reduce((a = {}, b = {}) => ({ ...a, ...b }), {})
		  }
		: null

	const handleInputChange = (e, w, task, record) => {
		const inputValue = e // Hours for a role
		const productID = record?.id // Object with Product Data {name, id, billable_tasks..}
		const costPerHour = w?.data?.cost_per_hour
		const role = w?.key // ID of the role
		const taskObject = task // Object {name, description}

		setCostingObject(prev => {
			const thisProduct = prev?.filter(x => x?.product_id === productID)[0]
			const otherProducts = prev.filter(x => x?.product_id !== productID)
			const thisTask = thisProduct?.billable_tasks?.filter(x => x?.name === taskObject?.name)[0]
			const otherTasks = thisProduct?.billable_tasks?.filter(x => x?.name !== taskObject?.name)

			const otherRoles = thisTask?.roles?.filter(z => z.id !== role)
			const thisCost = inputValue * costPerHour || 0
			const thisTaskWithoutThisRole =
				thisTask?.roles
					?.filter(x => x.id !== role)
					?.map(item => Number(item.hours) * item.cost_per_hour)
					?.reduce((a = 0, b = 0) => a + b, 0) || 0
			const otherTasksCost = otherTasks?.map(x => x.total_amount).reduce((a = 0, b = 0) => a + b, 0) || 0

			const deliverableCost = otherTasksCost + thisTaskWithoutThisRole + thisCost

			const otherCost = thisProduct?.other_costs?.map(x => x.value)?.reduce((a = 0, b = 0) => a + b, 0) || 0
			const additionalCosts =
				thisProduct?.subcontracting?.value ||
				0 + thisProduct?.accomodation?.value ||
				0 + thisProduct?.travel?.value ||
				0 + otherCost
			const totalCost = deliverableCost + additionalCosts

			const totalHoursForThisTask =
				thisTask?.roles
					?.filter(x => x.id !== role)
					?.map(item => Number(item.hours))
					?.reduce((a = 0, b = 0) => a + b, 0) || 0
			const totalAmountForThisTask =
				thisTask?.roles
					?.filter(x => x.id !== role)
					?.map(item => Number(item.hours) * item.cost_per_hour)
					?.reduce((a = 0, b = 0) => a + b, 0) || 0
			return [
				...otherProducts,
				{
					product_id: productID,
					...thisProduct,
					total_cost: totalCost,
					billable_tasks: thisProduct?.billable_tasks
						? [
								...otherTasks,
								{
									...taskObject,
									roles: thisTask?.roles
										? [...otherRoles, { id: role, hours: inputValue, cost_per_hour: costPerHour }]
										: [{ id: role, hours: inputValue, cost_per_hour: costPerHour }],
									total_hours: totalHoursForThisTask + Number(inputValue),
									total_amount: totalAmountForThisTask + thisCost
								}
						  ]
						: [
								{
									...taskObject,
									roles: thisTask?.roles
										? [...otherRoles, { id: role, hours: inputValue, cost_per_hour: costPerHour }]
										: [{ id: role, hours: inputValue, cost_per_hour: costPerHour }],
									total_hours: totalHoursForThisTask + Number(inputValue),
									total_amount: totalAmountForThisTask + thisCost
								}
						  ]
				}
			]
		})
	}

	const handleForm = (e, record) => {
		const productID = record?.id
		const obj = {
			travel: {
				value: Number(e[`${record.id}_travel_cost`]) || 0,
				description: e[`${record.id}_travel_desc`]
			},
			subcontracting: {
				value: Number(e[`${record.id}_subcontracting_cost`]) || 0,
				description: e[`${record.id}_subcontracting_desc`]
			},
			accomodation: {
				value: Number(e[`${record.id}_accomodation_cost`]) || 0,
				description: e[`${record.id}_accomodation_desc`]
			},
			other_costs:
				e[`${record.id}_other_costs`]?.map(item => ({
					value: Number(item?.value) || 0,
					name: item?.name
				})) || []
		}
		setCostingObject(prev => {
			const thisProduct = prev?.filter(x => x?.product_id === productID)[0]
			const otherProducts = prev?.filter(x => x?.product_id !== productID)
			const deliverableCost =
				thisProduct?.billable_tasks?.map(x => x.total_amount).reduce((a = 0, b = 0) => a + b, 0) || 0
			const otherCost = obj?.other_costs?.map(x => Number(x.value))?.reduce((a = 0, b = 0) => a + b, 0) || 0
			const additionalCosts = obj?.subcontracting?.value + obj?.accomodation?.value + obj?.travel?.value + otherCost
			const totalCost = deliverableCost + additionalCosts
			return [
				...otherProducts,
				{
					product_id: productID,
					...thisProduct,
					...obj,
					total_cost: totalCost
				}
			]
		})
	}

	const [expandedRowKeys, setExpandedRowKeys] = useState([])
	const { Option } = Select
	const [form] = Form.useForm() // Main Form

	const onTableRowExpand = (expanded, record) => {
		const rolesArray = form.getFieldValue(['roles'])
		if (rolesArray?.length > 0) {
			expanded && record.billable_tasks.length > 0
				? setExpandedRowKeys(prev => [record.id, ...prev])
				: setExpandedRowKeys(prev => [...prev.filter(x => x !== record.id)])
		} else {
			message.error('You have not selected any roles yet.')
		}
	}
	const initialState = [
		{ title: 'Billable Task', dataIndex: 'name', key: 'name', fixed: 'left', width: 200 },
		{ title: 'Task Description', dataIndex: 'description', key: 'description', fixed: 'left', width: 300 }
	]
	const [insideColumns, setInsideColumns] = useState(initialState)
	const [form2] = Form.useForm() // Expandable Row Form
	const expandedRowRender = record => {
		const data = record.billable_tasks.map(task => {
			const thisTask = costingObject
				.filter(x => x.product_id === record.id)[0]
				?.billable_tasks?.filter(z => z.name === task.name)[0]
			const totalHours = thisTask?.roles?.map(item => Number(item.hours))?.reduce((a = 0, b = 0) => a + b) || 0
			const totalAmount =
				thisTask?.roles?.map(item => Number(item.hours) * item.cost_per_hour)?.reduce((a = 0, b = 0) => a + b) || 0

			return {
				...task,
				...Object.assign(
					{},
					...insideColumns
						.filter(x => x.identifier)
						.map(role => ({
							[role.key]: (
								<Input
									suffix='Hrs'
									style={{ width: 100 }}
									onChange={e => handleInputChange(e.target.value, role, task, record)}
									defaultValue={0}
									value={thisTask?.roles?.filter(x => x.id === role.key)[0]?.hours || 0}
								/>
							)
						}))
				),
				total_hours: totalHours,
				amount: totalAmount
			}
		})

		const scrollWidth = () => {
			const width = 900
			const roles = form.getFieldValue(['roles'])
			if (roles && roles?.length > 3) {
				return width + 200 * Math.abs(roles.length - 3)
			}
			return width
		}

		return (
			<div>
				<Table
					columns={insideColumns}
					dataSource={data}
					pagination={false}
					style={{ width: window.innerWidth - 100 }}
					scroll={{ x: scrollWidth() }}
				/>
				<Form
					name='control-hooks-2'
					form={form2}
					onChange={() => handleForm(form2.getFieldsValue(), record)}
					{...formItemLayout}
					scrollToFirstError
					initialValues={formRefactor?.insideForm}
				>
					<div className='py-2 italic text-bell-gray'>Other Information</div>
					<Form.Item label='Subcontracting Cost'>
						<Form.Item
							name={`${record.id}_subcontracting_cost`}
							style={{
								display: 'inline-block',
								width: 'calc(20%)'
							}}
						>
							<Input type='number' addonBefore={currency} />
						</Form.Item>
						<Form.Item
							name={`${record.id}_subcontracting_desc`}
							style={{
								display: 'inline-block',
								width: 'calc(80%)'
							}}
						>
							<Input.TextArea rows={1} placeholder='Subcontracting Cost Description..' />
						</Form.Item>
					</Form.Item>

					<Form.Item label='Travel Cost'>
						<Form.Item
							name={`${record.id}_travel_cost`}
							style={{
								display: 'inline-block',
								width: 'calc(20%)'
							}}
						>
							<Input type='number' addonBefore={currency} />
						</Form.Item>
						<Form.Item
							name={`${record.id}_travel_desc`}
							style={{
								display: 'inline-block',
								width: 'calc(80%)'
							}}
						>
							<Input.TextArea rows={1} placeholder='Travel Cost Description..' />
						</Form.Item>
					</Form.Item>

					<Form.Item label='Accomodation Cost'>
						<Form.Item
							name={`${record.id}_accomodation_cost`}
							style={{
								display: 'inline-block',
								width: 'calc(20%)'
							}}
						>
							<Input type='number' addonBefore={currency} />
						</Form.Item>
						<Form.Item
							name={`${record.id}_accomodation_desc`}
							style={{
								display: 'inline-block',
								width: 'calc(80%)'
							}}
						>
							<Input.TextArea rows={1} placeholder='Accommodation Cost Description..' />
						</Form.Item>
					</Form.Item>

					<Form.List name={`${record.id}_other_costs`}>
						{(fields, { add, remove }) => (
							<>
								{fields.map(field => (
									<div key={field.key} className='w-full'>
										<div className='text-right'>
											<MinusCircleOutlined onClick={() => remove(field.name)} />
										</div>
										<div>
											<Form.Item
												{...field}
												label='Cost Name'
												name={[field.name, 'name']}
												fieldKey={[field.fieldKey, 'name']}
											>
												<Input />
											</Form.Item>
											<Form.Item
												{...field}
												name={[field.name, 'value']}
												fieldKey={[field.fieldKey, 'value']}
												label='Cost Value'
											>
												<Input type='number' addonBefore={currency} />
											</Form.Item>
										</div>
									</div>
								))}

								<Form.Item {...tailFormItemLayout}>
									<Button type='dashed' onClick={() => add()} block icon={<PlusOutlined />}>
										Add Other Cost
									</Button>
								</Form.Item>
							</>
						)}
					</Form.List>
				</Form>
			</div>
		)
	}
	const onFinish = async () => {
		try {
			const values = await form.validateFields()
			const dataObject = {
				id: data ? data.id : v4(),
				name: values.costing_name,
				description: values.costing_description,
				resources,
				deliverables: costingObject
			}
			handleData(dataObject)
			form.resetFields()
			form2.resetFields()
			setCostingObject([])
		} catch (error) {
			form.scrollToField(['costing_name'])
		}
	}

	const handleResources = w => {
		setInsideColumns([
			...initialState,
			...w.map(x => {
				const thisOption = rolesData.filter(z => z.id === x)[0]
				return {
					title: thisOption.name,
					dataIndex: thisOption.id,
					key: thisOption.id,
					identifier: 'role',
					data: thisOption
				}
			}),
			{ title: 'Total Hours', dataIndex: 'total_hours', key: 'total_hours', fixed: 'right', width: 100 },
			{ title: 'Amount', dataIndex: 'amount', key: 'amount', fixed: 'right', width: 100 }
		])
		if (resources.length > w.length) {
			const deletedResource = resources.filter(x => w.indexOf(x) === -1)[0]
			products.forEach(record => {
				record?.billable_tasks?.forEach(task => {
					const w = {
						key: deletedResource,
						data: {
							cost_per_hour: 0
						}
					}
					handleInputChange(0, w, task, record)
				})
			})
			setResources(w)
		} else {
			setResources(w)
		}
	}

	useEffect(() => {
		if (data && data.resources) {
			handleResources(data.resources)
		}
		// eslint-disable-next-line
	}, [])

	return (
		<div>
			<Form name='control-hooks' form={form} {...formItemLayout} scrollToFirstError initialValues={formRefactor}>
				<div className='py-2 italic text-bell-gray'>Enquiry Information</div>

				<Form.Item
					label='Costing Schema Name'
					name='costing_name'
					rules={[{ required: true, message: 'Please add costing name!' }]}
				>
					<Input placeholder='Higher Profit Schema' />
				</Form.Item>
				<Form.Item label='Costing Description' name='costing_description'>
					<Input placeholder='This costing is based on higher profit on lower resources.' />
				</Form.Item>

				<Form.Item label='Select Resources' name='roles'>
					<Select
						mode='multiple'
						placeholder='Choose Resources'
						style={{ minWidth: 200 }}
						loading={selectLoading}
						showSearch={false}
						notFoundContent='No resources found! Please ask your admin to add roles in the system.'
						onChange={e => {
							handleResources(e)
						}}
					>
						{rolesData?.map(option => (
							<Option value={option.id}>
								<p className='capitalize'>
									{option.name}
									{option.region && ` (${option.region})`}
								</p>
							</Option>
						))}
					</Select>
				</Form.Item>
			</Form>
			<Table
				columns={[
					{
						title: 'Name',
						dataIndex: 'name'
					},
					{
						title: 'Description',
						dataIndex: 'description'
					},
					{
						title: 'Total Tasks',
						render: (_, record) => record.billable_tasks.length
					},
					{
						title: 'Total Amount',
						render: (_, record) => {
							const totalCost = costingObject.filter(x => x.product_id === record.id)[0]?.total_cost
							return record.billable_tasks.length === 0 ? (
								<Input
									addonBefore={currency}
									onChange={e => {
										setCostingObject(prev => {
											const thisProduct = prev?.filter(x => x?.product_id === record.id)[0]
											const otherProducts = prev.filter(x => x?.product_id !== record.id)
											const totalCost = Number(e.target.value)
											return [
												...otherProducts,
												{
													product_id: record.id,
													...thisProduct,
													total_cost: totalCost
												}
											]
										})
									}}
									value={costingObject.filter(x => x.product_id === record.id)[0]?.total_cost}
								/>
							) : (
								<Input addonBefore={currency} value={totalCost} disabled />
							)
						}
					}
				]}
				rowKey={obj => obj.id}
				expandedRowKeys={expandedRowKeys}
				onExpand={onTableRowExpand}
				expandedRowRender={record => expandedRowRender(record)}
				dataSource={products}
			/>
			<Form.Item {...tailFormItemLayout}>
				<Button type='primary' htmlType='submit' onClick={onFinish}>
					Save Costing
				</Button>
			</Form.Item>
		</div>
	)
}

export default CostingSchema
