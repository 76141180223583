import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message, Space, Statistic, Tag } from 'antd'
import dayjs from 'dayjs'
import { DataView, DrawerComponent, InternalHeader, Stats } from '../../../Components'
import dateFormat from '../../../Content/dateFormat'
import { InvoiceForm } from '../../../Components/Forms'
import InvoiceExport from '../../../Components/Internal/InvoiceExport'
import { getAllInvoices, getInvoiceStatsByStatus } from '../../../Services/Contracts/invoices'
import { resetInvoice } from '../../../Store/Actions/invoices'
import { getInvoiceStats } from '../../../Services/stats'
import { renderStatus } from '../../../Content/renderTags'

const Invoices = () => {
	const invoicesData = useSelector(state => state.invoicesData)
	const { token } = useSelector(state => state.userSession)
	const { id, role } = useSelector(state => state.userProfile)

	const [stats, setStats] = useState(null)
	const [statusStats, setStatusStats] = useState(null)

	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		sortOrder: 'desc',
		sortField: 'created_at',
		total: ''
	})
	const [addDrawer, setAddDrawer] = useState(false)
	const [invoiceDrawer, setInvoiceDrawer] = useState(false)
	const [search, setSearch] = useState('')
	const dispatch = useDispatch()

	const handleSearch = e => {
		setSearch(e.target.value)
	}

	const fetchData = async (current, pageSize, sortOrder, sortField, query) => {
		try {
			const { data } = await getAllInvoices(token, current, pageSize, sortOrder, sortField, query)
			if (!data.data) {
				dispatch(resetInvoice([]))
			} else {
				dispatch(resetInvoice(data.data))
			}
			setPagination({
				current: current + 1,
				pageSize,
				sortOrder,
				sortField,
				total: data.count
			})
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	useEffect(() => {
		// fetches data everytime the user searches something or invoice drawer closes.
		if (!invoiceDrawer) {
			fetchData(0, pagination.pageSize, pagination.sortOrder, pagination.sortField, search)
		}
		// eslint-disable-next-line
	}, [search, invoiceDrawer])

	const getStats = async () => {
		try {
			const { data } = await getInvoiceStats(token)
			setStats(data.data)
		} catch (error) {
			setStats({
				invoices: 'NA'
			})
		}
	}

	// stats based on enquiry stages
	const getStatsByStatus = async () => {
		try {
			const { data } = await getInvoiceStatsByStatus(token)
			setStatusStats(data.data)
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	useEffect(() => {
		getStats()
		getStatsByStatus()
		// eslint-disable-next-line
	}, [invoicesData])

	const handleChange = (page, filters, sorter) => {
		const sortOrder = {
			ascend: 'asc',
			descend: 'desc'
		}
		fetchData(page.current - 1, page.pageSize, sortOrder[sorter.order], sorter.order ? sorter.columnKey : '', search)
	}

	const statsObject = {
		title: 'Invoices',
		total: stats?.invoices,
		data: []
	}

	const columns = [
		{
			title: 'Invoicing Entity',
			key: 'entity',
			render: (_, record) => record?.entity_data?.name
		},
		{
			title: 'Invoice Number',
			key: 'invoice_number',
			dataIndex: 'invoice_number'
		},
		{
			title: 'Subject',
			key: 'subject',
			sorter: true,
			dataIndex: 'subject'
		},
		{
			title: 'Contract',
			key: 'contract',
			render: (_, record) => record?.contract_data?.project_name
		},

		{
			title: 'Invoice Date',
			key: 'invoice_date',
			render: (_, record) => record.invoice_date !== 0 && dayjs.unix(record.invoice_date).format(dateFormat),
			sorter: true
		},

		{
			title: 'Due Date',
			key: 'due_date',
			render: (_, record) => record.due_date !== 0 && dayjs.unix(record.due_date).format(dateFormat),
			sorter: true
		},
		{
			title: 'Status',
			key: 'status',
			render: (_, record) => (
				<Tag color={renderStatus(record, id, role)[record?.status]?.color}>
					{renderStatus(record, id, role)[record?.status]?.title || record.status}
				</Tag>
			)
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<Space size='middle'>
					{/* eslint-disable-next-line */}
					<DrawerComponent
						form={<InvoiceExport invoiceId={record.id} closeDrawer={() => setInvoiceDrawer(false)} />}
						visible={invoiceDrawer[record.id]}
						onOpen={() => setInvoiceDrawer({ [record.id]: true })}
						onClose={() => setInvoiceDrawer(false)}
						buttonTitle='View'
						buttonType='link'
						drawerWidth={1100}
					/>
				</Space>
			)
		}
	]

	return (
		<div className='bg-bell-background'>
			<InternalHeader title='Contracts' selected='Invoices' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Invoices </div>
				<div>
					<DrawerComponent
						form={<InvoiceForm handleClose={() => setAddDrawer(false)} />}
						visible={addDrawer}
						onOpen={() => setAddDrawer(true)}
						onClose={() => setAddDrawer(false)}
						buttonTitle='Add Invoice'
						drawerWidth={700}
					/>
				</div>
			</div>
			<div className='grid grid-cols-4 px-10'>
				<div className='col-span-1'>
					<Stats data={statsObject} nograph />
				</div>
				{role !== 'Employee' && (
					<div className='col-span-2'>
						{statusStats && (
							<div className='flex flex-wrap gap-5 items-center pb-4'>
								{statusStats
									.filter(x => x?.status)
									.map(item => (
										<div className='px-2'>
											<Statistic title={item?.status} value={item?.cost} precision={2} prefix='$' />
										</div>
									))}
							</div>
						)}
					</div>
				)}
			</div>
			<div>
				<DataView
					columns={columns}
					data={invoicesData}
					type='invoices'
					rowClickable={false}
					pagination={pagination}
					handleChange={handleChange}
					handleSearch={handleSearch}
				/>
			</div>
		</div>
	)
}

export default Invoices
