import dayjs from 'dayjs'
import React from 'react'
import dateFormat from '../Content/dateFormat'

const PrintHeading = ({ title, contractData }) => {
	const heading = [
		{
			name: 'Account',
			value: contractData?.account_name
		},
		{
			name: 'Contract Number',
			value: contractData?.project_number
		},
		{
			name: 'Contract Title',
			value: contractData?.project_name
		}
	]
	return (
		<div className='pt-4 bg-bell-background print:p-10'>
			<div className='place-items-center hidden print:grid'>
				<div className='font-medium text-xl inline-block'>
					<span className='text-bell-blue font-semibold '>Paradigm </span> {title}
				</div>
				<div className='text-center text-sm text-bell-gray  pb-4'> {dayjs().format(dateFormat)}</div>
			</div>
			<div className='hidden print:block'>
				{heading.map((item, i) => (
					<div className='grid grid-cols-2 w-2/5 py-1' key={i}>
						<div className='text-bell-gray'>{item.name}:</div>
						<div>{item.value}</div>
					</div>
				))}
			</div>
		</div>
	)
}

export default PrintHeading
