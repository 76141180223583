import { PlayCircleOutlined } from '@ant-design/icons'
import { Button, message, Tooltip } from 'antd'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 } from 'uuid'
import { addTimeEntry, getTimeEntryById } from '../Services/timesheet'
import { addTime, currentTime } from '../Store/Actions/timesheets'
import { refreshComponent } from '../Store/Actions/userSession'
import { getTaskById, updateTask } from '../Services/task'
import { editTask } from '../Store/Actions/tasks'

const StartTask = ({ description, category, association, association_id, task_id }) => {
	const { token } = useSelector(state => state.userSession)
	const currentTimeEntry = useSelector(state => state?.currentTimeEntry)

	const [loading, setLoading] = useState(false)

	const dispatch = useDispatch()

	const onStart = async () => {
		setLoading(true)
		const dataObject = {
			description,
			category,
			association,
			association_id,
			task_id,
			timer_type: 'Timer',
			tag: 'Work',
			group_id: v4(),
			start_time: dayjs().unix()
		}
		try {
			const { data } = await addTimeEntry(token, dataObject)
			const res = await getTimeEntryById(token, data.data.id)

			// start task; set task status to in progress
			await updateTask(token, { id: task_id, status: 'In Progress' })
			const taskDetails = await getTaskById(token, task_id)
			dispatch(
				editTask({
					id: task_id,
					data: taskDetails.data.data
				})
			)

			dispatch(addTime(res.data.data))
			if (dataObject.timer_type === 'Timer') {
				dispatch(currentTime({ ...res.data.data, start_time: dayjs().unix() }))
			}
			dispatch(refreshComponent())
			setLoading(false)
			message.success('You have successfully started the task!')
		} catch (error) {
			setLoading(false)
			message.error(error?.response?.data?.message || 'Something went wrong!')
		}
	}

	return (
		<Tooltip title={currentTimeEntry ? 'A task is ongoing.' : 'Start this task'}>
			<Button
				icon={<PlayCircleOutlined />}
				type='link'
				onClick={onStart}
				loading={loading}
				disabled={currentTimeEntry}
			/>
		</Tooltip>
	)
}

export default StartTask
