import { ADD_EXPENSE, DELETE_EXPENSE, EDIT_EXPENSE, RESET_EXPENSE } from '../types'

export const addExpense = payload => ({
	type: ADD_EXPENSE,
	payload
})

export const editExpense = payload => ({
	type: EDIT_EXPENSE,
	payload
})

export const deleteExpense = payload => ({
	type: DELETE_EXPENSE,
	payload
})

export const resetExpense = payload => ({
	type: RESET_EXPENSE,
	payload
})
