import {
	ADD_OPPORTUNITY,
	EDIT_OPPORTUNITY,
	RESET_OPPORTUNITY,
	SINGLE_OPPORTUNITY,
	UPDATE_SINGLE_OPPORTUNITY
} from '../types'

export const addOpportunity = payload => ({
	type: ADD_OPPORTUNITY,
	payload
})

export const editOpportunity = payload => ({
	type: EDIT_OPPORTUNITY,
	payload
})

export const resetOpportunity = payload => ({
	type: RESET_OPPORTUNITY,
	payload
})

export const addSingleOpportunity = payload => ({
	type: SINGLE_OPPORTUNITY,
	payload
})

export const updateSingleOpportunity = payload => ({
	type: UPDATE_SINGLE_OPPORTUNITY,
	payload
})
