import { Divider, Empty } from 'antd'
import React from 'react'
import moment from 'moment'
import dateFormat from '../../../Content/dateFormat'

const Overview = ({ data }) => {
	const filteredArray = [
		'id',
		'created_at',
		'updated_at',
		'attachments',
		'email_opt_out',
		'share_only',
		'share_only_data',
		'access_specifier',
		'access_type',
		'share_and_edit',
		'share_and_edit_data'
	]

	const creationInformationArray = ['created_at', 'updated_at']

	return (
		<div className='p-4 space-y-4 bg-white'>
			{Object.keys(data).filter(item => filteredArray.indexOf(item) === -1 && data[item]).length ? (
				Object.keys(data)
					.filter(item => filteredArray.indexOf(item) === -1 && data[item])
					.map((item, i) => (
						<div className='grid grid-cols-3 gap-4' key={i}>
							<div className='capitalize col-span-1 text-bell-gray'>{item.replaceAll('_', ' ')}</div>
							<div className='col-span-2'> {data[item]}</div>
						</div>
					))
			) : (
				<Empty />
			)}
			<Divider />
			{creationInformationArray
				.filter(item => data[item])
				.map((item, i) => (
					<div className='grid grid-cols-3 gap-4' key={i}>
						<div className='capitalize col-span-1 text-bell-gray'>{item.replaceAll('_', ' ')}</div>
						<div className='col-span-2'>
							{moment.unix(data[item]).calendar(
								(null,
								{
									sameElse: dateFormat
								})
							)}
						</div>
					</div>
				))}
		</div>
	)
}

export default Overview
