import React from 'react'
import { Form, Input, Button, InputNumber } from 'antd'
import { tailFormItemLayout, formItemLayout } from '../../../Controllers/form'

const PaymentTermFrom = ({ handleClose, handleValues }) => {
	const [form] = Form.useForm()

	const onFinish = async values => {
		const dataObject = {
			name: values.name,
			increment: values.days,
			type: 'days'
		}
		handleValues(dataObject)
		handleClose()
		form.resetFields()
	}

	const handleChange = () => {
		form.setFieldsValue({ name: `Net ${form.getFieldValue('days') || ''}` })
	}

	return (
		<div className='space-y-20'>
			<Form
				name='control-hooks'
				form={form}
				onFinish={onFinish}
				{...formItemLayout}
				scrollToFirstError
				onChange={handleChange}
			>
				<Form.Item label='Name' name='name'>
					<Input disabled placeholder='Name will be auto filled with Days' />
				</Form.Item>
				<Form.Item label='Days' name='days' rules={[{ required: true, message: 'Days must be provided!' }]}>
					<InputNumber />
				</Form.Item>
				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit'>
						Save
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}
export default PaymentTermFrom
