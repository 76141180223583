import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import dayjs from 'dayjs'
import { DataView, DrawerComponent, InternalHeader, Stats } from '../../../Components'

import SingleContract from './SingleContract'
import { ContractForm } from '../../../Components/Forms'
import { getAllContracts } from '../../../Services/Contracts/contracts'
import { resetContract } from '../../../Store/Actions/contracts'
import { getContractStats } from '../../../Services/stats'
import dateFormat from '../../../Content/dateFormat'

const Contracts = () => {
	const location = useLocation()
	const contractsData = useSelector(state => state.contractsData)
	const { token } = useSelector(state => state.userSession)
	const [stats, setStats] = useState(null)

	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		sortOrder: 'desc',
		sortField: 'created_at',
		total: ''
	})
	const [addDrawer, setAddDrawer] = useState(false)
	const [search, setSearch] = useState('')
	const dispatch = useDispatch()

	const handleSearch = e => {
		setSearch(e.target.value)
	}

	const fetchData = async (current, pageSize, sortOrder, sortField, query) => {
		try {
			const { data } = await getAllContracts(token, current, pageSize, sortOrder, sortField, query)

			if (!data.data) {
				dispatch(resetContract([]))
			} else {
				dispatch(resetContract(data.data))
			}
			setPagination({
				current: current + 1,
				pageSize,
				sortOrder,
				sortField,
				total: data.count
			})
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	useEffect(() => {
		fetchData(0, pagination.pageSize, pagination.sortOrder, pagination.sortField, search)
		// eslint-disable-next-line
	}, [search])

	const getStats = async () => {
		try {
			const { data } = await getContractStats(token)
			setStats(data.data)
		} catch (error) {
			setStats({
				contracts: 'NA'
			})
		}
	}

	const handleChange = (page, filters, sorter) => {
		const sortOrder = {
			ascend: 'asc',
			descend: 'desc'
		}
		fetchData(page.current - 1, page.pageSize, sortOrder[sorter.order], sorter.order ? sorter.columnKey : '', search)
	}

	useEffect(() => {
		getStats(token)
		// eslint-disable-next-line
	}, [contractsData])

	const statsObject = {
		title: 'Contracts',
		total: stats?.contracts,
		data: []
	}

	const columns = [
		{
			title: 'Contract Number',
			key: 'project_number',
			dataIndex: 'project_number',
			sorter: true
		},
		{
			title: 'Project Name',
			key: 'project_name',
			dataIndex: 'project_name',
			sorter: true
		},
		{
			title: 'Account',
			key: 'account_id',
			dataIndex: 'account_name'
		},
		{
			title: 'Enquiry',
			key: 'enquiry_name',
			dataIndex: 'enquiry_name'
		},
		{
			title: 'Contract Owner',
			key: 'owner_name',
			dataIndex: 'owner_name'
		},
		{
			title: 'PO Date',
			key: 'po_date',
			render: (_, record) => (record.po_date ? dayjs.unix(record.po_date).format(dateFormat) : '-'),
			sorter: true
		}
	]

	return !location.search ? (
		<div className='bg-bell-background'>
			<InternalHeader title='Contracts' selected='Contracts' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Contracts</div>
				<div>
					<DrawerComponent
						form={<ContractForm handleClose={() => setAddDrawer(false)} />}
						visible={addDrawer}
						onOpen={() => setAddDrawer(true)}
						onClose={() => setAddDrawer(false)}
						buttonTitle='Add Contract'
						drawerWidth={900}
					/>
				</div>
			</div>
			<div className='grid grid-flow-col px-10'>
				<div className='col-span-2'>
					<Stats data={statsObject} nograph />
				</div>
			</div>
			<div>
				<DataView
					columns={columns}
					data={contractsData}
					type='contracts'
					rowClickable
					pagination={pagination}
					handleChange={handleChange}
					handleSearch={handleSearch}
				/>
			</div>
		</div>
	) : (
		<SingleContract />
	)
}

export default Contracts
