import React, { useState, useEffect } from 'react'
import { Avatar, Drawer, Dropdown, Tag, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import '../index.css'
import { Link, useLocation } from 'react-router-dom'
import { logout } from '../Store/Actions/userSession'
import logo from '../Assets/Images/paradigm_logo.png'
import ChangePasswordForm from './Forms/ChangePasswordForm'
import TimeTracker from './TimeTracker'
import { getCurrentTimeEntry } from '../Services/timesheet'
import { currentTime } from '../Store/Actions/timesheets'
import { SUB_DOMAIN } from '../config'

const Header = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const inTimesheets = location.pathname.split('/')[1] === 'timesheets'

	const { token } = useSelector(state => state.userSession)
	const { first_name, last_name } = useSelector(state => state.userProfile)

	const [open, setOpen] = useState(false)

	const showDrawer = () => {
		setOpen(true)
	}

	const onClose = () => {
		setOpen(false)
	}

	const fetchCurrentEntry = async () => {
		try {
			const { data } = await getCurrentTimeEntry(token)
			if (!data.data) {
				dispatch(currentTime(null))
			} else {
				dispatch(currentTime(data.data))
			}
		} catch (error) {
			dispatch(currentTime(null))
		}
	}

	useEffect(() => {
		fetchCurrentEntry()
	}, [])

	const handleLogout = () => {
		dispatch(logout())
	}
	const items = [
		{
			key: 0,
			label: <div onClick={showDrawer}>Change Password</div>
		},
		{
			key: 1,
			danger: true,
			label: <div onClick={handleLogout}>Log Out</div>
		}
	]

	return (
		<div>
			<div
				className={`flex justify-between items-center px-7 py-4 shadow-sm ${
					SUB_DOMAIN === 'try-paradigm' ? 'bg-violet-200' : 'bg-white'
				}`}
			>
				<div className='flex items-center space-x-5'>
					<Link to='/'>
						<div className='flex items-center'>
							<img src={logo} alt='paradigm logo' className='w-10' />
							<div className='text-bell-text text-xl font-bold hidden md:block'> Paradigm</div>
							{SUB_DOMAIN === 'try-paradigm' && (
								<Tooltip title='This is a trial account. Any changes you make here will remain here only and will not reflect on the main account.'>
									<Tag color='#8b5cf6' className='ml-2'>
										Trial Account
									</Tag>
								</Tooltip>
							)}
						</div>
					</Link>
				</div>
				<div className='flex items-center space-x-5'>
					{!inTimesheets && <TimeTracker />}
					<Drawer title='Change Password' width={500} onClose={onClose} open={open}>
						<ChangePasswordForm handleClose={onClose} />
					</Drawer>
					<Dropdown menu={{ items }} placement='bottom'>
						<div className='flex items-center space-x-1 cursor-pointer gap-1'>
							<div className='font-medium md:block hidden'>
								{first_name} {last_name}
							</div>
							<Avatar>
								{first_name && first_name[0]}
								{last_name && last_name[0]}
							</Avatar>
						</div>
					</Dropdown>
				</div>
			</div>
		</div>
	)
}

export default Header
