import React, { useState } from 'react'
import { Button, DatePicker, Form, Input, InputNumber, message } from 'antd'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import _ from 'lodash'

import { formItemLayout } from '../../Controllers/form'
import SelectDebounce from '../SelectDebounce'
import { fetchUserList } from '../../Controllers/fetchLists'
import dateFormat from '../../Content/dateFormat'
import { createPettyCash, getPettyCashById, updatePettyCashById } from '../../Services/pettycash'
import Files from '../Views/Information/Files'

const PettyCashForm = ({ edit, data, handleClose, disableEmployeeInput }) => {
	const { token } = useSelector(state => state.userSession)
	const [loading, setLoading] = useState(false)
	const [uploadedFiles, setUploadedFiles] = useState(data?.attachments || [])
	const [form] = Form.useForm()

	const formData = data
		? {
				...data,
				issued_on: dayjs.unix(data.issued_on),
				employee: {
					label: data.employee_name,
					value: data.employee_id,
					key: data.employee_id
				}
		  }
		: {
				issued_on: dayjs()
		  }

	const onAdd = async values => {
		const dataObject = {
			..._.omit(values, ['employee']),
			employee_id: values?.employee?.value,
			issued_on: dayjs(values?.issued_on).unix(),
			attachments: uploadedFiles
		}
		setLoading(true)
		try {
			const res = await createPettyCash(token, dataObject)
			const res2 = await getPettyCashById(token, res.data.data.id)
			handleClose(res2.data.data)
			setLoading(false)
			message.success('Disbursement added successfully!')
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong!')
			setLoading(false)
		}
	}

	const onEdit = async values => {
		const dataObject = {
			..._.omit(values, ['employee']),
			id: data.id,
			employee_id: values?.employee?.value,
			issued_on: dayjs(values?.issued_on).unix(),
			attachments: uploadedFiles
		}
		setLoading(true)
		try {
			await updatePettyCashById(token, dataObject)
			const res2 = await getPettyCashById(token, data.id)
			form.resetFields()
			setLoading(false)
			handleClose(res2.data.data)
			message.success('Disbursement updated successfully!')
		} catch (error) {
			message.error(error.response?.data?.message || 'Something went wrong!')
			setLoading(false)
		}
	}

	const handleFiles = async (currentAttachment, fileName) => {
		setUploadedFiles(prev => [currentAttachment, ...prev])
		message.success(`${fileName} uploaded successfully`)
	}

	const handleRemove = async fileName => {
		try {
			if (edit) {
				await updatePettyCashById(token, {
					id: formData.id,
					attachments: uploadedFiles.filter(x => x !== fileName)
				})
			}
			setUploadedFiles(prev => prev.filter(x => x !== fileName))
			message.success(`${fileName} removed successfully`)
		} catch (error) {
			message.error(`Deleting ${fileName} failed. Try again.`)
		}
	}

	return (
		<Form
			name='control-hooks'
			form={form}
			onFinish={edit ? onEdit : onAdd}
			{...formItemLayout}
			initialValues={formData}
		>
			<Form.Item
				label='Employee Name'
				name='employee'
				rules={[
					{
						required: true,
						message: 'Please select employee!'
					}
				]}
			>
				<SelectDebounce
					showSearch
					placeholder='Search Employees'
					fetchOptions={e => fetchUserList(e, token)}
					disabled={disableEmployeeInput}
				/>
			</Form.Item>
			<Form.Item
				label='Amount (USD)'
				name='amount'
				rules={[
					{
						required: true,
						message: 'Please input amount!'
					}
				]}
			>
				<InputNumber placeholder='0.00 USD' />
			</Form.Item>
			<Form.Item label='Issued Date' name='issued_on'>
				<DatePicker format={dateFormat} />
			</Form.Item>

			<div className='py-2 italic text-bell-gray'>Remarks & Attachments</div>
			<Form.Item label='Remarks' name='remarks'>
				<Input.TextArea rows={4} placeholder='Any remarks?' />
			</Form.Item>
			<Form.Item label='Attachments' name='attachments'>
				<Files attachments={uploadedFiles} handleFiles={handleFiles} handleRemove={handleRemove} />
			</Form.Item>
			<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
				<Button type='primary' htmlType='submit' loading={loading}>
					Save
				</Button>
			</Form.Item>
		</Form>
	)
}

export default PettyCashForm
