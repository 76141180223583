import { Button, Upload, message, Empty } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { UploadOutlined } from '@ant-design/icons'
import { MINIO, URL } from '../../../config'
import { removeAsset } from '../../../Services/upload'

const Files = ({ attachments, handleFiles, handleRemove, singleUpload, disabled, accessType, tabView }) => {
	const { token } = useSelector(state => state.userSession)
	const [files, setFiles] = useState([])

	useEffect(() => {
		setFiles(
			attachments.map((file, i) => ({
				uid: i,
				name: `${file.split('@c@')[0]}.${file.split('.')[file.split('.').length - 1]}`,
				url: `${MINIO}/${file}`
			}))
		)
	}, [attachments])

	const props = {
		name: 'file',
		action: `${URL}/assets/upload`,
		headers: {
			authorization: `Bearer ${token}`
		},
		multiple: !singleUpload,
		async onChange(info) {
			let w = [...info.fileList]
			if (info.file.status === 'done') {
				await handleFiles(info.file.response.data.name, info.file.name)
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`)
			}
			w = w.map(file => {
				if (file.response) {
					file.url = `${MINIO}/${file.response.data.name}`
				}
				return file
			})
			setFiles(w)
		},
		async onRemove(file) {
			const fileName = file.url.split('/paragon/')[1]
			try {
				await removeAsset(token, { name: fileName })
			} catch (error) {
				message.error('File could not be removed. Try again.')
			}
			await handleRemove(fileName)
		},
		fileList: files,
		showUploadList: {
			showDownloadIcon: false,
			showRemoveIcon: true
		}
	}
	return (
		<div>
			<Upload {...props}>
				{files?.length === 0 && tabView && <Empty />}
				{/* show button only if access type is set to public or it's not in the tabs */}
				{(accessType === 'Public' || !tabView) && (
					<div>
						<Button icon={<UploadOutlined />} disabled={disabled}>
							Upload
						</Button>
					</div>
				)}
			</Upload>
		</div>
	)
}

export default Files
