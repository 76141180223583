import * as Colors from '../styles/colors'

// eslint-disable-next-line import/prefer-default-export
export const timeCategory = [
	{
		name: 'Project',
		tagName: 'Work',
		color: Colors.BLUE,
		options: ['Project']
	},
	{
		name: 'Enquiry',
		tagName: 'Work',
		color: Colors.BLUE,
		options: ['Enquiry']
	},
	{
		name: 'Business Development',
		tagName: 'Work',
		color: Colors.BLUE,
		options: ['Lead', 'Enquiry', 'Opportunity']
	},
	{
		name: 'Training',
		tagName: 'Work',
		color: Colors.BLUE
	},
	{
		name: 'Meeting',
		tagName: 'Work',
		color: Colors.BLUE,
		options: ['Lead', 'Enquiry', 'Opportunity', 'Project', 'Account', 'Contact']
	},
	{
		name: 'Study or Article Preparation',
		tagName: 'Work',
		color: Colors.BLUE
	},
	{
		name: 'Holiday',
		showDateRange: true,
		tagName: 'Holiday',
		color: Colors.YELLOW
	},
	{
		name: 'Leave / Vacation',
		showDateRange: true,
		tagName: 'Holiday',
		color: Colors.YELLOW
	},
	{
		name: 'Medical / Grievance Leave',
		showDateRange: true,
		tagName: 'Holiday',
		color: Colors.YELLOW
	},
	{
		name: 'Non Utilized Time',
		tagName: 'Non Utilized Time',
		color: Colors.RED
	}
]

export const associationEnumURL = {
	Lead: '/sales/leads',
	Enquiry: '/sales/enquiries',
	Opportunity: '/sales/opportunities',
	Project: '/projects/projects',
	Account: '/sales/accounts',
	Contact: '/sales/contact',
	Contract: '/contracts/contracts',
	'Product / Service': '/sales/products-services'
}

export const taskAssociationSync = {
	Lead: {
		moduleName: 'Sales',
		plural: 'Leads'
	},
	Enquiry: {
		moduleName: 'Sales',
		plural: 'Enquiries'
	},
	Opportunity: {
		moduleName: 'Sales',
		plural: 'Opportunities'
	},
	Project: {
		moduleName: 'Projects',
		plural: 'Projects'
	},
	Account: {
		moduleName: 'Sales',
		plural: 'Accounts'
	},
	Contact: {
		moduleName: 'Sales',
		plural: 'Contacts'
	},
	Contract: {
		moduleName: 'Contracts',
		plural: 'Contracts'
	}
}

export const timeSheetSync = {
	Leads: 'Lead',
	Opportunities: 'Opportunity',
	Projects: 'Project',
	Accounts: 'Account',
	Contacts: 'Contact',
	Contracts: 'Contract'
}
