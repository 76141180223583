import axios from 'axios'
import { URL } from '../config'

export const getAllUsers = (
	token,
	offset = 0,
	limit = 10,
	sort_order = 'desc',
	sort_by = 'email',
	query = '',
	filter = ''
) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/user/all?offset=${offset}&limit=${limit}&sort_order=${sort_order}&sort_by=${sort_by}&query=${query}&filter=${filter}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getReportees = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/user/get-reportee/${id}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const createUser = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'post',
				url: `${URL}/user/create`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
export const getUserById = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/user/get/${id}`,

				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const bulkUploadUser = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'post',
				url: `${URL}/user/bulk-upload`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const updateUser = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'put',
				url: `${URL}/user/update`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
