import React, { useState } from 'react'
import {
	Alert,
	Button,
	Checkbox,
	DatePicker,
	Drawer,
	Form,
	Input,
	InputNumber,
	message,
	Radio,
	Select,
	Space,
	Switch,
	Upload
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'

import dayjs from 'dayjs'
import { formItemLayout, tailFormItemLayout } from '../../Controllers/form'
import { form_options } from '../../Content/expenses'
import SelectDebounce from '../SelectDebounce'
import { URL } from '../../config'
import { fetchAList, fetchDeepInformation, fetchMerchantList, fetchUserList } from '../../Controllers/fetchLists'
import { addExpense, editExpense } from '../../Store/Actions/expenses'
import dateFormat from '../../Content/dateFormat'
import MerchantForm from './MerchantForm'
import { createExpense, getExpenseById, updateExpense } from '../../Services/expenses'
import { objectDifference } from '../../Controllers/objectDifference'

const { Option } = Select

const ExpenseForm = ({ edit, handleClose, data }) => {
	const id = data ? data.id : null
	const { token } = useSelector(state => state.userSession)
	const { first_name, last_name, id: userId } = useSelector(state => state.userProfile)

	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const [visible, setVisible] = useState('')
	const [loading, setLoading] = useState(false)
	const [yourExpenses, setYourExpenses] = useState(data ? data.your_expense : true)
	const [uploadedFiles, setUploadedFiles] = useState([])
	const [checked, setChecked] = useState(false)

	const [association, setAssociation] = useState(data?.association)
	const [resetOptions, setResetOptions] = useState(false)

	const formData = data
		? {
				...data,
				expense_date: dayjs.unix(data.expense_date),
				merchant_id: {
					key: data.merchant_id,
					label: data.merchant_name,
					value: data.merchant_id
				},
				employee: {
					key: data.employee,
					label: data.employee_name,
					value: data.employee
				},
				association_id: {
					key: data.association_id,
					label: data.association_name,
					value: data.association_id
				}
		  }
		: {
				client_claim: false,
				expense_date: dayjs(),
				employee: {
					key: userId,
					value: userId,
					label: `${first_name} ${last_name}`
				},
				category: 'General',
				your_expense: yourExpenses
		  }

	const selectWidth = 300

	const showDrawer = (e, type) => {
		setVisible(type)
	}
	const onClose = () => {
		setVisible('')
	}
	const getDrawerComponent = () => {
		switch (visible) {
			case 'Add Merchant':
				return (
					<MerchantForm
						handleClose={onClose}
						handleValues={e =>
							form.setFieldsValue({
								merchant_id: {
									key: e.id,
									value: e.id,
									label: e.name
								}
							})
						}
					/>
				)
			default:
				return null
		}
	}

	const props = {
		name: 'file',
		action: `${URL}/assets/upload`,
		headers: {
			authorization: `Bearer ${token}`
		},
		multiple: true,
		onChange(info) {
			if (info.file.status === 'done') {
				message.success(`${info.file.name} file uploaded successfully`)
				setUploadedFiles([...uploadedFiles, info.file.response.data.name])
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`)
			}
		}
	}

	const onAdd = async values => {
		setLoading(true)
		const dataObject = {
			...values,
			attachments: uploadedFiles,
			expense_date: dayjs(values.expense_date).unix(),
			merchant_id: values.merchant_id?.value,
			employee: values.employee?.value,
			association_id: values.association_id?.value,
			status: 'Requested'
		}
		try {
			const { data } = await createExpense(token, dataObject)
			const { id } = data.data
			const res = await getExpenseById(token, id)
			if (values?.your_expense) {
				dispatch(addExpense(res.data.data))
			}
			message.success('Expense submitted successfully!')
			setLoading(false)
			form.resetFields()
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error.response?.data?.message || 'Something went wrong.')
		}
	}

	const onEdit = async values => {
		setLoading(true)
		const dataObject = {
			...values,
			attachments: data.attachments ? [...data.attachments, ...uploadedFiles] : uploadedFiles,
			expense_date: dayjs(values.expense_date).unix(),
			merchant_id: values.merchant_id?.value,
			employee: values.employee?.value,
			association_id: values.association_id?.value
		}
		try {
			const updatedData = objectDifference(data, dataObject)
			await updateExpense(token, { id, ...updatedData })
			const res = await getExpenseById(token, id)
			dispatch(
				editExpense({
					id,
					data: res.data.data
				})
			)
			message.success('Expense Information updated successfully!')
			setLoading(false)
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const handleAssociation = async (e, association) => {
		const associationId = e.value
		try {
			const {
				data: { data }
			} = await fetchDeepInformation(associationId, association, token)
			if (data?.access_specifier !== 'Public') {
				form.setFields([
					{
						name: 'association_id',
						errors: ['You are not authorized to use this entity to create the expense.']
					}
				])
			} else {
				form.setFields([
					{
						name: 'association_id',
						value: e,
						errors: null
					}
				])
			}
		} catch (e) {
			return null
		}
	}
	return (
		<div className='space-y-20'>
			<Form
				name='control-hooks'
				form={form}
				onFinish={edit ? onEdit : onAdd}
				{...formItemLayout}
				initialValues={formData}
				scrollToFirstError
			>
				<Form.Item
					label='Expense Title'
					name='title'
					rules={[
						{
							required: true,
							message: 'Please input expense title!'
						}
					]}
				>
					<Input placeholder='What is the expense?' />
				</Form.Item>
				<Form.Item label='Is this your expense?' name='your_expense'>
					<Radio.Group
						buttonStyle='solid'
						onChange={e => {
							setYourExpenses(e.target.value)
							if (e.target.value) {
								form.setFieldsValue({
									employee: {
										key: userId,
										value: userId,
										label: `${first_name} ${last_name}`
									}
								})
							}
						}}
					>
						<Radio.Button value>Yes</Radio.Button>
						<Radio.Button value={false}>No</Radio.Button>
					</Radio.Group>
				</Form.Item>
				<Form.Item label='Employee Name' name='employee'>
					<SelectDebounce
						showSearch
						placeholder='Select Employee'
						fetchOptions={e => fetchUserList(e, token)}
						style={{
							width: selectWidth
						}}
						disabled={yourExpenses}
					/>
				</Form.Item>
				<Form.Item label='Expense Date' name='expense_date'>
					<DatePicker format={dateFormat} />
				</Form.Item>
				<Form.Item label='Merchant'>
					<Space>
						<Form.Item name='merchant_id' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Select Merchant'
								fetchOptions={e => fetchMerchantList(e, token)}
								style={{
									width: selectWidth
								}}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, 'Add Merchant')} type='link'>
							Add Merchant
						</Button>
					</Space>
				</Form.Item>

				<Form.Item label='Association'>
					<Form.Item
						name='association'
						style={{
							display: 'inline-block',
							width: 'calc(30%)'
						}}
					>
						<Select
							value={association}
							onChange={e => {
								setAssociation(e)
								form.setFieldsValue({
									association_id: null
								})
								setResetOptions(true)
							}}
							defaultValue={association}
							placeholder='Select Association'
						>
							{form_options.association?.map(option => (
								<Option value={option.name}>{option.name}</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item
						name='association_id'
						style={{
							display: 'inline-block',
							width: 'calc(70%)'
						}}
					>
						<SelectDebounce
							showSearch
							placeholder={association ? `Search ${association}` : 'Association Entity'}
							fetchOptions={e => {
								setResetOptions(false)
								const w = fetchAList(e, association, token)
								return w
							}}
							resetOptions={resetOptions}
							onChange={e => handleAssociation(e, association)}
						/>
					</Form.Item>
				</Form.Item>

				<Form.Item label='Expense Category' name='category'>
					<Select placeholder='Select a Salutation'>
						{form_options.category.map(option => (
							<Option value={option}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label='Amount (USD)'>
					<Form.Item
						name='amount'
						rules={[
							{
								required: true,
								message: 'Please input amount!'
							}
						]}
					>
						<InputNumber placeholder='0.00 USD' />
					</Form.Item>

					<Alert
						type='warning'
						description={
							<div className='flex gap-4'>
								<Checkbox checked={checked} onChange={e => setChecked(e.target.checked)} />
								<div>
									I understand that the designated currency for registration is solely USD. Any alternative currencies
									requires manual conversion prior to submission of the form.
								</div>
							</div>
						}
					/>
				</Form.Item>

				<Form.Item label='Claim Reimbursement (from Client)'>
					<Space align='center'>
						<Form.Item name='client_claim'>
							<Switch defaultChecked={formData.client_claim} />
						</Form.Item>
						<p className=' text-bell-gray'>
							Check the option if this expense involves adding this expense to the invoice.{' '}
						</p>
					</Space>
				</Form.Item>
				<Form.Item label='Description' name='description'>
					<Input.TextArea rows={4} placeholder='Explain the expense in detail!' />
				</Form.Item>
				<Form.Item label='Insert Attachments' name='attachments'>
					<Upload {...props}>
						<Button icon={<UploadOutlined />}>Upload</Button>
					</Upload>
					<div className='text-bell-gray py-1'>
						Add any receipts or vouchers that may be required for the approval of this expense.
					</div>
				</Form.Item>

				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading} disabled={!checked}>
						Submit for Approval
					</Button>
					{!checked && <div className='text-bell-gray py-1'>Check any warnings before submitting.</div>}
				</Form.Item>
			</Form>
			<Drawer
				title={visible}
				width={700}
				onClose={onClose}
				open={!!visible}
				bodyStyle={{ paddingBottom: 80 }}
				footer={null}
			>
				{getDrawerComponent(onClose)}
			</Drawer>
		</div>
	)
}
export default ExpenseForm
