import { Button, Result } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'

const Page403 = () => (
	<Result
		status='403'
		title='403'
		subTitle='Sorry, you are not authorized to access this page. Please contact your admin for further details!'
		extra={
			<Link to='/'>
				<Button type='primary'>Back Home</Button>
			</Link>
		}
	/>
)

export default Page403
