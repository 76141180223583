import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, message, Tag } from 'antd'
import PropTypes from 'prop-types'
import { deleteGuaranteeById, getGuaranteeById } from '../../Services/guarantees'
import { drawerData } from '../../Content/bank-guarantees'
import ModalView from '../../Components/ModalView'
import { BankGuaranteeForm } from '../../Components/Forms'
import { DeleteItem, DrawerComponent, Spinner } from '../../Components'
import * as Colors from '../../styles/colors'
import renderShareTag from '../../Controllers/shareTag'

const SingleGuarantee = ({ guaranteeId, type, closeDrawer }) => {
	const { token, id: userId } = useSelector(state => state.userSession)
	const [editDrawer, setEditDrawer] = useState(false)
	const [data, setData] = useState('')
	const [errorCode, setErrorCode] = useState(null)
	const accessType = data?.access_specifier
	const shareType = data?.access_type

	const fetchGuaranteeData = async () => {
		try {
			const { data } = await getGuaranteeById(token, guaranteeId)
			if (data?.data?.access_specifier === 'Private') {
				setErrorCode(403)
			} else {
				setData(data.data)
			}
		} catch (error) {
			setErrorCode(error?.response?.data?.status || 401)
			message.error('The entity could not be loaded.')
		}
	}

	useEffect(() => {
		fetchGuaranteeData()
	}, [])

	const handleDelete = async () => {
		try {
			await deleteGuaranteeById(token, guaranteeId)
			message.success('Item deleted successfully!')
			closeDrawer()
		} catch (error) {
			message.error(error?.response?.data?.message || 'Item could not be deleted. Please try again!')
		}
	}
	return data ? (
		<div>
			<div className='flex justify-between items-center'>
				<div>
					<Tag color={userId === data?.contract_data?.owner ? Colors.GREEN : Colors.LIGHT_GREY}>
						{renderShareTag({
							shareType,
							isOwner: userId === data?.contract_data?.owner,
							accessType
						})}
					</Tag>
				</div>
				<div className='flex space-x-2'>
					<DrawerComponent
						form={
							<BankGuaranteeForm
								edit
								handleClose={() => setEditDrawer(false)}
								data={data}
								type={type}
								handleValues={e => setData(e)}
							/>
						}
						visible={editDrawer}
						onOpen={() => setEditDrawer(true)}
						onClose={() => setEditDrawer(false)}
						buttonTitle='Edit'
						buttonType='primary'
						drawerWidth={900}
						isHidden={accessType !== 'Public'}
					/>
				</div>
			</div>
			<Divider />
			<ModalView data={drawerData(data)} />
			{accessType === 'Public' && (
				<div className='absolute bottom-5 w-11/12 bg-white text-center'>
					<DeleteItem onDelete={handleDelete} popTitle={`Are you sure to delete this ${type}?`}>
						Delete this {type}
					</DeleteItem>
				</div>
			)}
		</div>
	) : (
		<Spinner text='Loading' errorCode={errorCode} />
	)
}

SingleGuarantee.propTypes = {
	guaranteeId: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	closeDrawer: PropTypes.func
}

SingleGuarantee.defaultProps = {
	closeDrawer: () => {}
}

export default SingleGuarantee
