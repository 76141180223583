import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Statistic, message } from 'antd'
import { getPettyCashReportByEmployeeId } from '../Services/pettycash'
import accounting from '../Controllers/accountingNumbers'

const PettyCashReportStats = ({ employeeId, handleReport, thisExpense, refresh }) => {
	const { token } = useSelector(state => state.userSession)
	const [pettyReport, setPettyReport] = useState(null)

	const fetchEmployeePettyCashReport = async () => {
		try {
			const res = await getPettyCashReportByEmployeeId(token, employeeId)
			if (res?.data?.data) {
				setPettyReport(res.data.data)
				if (handleReport) {
					handleReport(res.data.data)
				}
			}
		} catch (error) {
			message.error('The entity could not be loaded.')
		}
	}

	useEffect(() => {
		fetchEmployeePettyCashReport()
	}, [refresh])

	return (
		pettyReport && (
			<div className='flex space-x-6 justify-center'>
				{!thisExpense && (
					<>
						<Statistic title='Total Disbursement Issued' value={accounting(pettyReport.total_cash_issued)} />
						<Statistic title='Total Closed Out Expenses' value={accounting(pettyReport.total_close_out_expenses)} />
					</>
				)}
				<Statistic
					title='Balance'
					value={accounting(pettyReport.total_cash_issued - pettyReport.total_close_out_expenses)}
				/>
				{thisExpense && (
					<>
						<Statistic title='This Expense' value={accounting(thisExpense)} />
						<Statistic
							title='Net Amount'
							value={accounting(pettyReport.total_cash_issued - pettyReport.total_close_out_expenses - thisExpense)}
						/>
					</>
				)}
			</div>
		)
	)
}

export default PettyCashReportStats
