/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { message, Spin, Tag } from 'antd'
import { AiTwotoneEdit } from 'react-icons/ai'
import _ from 'lodash'
import { FaBriefcase } from 'react-icons/fa'
import * as Colors from '../../styles/colors'
import {
	ActivityTimeline,
	Details,
	Files,
	Information,
	LogInformation,
	Notes,
	Overview,
	TasksView,
	Title
} from '../../Components/Views'
import { DrawerComponent, InternalHeader } from '../../Components'
import { TaskForm } from '../../Components/Forms'
// import accounting from '../../Controllers/accountingNumbers'
import ShareModal from '../../Components/ShareModal'
import renderShareTag from '../../Controllers/shareTag'
import ProjectForm from '../../Components/Forms/ProjectForm'
// import StatusView from '../../Components/Views/StatusView'
import { getProjectById, updateProject } from '../../Services/projects'
import TimesheetView from '../../Components/Views/Details/TimesheetView'
import { associationEnumURL } from '../../Content/timetracker'
import DeliverablesView from '../../Components/Views/Details/DeliverablesView'
import ExpensesView from '../../Components/Views/Details/ExpensesView'

const SingleProject = () => {
	const location = useLocation()
	const query = new URLSearchParams(location.search)
	const id = query.get('id')

	const { token, id: userId } = useSelector(state => state.userSession)
	const refreshComponent = useSelector(state => state.refreshComponent)

	const [editDrawer, setEditDrawer] = useState(false)
	const [shareDrawer, setShareDrawer] = useState(false)
	const [project, setProject] = useState('')

	const history = useHistory()
	const accessType = project?.access_specifier
	const shareType = project?.access_type

	const fetchSingleProject = async () => {
		try {
			const { data } = await getProjectById(token, id)
			if (!data.data) {
				history.push('/projects')
			}
			setProject(data.data)
		} catch (error) {
			history.push('/projects')
		}
	}

	useEffect(() => {
		fetchSingleProject()
		// eslint-disable-next-line
	}, [editDrawer, refreshComponent, shareDrawer])

	const handleFiles = async (currentAttachment, fileName) => {
		try {
			await updateProject(token, {
				id: project.id,
				attachments: project.attachments ? [currentAttachment, ...project.attachments] : [currentAttachment]
			})
			setProject(prev => ({
				...prev,
				attachments: [...prev.attachments, currentAttachment]
			}))
			message.success(`${fileName} uploaded successfully`)
		} catch (error) {
			message.error(`Uploading ${fileName} failed. Try again.`)
		}
	}
	const handleRemoveAsset = async fileName => {
		try {
			await updateProject(token, {
				id: project.id,
				attachments: project?.attachments?.filter(x => x !== fileName)
			})
			setProject(prev => ({
				...prev,
				attachments: prev.attachments.filter(x => x !== fileName)
			}))
			message.success(`${fileName} removed successfully`)
		} catch (error) {
			message.error(`Deleting ${fileName} failed. Try again.`)
		}
	}
	const handleSharing = async sharingData => {
		try {
			const dataObject = {
				id: project.id,
				...sharingData
			}
			await updateProject(token, dataObject)
			message.success(`Project updated successfully`)
			setShareDrawer(false)
		} catch (error) {
			message.error(`Something went wrong!`)
		}
	}

	const overviewData = project && {
		..._.omit(project, [
			'attachments',
			'ps',
			'account_id',
			'account_name',
			'project_manager_id',
			'project_manager_name',
			'deputy_id',
			'deputy_name',
			'team_details',
			'budget_type',
			'association_id',
			'association',
			'association_name',
			'color',
			'contract_name'
		]),
		account: project.account_id && (
			<Link to={`../sales/accounts?id=${project.account_id}`}> {project.account_name}</Link>
		),
		association: project.association_id && (
			<Link to={`..${associationEnumURL[project.association]}?id=${project.association_id}`}>
				{project.association_name}
			</Link>
		),
		project_manager: project.project_manager_name,
		deputy_manager: project.deputy_name,
		enquiry: project.enquiry_id && (
			<Link to={`../sales/enquiries?id=${project.enquiry_id}`}> {project.enquiry_name}</Link>
		),
		// budget: project.budget_type === 'Time' ? `${project.budget}h` : `USD ${accounting(project.budget)}`,
		team: project.team_details?.length ? project?.team_details?.map((a, i) => <div key={i}>{a.name}</div>) : null
	}

	const details = {
		leftColumn: [
			{
				name: 'Overview',
				content: <Overview data={overviewData} />
			},
			// {
			// 	name: 'Status',
			// 	content: <StatusView projectData={project} />
			// },
			{
				name: 'Timesheets',
				content: <TimesheetView id={id} />
			},
			{
				name: 'Deliverables',
				content: (
					<DeliverablesView projectId={id} projectName={project.name} data={project.ps} accessType={accessType} />
				)
			},
			{
				name: 'Tasks',
				content: <TasksView id={id} />
			},
			{
				name: 'Details',
				content: (
					<Details
						data={[
							{
								name: 'Expenses',
								content: <ExpensesView id={id} />
							},
							{
								name: 'Notes',
								content: <Notes association_id={id} accessType={accessType} />
							},
							{
								name: 'Files',
								content: (
									<Files
										id={id}
										attachments={project?.attachments || []}
										handleFiles={(a, b) => handleFiles(a, b, 'contract_documents')}
										handleRemove={a => handleRemoveAsset(a, 'contract_documents')}
										accessType={accessType}
										tabView
									/>
								)
							}
						]}
					/>
				)
			},
			{
				name: 'Timeline',
				content: <ActivityTimeline association_id={id} />
			}
		],
		rightColumn: [
			{
				name: 'Activity',
				content: (
					<Details
						data={[
							{
								name: 'Log Information',
								content: <LogInformation association_id={id} association_type='Projects' />
							},
							{
								name: 'New Task',
								content: <TaskForm association_id={id} />
							}
						]}
					/>
				)
			}
		]
	}

	return (
		<div>
			<InternalHeader title='Projects' selected='Projects' />
			{project ? (
				<div className='bg-bell-background px-10 py-6 min-h-screen space-y-10'>
					<div>
						<Title
							title='Project'
							name={
								<div className='flex items-center space-x-2 pb-2'>
									<div
										className='w-10 h-10 rounded-md grid place-items-center'
										style={{ backgroundColor: project.color }}
									>
										<FaBriefcase color='#fff' fontSize={18} />
									</div>
									<div className='text-bell-text font-medium'>{project.name}</div>
								</div>
							}
							hasSubtitle
							extraDetail={
								<div>
									<Tag color={userId === project?.owner ? Colors.GREEN : Colors.LIGHT_GREY}>
										{renderShareTag({
											shareType,
											isOwner: userId === project?.owner,
											accessType
										})}
									</Tag>
								</div>
							}
							editButton={
								<DrawerComponent
									form={
										<ProjectForm
											edit
											handleClose={() => {
												setEditDrawer(false)
											}}
											data={project}
										/>
									}
									visible={editDrawer}
									onOpen={() => setEditDrawer(true)}
									onClose={() => setEditDrawer(false)}
									buttonTitle={<AiTwotoneEdit color={Colors.BLUE} fontSize={20} />}
									buttonType='link'
									drawerTitle='Edit Contract'
									drawerWidth={900}
									isHidden={accessType !== 'Public'}
								/>
							}
							shareButton={
								<ShareModal
									visible={shareDrawer}
									onOpen={() => setShareDrawer(true)}
									onClose={() => setShareDrawer(false)}
									modalTitle='Share Project'
									isHidden={accessType !== 'Public'}
									handleValues={handleSharing}
									data={project}
								/>
							}
							overview={[
								{
									title: 'Account',
									value:
										project.association !== 'Account' && project.account_id ? (
											<Link to={`../sales/accounts?id=${project?.account_id}`}>
												{project?.account_name || 'This Account'}
											</Link>
										) : null
								},
								{
									title: 'Project Manager',
									value: project.project_manager_id && (project?.project_manager_name || 'This Person')
								},
								{
									title: project.association,
									value: project.association && project.association_id && (
										<Link to={`..${associationEnumURL[project.association]}?id=${project.association_id}`}>
											{project.association_name || `This ${project.association}`}
										</Link>
									)
								}
							]}
						/>
					</div>
					<Information details={details} accessType={accessType} />
				</div>
			) : (
				<Spin />
			)}
		</div>
	)
}

export default SingleProject
