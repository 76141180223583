import { message, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getEnquiriesByAccountID } from '../../../Services/Sales/account'

const EnquiriesView = ({ id }) => {
	const { token } = useSelector(state => state.userSession)
	const [loading, setLoading] = useState(true)
	const [enquiryData, setEnquiryData] = useState([])
	// GET ENQUIRIES BY ACCOUNT ID
	const fetchData = async () => {
		try {
			const { data } = await getEnquiriesByAccountID(token, id)
			setEnquiryData(data?.data)
			setLoading(false)
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const enquiryColumn = [
		{
			title: 'Enquiry Name',
			key: 'enquiry_name',
			dataIndex: 'job_description'
		},
		{
			title: 'Enquiry Number',
			key: 'enquiry_number',
			dataIndex: 'enquiry_number'
		},
		{
			title: 'Enquiry Stage',
			key: 'enquiry_stage',
			dataIndex: 'enquiry_stage'
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<div className='z-10'>
					<Space size='middle'>
						{/* eslint-disable-next-line */}
						<Link to={`/sales/enquiries?id=${record.id}`}>View Enquiry</Link>
					</Space>
				</div>
			)
		}
	]

	// FETCH DATA ON PAGE LOAD
	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, [])
	return <Table columns={enquiryColumn} dataSource={enquiryData} loading={loading} />
}

export default EnquiriesView
