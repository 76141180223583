import { ADD_INVOICE, EDIT_INVOICE, RESET_INVOICE, SINGLE_INVOICE, UPDATE_SINGLE_INVOICE } from '../types'

export const addInvoice = payload => ({
	type: ADD_INVOICE,
	payload
})

export const editInvoice = payload => ({
	type: EDIT_INVOICE,
	payload
})

export const resetInvoice = payload => ({
	type: RESET_INVOICE,
	payload
})

export const addSingleInvoice = payload => ({
	type: SINGLE_INVOICE,
	payload
})

export const updateSingleInvoice = payload => ({
	type: UPDATE_SINGLE_INVOICE,
	payload
})
