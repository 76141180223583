import { Button, Result, Spin } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import { ERROR_MESSAGE } from '../Content/messages'

const errorDescription = {
	401: 'Something went wrong! The data could not be fetched.',
	403: 'You are not authorized to view this information. You may ask admin or the owner of this entity for the access.'
}
const Spinner = ({ text, errorCode }) =>
	errorCode ? (
		<Result
			status={errorCode === 403 ? 403 : 404}
			title={errorCode}
			subTitle={errorDescription[errorCode] || ERROR_MESSAGE}
			extra={
				<Link to='/'>
					<Button type='primary'>Back Home</Button>
				</Link>
			}
		/>
	) : (
		<div className='grid place-items-center mt-48'>
			<Spin size='large' />
			<div className='text-bell-blue pt-2'>{text}</div>
		</div>
	)

export default Spinner
