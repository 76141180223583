import React, { useEffect, useState } from 'react'
import { message, Space, Table, Tag } from 'antd'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'

// COMPONENTS
import { DrawerComponent } from '../..'
import dateFormat from '../../../Content/dateFormat'
import accounting from '../../../Controllers/accountingNumbers'
import { BankGuaranteeForm } from '../../Forms'
import { renderBankGuaranteeTag } from '../../../Content/bank-guarantees'
import { getGuaranteesByAssociationID } from '../../../Services/guarantees'
import SingleGuarantee from '../../../Pages/Guarantees/SingleGuarantee'

const BankGuaranteesView = ({ associationData, type, accessType }) => {
	const { token } = useSelector(state => state.userSession)
	const [addDrawer, setAddDrawer] = useState(false)
	const [viewDrawer, setViewDrawer] = useState(false)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)

	const fetchData = async () => {
		setLoading(true)
		try {
			const { data: guaranteeData } = await getGuaranteesByAssociationID(token, associationData.id)
			if (guaranteeData.data) {
				setData(guaranteeData.data)
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (!viewDrawer) {
			fetchData()
		}
		// eslint-disable-next-line
	}, [viewDrawer])

	const columns = [
		{
			title: 'Bank Name',
			key: 'name',
			dataIndex: 'name'
		},
		{
			title: 'Amount',
			key: 'amount',
			dataIndex: 'amount',
			render: (_, record) => `USD ${accounting(record.amount)}`
		},
		{
			title: 'Issue Date',
			key: 'issue_date',
			render: (_, record) => record.issue_date !== 0 && dayjs.unix(record.issue_date).format(dateFormat)
		},

		{
			title: 'Expiration Date',
			key: 'expiration_date',
			render: (_, record) => record.expiration_date !== 0 && dayjs.unix(record.expiration_date).format(dateFormat)
		},
		{
			title: 'Status',
			key: 'status',
			dataIndex: 'status',
			render: (_, record) => <Tag color={renderBankGuaranteeTag[record?.status]?.color}>{record.status}</Tag>
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<Space size='middle'>
					<DrawerComponent
						visible={viewDrawer[record.id]}
						onOpen={() => setViewDrawer({ [record.id]: true })}
						onClose={() => setViewDrawer(false)}
						drawerTitle={`${type} Details`}
						buttonTitle='View'
						drawerWidth={900}
						buttonType='link'
						form={<SingleGuarantee guaranteeId={record.id} type={type} closeDrawer={() => setViewDrawer(false)} />}
					/>
				</Space>
			)
		}
	]

	return (
		<div className='space-y-5'>
			<div className='text-right'>
				<DrawerComponent
					form={
						<BankGuaranteeForm
							associationData={{
								id: associationData.id
							}}
							handleClose={() => {
								setAddDrawer(false)
							}}
							type={type}
							handleValues={w => setData(prev => [w, ...prev])}
						/>
					}
					visible={addDrawer}
					onOpen={() => setAddDrawer(true)}
					onClose={() => setAddDrawer(false)}
					buttonTitle={`Add ${type}`}
					drawerWidth={900}
					isHidden={accessType !== 'Public'}
				/>
			</div>
			<Table columns={columns} dataSource={data} pagination={false} loading={loading} />
		</div>
	)
}

export default BankGuaranteesView
