import React, { useState } from 'react'
import { Upload, message } from 'antd'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { MINIO, URL } from '../config'

const beforeUpload = file => {
	const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
	if (!isJpgOrPng) {
		message.error('You can only upload JPG/PNG file!')
	}
	const isLt2M = file.size / 1024 / 1024 < 2
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!')
	}
	return isJpgOrPng && isLt2M
}

const AvatarUploader = ({ image, handleImage }) => {
	const [loading, setLoading] = useState(false)
	const { token } = useSelector(state => state.userSession)

	const handleChange = info => {
		if (info.file.status === 'uploading') {
			setLoading(true)
			return
		}
		if (info.file.status === 'done') {
			setLoading(false)
			handleImage(info.file.response.data.name)
		}
	}
	const uploadButton = (
		<div>
			{loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	)

	const props = {
		name: 'file',
		action: `${URL}/assets/upload`,
		headers: {
			authorization: `Bearer ${token}`
		}
	}
	return (
		<Upload
			name='avatar'
			listType='picture-card'
			showUploadList={false}
			beforeUpload={beforeUpload}
			onChange={handleChange}
			{...props}
		>
			{image ? <img src={`${MINIO}/${image}`} alt='avatar' style={{ width: '100%' }} /> : uploadButton}
		</Upload>
	)
}

export default AvatarUploader
