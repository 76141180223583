/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-console */
import React from 'react'
import { Tag } from 'antd'
import { getAccountById, getAllAccounts } from '../Services/Sales/account'
import { getAllPS } from '../Services/products-services'
import { getAllRoles } from '../Services/roles'
import { getAllContacts, getContactById } from '../Services/Sales/contacts'
import { getAllEnquiries, getEnquiryById } from '../Services/Sales/enquiries'
import { getAllLeads, getLeadById } from '../Services/Sales/leads'
import { getAllTasks, getContactsOrLeadsForNoBid, getTasksByAssociationID } from '../Services/task'
import { getAllUsers } from '../Services/user'
import { getAllOpportunities, getAllOpportunitySources, getOpportunityByID } from '../Services/Sales/opportunity'
import { getAllContracts, getContractById } from '../Services/Contracts/contracts'
import { getAllEntities } from '../Services/Contracts/entity'
import { getAllProjects, getProjectById } from '../Services/projects'
import * as Colors from '../styles/colors'
import { getAllMerchants } from '../Services/merchant'

// eslint-disable-next-line
export const fetchAccountList = async (query, token) => {
	try {
		const response = await getAllAccounts(token, 0, 5, '', '', query)
		return response.data.data.map(account => ({
			label: account.account_name,
			value: account.id,
			data: account
		}))
	} catch (error) {
		console.log(error)
	}
}

export const fetchMerchantList = async (query, token) => {
	try {
		const response = await getAllMerchants(token, 0, 5, '', '', query)
		return response.data.data.map(merchant => ({
			label: merchant.name,
			value: merchant.id,
			key: merchant.id
		}))
	} catch (error) {
		console.log(error)
	}
}

export const fetchUserList = async (query, token) => {
	try {
		const response = await getAllUsers(token, 0, 5, '', '', query)
		return response.data.data.map(user => ({
			label: `${user.first_name} ${user.last_name}`,
			value: user.id
		}))
	} catch (error) {
		console.log(error)
	}
}

export const fetchRoleList = async (query, token) => {
	try {
		const response = await getAllRoles(token, 0, 10, '', '', query)
		return response.data.data.map(role => ({
			label: `${role.name} ${role.region && `(${role.region})`}`,
			value: role.id
		}))
	} catch (error) {
		console.log(error)
	}
}

export const fetchContactsList = async (query, token) => {
	try {
		const response = await getAllContacts(token, 0, 5, '', '', query)

		return response?.data?.data?.map(contact => ({
			label: `${contact.first_name} ${contact.last_name}`,
			value: contact.id
		}))
	} catch (error) {
		console.log(error)
	}
}

export const fetchLeadsList = async (query, token) => {
	try {
		const response = await getAllLeads(token, 0, 5, '', '', query)

		return response?.data?.data?.map(lead => ({
			label: `${lead.first_name} ${lead.last_name}`,
			value: lead.id
		}))
	} catch (error) {
		console.log(error)
	}
}

export const fetchEnquiriesList = async (query, token) => {
	try {
		const response = await getAllEnquiries(token, 0, 10, '', '', query, [])
		return response.data.data.map(enq => ({
			// the dash is being used to split the label to display the name only.
			label: `${enq.enquiry_number} - ${enq.job_description}`,
			value: enq.id
		}))
	} catch (error) {
		console.log(error)
	}
}

export const fetchContactsOrLeads = async (query, token) => {
	try {
		const response = await getContactsOrLeadsForNoBid(token, 0, 10, 'desc', 'created_at', query)
		return response.data.data.map(con => ({
			label: con.first_name ? (
				con.lead_status ? (
					<div>
						<Tag color={Colors.LIGHT_BLUE}>Leads</Tag>
						{`${con.first_name} ${con.last_name}`}
					</div>
				) : (
					<div>
						<Tag color={Colors.LIGHT_GREEN}>Contacts</Tag>
						{`${con.first_name} ${con.last_name}`}
					</div>
				)
			) : (
				<div>
					<Tag color={Colors.LIGHT_RED}>Accounts</Tag>
					{con.account_name}
				</div>
			),
			value: con.id
		}))
	} catch (error) {
		console.log(error)
	}
}

export const fetchPSList = async (query, token) => {
	try {
		const response = await getAllPS(token, 0, 5, 'desc', 'created_at', query)
		return response.data.data.map(item => ({
			label: item.name,
			value: item.id,
			data: item
		}))
	} catch (error) {
		console.log(error)
	}
}

export const fetchOpportunitySources = async (query, token) => {
	try {
		const response = await getAllOpportunitySources(token, 0, 10, query)
		return response.data.data.map(one => ({
			label: one.name,
			value: one.id
		}))
	} catch (error) {
		console.log(error)
	}
}
export const fetchOpportunitiesList = async (query, token) => {
	try {
		const response = await getAllOpportunities(token, 0, 10, query)
		return response.data.data.map(one => ({
			label: one.name,
			value: one.id
		}))
	} catch (error) {
		console.log(error)
	}
}

export const fetchOpportunitiesListForEnquiries = async (query, token) => {
	try {
		const response = await getAllOpportunities(token, 0, 10, '', '', query, [], 'no')
		return response.data.data.map(one => ({
			label: one.name,
			value: one.id
		}))
	} catch (error) {
		console.log(error)
	}
}

export const fetchContractsList = async (query, token) => {
	try {
		const response = await getAllContracts(token, 0, 10, '', '', query)
		return response.data.data.map(one => ({
			label: one.project_name,
			value: one.id,
			data: one
		}))
	} catch (error) {
		console.log(error)
	}
}

export const fetchProjectsList = async (query, token) => {
	try {
		const response = await getAllProjects(token, 0, 10, '', '', query)
		return response.data.data.map(one => ({
			label: one.name,
			value: one.id,
			data: one
		}))
	} catch (error) {
		console.log(error)
	}
}

export const fetchEntitiesList = async (query, token) => {
	try {
		const response = await getAllEntities(token, 0, 10, '', '', query)
		return response.data.data.map(one => ({
			label: one.name,
			value: one.id,
			data: one
		}))
	} catch (error) {
		console.log(error)
	}
}

export const fetchAssignedTasks = async (query, association, token) => {
	if (association) {
		try {
			const response = await getTasksByAssociationID(token, association, 0, 100)
			return response.data.data.map(q => ({
				label: q.name,
				value: q.id,
				data: q
			}))
		} catch (error) {
			console.log(error)
		}
	} else {
		try {
			const response = await getAllTasks(token, 0, 10, '', '', query)
			return response.data.data.map(q => ({
				label: q.name,
				value: q.id,
				data: q
			}))
		} catch (error) {
			console.log(error)
		}
	}
}

export const fetchAList = (e, association, token) => {
	switch (association) {
		case 'Accounts':
			return fetchAccountList(e, token)
		case 'Contacts':
			return fetchContactsList(e, token)
		case 'Enquiries':
			return fetchEnquiriesList(e, token)
		case 'Leads':
			return fetchLeadsList(e, token)
		case 'Opportunities':
			return fetchOpportunitiesList(e, token)
		case 'Contracts':
			return fetchContractsList(e, token)
		case 'Projects':
			return fetchProjectsList(e, token)
		default:
			return null
	}
}

export const fetchDeepInformation = (id, association, token) => {
	switch (association) {
		case 'Accounts':
			return getAccountById(token, id)
		case 'Contacts':
			return getContactById(token, id)
		case 'Enquiries':
			return getEnquiryById(token, id)
		case 'Leads':
			return getLeadById(token, id)
		case 'Opportunities':
			return getOpportunityByID(token, id)
		case 'Contracts':
			return getContractById(token, id)
		case 'Projects':
			return getProjectById(token, id)
		default:
			return null
	}
}
