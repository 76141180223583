export const SUB_DOMAIN = window.location.host.split('.')[0]

export const BASE_URL = `${window.location.protocol}//${window.location.host}`

// API controllers
const PRE_PRODUCTION = false
const ENABLE_TBG = false
export const UNDER_MAINTENANCE = false

export const urlGenerator = () => {
	if (SUB_DOMAIN === 'tbg-paradigm') {
		return 'https://api.tbg.bellcorps.com/api'
	}
	if (SUB_DOMAIN === 'fi-paradigm') {
		return 'https://api.fi.bellcorps.com/api'
	}
	if (SUB_DOMAIN === 'cert-paradigm') {
		return 'https://api.cert.bellcorps.com/api'
	}
	if (SUB_DOMAIN === 'try-paradigm') {
		return 'https://preprod-paradigm.bellcorps.com/api'
	}
	if (ENABLE_TBG) {
		return 'https://api.tbg.bellcorps.com/api'
	}
	if (PRE_PRODUCTION) {
		return 'https://preprod-paradigm.bellcorps.com/api'
	}
	return 'https://api-paradigm.bellcorps.com/api'
}

export const URL = urlGenerator()

export const MINIO = 'https://minio.bellcorps.com/paragon'
export const ONE_SIGNAL = 'bab58d7c-fbdd-4046-aaa1-b3bf51e50ca9'
