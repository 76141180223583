import { message, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getContactsByAccountID } from '../../../Services/Sales/account'

const ContactsView = ({ id }) => {
	const { token } = useSelector(state => state.userSession)
	const [loading, setLoading] = useState(true)
	const [contactsData, setContactsData] = useState([])

	const fetchData = async () => {
		try {
			const { data } = await getContactsByAccountID(token, id)
			setContactsData(data?.data)
			setLoading(false)
		} catch (error) {
			setContactsData([])
			setLoading(false)
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const enquiryColumn = [
		{
			title: 'Contact Name',
			key: 'contact_name',
			render: (_, record) => (
				<div>
					{record?.first_name} {record?.last_name}
				</div>
			)
		},
		{
			title: 'Email',
			key: 'email',
			dataIndex: 'official_email_address'
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<div className='z-10'>
					<Space size='middle'>
						{/* eslint-disable-next-line */}
						<Link to={`/sales/contacts?id=${record.id}`}>View Contact</Link>
					</Space>
				</div>
			)
		}
	]

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, [])
	return <Table columns={enquiryColumn} dataSource={contactsData} loading={loading} />
}

export default ContactsView
