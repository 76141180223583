import axios from 'axios'
import { URL } from '../../config'

export const getAllContracts = (
	token,
	offset = 0,
	limit = 10,
	sort_order = 'desc',
	sort_by = 'created_at',
	query = ''
) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/contract/all?offset=${offset}&limit=${limit}&sort_order=${sort_order}&sort_by=${sort_by}&query=${query}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const createContract = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'post',
				url: `${URL}/contract/add`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const updateContract = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'put',
				url: `${URL}/contract/edit`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getContractById = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/contract/get/${id}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const createVariation = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'post',
				url: `${URL}/variation/init`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const updateVariation = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'put',
				url: `${URL}/variation/edit`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
export const getVariationById = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/variation/get/${id}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getVariationByContractId = (token, id, offset = 0, limit = 10) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/variation/get-by-contract/${id}?offset=${offset}&limit=${limit}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getContractInvoicingReport = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/contract/contract-invoicing-status/${id}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getContractDialStat = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/contract/get-invoice-stat/${id}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getContractAPRStat = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/invoice/get-apr/${id}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getContractLastSubmittedStat = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/contract/last-submitted/${id}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getContractInvoiceDeviationStat = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/contract/stat/invoice-plan/${id}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
