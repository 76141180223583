import { Avatar, Popover } from 'antd'
import React from 'react'
import { BlockPicker } from 'react-color'
import { colorsArray } from '../Content/projects'

const ColorPicker = ({ color, handleColor }) => (
	<div className='cursor-pointer'>
		<Popover
			content={<BlockPicker color={color} onChangeComplete={handleColor} colors={colorsArray} />}
			trigger='click'
		>
			<Avatar style={{ backgroundColor: color }} />
		</Popover>
	</div>
)

export default ColorPicker
