/* eslint-disable default-param-last */
import { ADD_TASK, DELETE_TASK, EDIT_TASK, RESET_TASK } from '../types'

const tasks = []

// eslint-disable-next-line import/prefer-default-export
export const tasksData = (state = tasks, { type, payload }) => {
	switch (type) {
		case ADD_TASK:
			return [payload, ...state]
		case EDIT_TASK:
			return state.map(x => {
				if (x.id === payload.id) {
					return payload.data
				}
				return x
			})
		case RESET_TASK:
			return payload
		case DELETE_TASK:
			return state.filter(task => task.id !== payload)

		default:
			return state
	}
}
