/* eslint-disable default-param-last */
import {
	ADD_EMPLOYEE,
	ADD_ROLE,
	DELETE_EMPLOYEE,
	DELETE_ROLE,
	EDIT_EMPLOYEE,
	EDIT_ROLE,
	RESET_EMPLOYEE,
	RESET_ROLE
} from '../types'

const employees = []

export const employeesData = (state = employees, { type, payload }) => {
	switch (type) {
		case ADD_EMPLOYEE:
			return [payload, ...state]
		case RESET_EMPLOYEE:
			return payload
		case EDIT_EMPLOYEE:
			return state.map(x => {
				if (x.id === payload.id) {
					return payload.data
				}
				return x
			})
		case DELETE_EMPLOYEE:
			return state.filter(emp => emp.id !== payload)

		default:
			return state
	}
}

const roles = []
export const rolesData = (state = roles, { type, payload }) => {
	switch (type) {
		case ADD_ROLE:
			return [payload, ...state]
		case RESET_ROLE:
			return payload
		case EDIT_ROLE:
			return [payload.data, ...state.filter(role => role.id !== payload.id)]
		case DELETE_ROLE:
			return state.filter(role => role.id !== payload)
		default:
			return state
	}
}
