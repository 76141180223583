/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { Button, message, Tooltip } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { getTaskById, updateTask } from '../Services/task'
import { editTask } from '../Store/Actions/tasks'

const MarkAsCompleted = ({ task_id, status, showExtendedView = false, handleData }) => {
	const currentTimeEntry = useSelector(state => state?.currentTimeEntry)
	const { token } = useSelector(state => state.userSession)
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const handleMarkAsCompleted = async () => {
		setLoading(true)
		try {
			await updateTask(token, { id: task_id, status: 'Completed' })
			const res = await getTaskById(token, task_id)
			dispatch(
				editTask({
					id: task_id,
					data: res.data.data
				})
			)
			if (handleData) {
				handleData()
			}
			message.success('Task marked as completed.')
		} catch (error) {
			message.error('Failed to mark task as completed.')
		} finally {
			setLoading(false)
		}
	}
	return (
		<Tooltip
			title={
				currentTimeEntry
					? 'Stop the task to update the status of this task.'
					: status === 'Completed'
					? 'Task already completed. You can start the task again.'
					: 'Mark as Completed.'
			}
		>
			<Button
				icon={<CheckCircleOutlined />}
				type='link'
				onClick={handleMarkAsCompleted}
				disabled={status === 'Completed' || currentTimeEntry}
				loading={loading}
			>
				{showExtendedView ? 'Mark as Completed' : ''}
			</Button>
		</Tooltip>
	)
}

export default MarkAsCompleted
