import axios from 'axios'
import dayjs from 'dayjs'
import { URL } from '../config'

export const getAllPettyCash = (token, offset = 0, limit = 10) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/petty/all?offset=${offset}&limit=${limit}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const createPettyCash = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'post',
				url: `${URL}/petty/add`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getPettyCashById = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/petty/get/${id}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const updatePettyCashById = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'put',
				url: `${URL}/petty/edit`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const deletePettyCashById = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/petty/delete/${id}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getPettyCashByEmployeeId = (token, id, offset = 0, limit = 10) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/petty/get-by-employee/${id}?offset=${offset}&limit=${limit}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getPettyCashReportByEmployeeId = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/petty/get-report-by-employee/${id}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getAllEmployeesPettyCashReport = (token, offset = 0, limit = 10, query = '') =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/petty/all-employee?offset=${offset}&limit=${limit}&query=${query}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getEmployeeWisePettyCashReport = (token, employeeId = '', startTime = 0, endTime = dayjs().unix()) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/petty/get-employee-record/${employeeId}?start=${startTime}&end=${endTime}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
