import React, { useState } from 'react'
import { Button, Form, Input, message, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { addRole, editRole } from '../../Store/Actions/employees'
import { objectDifference } from '../../Controllers/objectDifference'
import { createRole, updateRole } from '../../Services/roles'
import { worldCurrencies } from '../../Content/worldCurrencies'

const RolesForm = ({ handleClose, edit, data, handleRoleValue }) => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const userSession = useSelector(state => state.userSession)
	const { Option } = Select
	const [form] = Form.useForm()

	const onAdd = async values => {
		const dataObject = {
			...values,
			currency: values.currency || 'USD',
			cost_per_hour: Number(values.cost_per_hour),
			active: true
		}
		setLoading(true)
		try {
			const { data } = await createRole(userSession.token, dataObject)
			dispatch(
				addRole({
					id: data.data.id,
					...dataObject
				})
			)
			if (handleRoleValue) {
				handleRoleValue({
					id: data.data.id,
					...dataObject
				})
			}

			message.success('Resource added successfully!')
			setLoading(false)
			form.resetFields()
			handleClose()
		} catch (error) {
			message.error(error.response?.data?.message || 'Something went wrong!')
			setLoading(false)
		}
	}
	const onEdit = async values => {
		const dataObject = {
			...values,
			currency: values.currency || data?.currency,
			cost_per_hour: Number(values.cost_per_hour),
			active: true
		}
		setLoading(true)
		try {
			const updatedData = objectDifference(data, dataObject)
			await updateRole(userSession.token, { id: data.id, ...updatedData })
			dispatch(
				editRole({
					id: data.id,
					data: { id: data.id, ...dataObject }
				})
			)
			message.success('Resource updated successfully!')
			setLoading(false)
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error.response?.data?.message || 'Something went wrong')
		}
	}

	return (
		<Form
			name='control-hooks'
			form={form}
			onFinish={edit ? onEdit : onAdd}
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			initialValues={edit ? data : false}
		>
			<Form.Item
				label='Resource Name'
				name='name'
				rules={[
					{
						required: true,
						message: 'Please input resource name!'
					}
				]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label='Cost Per Hour'
				name='cost_per_hour'
				rules={[
					{
						required: true,
						message: 'Please input cost per hour!'
					}
				]}
			>
				<Input
					addonBefore={
						<Form.Item name='currency' noStyle>
							<Select
								style={{
									width: 70
								}}
								defaultValue='USD'
								disabled
							>
								{worldCurrencies.slice(0, 5).map(currency => (
									<Option value={currency.code}>{currency.symbol}</Option>
								))}
							</Select>
						</Form.Item>
					}
					style={{
						width: '70%'
					}}
					type='number'
				/>
			</Form.Item>
			<Form.Item label='Resource Level' name='level'>
				<Input />
			</Form.Item>
			<Form.Item label='Region' name='region'>
				<Select placeholder='Select Region' allowClear>
					{['UAE', 'India'].map((opt, i) => (
						<Option value={opt} key={i}>
							{opt}
						</Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
				<Button type='primary' htmlType='submit' loading={loading}>
					Save
				</Button>
			</Form.Item>
		</Form>
	)
}

export default RolesForm
