import { Input, Radio } from 'antd'
import { debounce } from 'lodash'
import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

const TableHeader = ({ handleSearch, showKanban }) => {
	const location = useLocation()
	const history = useHistory()

	const [values, setValues] = useState({
		view: location.pathname.split('/enquiries/')[1] ? 'Board' : 'List'
	})
	const handleKanban = ({ target: { value } }) => {
		if (value === 'Board') {
			history.push('./enquiries/kanban')
		}
		if (value === 'List') {
			history.push('../enquiries')
		}
		setValues(prev => ({ ...prev, view: value }))
	}
	return (
		<div className='flex justify-between items-center py-2'>
			<div className='flex space-x-4 items-center'>
				{showKanban && (
					<Radio.Group value={values.view} options={['Board', 'List']} optionType='button' onChange={handleKanban} />
				)}
			</div>
			<div>
				<Input placeholder='Search' onChange={debounce(handleSearch, 300)} />
			</div>
		</div>
	)
}

export default TableHeader
