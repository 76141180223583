import _ from 'lodash'
import dayjs from 'dayjs'
import React from 'react'
import { Link } from 'react-router-dom'

const New = ({ data }) => {
	const lead = data
	const refactor = {
		..._.omit(lead, [
			'lead_account_name',
			'lead_owner_name',
			'working_data',
			'nurturing_data',
			'closed_data',
			'attachments',
			'created_at',
			'updated_at',
			'id',
			'share_only',
			'access_specifier',
			'access_type',
			'share_and_edit'
		]),
		account_name: <Link to={`./accounts?id=${lead?.account_name}`}>{lead?.lead_account_name}</Link>,
		lead_created_on: dayjs.unix(lead.created_at).format('DD MMM YYYY'),
		lead_owner: lead?.lead_owner_name,
		date_of_birth: lead.date_of_birth ? dayjs.unix(lead.date_of_birth).format('DD MMM YYYY') : undefined,
		mobile_phone: lead.mobile_phone ? <a href={`tel:${lead.mobile_phone}`}>{lead.mobile_phone}</a> : null,
		official_email_address: lead.official_email_address && (
			<a href={`mailto:${lead.official_email_address}`}> {lead.official_email_address}</a>
		),
		personal_email_address: lead.personal_email_address && (
			<a href={`mailto:${lead.personal_email_address}`}> {lead.personal_email_address}</a>
		),
		fax: lead.fax && <a href={`tel:${lead.fax}`}>{lead.fax}</a>,
		office_phone: lead.office_phone && <a href={`tel:${lead.office_phone}`}>{lead.office_phone}</a>,
		other_phone: lead.other_phone && <a href={`tel:${lead.other_phone}`}>{lead.other_phone}</a>,
		home_phone: lead.home_phone && <a href={`tel:${lead.home_phone}`}>{lead.home_phone}</a>,
		linkedin: lead.linkedin && <a href={`https://www.linkedin.com/in/${lead.linkedin}/`}>{lead.linkedin}</a>,
		twitter_id: lead.twitter_id && <a href={`https://twitter.com/${lead.twitter_id}/`}>{lead.twitter_id}</a>
	}
	return (
		<div>
			<div className='font-bold text-base text-bell-text py-2'> New</div>
			<div className='p-4 space-y-4'>
				{Object.keys(_.omit(refactor, ['contact_person_data', 'account_id', 'end_user_account_id']))
					.filter(item => refactor[item])
					.map((item, i) => (
						<div className='grid grid-cols-3 lg:grid-cols-5 gap-4' key={i}>
							<div className='capitalize col-span-1 lg:col-span-1 text-bell-gray'>{item.replaceAll('_', ' ')}</div>
							<div className='col-span-2 lg:col-span-2'> {refactor[item]}</div>
						</div>
					))}
			</div>
		</div>
	)
}

export default New
