/* eslint-disable import/prefer-default-export */
export const whatsNew = {
	version: '9.3',
	date: '25-10-2024',
	content: [
		{
			title: 'Delete your tasks',
			description:
				'Tasks can now be deleted. It is however important to note that your tasks are associated with your projects and timesheets and must be only deleted in case of a mistake. This feature is available in Task Viewer.',
			link: '/tasks',
			tagName: 'Feature'
		},
		{
			title: 'Design Library Update',
			description:
				'We have migrated to a new version of Ant Design and some changes are expected. Please report any issues if you encounter any.',
			tagName: 'Important'
		},
		{
			title: 'My Projects',
			description:
				'We have added a new filter to the projects page. You can now view all projects or only projects that you are part of.',
			link: '/projects/projects',
			tagName: 'Feature'
		}
	]
}
