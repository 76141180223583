import { QuestionCircleFilled } from '@ant-design/icons'
import { Button, Popconfirm } from 'antd'
import React from 'react'
import { RED } from '../styles/colors'

const DeleteItem = ({ popTitle, popDescription, onDelete, loading, ...props }) => (
	<Popconfirm
		title={popTitle || 'Are you sure to delete?'}
		description={popDescription ? <div className='w-80'>{popDescription}</div> : null}
		onConfirm={onDelete}
		okText='Delete'
		cancelText='Cancel'
		okButtonProps={{ danger: true }}
		icon={<QuestionCircleFilled style={{ color: RED }} />}
	>
		<Button type={props.buttonType || 'link'} danger loading={loading} disabled={props.disabled}>
			{props.children || 'Delete'}
		</Button>
	</Popconfirm>
)

export default DeleteItem
