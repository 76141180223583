import { ADD_PS, EDIT_PS, RESET_PS, BULK_ADD_PS } from '../types'

export const addPS = payload => ({
	type: ADD_PS,
	payload
})
export const bulkAddPS = payload => ({
	type: BULK_ADD_PS,
	payload
})
export const editPS = payload => ({
	type: EDIT_PS,
	payload
})
export const resetPS = payload => ({
	type: RESET_PS,
	payload
})
