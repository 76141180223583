import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Space, message, Table, Divider } from 'antd'

import _ from 'lodash'
import { v4 } from 'uuid'
import { DrawerComponent, InternalHeader, Stats } from '../../Components'
import { getEntityStats } from '../../Services/stats'
import { getAllEntities } from '../../Services/Contracts/entity'
import { InvoiceEntityForm } from '../../Components/Forms'
import { MINIO } from '../../config'

// eslint-disable-next-line react/no-unstable-nested-components
const ImageDisplay = ({ fileName }) => (
	<a href={`${MINIO}/${fileName}`} target='_blank' rel='noreferrer'>
		<img src={`${MINIO}/${fileName}`} className='w-52 object-cover rounded-md' alt='entity attachment' />
	</a>
)

const Entities = () => {
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		sortOrder: 'desc',
		sortField: 'created_at',
		total: ''
	})
	const [search, setSearch] = useState('')
	const [stats, setStats] = useState(null)
	const [entityData, setEntityData] = useState([])

	const handleSearch = e => {
		setSearch(e.target.value)
	}
	const [addDrawer, setAddDrawer] = useState(false)
	const [editDrawer, setEditDrawer] = useState(false)
	const userSession = useSelector(state => state.userSession)

	const fetchData = async (token, current, pageSize, sortOrder, sortField, query) => {
		try {
			const { data } = await getAllEntities(token, current, pageSize, sortOrder, sortField, query)
			if (!data.data) {
				setEntityData([])
			} else {
				setEntityData(data.data)
			}
			setPagination({
				current: current + 1,
				pageSize,
				sortOrder,
				sortField,
				total: data?.count
			})
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const handleChange = (page, filters, sorter) => {
		const sortOrder = {
			ascend: 'asc',
			descend: 'desc'
		}
		fetchData(
			userSession.token,
			page.current - 1,
			page.pageSize,
			sortOrder[sorter.order],
			sorter.order ? sorter.columnKey : '',
			search
		)
	}

	useEffect(() => {
		fetchData(userSession.token, 0, pagination.pageSize, pagination.sortOrder, pagination.sortField, search)
		// eslint-disable-next-line
	}, [search])

	const getStats = async token => {
		try {
			const { data } = await getEntityStats(token)
			setStats(data.data)
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	useEffect(() => {
		getStats(userSession.token)
		// eslint-disable-next-line
	}, [entityData])

	const statsData = {
		title: 'Entities',
		total: stats?.entities,
		data: []
	}
	const columns = [
		{
			title: 'Entity Name',
			dataIndex: 'name',
			sorter: true,
			key: 'name'
		},
		{
			title: 'Country',
			dataIndex: 'country',
			sorter: true,
			key: 'country'
		},
		{
			title: 'Email',
			dataIndex: 'email',
			sorter: true,
			key: 'email'
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<Space size='middle'>
					<DrawerComponent
						form={
							<InvoiceEntityForm
								edit
								handleClose={() => setEditDrawer(false)}
								data={record}
								handleValues={w =>
									setEntityData(prev =>
										prev.map(one => {
											if (one.id === w.id) return w
											return one
										})
									)
								}
							/>
						}
						visible={editDrawer[record.id]}
						onOpen={() => setEditDrawer({ [record.id]: true })}
						onClose={() => setEditDrawer(false)}
						buttonTitle='Edit'
						buttonType='link'
						drawerWidth='800'
					/>
				</Space>
			)
		}
	]

	const expandedRowRender = record => {
		const data = {
			..._.omit(record, [
				'signatory_id',
				'id',
				'key',
				'bank_accounts',
				'tax_details',
				'trn',
				'created_at',
				'updated_at',
				'logo_file'
			]),
			logo: record.logo_file && <ImageDisplay fileName={record.logo_file} />,
			signature_file: record.signature_file && <ImageDisplay fileName={record.signature_file} />,
			stamp_file: record.stamp_file && <ImageDisplay fileName={record.stamp_file} />,
			TRN: record.trn,
			signatory_name: record.signatory_id && record.signatory_name,
			_bank_accounts: record?.bank_accounts?.length && (
				<Table
					dataSource={record?.bank_accounts}
					columns={[
						{
							title: 'Bank Account Name',
							dataIndex: 'account_name',
							key: 'account_name'
						},
						{
							title: 'Account Number',
							dataIndex: 'account_number',
							key: 'account_number'
						},
						{
							title: 'Bank Name',
							dataIndex: 'bank_name',
							key: 'bank_name'
						},
						{
							title: 'SWIFT / IFSC Code',
							dataIndex: 'code',
							key: 'code'
						},
						{
							title: 'IBAN',
							dataIndex: 'iban',
							key: 'iban'
						},
						{
							title: 'Currency',
							dataIndex: 'currency',
							key: 'currency'
						},
						{
							title: 'Street Address',
							dataIndex: 'street_address',
							key: 'street_address'
						}
					]}
					pagination={false}
					size='small'
				/>
			),
			_tax_details: record?.tax_details?.length && (
				<div>
					<Divider />
					<Table
						dataSource={record?.tax_details}
						columns={[
							{
								title: 'Tax Name',
								dataIndex: 'tax_name',
								key: 'tax_name'
							},
							{
								title: 'Tax',
								dataIndex: 'tax',
								key: 'tax',
								render: (_, record) => record.tax && `${record.tax}%`
							}
						]}
						pagination={false}
						size='small'
					/>
				</div>
			)
		}
		return (
			<div>
				<div className='text-center font-medium py-4 text-lg'>Entity Overview</div>
				<div className='p-4 space-y-4 bg-white'>
					{Object.keys(data)
						.filter(item => data[item])
						.map((item, i) => (
							<div className='grid grid-cols-4 gap-4' key={i}>
								<div className='capitalize col-span-1 text-bell-gray'>{item.replaceAll('_', ' ')}</div>
								<div className='col-span-3'> {data[item]}</div>
							</div>
						))}
				</div>
			</div>
		)
	}

	return (
		<div className='bg-bell-background'>
			<InternalHeader title='Organization' selected='Entities' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Entities </div>
				<div>
					<div>
						<DrawerComponent
							visible={addDrawer}
							onOpen={() => setAddDrawer(true)}
							onClose={() => setAddDrawer(false)}
							buttonTitle='Add Entity'
							drawerWidth='800'
							form={
								<InvoiceEntityForm
									handleClose={() => setAddDrawer(false)}
									handleValues={w => setEntityData(prev => [w, ...prev])}
								/>
							}
						/>
					</div>
				</div>
			</div>
			<div className='grid grid-flow-col px-10'>
				<div className='col-span-2'>
					<Stats data={statsData} nograph />
				</div>
			</div>
			<div className='px-10 mt-10'>
				<Table
					columns={columns}
					dataSource={entityData?.map(one => ({ ...one, key: v4() }))}
					pagination={pagination}
					handleChange={handleChange}
					handleSearch={handleSearch}
					expandedRowRender={record => expandedRowRender(record)}
				/>
			</div>
		</div>
	)
}

export default Entities
