import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { getToken } from './Common'

const PublicRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={props =>
			!getToken() ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
						pathname: '/'
					}}
				/>
			)
		}
	/>
)

export default PublicRoute
