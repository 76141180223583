import React from 'react'
import { Space } from 'antd'
import { SwapOutlined } from '@ant-design/icons'

const CustomTimePickerItem = ({ value, onChange, form }) => {
	const error = form.getFieldsError(['timer'])

	const handleChange = (type, e) => {
		onChange({
			...value,
			[type]: e.target.value
		})
	}

	const inputStyle = 'w-[70px] border-0 focus:underline focus:outline-none font-sans text-sm  time-input-no-spinner'
	return (
		<Space
			className={`border-solid border-[1px]  rounded-md px-3 py-[5px] text-gray-400 hover:border-bell-blue focus-within:border-bell-blue ${
				error[0]?.errors?.length ? 'border-red-500' : 'border-gray-300'
			}`}
		>
			<span>From</span>
			<input
				type='time'
				className={inputStyle}
				defaultValue={value?.start || ''}
				onChange={e => {
					handleChange('start', e)
				}}
			/>
			<SwapOutlined style={{ color: error[0]?.errors?.length ? 'red' : 'gray' }} />
			<span>To</span>
			<input
				type='time'
				className={inputStyle}
				defaultValue={value?.end || ''}
				onChange={e => {
					handleChange('end', e)
				}}
			/>
		</Space>
	)
}

export default CustomTimePickerItem
