import React from 'react'
import { Table } from 'antd'
import { useHistory } from 'react-router-dom'
import '../index.css'
import { v4 } from 'uuid'

const TableComponent = ({ columns, data, type, pagination, rowClickable, handleChange, loading }) => {
	const history = useHistory()
	return (
		<div style={{ width: '100%', overflow: 'auto' }}>
			<Table
				columns={columns}
				dataSource={data ? data.map(x => ({ key: v4(), ...x })) : []}
				pagination={pagination}
				loading={loading || false}
				onChange={handleChange}
				onRow={record => {
					if (rowClickable) {
						return {
							onClick: () => {
								history.push(`./${type}?id=${record.id}`)
							}
						}
					}
					return null
				}}
			/>
		</div>
	)
}

export default TableComponent
