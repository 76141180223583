/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Breadcrumb, Button, DatePicker, Empty, Spin, message, Tabs, Tag, Table } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { Bar, Line } from 'react-chartjs-2'
import { Link } from 'react-router-dom'
import { InfoCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { InternalHeader, SelectDebounce } from '../../Components'
import { addTimesheetReport } from '../../Store/Actions/reports'
import {
	getTimesheetReportPerProject,
	getTimesheetReportPerUser,
	getTimesheetReportPerDeliverable,
	getTimesheetReportPerActivity
} from '../../Services/reports'
import { fetchUserList } from '../../Controllers/fetchLists'
import ReporteeSelect from '../../Components/ReporteeSelect'
import dateFormat from '../../Content/dateFormat'
import { DownloadExcel } from '../../Components/DownloadExcel'
import { getTimesheetByUserId } from '../../Services/timesheet'
import { associationEnumURL, timeCategory } from '../../Content/timetracker'
import { timeDuration } from '../../Controllers/timeDuration'
import * as Colors from '../../styles/colors'
import { ERROR_MESSAGE } from '../../Content/messages'

const TimesheetsReport = () => {
	// states
	const { token, role } = useSelector(state => state.userSession)
	const { timesheetReport } = useSelector(state => state.reportsData)
	const { first_name, last_name, id } = useSelector(state => state.userProfile)
	const { user, start_time, end_time } = timesheetReport
	const [reportData, setReportData] = useState(null)
	const [timesheetData, setTimesheetData] = useState(null)
	const [currentView, setCurrentView] = useState({ type: 'all' })
	const [loading, setLoading] = useState(false)
	const [timesheetLoader, setTimesheetLoader] = useState(false)

	// hooks
	const dispatch = useDispatch()

	const fetchTimesheet = async () => {
		setTimesheetLoader(true)
		setTimesheetData(null)
		try {
			const { data } = await getTimesheetByUserId(
				token,
				// either id selected from dropdown or id of the logged in user.
				user.id || id,
				'',
				// start from 0 if no start time mentioned
				start_time ? dayjs(start_time).unix() : 0,
				// end with today if no end time mentioned
				end_time ? dayjs(end_time).unix() : dayjs().unix()
			)
			if (!data.data) {
				setTimesheetData(null)
			} else {
				setTimesheetData(data.data)
			}
		} catch (error) {
			message.error(error?.response?.data?.message || ERROR_MESSAGE)
		} finally {
			setTimesheetLoader(false)
		}
	}

	// fetch timesheet by report by user id, start time, and end time
	const fetchDataForAllProjects = async () => {
		try {
			const { data } = await getTimesheetReportPerUser(
				token,
				// either id selected from dropdown or id of the logged in user.
				user.id || id,
				// start from 0 if no start time mentioned
				start_time ? dayjs(start_time).unix() : 0,
				// end with today if no end time mentioned
				end_time ? dayjs(end_time).unix() : dayjs().unix()
			)
			if (!data.data) {
				setReportData(null)
			} else {
				setReportData(
					data.data.map(a => ({
						name: a.project_name,
						id: a.project_id,
						time: a.time_spent
					}))
				)
			}
		} catch (error) {
			// message.error(error?.response?.data?.message || ERROR_MESSAGE)
		}
	}

	const fetchDataPerProject = async () => {
		try {
			const { data } = await getTimesheetReportPerProject(
				token,
				// either id selected from dropdown or id of the logged in user.
				user.id || id,
				currentView?.project?.id,
				// start from 0 if no start time mentioned
				start_time ? dayjs(start_time).unix() : 0,
				// end with today if no end time mentioned
				end_time ? dayjs(end_time).unix() : dayjs().unix()
			)
			if (!data.data) {
				setReportData(null)
			} else {
				setReportData(
					data.data.map(a => ({
						name: a.deliverable_name,
						id: a.deliverable_id,
						time: a.time_spent
					}))
				)
			}
		} catch (error) {
			message.error(error?.response?.data?.message || ERROR_MESSAGE)
			setCurrentView({ type: 'all' })
		}
	}

	const fetchDataPerDeliverable = async () => {
		try {
			const { data } = await getTimesheetReportPerDeliverable(
				token,
				// either id selected from dropdown or id of the logged in user.
				user.id || id,
				currentView?.project?.id,
				currentView?.deliverable?.id,
				// start from 0 if no start time mentioned
				start_time ? dayjs(start_time).unix() : 0,
				// end with today if no end time mentioned
				end_time ? dayjs(end_time).unix() : dayjs().unix()
			)
			if (!data.data) {
				setReportData(null)
			} else {
				setReportData(
					data.data.map(a => ({
						name: a.activity_name,
						id: a.activity_id,
						time: a.time_spent
					}))
				)
			}
		} catch (error) {
			message.error(error?.response?.data?.message || ERROR_MESSAGE)
			setCurrentView(prev => ({
				...prev,
				type: 'project',
				deliverable: null,
				activity: null,
				task: null
			}))
		}
	}

	const fetchDataPerActivity = async () => {
		try {
			const { data } = await getTimesheetReportPerActivity(
				token,
				// either id selected from dropdown or id of the logged in user.
				user.id || id,
				currentView?.project?.id,
				currentView?.deliverable?.id,
				currentView?.activity?.id,
				// start from 0 if no start time mentioned
				start_time ? dayjs(start_time).unix() : 0,
				// end with today if no end time mentioned
				end_time ? dayjs(end_time).unix() : dayjs().unix()
			)
			if (!data.data) {
				setReportData(null)
			} else {
				setReportData(
					data.data.map(a => ({
						name: a.task_name,
						id: a.task_id,
						time: a.time_spent
					}))
				)
			}
		} catch (error) {
			message.error(error?.response?.data?.message || ERROR_MESSAGE)
			setCurrentView(prev => ({ ...prev, type: 'deliverable', activity: null, task: null }))
		}
	}

	const fetchDataPerTask = async () => {
		try {
			const { data } = await getTimesheetByUserId(
				token,
				// either id selected from dropdown or id of the logged in user.
				user.id || id,
				currentView?.task?.id,
				// start from 0 if no start time mentioned
				start_time ? dayjs(start_time).unix() : 0,
				// end with today if no end time mentioned
				end_time ? dayjs(end_time).unix() : dayjs().unix()
			)
			if (!data.data) {
				setReportData(null)
			} else {
				setReportData(
					data.data
						.sort((a, b) => a.start_time - b.start_time)
						.map(a => ({
							name: a.description,
							id: a.id,
							time: a.end_time ? a.end_time - a.start_time : dayjs().unix() - a.start_time,
							date: dayjs.unix(a.start_time).format(dateFormat)
						}))
				)
			}
		} catch (error) {
			message.error(error?.response?.data?.message || ERROR_MESSAGE)
			setCurrentView(prev => ({ ...prev, type: 'activity', task: null }))
		}
	}

	const fetchData = async () => {
		setLoading(true)
		switch (currentView?.type) {
			case 'all':
				await fetchDataForAllProjects()
				break
			case 'project':
				await fetchDataPerProject()
				break
			case 'deliverable':
				await fetchDataPerDeliverable()
				break
			case 'activity':
				await fetchDataPerActivity()
				break
			case 'task':
				await fetchDataPerTask()
				break
			default:
				return null
		}
		setLoading(false)
		return null
	}

	// fetch report every time the user or time inputs change or the view changes
	useEffect(() => {
		fetchData()
	}, [timesheetReport, currentView])

	useEffect(() => {
		fetchTimesheet()
	}, [timesheetReport])

	const barData = reportData
		? {
				datasets: [
					{
						label: 'Hours',
						data: reportData.map(a => ({
							x: currentView.type === 'task' ? a.date : a.name,
							y: (a.time || 0) / 3600,
							id: a.id,
							name: a.name
						})),
						backgroundColor: 'rgba(54, 162, 235, 0.4)',
						borderColor: 'rgb(54, 162, 235)',
						borderWidth: 1
					}
				]
		  }
		: null

	const chartOptions = {
		scales: {
			x: {
				offset: true
			}
		},
		plugins: {
			legend: {
				labels: {
					font: {
						family: 'Fira Sans'
					}
				}
			},
			tooltip: {
				callbacks: {
					title(context) {
						return currentView.type === 'task' ? context[0].raw.name : context[0].label
					}
				}
			}
		},
		onClick: (e, w) => {
			const { datasetIndex } = w[0]
			const dataIndex = w[0].index
			const value = e.chart.data.datasets[datasetIndex].data[dataIndex]
			switch (currentView.type) {
				case 'all':
					setCurrentView(prev => ({ ...prev, type: 'project', project: { id: value.id, name: value.x } }))
					break
				case 'project':
					setCurrentView(prev => ({ ...prev, type: 'deliverable', deliverable: { id: value.id, name: value.x } }))
					break
				case 'deliverable':
					setCurrentView(prev => ({ ...prev, type: 'activity', activity: { id: value.id, name: value.x } }))
					break
				case 'activity':
					setCurrentView(prev => ({ ...prev, type: 'task', task: { id: value.id, name: value.x } }))
					break
				default:
					break
			}
		}
	}

	// export as excel sheet
	const excelData =
		reportData?.length && timesheetData?.length
			? {
					Overview: [
						{
							'User Name': user?.name || `${first_name} ${last_name}`,
							'Start Time': start_time ? dayjs(start_time).format(dateFormat) : 'Start of Work',
							'End Time': end_time ? dayjs(end_time).format(dateFormat) : 'Today',
							'Project Name': currentView?.project?.name,
							'Deliverable Name': currentView?.deliverable?.name,
							'Activity Name': currentView?.activity?.name,
							'Task Name': currentView?.task?.name
						}
					],
					'Item - Hours Timesheet': reportData.map(a => ({
						Name: a.name,
						Hours: (a.time || 0) / 3600
					})),
					'Detailed Timesheet': timesheetData.map(a => ({
						Description: a.description,
						Category: a.category,
						Association: a.association,
						'Associated With': a.association_name,
						Tag: a.tag,
						'Start Time': dayjs.unix(a.start_time).format(dateFormat),
						'End Time': a.end_time ? dayjs.unix(a.end_time).format(dateFormat) : 'In Progress'
					}))
			  }
			: null

	const columns = [
		{
			title: 'Description',
			key: 'description',
			dataIndex: 'description'
		},
		{
			title: 'Category',
			key: 'category',
			dataIndex: 'category'
		},
		{
			title: 'Associated With',
			key: 'association_name',
			render: (_, record) =>
				record.association_id && record.association_id ? (
					<div>
						<div className='text-xs text-bell-gray uppercase'>{record.association}</div>
						<Link to={`..${associationEnumURL[record.association]}?id=${record.association_id}`}>
							{record.association_name}
						</Link>
					</div>
				) : (
					<div className='border border-solid border-bell-blue w-2 ml-6' />
				)
		},
		{
			title: 'Tag',
			key: 'tags',
			dataIndex: 'tags',
			render: (_, rec) => <Tag color={timeCategory.filter(a => a.tagName === rec.tag)[0]?.color}>{rec.tag}</Tag>
		},
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			render: (_, rec) =>
				rec.start_time && !timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange ? (
					dayjs.unix(rec.start_time).format(dateFormat)
				) : (
					<Tag />
				)
		},
		{
			title: 'Start',
			dataIndex: 'start_time',
			key: 'start_time',
			render: (_, rec) =>
				rec.start_time
					? dayjs
							.unix(rec.start_time)
							.format(timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange ? dateFormat : 'hh:mm A')
					: null
		},
		{
			title: 'End',
			key: 'end_time',
			dataIndex: 'end_time',
			render: (_, rec) =>
				rec.end_time ? (
					dayjs
						.unix(rec.end_time)
						.format(timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange ? dateFormat : 'hh:mm A')
				) : (
					<Tag color={Colors.GREEN}> In Progress </Tag>
				)
		},
		{
			title: 'Duration',
			key: 'duration',
			dataIndex: 'duration',
			render: (_, rec) => {
				const duration = rec.end_time
					? moment.duration(dayjs.unix(rec.end_time).diff(dayjs.unix(rec.start_time)))
					: false

				if (duration) {
					if (timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange) {
						return timeDuration(duration, 'days')
					}
					return timeDuration(duration, 'table')
				}
				return null
			}
		}
	]

	const items = [
		{
			key: '1',
			label: `Graph View`,
			children: reportData ? (
				<div className='w-1/2 m-auto'>
					<Breadcrumb>
						<Breadcrumb.Item onClick={() => setCurrentView({ type: 'all' })} className='cursor-pointer'>
							All Projects
						</Breadcrumb.Item>
						<Breadcrumb.Item
							onClick={() =>
								setCurrentView(prev => ({
									...prev,
									type: 'project',
									deliverable: null,
									activity: null,
									task: null
								}))
							}
							className='cursor-pointer'
						>
							{currentView?.project?.name}
						</Breadcrumb.Item>
						<Breadcrumb.Item
							onClick={() => setCurrentView(prev => ({ ...prev, type: 'deliverable', activity: null, task: null }))}
							className='cursor-pointer'
						>
							{currentView?.deliverable?.name}
						</Breadcrumb.Item>
						<Breadcrumb.Item
							onClick={() => setCurrentView(prev => ({ ...prev, type: 'activity', task: null }))}
							className='cursor-pointer'
						>
							{currentView?.activity?.name}
						</Breadcrumb.Item>
						<Breadcrumb.Item
							onClick={() => setCurrentView(prev => ({ ...prev, type: 'task' }))}
							className='cursor-pointer'
						>
							{currentView?.task?.name}
						</Breadcrumb.Item>
					</Breadcrumb>
					{currentView.type === 'task' ? (
						<Line data={barData} options={chartOptions} className='my-4' />
					) : (
						<Bar data={barData} options={chartOptions} className='my-4' />
					)}
					<div className='text-gray-600 pr-10 flex items-center gap-2' hidden={currentView.task}>
						<InfoCircleOutlined />
						Click on the blue bar of any item to know in-depth details.
					</div>
				</div>
			) : (
				<Empty description='No project report available!' className='py-8' />
			)
		},
		{
			key: '2',
			label: `Detailed List View`,
			children: <Table columns={columns} dataSource={timesheetData} pagination={false} loading={timesheetLoader} />
		}
	]

	return (
		<div className='bg-bell-background'>
			<InternalHeader title='Reports' selected='All Reports' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Timesheets Report </div>
			</div>
			<div className='px-10'>
				<div className='flex flex-row-reverse justify-between items-center'>
					<div className='flex space-x-4'>
						<DownloadExcel
							data={excelData}
							fileName={`Timesheet Report for ${user?.name || `${first_name} ${last_name}`}.xlsx`}
							disabled={timesheetLoader}
						/>
					</div>
					<div className='flex space-x-4 text-xs text-gray-600'>
						{role === 'Admin' ? (
							<SelectDebounce
								showSearch
								placeholder='Search for Users'
								fetchOptions={e => fetchUserList(e, token)}
								currentSearchValue={user?.name || `${first_name} ${last_name}`}
								onChange={e => dispatch(addTimesheetReport({ user: { name: e.label, id: e.value } }))}
								style={{ width: 200 }}
							/>
						) : (
							<ReporteeSelect
								currentValue={user?.name || `${first_name} ${last_name}`}
								onChange={w => dispatch(addTimesheetReport({ user: { name: w.label, id: w.id } }))}
							/>
						)}
						<DatePicker.RangePicker
							format={dateFormat}
							onChange={e => {
								dispatch(addTimesheetReport({ user, start_time: e[0], end_time: e[1] }))
							}}
							value={[start_time ? dayjs(start_time) : null, end_time ? dayjs(end_time) : null]}
						/>
						{/* reset button if any other user or any other time selected other than default */}
						<Button
							disabled={!user.id && !start_time && !end_time}
							onClick={() => dispatch(addTimesheetReport({ user: { name: '', id: '' } }))}
						>
							Reset
						</Button>
					</div>
				</div>
				<div className='pb-10'>
					<section className='text-2xl font-semibold py-4'>
						Project Timesheet:{' '}
						<span className='font-medium text-bell-blue'>{user?.name || `${first_name} ${last_name}`}</span>
						<span className='text-base text-bell-text font-normal pl-2'>
							{start_time
								? `(${dayjs(start_time).format(dateFormat)} - ${dayjs(end_time).format(dateFormat)})`
								: '(All-time)'}
						</span>
					</section>

					{loading ? <Spin /> : <Tabs defaultActiveKey='1' items={items} />}
				</div>
			</div>
		</div>
	)
}

export default TimesheetsReport
