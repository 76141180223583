import axios from 'axios'
import { URL } from '../config'

export const getSalesReport = (token, id, duration) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/stats/report?id=${id}&duration=${duration}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getCountriesList = token =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/utility/get-countries`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getLeadsAndAccountsByCountryReport = (token, country) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/utility/leads-accounts-by-country/${country}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getAccountsByCountry = (token, country) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/account/get-by-country/${country}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getEnquiriesByAccountReport = (token, account) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/enquiry/get-report-by-account/${account}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getEnquiriesByActiveOrLostStatusReport = (token, status) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/enquiry/get-active?status=${status}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getClientEngagementReport = (token, duration) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/activity/get-client-report?d=${duration}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

// timesheet reports

export const getTimesheetPerUser = (token, userId) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/timesheet/report/time-by-users/${userId}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

// user > projects
export const getTimesheetReportPerUser = (token, userId, startTime, endTime) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/timesheet/report/time-by-project?id=${userId}&start=${startTime}&end=${endTime}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

// user > project > deliverables
export const getTimesheetReportPerProject = (token, userId, projectId, startTime, endTime) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/timesheet/report/time-by-deliverable?id=${userId}&pid=${projectId}&start=${startTime}&end=${endTime}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

// user > project > deliverable > activities
export const getTimesheetReportPerDeliverable = (token, userId, projectId, deliverableId, startTime, endTime) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/timesheet/report/time-by-activity?id=${userId}&pid=${projectId}&did=${deliverableId}&start=${startTime}&end=${endTime}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

// user > project > deliverable > activity > tasks
export const getTimesheetReportPerActivity = (
	token,
	userId,
	projectId,
	deliverableId,
	activityId,
	startTime,
	endTime
) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/timesheet/report/time-per-activity?id=${userId}&pid=${projectId}&did=${deliverableId}&aid=${activityId}&start=${startTime}&end=${endTime}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

// user > project > tasks
export const getTasksTimesheetPerProjectPerUser = (token, userId, projectId) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/timesheet/report/tasks-by-project?id=${userId}&pid=${projectId}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

// project > time for all users
export const getProjectUtilizationReport = (token, projectId) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/timesheet/report/time-by-users/${projectId}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

// project > deliverables / activity / tasks time
export const getProjectDurationReport = (token, projectId) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/timesheet/report/deliverable-by-project/${projectId}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
