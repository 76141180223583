/* eslint-disable import/prefer-default-export */
import React from 'react'
import { Button } from 'antd'
import XLSX from 'xlsx'
import { FileExcelOutlined } from '@ant-design/icons'

export const DownloadExcel = ({ data, fileName, sheetName, disabled }) => {
	const handleClick = () => {
		const wb = XLSX.utils.book_new()
		if (typeof data === 'object') {
			Object.keys(data).forEach(a => {
				const ws = XLSX.utils.json_to_sheet(data[a])
				XLSX.utils.book_append_sheet(wb, ws, a)
			})
		} else {
			const ws = XLSX.utils.json_to_sheet(data)
			XLSX.utils.book_append_sheet(wb, ws, sheetName)
		}
		XLSX.writeFile(wb, fileName)
	}
	return (
		<Button icon={<FileExcelOutlined />} onClick={handleClick} disabled={disabled}>
			Download in Excel
		</Button>
	)
}
