import React, { useState } from 'react'
import { Button, message, Upload } from 'antd'
import * as XLSX from 'xlsx'
import { useDispatch, useSelector } from 'react-redux'

import { InboxOutlined, DownloadOutlined } from '@ant-design/icons'
import * as Colors from '../../../styles/colors'
import { bulkUploadPS } from '../../../Services/products-services'
import { bulkAddPS } from '../../../Store/Actions/products-services'

const { Dragger } = Upload
const PSBulk = ({ handleClose }) => {
	const dispatch = useDispatch()
	const { token } = useSelector(state => state.userSession)
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState([])

	const onUpload = async () => {
		const serialArray = [...new Set(data.map(one => one['S.No.']))]
		const dataArray = serialArray.map(serial => {
			const filteredArray = data.filter(x => x['S.No.'] === serial)
			const firstRow = filteredArray[0]
			return {
				name: firstRow['Product Name']?.toString(),
				type: firstRow['Product Type']?.toString(),
				code: firstRow['Product Code']?.toString(),
				sku: firstRow.SKU?.toString(),
				unit: firstRow.Unit?.toString(),
				description: firstRow.Description?.toString(),
				billable_tasks:
					firstRow['Task Name'] && firstRow['Task Description']
						? filteredArray.map(x => ({
								name: x['Task Name']?.toString(),
								description: x['Task Description']?.toString()
						  }))
						: []
			}
		})
		setLoading(true)
		try {
			const res = await bulkUploadPS(token, dataArray)
			const { ids } = res.data.data
			const updater = dataArray.map((product, i) => ({ id: ids[i], ...product }))
			dispatch(bulkAddPS(updater))
			message.success('Products & Services saved successfully!')
			setLoading(false)
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error.response?.data?.message || 'Something went wrong')
		}
	}

	const props = {
		name: 'file',
		multiple: false,
		accept: '.xlsx',
		onChange(e) {
			e.file.status = 'success'
			const f = e.file.originFileObj
			const reader = new FileReader()
			reader.onload = event => {
				const w = event.target.result
				const read = XLSX.read(w, { type: 'binary', cellDates: true })
				const worksheetName = read.SheetNames[0]
				const ws = read.Sheets[worksheetName]
				const parsedData = XLSX.utils.sheet_to_json(ws)
				setData(parsedData)
			}
			reader.readAsBinaryString(f)
		}
	}

	return (
		<div className='w-full'>
			<div>Please download the template to ease the process of upload.</div>

			<a
				href={`${process.env.PUBLIC_URL}/ps-excel-template.xlsx`}
				download='Paradigm Products & Services Template'
				target='_blank'
				rel='noreferrer'
				className='flex space-x-2 items-end my-4'
			>
				<div>
					<DownloadOutlined style={{ color: Colors.BLUE, fontSize: 14 }} />
				</div>
				<div className='text-bell-blue font-semibold text-sm'>Download Template</div>
			</a>
			<div>
				<Dragger {...props}>
					<p className='ant-upload-drag-icon'>
						<InboxOutlined />
					</p>
					<p className='ant-upload-text'>Click or drag file to this area to upload</p>
					<p className='ant-upload-hint'>Please read instructions and check example in the template.</p>
				</Dragger>
			</div>
			<div className='mt-4'>
				<Button type='primary' htmlType='button' loading={loading} onClick={onUpload} disabled={data.length === 0}>
					Upload
				</Button>
			</div>
		</div>
	)
}

export default PSBulk
