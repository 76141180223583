/* eslint-disable default-param-last */
import { ADD_NOTE, DELETE_NOTE, RESET_NOTE } from '../types'

const notes = []

// eslint-disable-next-line import/prefer-default-export
export const notesData = (state = notes, { type, payload }) => {
	switch (type) {
		case ADD_NOTE:
			return [payload, ...state]

		case RESET_NOTE:
			return payload

		case DELETE_NOTE:
			return state.filter(note => note.id !== payload)

		default:
			return state
	}
}
