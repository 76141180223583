import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { message, Tag } from 'antd'
import { AiTwotoneEdit } from 'react-icons/ai'
import dayjs from 'dayjs'
import _ from 'lodash'
import * as Colors from '../../../styles/colors'
import {
	ActivityTimeline,
	Details,
	Files,
	Information,
	LogInformation,
	Notes,
	Overview,
	TasksView,
	Title
} from '../../../Components/Views'
import { DrawerComponent, InternalHeader, Spinner } from '../../../Components'
import { OpportunitiesForm, TaskForm } from '../../../Components/Forms'
import OpportunityStepper from '../../../Components/Internal/OpportunityStepper'
import AddToCalendar from '../../../Components/AddToCalendar'
import dateFormat from '../../../Content/dateFormat'
import { getOpportunityByID, updateOpportunity } from '../../../Services/Sales/opportunity'
import accounting from '../../../Controllers/accountingNumbers'
import renderShareTag from '../../../Controllers/shareTag'
import ShareModal from '../../../Components/ShareModal'
import ExpensesView from '../../../Components/Views/Details/ExpensesView'

const SingleOpportunity = () => {
	const history = useHistory()
	const location = useLocation()
	const query = new URLSearchParams(location.search)
	const id = query.get('id')
	const { token, id: userId } = useSelector(state => state.userSession)
	const refreshComponent = useSelector(state => state.refreshComponent)

	const [editDrawer, setEditDrawer] = useState(false)
	const [shareDrawer, setShareDrawer] = useState(false)
	const [thisData, setThisData] = useState(false)

	const accessType = thisData?.access_specifier
	const shareType = thisData?.access_type

	const fetchThisOpportunity = async () => {
		try {
			const { data } = await getOpportunityByID(token, id)
			if (!data.data) {
				history.push('/sales/opportunities')
			}
			setThisData(data.data)
		} catch (error) {
			message.error('Something went wrong!')
			history.push('/sales/opportunities')
		}
	}
	useEffect(() => {
		fetchThisOpportunity()
		// eslint-disable-next-line
	}, [editDrawer, shareDrawer, refreshComponent])

	const handleFiles = async (currentAttachment, fileName) => {
		try {
			await updateOpportunity(token, {
				id: thisData.id,
				attachments: [...thisData.attachments, currentAttachment]
			})
			setThisData(prev => ({ ...prev, attachments: [...prev.attachments, currentAttachment] }))
			message.success(`${fileName} uploaded successfully`)
		} catch (error) {
			message.error(`Uploading ${fileName} failed. Try again.`)
		}
	}

	const handleRemoveAsset = async fileName => {
		try {
			await updateOpportunity(token, {
				id: thisData.id,
				attachments: thisData.attachments.filter(x => x !== fileName)
			})
			setThisData(prev => ({ ...prev, attachments: prev.attachments.filter(x => x !== fileName) }))
			message.success(`${fileName} removed successfully`)
		} catch (error) {
			message.error(`Deleting ${fileName} failed. Try again.`)
		}
	}

	const handleSharing = async sharingData => {
		try {
			const dataObject = {
				id: thisData.id,
				...sharingData
			}
			await updateOpportunity(token, dataObject)
			message.success(`Opportunity updated successfully`)
			setShareDrawer(false)
		} catch (error) {
			message.error(`Something went wrong!`)
		}
	}
	const overviewData = {
		..._.omit(thisData, [
			'decision_makers_procurement_data',
			'decision_makers_registration_data',
			'decision_makers_technical_data',
			'decision_makers_procurement',
			'decision_makers_registration',
			'decision_makers_technical',
			'related_accounts_data',
			'source_data',
			'owner',
			'name',
			'attachments',
			'account_id',
			'stage',
			'ps',
			'converted_to_name'
		]),
		converted_to: thisData.converted_to_name && (
			<Link to={`./enquiries?id=${thisData?.converted_to}`}>{thisData?.converted_to_name}</Link>
		),
		account_name: thisData.account_name && (
			<Link to={`./accounts?id=${thisData?.account_id}`}>{thisData?.account_name}</Link>
		),
		source: thisData?.source_data?.name,
		closing_date:
			thisData.closing_date !== 0 ? (
				<AddToCalendar
					subject={`Closing Date for ${thisData.name}`}
					description={thisData.description}
					unixDate={thisData.closing_date}
				/>
			) : undefined,
		date: thisData.date ? dayjs.unix(thisData.date).format(dateFormat) : undefined,
		'Decision Makers (Registration / Prequal)':
			thisData?.decision_makers_registration?.length > 0 &&
			thisData.decision_makers_registration_data.map(one => (
				<div>
					<Link to={`./leads?id=${one?.id}`}>{one.name}</Link>
				</div>
			)),
		'Decision Makers (Technical)':
			thisData?.decision_makers_technical?.length > 0 &&
			thisData.decision_makers_technical_data.map(one => (
				<div>
					<Link to={`./leads?id=${one?.id}`}>{one.name}</Link>
				</div>
			)),
		'Decision Makers (Procurement)':
			thisData?.decision_makers_procurement?.length > 0 &&
			thisData.decision_makers_procurement_data.map(one => (
				<div>
					<Link to={`./leads?id=${one?.id}`}>{one.name}</Link>
				</div>
			)),
		related_accounts:
			thisData?.related_accounts_data?.length > 0 &&
			thisData.related_accounts_data.map(one => (
				<div>
					<Link to={`./accounts?id=${one?.id}`}>{one.name}</Link>
				</div>
			)),
		expected_budget: thisData.expected_budget && `USD ${accounting(thisData.expected_budget)}`,
		'Products & Services': thisData.ps
	}
	const details = {
		leftColumn: [
			{
				name: 'Overview',
				content: <Overview data={overviewData} />
			},
			{
				name: 'Details',
				content: (
					<Details
						data={[
							{
								name: 'Expenses',
								content: <ExpensesView id={id} />
							},
							{
								name: 'Notes',
								content: <Notes association_id={id} accessType={accessType} />
							},
							{
								name: 'Files',
								content: (
									<Files
										id={id}
										attachments={thisData?.attachments || []}
										handleFiles={handleFiles}
										handleRemove={handleRemoveAsset}
										accessType={accessType}
										tabView
									/>
								)
							}
						]}
					/>
				)
			},
			{
				name: 'Timeline',
				content: <ActivityTimeline association_id={id} />
			},
			{
				name: 'Tasks',
				content: <TasksView id={thisData?.id} />
			}
		],
		rightColumn: [
			{
				name: 'Activity',
				content: (
					<Details
						data={[
							{
								name: 'Log Information',
								content: <LogInformation association_id={id} association_type='Opportunities' />
							},
							{
								name: 'New Task',
								content: <TaskForm association_id={id} />
							}
						]}
					/>
				)
			}
		]
	}
	return (
		<div>
			<InternalHeader title='Sales' selected='Opportunities' />
			{thisData ? (
				<div className='bg-bell-background px-10 py-6 min-h-screen space-y-10'>
					<Title
						title='Opportunity'
						name={thisData.name}
						hasSubtitle
						extraDetail={
							<div className='space-y-2'>
								{thisData.converted_to && <Tag color={Colors.GREEN}> CONVERTED TO ENQUIRY </Tag>}
								<div>
									<Tag color={userId === thisData?.owner ? Colors.GREEN : Colors.LIGHT_GREY}>
										{renderShareTag({
											shareType,
											isOwner: userId === thisData?.owner,
											accessType
										})}
									</Tag>
								</div>
							</div>
						}
						editButton={
							thisData.converted_to ? (
								false
							) : (
								<DrawerComponent
									form={
										<OpportunitiesForm
											edit
											handleClose={() => {
												setEditDrawer(false)
											}}
											data={thisData}
										/>
									}
									visible={editDrawer}
									onOpen={() => setEditDrawer(true)}
									onClose={() => setEditDrawer(false)}
									buttonTitle={<AiTwotoneEdit color={Colors.BLUE} fontSize={20} />}
									buttonType='link'
									drawerTitle='Edit Opportunity'
									drawerWidth={900}
									isHidden={accessType !== 'Public'}
								/>
							)
						}
						shareButton={
							<ShareModal
								visible={shareDrawer}
								onOpen={() => setShareDrawer(true)}
								onClose={() => setShareDrawer(false)}
								modalTitle='Share Opportunity'
								isHidden={accessType !== 'Public'}
								handleValues={handleSharing}
								data={thisData}
							/>
						}
						overview={[
							{
								title: 'Account Name',
								value: thisData?.account_name && (
									<Link to={`./accounts?id=${thisData?.account_id}`}>{thisData?.account_name}</Link>
								)
							},
							{
								title: 'Closing Date',
								value: thisData.closing_date !== 0 && dayjs.unix(thisData?.closing_date).format(dateFormat)
							},
							{
								title: 'Opportunity Owner',
								value: thisData?.owner_name
							},
							{
								title: 'Converted To',
								value: thisData.converted_to && (
									<Link to={`./enquiries?id=${thisData.converted_to}`}>
										{thisData?.converted_to_name || 'This Enquiry'}
									</Link>
								)
							}
						]}
					/>

					{(accessType === 'Public' || accessType === 'Protect') && (
						<div className='w-2/3'>
							<OpportunityStepper data={thisData} accessType={accessType} />
						</div>
					)}

					<Information details={details} accessType={accessType} />
				</div>
			) : (
				<Spinner />
			)}
		</div>
	)
}

export default SingleOpportunity
