import { getContractById } from '../Services/Contracts/contracts'
import { getProjectById } from '../Services/projects'
import { getAccountById } from '../Services/Sales/account'
import { getContactById } from '../Services/Sales/contacts'
import { getEnquiryById } from '../Services/Sales/enquiries'
import { getLeadById } from '../Services/Sales/leads'
import { getOpportunityByID } from '../Services/Sales/opportunity'
import {
	fetchAccountList,
	fetchContactsList,
	fetchContractsList,
	fetchEnquiriesList,
	fetchLeadsList,
	fetchOpportunitiesList,
	fetchProjectsList,
	fetchPSList
} from './fetchLists'

// eslint-disable-next-line import/prefer-default-export
export const fetchDeepInformation = (id, association, token) => {
	switch (association) {
		case 'Project':
			return getProjectById(token, id)
		case 'Contract':
			return getContractById(token, id)
		case 'Account':
			return getAccountById(token, id)
		case 'Contact':
			return getContactById(token, id)
		case 'Enquiry':
			return getEnquiryById(token, id)
		case 'Lead':
			return getLeadById(token, id)
		case 'Opportunity':
			return getOpportunityByID(token, id)
		case 'Product / Service':
			return { data: { data: { access_specifier: 'Public' } } }
		default:
			return null
	}
}

export const fetchLists = (e, association, token) => {
	switch (association) {
		case 'Project':
			return fetchProjectsList(e, token)
		case 'Contract':
			return fetchContractsList(e, token)
		case 'Account':
			return fetchAccountList(e, token)
		case 'Contact':
			return fetchContactsList(e, token)
		case 'Enquiry':
			return fetchEnquiriesList(e, token)
		case 'Lead':
			return fetchLeadsList(e, token)
		case 'Opportunity':
			return fetchOpportunitiesList(e, token)
		case 'Product / Service':
			return fetchPSList(e, token)
		default:
			return null
	}
}
