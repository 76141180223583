import {
	ADD_ACCOUNT,
	EDIT_ACCOUNT,
	DELETE_ACCOUNT,
	RESET_ACCOUNT,
	SINGLE_ACCOUNT,
	UPDATE_SINGLE_ACCOUNT
} from '../types'

export const addAccount = payload => ({
	type: ADD_ACCOUNT,
	payload
})

export const editAccount = payload => ({
	type: EDIT_ACCOUNT,
	payload
})

export const deleteAccount = payload => ({
	type: DELETE_ACCOUNT,
	payload
})

export const resetAccount = payload => ({
	type: RESET_ACCOUNT,
	payload
})

export const addSingleAccount = payload => ({
	type: SINGLE_ACCOUNT,
	payload
})

export const updateSingleAccount = payload => ({
	type: UPDATE_SINGLE_ACCOUNT,
	payload
})
