import { message, Radio, Table, Timeline } from 'antd'
import dayjs from 'dayjs'
import React, { useState, useEffect } from 'react'
import { MdCall, MdEmail } from 'react-icons/md'
import { BiRevision } from 'react-icons/bi'
import { useDispatch, useSelector } from 'react-redux'
import * as Colors from '../../../styles/colors'
import dateFormat from '../../../Content/dateFormat'
import { getActivitiesByAssociationID } from '../../../Services/activity'
import { timelineColumns } from '../../../Content/activity'
import { resetActivity } from '../../../Store/Actions/activity'

const ActivityTimeline = ({ association_id }) => {
	const [view, setView] = useState('Table')
	const { token } = useSelector(state => state.userSession)
	const activitiesData = useSelector(state => state.activitiesData)
	const dispatch = useDispatch()

	const fetchActivities = async (current, pageSize) => {
		try {
			const { data } = await getActivitiesByAssociationID(token, association_id, current, pageSize)
			if (!data.data) {
				dispatch(resetActivity([]))
			} else {
				dispatch(resetActivity(data.data))
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}
	useEffect(() => {
		fetchActivities(0, 10)
		// eslint-disable-next-line
	}, [])

	const iconSelector = w => {
		switch (w) {
			case 'Call':
				return (
					<div className='bg-bell-background grid place-items-center'>
						<MdCall style={{ color: Colors.RED, fontSize: '18px' }} />
					</div>
				)
			case 'Email':
				return (
					<div className='bg-bell-background grid place-items-center'>
						<MdEmail style={{ color: Colors.GREEN, fontSize: '18px' }} />
					</div>
				)
			case 'Revision':
				return (
					<div className='bg-bell-background grid place-items-center'>
						<BiRevision style={{ color: Colors.BLUE, fontSize: '18px' }} />
					</div>
				)
			default:
				return null
		}
	}

	return (
		<div className='flex space-x-2'>
			<div className='w-full'>
				<div className='pb-2'>
					<Radio.Group
						value={view}
						options={['Table', 'Timeline']}
						optionType='button'
						onChange={({ target: { value } }) => setView(value)}
					/>
				</div>
				{view === 'Table' ? (
					<Table columns={timelineColumns} dataSource={activitiesData} pagination={false} />
				) : (
					<div className='mt-5 h-screen overflow-auto'>
						<Timeline mode='alternate'>
							{activitiesData.map(item => (
								<Timeline.Item dot={iconSelector(item?.activity_type)} style={{ paddingTop: 2 }}>
									<div className='space-y-2'>
										<div>
											<div className='text-lg text-bell-text'> {item?.activity_type} </div>
											<div className='text-sm text-gray-600'>{item.subject}</div>
										</div>
										<div className='text-xs text-bell-gray'>
											<span className='pr-5'> {item.created_by_name}</span>
											<span> {dayjs.unix(item.created_at).format(dateFormat)} </span>
										</div>
									</div>
								</Timeline.Item>
							))}
						</Timeline>
					</div>
				)}
			</div>
		</div>
	)
}

export default ActivityTimeline
