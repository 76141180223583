import React, { useState } from 'react'
import { AiTwotoneEdit } from 'react-icons/ai'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import DrawerComponent from '../../../DrawerComponent'
import * as Colors from '../../../../styles/colors'
import NurturingLeadForm from '../../../Forms/Leads/NurturingLeadForm'

const Nurturing = ({ data, isFilled, editable }) => {
	const [form, setForm] = useState(false)
	const refactor = data
		? {
				...data,
				enquiry: data.enquiry && <Link to={`./enquiries?id=${data.enquiry}`}>{data.enquiry_name} </Link>
		  }
		: null
	return (
		<div>
			<div className='flex items-center'>
				<div className='font-bold text-base text-bell-text py-2'> Nurturing</div>
				{isFilled && editable && (
					<DrawerComponent
						form={<NurturingLeadForm edit data={data} handleClose={() => setForm(false)} />}
						visible={form}
						onOpen={() => setForm(true)}
						onClose={() => setForm(false)}
						buttonTitle={<AiTwotoneEdit color={Colors.BLUE} fontSize={20} />}
						buttonType='link'
						drawerTitle='Nurturing'
						drawerWidth='1000'
					/>
				)}
			</div>

			{isFilled ? (
				<div className='p-4 space-y-4'>
					{Object.keys(_.omit(refactor, ['enquiry_name']))
						.filter(item => refactor[item])
						.map((item, i) => (
							<div className='grid grid-cols-3 lg:grid-cols-5 gap-4' key={i}>
								<div className='capitalize col-span-1 lg:col-span-1 text-bell-gray'>{item.replaceAll('_', ' ')}</div>
								<div className='col-span-2 lg:col-span-2'> {refactor[item]}</div>
							</div>
						))}
				</div>
			) : (
				<div>
					<div className='text-bell-gray text-sm py-2'>No Information Available</div>
					{editable && (
						<DrawerComponent
							form={<NurturingLeadForm handleClose={() => setForm(false)} />}
							visible={form}
							onOpen={() => setForm(true)}
							onClose={() => setForm(false)}
							buttonTitle='Add Information'
							drawerTitle='Nurturing'
							drawerWidth='1000'
						/>
					)}
				</div>
			)}
		</div>
	)
}

export default Nurturing
