import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Space, message } from 'antd'

import { DrawerComponent, InternalHeader, Stats, DataView, DeleteItem } from '../../Components'
import RolesForm from '../../Components/Forms/RolesForm'
import {
	getAllRoles,
	updateRole
	// getRoleStats
} from '../../Services/roles'
import { deleteRole, resetRole } from '../../Store/Actions/employees'
import accounting from '../../Controllers/accountingNumbers'
import { getRoleStats } from '../../Services/stats'

const Roles = () => {
	const dispatch = useDispatch()
	const rolesData = useSelector(state => state.rolesData)
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		sortOrder: 'desc',
		sortField: 'created_at',
		total: ''
	})
	const [search, setSearch] = useState('')
	const [stats, setStats] = useState(null)
	const handleSearch = e => {
		setSearch(e.target.value)
	}

	const [addRolesDrawer, setAddRolesDrawer] = useState(false)
	const [editRolesDrawer, setEditRolesDrawer] = useState(false)
	const userSession = useSelector(state => state.userSession)

	const fetchData = async (token, current, pageSize, sortOrder, sortField, query) => {
		try {
			const { data } = await getAllRoles(token, current, pageSize, sortOrder, sortField, query)
			if (!data.data) {
				dispatch(resetRole([]))
			} else {
				dispatch(resetRole(data.data))
			}
			setPagination({
				current: current + 1,
				pageSize,
				sortOrder,
				sortField,
				total: data.count
			})
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const handleChange = (page, filters, sorter) => {
		const sortOrder = {
			ascend: 'asc',
			descend: 'desc'
		}
		fetchData(
			userSession.token,
			page.current - 1,
			page.pageSize,
			sortOrder[sorter.order],
			sorter.order ? sorter.columnKey : '',
			filters.role ? filters.role[0] : search
		)
	}

	useEffect(() => {
		fetchData(userSession.token, 0, pagination.pageSize, pagination.sortOrder, pagination.sortField, search)
		// eslint-disable-next-line
	}, [search])

	const getStats = async token => {
		try {
			const { data } = await getRoleStats(token)
			setStats(data.data)
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}
	useEffect(() => {
		getStats(userSession.token)
		// eslint-disable-next-line
	}, [rolesData])

	const onDelete = async id => {
		const updatedData = {
			id,
			active: false
		}
		try {
			await updateRole(userSession.token, updatedData)
			message.success('Role Archived Successfully.')
			dispatch(deleteRole(id))
		} catch (error) {
			message.error(error.response?.data?.message || 'Something went wrong')
		}
	}
	const statsData = {
		title: 'Resources',
		total: stats?.roles,
		data: []
	}
	const columns = [
		{
			title: 'Resource Name',
			dataIndex: 'name',
			sorter: true,
			key: 'name'
		},
		{
			title: 'Cost Per Hour',
			sorter: true,
			key: 'cost_per_hour',
			render: (_, record) => (
				<div>
					{record.currency} {accounting(record.cost_per_hour)}
				</div>
			)
		},
		{
			title: 'Resource Level',
			dataIndex: 'level',
			sorter: true,
			key: 'level'
		},
		{
			title: 'Region',
			dataIndex: 'region',
			sorter: true,
			key: 'region'
		},
		{
			title: 'Actions',

			key: 'actions',
			render: (text, record) => (
				<Space size='middle'>
					<DrawerComponent
						form={<RolesForm edit handleClose={() => setEditRolesDrawer(false)} data={record} />}
						visible={editRolesDrawer[record.id]}
						onOpen={() => setEditRolesDrawer({ [record.id]: true })}
						onClose={() => setEditRolesDrawer(false)}
						buttonTitle='Edit'
						buttonType='link'
						drawerWidth='500'
					/>
					<DeleteItem
						onDelete={() => {
							onDelete(record.id)
						}}
						popTitle='Are you sure to archive this resource?'
					/>
				</Space>
			)
		}
	]
	return (
		<div className='bg-bell-background'>
			<InternalHeader title='Employees' selected='Resources' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Resources </div>
				<div>
					<div>
						<DrawerComponent
							visible={addRolesDrawer}
							onOpen={() => setAddRolesDrawer(true)}
							onClose={() => setAddRolesDrawer(false)}
							buttonTitle='Add Resource'
							drawerWidth='500'
							form={<RolesForm handleClose={() => setAddRolesDrawer(false)} />}
						/>
					</div>
				</div>
			</div>
			<div className='grid grid-flow-col px-10'>
				<div className='col-span-2'>
					<Stats data={statsData} nograph />
				</div>
			</div>
			<div className='px-10 mt-10'>
				<DataView
					columns={columns}
					data={rolesData}
					pagination={pagination}
					handleChange={handleChange}
					handleSearch={handleSearch}
				/>
			</div>
		</div>
	)
}

export default Roles
