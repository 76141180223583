import { ADD_CONTACT, EDIT_CONTACT, RESET_CONTACT, SINGLE_CONTACT, UPDATE_SINGLE_CONTACT } from '../types'

export const addContact = payload => ({
	type: ADD_CONTACT,
	payload
})

export const editContact = payload => ({
	type: EDIT_CONTACT,
	payload
})

export const resetContact = payload => ({
	type: RESET_CONTACT,
	payload
})

export const addSingleContact = payload => ({
	type: SINGLE_CONTACT,
	payload
})

export const updateSingleContact = payload => ({
	type: UPDATE_SINGLE_CONTACT,
	payload
})
