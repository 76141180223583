// Bank Guarantees & Tender Bonds

import axios from 'axios'
import { URL } from '../config'

export const getAllGuarantees = (token, offset = 0, limit = 10, sort_order = '', sort_by = '', query = '', filter) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/guarantee/all?offset=${offset}&limit=${limit}&sort_order=${sort_order}&sort_by=${sort_by}&query=${query}&filter=${filter}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const registerGuarantee = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'post',
				url: `${URL}/guarantee/add`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
export const updateGuarantee = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'put',
				url: `${URL}/guarantee/edit`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getGuaranteeById = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/guarantee/get/${id}`,

				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const deleteGuaranteeById = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/guarantee/delete/${id}`,

				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getGuaranteesByAssociationID = (
	token,
	id,
	offset = 0,
	limit = 10,
	sort_order = 'desc',
	sort_by = 'created_at'
) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/guarantee/get-by-contract/${id}?offset=${offset}&limit=${limit}&sort_order=${sort_order}&sort_by=${sort_by}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
