import { message, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getChildAccountByAccountID } from '../../../Services/Sales/account'

const ChildAccountView = ({ id }) => {
	const { token } = useSelector(state => state.userSession)
	const [loading, setLoading] = useState(true)
	const [childData, setChildData] = useState([])

	// GET ENQUIRIES BY ACCOUNT ID
	const fetchData = async () => {
		try {
			const { data } = await getChildAccountByAccountID(token, id)
			setChildData(data?.data)
			setLoading(false)
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const enquiryColumn = [
		{
			title: 'Account Name',
			key: 'account_name',
			dataIndex: 'account_name'
		},
		{
			title: 'Email',
			key: 'email',
			dataIndex: 'email'
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<div className='z-10'>
					<Space size='middle'>
						{/* eslint-disable-next-line */}
						<a href={`/sales/accounts?id=${record.id}`}>View Account</a>
					</Space>
				</div>
			)
		}
	]

	// FETCH DATA ON PAGE LOAD
	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, [])
	return <Table columns={enquiryColumn} dataSource={childData} loading={loading} />
}

export default ChildAccountView
