/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import { ADD_PS, BULK_ADD_PS, EDIT_PS, RESET_PS } from '../types'

const ps = []

export const productsServicesData = (state = ps, { type, payload }) => {
	switch (type) {
		case ADD_PS:
			return [payload, ...state]
		case EDIT_PS:
			return state.map(x => {
				if (x.id === payload.id) {
					return payload.data
				}
				return x
			})
		case BULK_ADD_PS:
			return [...payload, ...state]
		case RESET_PS:
			return payload
		default:
			return state
	}
}
