/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react'
import { Spin, message } from 'antd'
import { useSelector } from 'react-redux'
import { Bar } from 'react-chartjs-2'
import { Link } from 'react-router-dom'

import { InternalHeader, SelectDebounce } from '../../Components'
import { getProjectUtilizationReport } from '../../Services/reports'
import { fetchProjectsList } from '../../Controllers/fetchLists'
import { DownloadExcel } from '../../Components/DownloadExcel'
import NoData from '../../Components/NoData'

const ProjectTimeUtilizationReport = () => {
	// states
	const { token } = useSelector(state => state.userSession)
	const [loading, setLoading] = useState(false)
	const [selectedProject, setSelectedProject] = useState(null)
	const [reportData, setReportData] = useState(null)

	// fetch timesheet by report by user id, start time, and end time
	const fetchData = async projectId => {
		setLoading(true)
		try {
			const { data } = await getProjectUtilizationReport(token, projectId)

			if (!data.data) {
				setReportData(null)
			} else {
				setReportData(
					data.data.map(a => ({
						name: a.user_name,
						id: a.id,
						time: a.time_spent
					}))
				)
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		} finally {
			setLoading(false)
		}
	}

	const barData = reportData
		? {
				datasets: [
					{
						label: 'Hours',
						data: reportData.map(a => ({
							x: a.name,
							y: (a.time || 0) / 3600,
							id: a.id,
							name: a.name
						})),
						backgroundColor: 'rgba(54, 162, 235, 0.4)',
						borderColor: 'rgb(54, 162, 235)',
						borderWidth: 1
					}
				]
		  }
		: null

	const chartOptions = {
		scales: {
			x: {
				offset: true
			}
		},
		plugins: {
			legend: {
				labels: {
					font: {
						family: 'Fira Sans'
					}
				}
			}
		}
	}

	// export as excel sheet
	const excelData = reportData?.length
		? {
				Timesheet: reportData.map(a => ({
					Name: a.name,
					Hours: (a.time || 0) / 3600
				}))
		  }
		: null

	return (
		<div className='bg-bell-background'>
			<InternalHeader title='Reports' selected='All Reports' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Time Utilization Report </div>
			</div>
			<div className='px-10'>
				<div className='flex flex-row-reverse justify-between items-center'>
					<div className='flex space-x-4'>
						<DownloadExcel data={excelData} fileName={`Timesheet Report for ${selectedProject?.name}.xlsx`} />
					</div>
					<div className='flex space-x-4 text-xs text-gray-600'>
						<SelectDebounce
							showSearch
							placeholder='Search for Projects'
							fetchOptions={e => fetchProjectsList(e, token)}
							onChange={e => {
								setSelectedProject({
									name: e.label,
									id: e.value
								})
								fetchData(e.value)
							}}
							style={{ width: 400 }}
						/>
					</div>
				</div>
				<div>
					{selectedProject?.name && (
						<section className='text-2xl font-semibold pt-4'>
							Project Utilization Report: <span className='font-medium text-bell-blue'>{selectedProject?.name}</span>
						</section>
					)}
					{reportData ? (
						<div>
							<div className='w-1/2 m-auto'>
								<Bar data={barData} options={chartOptions} className='my-4' />
							</div>
							<div className='text-gray-600'>
								To know a particular user&apos;s in-depth time utilization for all projects, go to
								<Link to='./timesheet-report'> Timesheet Report</Link>.
							</div>
						</div>
					) : loading ? (
						<Spin />
					) : (
						<NoData title={selectedProject ? 'No Timesheet available for this project.' : 'Please select a project!'} />
					)}
				</div>
			</div>
		</div>
	)
}

export default ProjectTimeUtilizationReport
