import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { message, Tag } from 'antd'
import { AiTwotoneEdit } from 'react-icons/ai'

import _ from 'lodash'
import * as Colors from '../../styles/colors'

// COMPONENTS
import {
	ActivityTimeline,
	ChildAccountView,
	ContactsView,
	Details,
	EnquiriesView,
	Information,
	LeadsView,
	LogInformation,
	Notes,
	Overview,
	TasksView,
	Title
} from '../../Components/Views'
import { DrawerComponent, InternalHeader, Spinner } from '../../Components'
import { addSingleAccount } from '../../Store/Actions/accounts'
import { getAccountById, updateAccount } from '../../Services/Sales/account'
import accounting from '../../Controllers/accountingNumbers'
import { AccountForm, TaskForm } from '../../Components/Forms'
import renderShareTag from '../../Controllers/shareTag'
import ShareModal from '../../Components/ShareModal'
import ExpensesView from '../../Components/Views/Details/ExpensesView'

const SingleAccount = () => {
	const history = useHistory()
	const location = useLocation()
	const dispatch = useDispatch()
	const query = new URLSearchParams(location.search)
	const id = query.get('id')
	const { token, id: userId } = useSelector(state => state.userSession)
	const [editAccountDrawer, setEditAccountDrawer] = useState(false)
	const [shareDrawer, setShareDrawer] = useState(false)
	const [loader, setLoader] = useState(true)
	const refreshComponent = useSelector(state => state.refreshComponent)
	const singleAccountsData = useSelector(state => state.singleAccountsData)

	const accessType = singleAccountsData?.access_specifier
	const shareType = singleAccountsData?.access_type

	const fetchSingleAccount = async () => {
		try {
			const { data } = await getAccountById(token, id)
			if (!data.data) {
				history.push('/sales/accounts')
			}
			dispatch(addSingleAccount(data.data))
			setLoader(false)
		} catch (error) {
			message.error('Something wrong happened!')
			history.push('/sales/accounts')
		}
	}

	// fetch account information on every refreshComponent update and changes in share drawer.
	useEffect(() => {
		fetchSingleAccount()
		// eslint-disable-next-line
	}, [refreshComponent, shareDrawer])

	const handleSharing = async sharingData => {
		try {
			const dataObject = {
				id: singleAccountsData.id,
				...sharingData
			}
			await updateAccount(token, dataObject)
			message.success(`Account updated successfully`)
			setShareDrawer(false)
		} catch (error) {
			message.error(`Something went wrong!`)
		}
	}

	const overviewData = {
		..._.omit(singleAccountsData, ['parent_account_name', 'owner_name']),
		owner: singleAccountsData?.owner_name,
		email: singleAccountsData.email && <a href={`mailto:${singleAccountsData?.email}`}> {singleAccountsData?.email}</a>,
		fax: singleAccountsData.fax && <a href={`tel:${singleAccountsData.fax}`}> {singleAccountsData.fax} </a>,
		phone: singleAccountsData.phone && <a href={`tel:${singleAccountsData.phone}`}> {singleAccountsData.phone} </a>,
		website: singleAccountsData.website && <a href={singleAccountsData.website}>{singleAccountsData.website}</a>,
		parent_account: singleAccountsData.parent_account_name && (
			<a href={`?id=${singleAccountsData.parent_account}`}> {singleAccountsData.parent_account_name}</a>
		),
		annual_revenue: singleAccountsData.annual_revenue && `USD ${accounting(singleAccountsData.annual_revenue)}`
	}
	const details = {
		leftColumn: [
			{
				name: 'Overview',
				content: <Overview data={overviewData} />
			},
			{
				name: 'Details',
				content: (
					<Details
						data={[
							{
								name: 'Child Accounts',
								content: <ChildAccountView id={id} />
							},
							{
								name: 'Enquiries',
								content: <EnquiriesView id={id} />
							},
							{
								name: 'Leads',
								content: <LeadsView id={id} />
							},
							{
								name: 'Contacts',
								content: <ContactsView id={id} />
							},
							{
								name: 'Expenses',
								content: <ExpensesView id={id} />
							},
							{
								name: 'Notes',
								content: <Notes association_id={id} accessType={accessType} />
							}
						]}
					/>
				)
			},
			{
				name: 'Timeline',
				content: <ActivityTimeline association_id={id} />
			},
			{
				name: 'Tasks',
				content: <TasksView id={id} />
			}
		],
		rightColumn: [
			{
				name: 'Activity',
				content: (
					<Details
						data={[
							{
								name: 'Log Information',
								content: <LogInformation association_id={id} association_type='Accounts' />
							},
							{
								name: 'New Task',
								content: <TaskForm association_id={id} />
							}
						]}
					/>
				)
			}
		]
	}
	return (
		<div>
			<InternalHeader title='Sales' selected='Accounts' />
			{singleAccountsData && !loader ? (
				<div className='bg-bell-background px-10 py-6 min-h-screen space-y-10'>
					<Title
						title='Account'
						name={
							<div className='flex items-center space-x-2'>
								{singleAccountsData.website && (
									<img alt='' src={`//logo.clearbit.com/${singleAccountsData?.website}?size=40`} />
								)}
								<div> {singleAccountsData?.account_name}</div>
							</div>
						}
						hasSubtitle
						extraDetail={
							<div className='space-y-2'>
								<div>
									<Tag color={userId === singleAccountsData?.owner ? Colors.GREEN : Colors.LIGHT_GREY}>
										{renderShareTag({
											shareType,
											isOwner: userId === singleAccountsData?.owner,
											accessType
										})}
									</Tag>
								</div>
							</div>
						}
						editButton={
							<DrawerComponent
								form={
									<AccountForm
										edit
										handleClose={() => {
											setEditAccountDrawer(false)
										}}
										data={singleAccountsData}
									/>
								}
								visible={editAccountDrawer}
								onOpen={() => setEditAccountDrawer(true)}
								onClose={() => setEditAccountDrawer(false)}
								buttonTitle={<AiTwotoneEdit color={Colors.BLUE} fontSize={20} />}
								buttonType='link'
								drawerTitle='Edit Account'
								isHidden={accessType !== 'Public'}
							/>
						}
						shareButton={
							<ShareModal
								visible={shareDrawer}
								onOpen={() => setShareDrawer(true)}
								onClose={() => setShareDrawer(false)}
								modalTitle='Share Account'
								isHidden={accessType !== 'Public'}
								handleValues={handleSharing}
								data={singleAccountsData}
							/>
						}
						overview={[
							{
								title: 'Account Name',
								value: singleAccountsData?.account_name
							},
							{
								title: 'Email Address',
								value: <a href={`mailto:${singleAccountsData?.email}`}> {singleAccountsData?.email}</a>
							},
							{
								title: 'Parent Account',
								value: (
									<a href={`?id=${singleAccountsData?.parent_account}`}>{singleAccountsData?.parent_account_name}</a>
								)
							},
							{
								title: 'Account Owner',
								// value: `${account?.first_name} ${account?.last_name}`
								value: `${singleAccountsData?.owner_name}`
							}
						]}
					/>
					<Information details={details} accessType={accessType} />
				</div>
			) : (
				<Spinner />
			)}
		</div>
	)
}

export default SingleAccount
