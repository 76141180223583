import React, { useEffect, useState } from 'react'
import { MenuOutlined } from '@ant-design/icons'

import { Route, Redirect, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Menu } from 'antd'

import { BiError } from 'react-icons/bi'
import Header from '../Components/Header'
import renderFilteredMenu from '../Controllers/renderMenu'
import Maintenance from '../Components/Maintenance'
import { UNDER_MAINTENANCE } from '../config'
import { BACKGROUND } from '../styles/colors'
import { getUserById } from '../Services/user'
import { logout } from '../Store/Actions/userSession'

const { Content, Sider } = Layout

function getItem(label, key, icon, children, url) {
	return {
		key,
		icon,
		children,
		label,
		url
	}
}

export const moduleItems = role => [
	...renderFilteredMenu()
		.filter(menu => menu.roles?.indexOf(role) !== -1)
		.map((item, i) =>
			getItem(
				item.title,
				i,
				<Link to={item.url} key={i}>
					{item.icon}
				</Link>,
				item.children.length > 1
					? item.children.map((a, j) =>
							getItem(
								<Link to={a.url} key={i}>
									{a.title}
								</Link>,
								`${i}-${j}`,
								''
							)
					  )
					: null,
				item.url
			)
		)
]

const PrivateRoute = ({ component: Component, name, ...rest }) => {
	const [collapsed, setCollapsed] = useState(true)
	const { token, role, id } = useSelector(state => state?.userSession)
	const { modules } = useSelector(state => state.userProfile)
	const allowedModules = modules ? [...modules, 'Home'] : ['Home']

	const dispatch = useDispatch()

	const fetchUserInfo = async () => {
		try {
			const { data } = await getUserById(token, id)
			if (data.data.account_status !== 'Active') {
				dispatch(logout())
			}
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		fetchUserInfo()
	}, [])

	return (
		<Layout style={{ minHeight: '100vh', background: 'white' }}>
			<Sider
				collapsible
				theme='light'
				collapsed={collapsed}
				onCollapse={value => setCollapsed(value)}
				className='h-screen overflow-auto bg-white'
			>
				<div className=' h-[72px] grid place-items-center cursor-pointer' onClick={() => setCollapsed(prev => !prev)}>
					<MenuOutlined fontSize={28} />
				</div>
				<div>
					{moduleItems(role).length || collapsed ? (
						<Menu mode='inline' items={moduleItems(role)} />
					) : (
						<div className='m-8 text-center grid place-items-center gap-2'>
							<div className=' text-bell-red'>
								<BiError fontSize={28} />
							</div>
							No modules here!
							<div className='text-gray-600 text-xs'> Contact your admin.</div>
						</div>
					)}
				</div>
			</Sider>
			<Layout className='site-layout'>
				<Header />
				<Content style={{ backgroundColor: BACKGROUND }}>
					<Route
						{...rest}
						render={props => {
							if (!token) {
								return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
							}

							if (name && allowedModules.indexOf(name) === -1 && role !== 'Admin') {
								return <Redirect to={{ pathname: '/403' }} />
							}
							if (role !== 'Admin' && UNDER_MAINTENANCE) {
								return <Maintenance />
							}

							return <Component {...props} />
						}}
					/>
				</Content>
			</Layout>
		</Layout>
	)
}

export default PrivateRoute
