import React, { useState } from 'react'
import { Button, Form, Input, message, Select } from 'antd'

import { formItemLayout, tailFormItemLayout } from '../../Controllers/form'
import { form_options } from '../../Content/enquiries'

const { Option } = Select
const RiskAssessmentForm = ({ handleClose, handleRiskManagement }) => {
	// SELECTORS
	const [form] = Form.useForm()

	const [loading, setLoading] = useState(false)

	// ADD ENQUIRY
	const onAdd = async values => {
		setLoading(true)
		message.success('Risk Assessment added successfully!')
		handleRiskManagement(values)
		setLoading(false)
		form.resetFields()
		handleClose()
	}

	return (
		<div className='space-y-20'>
			<Form name='control-hooks' form={form} {...formItemLayout} onFinish={onAdd} scrollToFirstError>
				<Form.Item
					label='Risk Factor(s)'
					name='risk_factors'
					rules={[{ required: 'true', message: 'Please select at least one risk factor! ' }]}
				>
					<Select placeholder='Specify risk factors for this type of enquiry' mode='multiple'>
						{form_options.risk_factors.map(option => (
							<Option value={option}>
								<p className='capitalize'>{option}</p>
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label='Risk' name='risk'>
					<Input placeholder='Describe the risks involved!' />
				</Form.Item>
				<Form.Item label='Mitigation' name='mitigation'>
					<Input placeholder='Suggest actions of mitigation' />
				</Form.Item>
				<Form.Item label='Remarks' name='remarks'>
					<Input />
				</Form.Item>

				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' loading={loading} htmlType='submit'>
						Save and Close
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}
export default RiskAssessmentForm
