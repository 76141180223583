import axios from 'axios'
import { URL } from '../config'

export const loginAPI = data =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'post',
				url: `${URL}/user/login`,
				data
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const forgotPassword = data =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'post',
				url: `${URL}/user/forgot-password`,
				data
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const resetPassword = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'post',
				url: `${URL}/user/reset-password`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
