import dayjs from 'dayjs'
import React from 'react'
import { AiFillDelete } from 'react-icons/ai'
import dateFormat from '../../../Content/dateFormat'
import DeleteItem from '../../DeleteItem'

const SingleNoteView = ({ note, accessType, onDelete }) => (
	<div className='bg-orange-50 p-2 pt-4 rounded-lg flex flex-col justify-between space-y-2'>
		<div>{note.note}</div>
		<div className='flex justify-between text-xs text-gray-400 items-center'>
			<div>{dayjs.unix(note.created_at).format(dateFormat)}</div>
			{accessType === 'Public' && (
				<DeleteItem onDelete={() => onDelete(note.id)} popTitle='Are you sure to delete this note?'>
					<div className='hover:text-orange-600 cursor-pointer text-gray-400'>
						<AiFillDelete fontSize={14} />
					</div>
				</DeleteItem>
			)}
		</div>
	</div>
)

export default SingleNoteView
