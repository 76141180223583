import {
	ADD_OPPORTUNITY,
	EDIT_OPPORTUNITY,
	RESET_OPPORTUNITY,
	SINGLE_OPPORTUNITY,
	UPDATE_SINGLE_OPPORTUNITY
} from '../types'

const opportunities = []
const singleOpportunitiesState = []

export const opportunitiesData = (state = opportunities, { type, payload }) => {
	switch (type) {
		case ADD_OPPORTUNITY:
			return [payload, ...state]
		case EDIT_OPPORTUNITY:
			return state.map(x => {
				if (x.id === payload.id) {
					return payload.data
				}
				return x
			})
		case RESET_OPPORTUNITY:
			return payload

		default:
			return state
	}
}

export const singleOpportunitiesData = (state = singleOpportunitiesState, { type, payload }) => {
	switch (type) {
		case SINGLE_OPPORTUNITY:
			return payload
		case UPDATE_SINGLE_OPPORTUNITY:
			return {
				...state,
				...payload
			}
		default:
			return state
	}
}
