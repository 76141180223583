import { Popover } from 'antd'
import React from 'react'
import GaugeChart from 'react-gauge-chart'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { v4 } from 'uuid'
import * as Colors from '../styles/colors'

const GaugeMeter = ({ percent, heading, subHeading, ...props }) => (
	<div>
		<div className='flex items-baseline justify-center'>
			<GaugeChart
				id={v4()}
				arcsLength={props.arcsLength || [0.3, 0.45, 0.25]}
				colors={props.colors || [Colors.RED, Colors.YELLOW, Colors.GREEN]}
				percent={percent <= 100 ? percent / 100 : 1}
				style={{ height: 90, width: 200 }}
				textColor='#333'
				needleColor='#c9c9c9'
				needleBaseColor='#c9c9c9'
				formatTextValue={() => `${percent}%`}
			/>
		</div>
		<div className='text-center'>
			<div className='text-bell-text text-base font-medium flex items-center space-x-2 justify-center'>
				<span>{heading}</span>
				{props.popoverContent && (
					<span className='text-bell-gray cursor-pointer'>
						<Popover content={props.popoverContent} overlayStyle={{ width: '14rem' }}>
							<AiOutlineInfoCircle fontSize={18} />
						</Popover>
					</span>
				)}
			</div>
			<div className='text-bell-gray font-light'>{subHeading}</div>
		</div>
	</div>
)

export default GaugeMeter
