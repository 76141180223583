/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import { PrinterOutlined } from '@ant-design/icons'
import { Button, Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import ReactToPrint from 'react-to-print'
import { statusReportColumns } from '../../Content/contracts'
import { getContractInvoicingReport } from '../../Services/Contracts/contracts'
import PrintHeading from '../PrintHeading'

const ComponentToPrint = React.forwardRef(({ data, contractData, loading }, ref) => (
	<div ref={ref}>
		<PrintHeading title='Contract Invoicing Status Report' contractData={contractData} />
		<Table columns={statusReportColumns} dataSource={data} pagination={false} loading={loading} />
	</div>
))

const InvoiceStatusReport = ({ contractData }) => {
	const [data, setData] = useState(null)
	const [loading, setLoading] = useState(true)
	const { id } = contractData
	const { token } = useSelector(state => state.userSession)

	const fetchStatusReport = async () => {
		try {
			const {
				data: { data: reportData }
			} = await getContractInvoicingReport(token, id)
			setData(Object.values(reportData))
		} catch (error) {
			setData([])
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchStatusReport()
	}, [contractData])

	const componentRef = useRef()
	return (
		<div>
			<div className='flex flex-row-reverse'>
				<ReactToPrint
					trigger={() => <Button icon={<PrinterOutlined />}>Print Report</Button>}
					content={() => componentRef.current}
				/>
			</div>
			<ComponentToPrint ref={componentRef} data={data} loading={loading} contractData={contractData} />
		</div>
	)
}

export default InvoiceStatusReport
