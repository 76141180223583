import { ADD_TIME, CURRENT_TIME, DELETE_TIME, EDIT_TIME, RESET_TIME } from '../types'

export const addTime = payload => ({
	type: ADD_TIME,
	payload
})

export const editTime = payload => ({
	type: EDIT_TIME,
	payload
})

export const deleteTime = payload => ({
	type: DELETE_TIME,
	payload
})

export const resetTime = payload => ({
	type: RESET_TIME,
	payload
})

export const currentTime = payload => ({
	type: CURRENT_TIME,
	payload
})
