/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
// eslint-disable-next-line camelcase

import _ from 'lodash'
import { Link } from 'react-router-dom'
import { associationData } from './association'

import * as Colors from '../styles/colors'
import MINIOLink from '../Controllers/minioLink'

// eslint-disable-next-line import/prefer-default-export
export const form_options = {
	category: [
		'General',
		'Advance Tax',
		'Air Travel Expense',
		'Automobile Expense',
		'Employee Advance',
		'Fuel/Mileage Expense',
		'Furniture & Equipment',
		'IT & Internet Expenses',
		'Lodging',
		'Meals & Entertainment',
		'Office Supplies',
		'Parking',
		'Telephone Expenses',
		'Other Expenses'
	],
	association: associationData
}

export const drawerData = record => ({
	..._.omit(record, [
		'association_id',
		'association_name',
		'employee',
		'id',
		'merchant_id',
		'attachments',
		'title',
		'client_claim',
		'amount',
		'expense_date',
		'employee_name',
		'your_expense',
		'approvals',
		'status'
	]),
	association: record.association_id && record.association_id && (
		<Link
			to={`.${form_options.association.filter(x => x.name === record.association)[0].link}?id=${record.association_id}`}
		>
			{record?.association_name}
		</Link>
	),
	_attachments:
		record?.attachments?.length &&
		record?.attachments?.map((file, i) => (
			<div key={i}>
				<MINIOLink item={file} />
			</div>
		)),
	created_at: record.created_at,
	updated_at: record.updated_at
})

export const segmentOptions = {
	yourExpenses: 'Your Expenses',
	createdByYou: 'Created By You',
	requested: 'Requested',
	queued: 'Queued',
	completed: 'Completed'
}

export const StepStatus = {
	Requested: 'process',
	Approved: 'finish',
	Declined: 'error',
	Queued: 'wait',
	Bypassed: 'wait'
}

export const ExpenseStatus = {
	REQUESTED: 'Requested',
	IN_PROCESS: 'In Process',
	APPROVED: 'Approved',
	DECLINED: 'Declined',
	CLOSE_OUT: 'Close Out'
}

export const statusColor = {
	[ExpenseStatus.REQUESTED]: Colors.LIGHT_GREY,
	[ExpenseStatus.IN_PROCESS]: Colors.LIGHT_BLUE,
	[ExpenseStatus.APPROVED]: Colors.GREEN,
	[ExpenseStatus.DECLINED]: Colors.RED,
	[ExpenseStatus.CLOSE_OUT]: Colors.LIGHT_RED
}
