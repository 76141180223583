/* eslint-disable arrow-body-style */
import React from 'react'
import { Tabs } from 'antd'
import { InternalHeader } from '../../../Components'
import WebsiteInquiriesView from '../../../Components/WebsiteInquiriesView'
import WhitePaperListView from '../../../Components/WhitePaperListView'
import AlertSimProductBriefListView from '../../../Components/AlertSimProductBriefListView'
import DICECONRegistrationListView from '../../../Components/DiceConRegistrationList'

const WebsiteInquiries = () => {
	return (
		<div className='bg-bell-background'>
			<InternalHeader title='Sales' selected='Website Data' />
			<div className='mx-10 my-4'>
				<Tabs
					items={[
						{
							key: '1',
							label: 'Inquiries',
							children: (
								<div className='-mx-10'>
									<WebsiteInquiriesView />
								</div>
							)
						},
						{
							key: '4',
							label: 'DICECON Registration List',
							children: (
								<div className='-mx-10'>
									<DICECONRegistrationListView />
								</div>
							)
						},
						{
							key: '2',
							label: 'White Paper Access List',
							children: (
								<div className='-mx-10'>
									<WhitePaperListView />
								</div>
							)
						},
						{
							key: '3',
							label: 'ALERTSim Product Brief Access List',
							children: (
								<div className='-mx-10'>
									<AlertSimProductBriefListView />
								</div>
							)
						}
					]}
				/>
			</div>
		</div>
	)
}

export default WebsiteInquiries
