/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-shadow */
/* eslint-disable react/no-unstable-nested-components */
import { Button, Empty, message, Tabs, Upload } from 'antd'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UploadOutlined } from '@ant-design/icons'
import { URL } from '../../../config'
import { updateEnquiry } from '../../../Services/Sales/enquiries'
import { addEnquiryAttachment } from '../../../Store/Actions/enquiries'
import MinioLink from '../../../Controllers/minioLink'

const EnquiryAttachments = ({ id }) => {
	const attachmentsData = useSelector(state => state.attachmentsData)
	const { token } = useSelector(state => state.userSession)
	const dispatch = useDispatch()
	const { TabPane } = Tabs
	const [loading, setLoading] = useState(false)

	const props = {
		name: 'file',
		action: `${URL}/assets/upload`,
		headers: {
			authorization: `Bearer ${token}`
		},
		showUploadList: false,
		multiple: true,
		async onChange(info) {
			setLoading(true)

			if (info.file.status === 'done') {
				message.success(`${info.file.name} file uploaded successfully`)
				try {
					await updateEnquiry(token, {
						id,
						attachments: attachmentsData.commonAttachments
							? [...attachmentsData.commonAttachments, info.file.response.data.name]
							: [info.file.response.data.name]
					})
					dispatch(addEnquiryAttachment(info.file.response.data.name))
				} catch (error) {
					message.error(error?.response?.data?.message || 'Something went wrong.')
				} finally {
					setLoading(false)
				}
			} else if (info.file.status === 'error') {
				message.error(`${info.file.name} file upload failed.`)
				setLoading(false)
			}
		}
	}

	const TabContent = ({ data }) => (
		<div className='space-y-2'>
			<div>
				<div className='py-2 italic text-bell-gray'>Scope and Evaluation</div>
				<div className='pl-5'>
					{data?.scope_and_evaluation_attachments?.map(item => (
						<li>
							<MinioLink item={item} />
						</li>
					))}
				</div>
			</div>

			<div>
				<div className='py-2 italic text-bell-gray'>Costing</div>
				<div className='pl-5'>
					{data?.costing_attachments?.map(item => (
						<li>
							<MinioLink item={item} />
						</li>
					))}
				</div>
			</div>
			<div>
				<div className='py-2 italic text-bell-gray'>Submission</div>
				<div className='pl-5 space-y-2'>
					{data?.submission?.submission_data?.map(item => (
						<div>
							<div> {item?.proposal_type} </div>
							<div>
								{' '}
								{item?.attachments?.map(item => (
									<li>
										<MinioLink item={item} />
									</li>
								))}
							</div>
							<div>
								{item?.evidence?.map(item => (
									<li>
										<MinioLink item={item} />
									</li>
								))}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)

	return (
		<div className='flex justify-between'>
			<div>
				<Tabs tabPosition='left'>
					<TabPane tab='Common' key='1'>
						<div className='pb-4'>
							<Upload {...props}>
								<Button icon={<UploadOutlined />}>{loading ? 'Uploading..' : 'Upload'}</Button>
							</Upload>
						</div>
						{attachmentsData?.commonAttachments?.map(item => (
							<li>
								<MinioLink item={item} />
							</li>
						))}
					</TabPane>
					<TabPane tab='Current Revision' key='2'>
						<TabContent data={attachmentsData?.revisionAttachments[attachmentsData?.revisionAttachments?.length - 1]} />
					</TabPane>
					{attachmentsData?.revisionAttachments[attachmentsData?.revisionAttachments?.length - 2] && (
						<TabPane tab='Previous Revision' key='3'>
							<TabContent
								data={attachmentsData?.revisionAttachments[attachmentsData?.revisionAttachments?.length - 2]}
							/>
						</TabPane>
					)}

					<TabPane tab='All' key='4'>
						<Empty description='Coming Soon' />
					</TabPane>
				</Tabs>
			</div>
		</div>
	)
}

export default EnquiryAttachments
