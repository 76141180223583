import React, { useState } from 'react'
import { Button, Form, Input, message } from 'antd'
import { useSelector } from 'react-redux'
import { resetPassword } from '../../Services/access'

const ChangePasswordForm = ({ handleClose }) => {
	const [loading, setLoading] = useState(false)
	const { token } = useSelector(state => state.userSession)
	const [form] = Form.useForm()

	const onSubmit = async values => {
		setLoading(true)
		try {
			await resetPassword(token, { password: values.password })
			message.success('Your password has been successfully updated.')
			setLoading(false)
			handleClose()
		} catch (error) {
			message.error(error.response?.data?.message || 'Something went wrong')
			setLoading(false)
		}
	}

	return (
		<Form name='control-hooks' form={form} onFinish={onSubmit} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
			<Form.Item
				label='New Password'
				name='password'
				rules={[
					{
						required: true,
						message: 'Please input your password!'
					},
					() => ({
						validator(_, value) {
							if (!value || value.length > 7) {
								return Promise.resolve()
							}
							return Promise.reject(new Error('Password should be 8 characters long'))
						}
					})
				]}
			>
				<Input.Password />
			</Form.Item>
			<Form.Item
				label='Confirm Password'
				name='confirm_password'
				rules={[
					{
						required: true,
						message: 'Please confirm your password!'
					},
					({ getFieldValue }) => ({
						validator(_, value) {
							if (!value || getFieldValue('password') === value) {
								return Promise.resolve()
							}
							return Promise.reject(new Error('The two passwords that you entered do not match!'))
						}
					})
				]}
			>
				<Input.Password />
			</Form.Item>
			<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
				<Button type='primary' htmlType='submit' loading={loading}>
					Change
				</Button>
			</Form.Item>
		</Form>
	)
}

export default ChangePasswordForm
