/* eslint-disable default-param-last */
import { SET_PREFERENCE, SET_PROFILE } from '../types'

const userProfileState = false
const userPreferenceState = { calendarInfo: true }

export const userProfile = (state = userProfileState, { payload, type }) => {
	switch (type) {
		case SET_PROFILE:
			return payload
		default:
			return state
	}
}

export const userPreference = (state = userPreferenceState, { payload, type }) => {
	switch (type) {
		case SET_PREFERENCE:
			return payload
		default:
			return state
	}
}
