import React, { useEffect, useState } from 'react'
import { AiTwotoneEdit } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import DrawerComponent from '../../../DrawerComponent'
import CostingForm from '../../../Forms/Enquiries/CostingForm'
import * as Colors from '../../../../styles/colors'
import CostingCollapsibleTable from '../../../Internal/CostingCollapsibleTable'
import { enquiryStages } from '../../../../Content/enquiries'

const Costing = ({ data, isFilled, isCurrent, submission_schema, currentStage, showButton }) => {
	const singleEnquiriesData = useSelector(state => state.singleEnquiriesData)
	const editable = () => singleEnquiriesData.current_revision_data.revision_stage !== 'Submission'

	useEffect(() => {}, [data])
	const [form, setForm] = useState(false)
	return (
		<div>
			<div className='flex items-center'>
				<div className='font-bold text-base text-bell-text py-2'> Costing </div>
				{isFilled && isCurrent && editable() && showButton && (
					<DrawerComponent
						form={
							<CostingForm
								edit
								data={data}
								handleClose={() => setForm(false)}
								updateStage={enquiryStages.indexOf(currentStage) < enquiryStages.indexOf('Scope and Evaluation')}
							/>
						}
						visible={form}
						onOpen={() => setForm(true)}
						onClose={() => setForm(false)}
						buttonTitle={<AiTwotoneEdit color={Colors.BLUE} fontSize={20} />}
						buttonType='link'
						drawerTitle='Costing'
						drawerWidth={1000}
					/>
				)}
			</div>
			{isFilled ? (
				<div className='pt-4'>
					<CostingCollapsibleTable data={data?.costing} currency={data.currency} submitted={submission_schema} />
				</div>
			) : (
				<div>
					<div className='text-bell-gray text-sm py-2'>No Information Available</div>
					{isCurrent && showButton && (
						<DrawerComponent
							form={<CostingForm handleClose={() => setForm(false)} />}
							visible={form}
							onOpen={() => setForm(true)}
							onClose={() => setForm(false)}
							buttonTitle='Add Information'
							drawerTitle='Costing'
							drawerWidth={1000}
						/>
					)}
				</div>
			)}
		</div>
	)
}

export default Costing
