import React from 'react'
import { Empty, Tabs } from 'antd'
import './details.css'

const { TabPane } = Tabs

const Details = ({ data }) => (
	<div className='card-container'>
		<Tabs type='card'>
			{data.map((tab, i) => (
				<TabPane tab={tab.name} key={i}>
					{tab.content || <Empty description='Coming Soon' />}
				</TabPane>
			))}
		</Tabs>
	</div>
)

export default Details
