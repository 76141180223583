import { LOGGED_IN, LOGGED_OUT, REFRESH_COMPONENT } from '../types'

export const login = payload => ({
	type: LOGGED_IN,
	payload
})
export const logout = () => ({
	type: LOGGED_OUT
})

export const refreshComponent = () => ({
	type: REFRESH_COMPONENT
})
