import React from 'react'

import illustration from '../Assets/Images/no_data.svg'

const NoData = ({ title }) => (
	<div className='flex justify-center items-center flex-col py-24 space-y-4'>
		<img src={illustration} className='w-52 h-52' alt='Illustration showing no data' />
		<div className='text-base text-bell-text'>{title || 'No Data Available'}</div>
	</div>
)

export default NoData
