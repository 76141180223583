/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import {
	CloudOutlined,
	ContactsOutlined,
	ContainerOutlined,
	DashboardOutlined,
	DollarOutlined,
	ExperimentOutlined,
	HourglassOutlined,
	TeamOutlined,
	UnorderedListOutlined,
	WalletOutlined
} from '@ant-design/icons'

// eslint-disable-next-line
export const drawerMenu = [
	{
		title: 'Organization',
		icon: <TeamOutlined />,
		url: '/organization',
		children: [
			{
				title: 'Entities',
				url: '/organization/entities'
			}
		]
	},
	{
		title: 'Employees',
		icon: <ContactsOutlined />,
		url: '/employees',
		comingsoon: false,
		children: [
			{
				title: 'Employees',
				url: '/employees'
			},
			{
				title: 'Resources',
				url: '/employees/resources'
			}
			// {
			// 	title: 'Leaves',
			// 	url: '/employees/leaves'
			// },
			// {
			// 	title: 'Settings',
			// 	url: '/employees/settings'
			// }
		]
	},
	{
		title: 'Finance',
		icon: <DollarOutlined />,
		url: '/finance',
		comingsoon: false,
		children: [
			{
				title: 'Disbursement Management',
				url: 'finance/disbursement'
			}
		]
	},
	{
		title: 'Sales',
		icon: <CloudOutlined />,
		url: '/sales',
		comingsoon: false,
		children: [
			{
				title: 'Leads',
				url: '/sales/leads'
			},
			{
				title: 'Website Data',
				url: '/sales/website'
			},
			{
				title: 'Opportunities',
				url: '/sales/opportunities'
			},
			{
				title: 'Enquiries',
				url: '/sales/enquiries'
			},
			{
				title: 'Accounts',
				url: '/sales/accounts'
			},
			{
				title: 'Contacts',
				url: '/sales/contacts'
			},
			{
				title: 'Tasks',
				url: '/sales/tasks'
			},
			{
				title: 'Products & Services',
				url: '/sales/products-services'
			},
			{
				title: 'Tender Bonds',
				url: '/sales/tender-bonds'
			}
		]
	},
	{
		title: 'Contracts',
		icon: <ContainerOutlined />,
		url: '/contracts',
		comingsoon: false,
		children: [
			{
				title: 'Contracts',
				url: '/contracts/contracts'
			},
			{
				title: 'Invoices',
				url: '/contracts/invoices'
			},
			{
				title: 'Bank Guarantees',
				url: '/contracts/bank-guarantees'
			},
			{
				title: 'Tasks',
				url: '/contracts/tasks'
			}
		]
	},
	{
		title: 'Projects',
		icon: <ExperimentOutlined />,
		url: '/projects',
		comingsoon: false,
		children: [
			{
				title: 'Projects',
				url: '/projects'
			}
		]
	},
	{
		title: 'Timesheets',
		icon: <HourglassOutlined />,
		url: '/timesheets',
		comingsoon: false,
		children: [
			{
				title: 'Timesheets',
				url: '/timesheets'
			}
		]
	},
	{
		title: 'Tasks',
		icon: <UnorderedListOutlined />,
		url: '/tasks',
		comingsoon: false,
		children: [
			{
				title: 'Tasks',
				url: '/tasks'
			}
		]
	},
	{
		title: 'Reports',
		icon: <DashboardOutlined />,
		url: '/reports',
		comingsoon: false,
		children: [
			{
				title: 'All Reports',
				url: '/reports'
			}
		]
	},
	{
		title: 'Expenses',
		icon: <WalletOutlined />,
		url: '/expenses',
		comingsoon: false,
		children: [
			{
				title: 'All Expenses',
				url: '/expenses'
			}
		]
	}
	// {
	// 	title: 'Marketing',
	// 	icon: <SiMarketo />,
	// 	url: '/marketing',
	// 	comingsoon: true
	// },
	// {
	// 	title: 'Cashflow',
	// 	icon: <IoIosCash />,
	// 	url: '/cashflow',
	// 	comingsoon: true
	// },
	// {
	// 	title: 'Budgeting',
	// 	icon: <FaPiggyBank />,
	// 	url: '/budgeting',
	// 	comingsoon: true
	// },
	// {
	// 	title: 'Scorecards & KPIs',
	// 	icon: <GiStockpiles />,
	// 	url: '/scorecards',
	// 	comingsoon: true
	// },
	// {
	// 	title: 'Business Plans',
	// 	icon: <RiBuildingFill />,
	// 	url: '/business-plans',
	// 	comingsoon: true
	// },
	// {
	// 	title: 'Procurement',
	// 	icon: <MdBusiness />,
	// 	url: '/procurements',
	// 	comingsoon: true
	// },
	// {
	// 	title: 'Audits',
	// 	icon: <HiShieldCheck />,
	// 	url: '/my-dashboard',
	// 	comingsoon: true
	// },
	// {
	// 	title: 'Assets',
	// 	icon: <GoFileSubmodule />,
	// 	url: '/assets',
	// 	comingsoon: true
	// }
]
