/* eslint-disable default-param-last */
import { ADD_CONTACT, EDIT_CONTACT, RESET_CONTACT, SINGLE_CONTACT, UPDATE_SINGLE_CONTACT } from '../types'

const contacts = []
const singleContactsState = []

export const contactsData = (state = contacts, { type, payload }) => {
	switch (type) {
		case ADD_CONTACT:
			return [payload, ...state]
		case EDIT_CONTACT:
			return state.map(x => {
				if (x.id === payload.id) {
					return payload.data
				}
				return x
			})
		case RESET_CONTACT:
			return payload

		default:
			return state
	}
}

export const singleContactsData = (state = singleContactsState, { type, payload }) => {
	switch (type) {
		case SINGLE_CONTACT:
			return payload
		case UPDATE_SINGLE_CONTACT:
			return {
				...state,
				...payload
			}
		default:
			return state
	}
}
