import React, { useEffect, useState } from 'react'
import { Form, Input, Button, message } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

// COMPONENTS
import { addNote, deleteNote, resetNote } from '../../../Store/Actions/notes'
import { createNote, deleteNoteAPI, getNotesByAssociationID } from '../../../Services/notes'
import SingleNoteView from './SingleNoteView'

const Notes = ({ association_id, accessType }) => {
	// STATES
	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const notesData = useSelector(state => state.notesData)
	const { token } = useSelector(state => state.userSession)
	const [loading, setLoading] = useState(false)

	// GET NOTES BY LEAD ID
	const fetchData = async (current, pageSize) => {
		try {
			const { data } = await getNotesByAssociationID(token, association_id, current, pageSize)
			if (!data.data) {
				dispatch(resetNote([]))
			} else {
				dispatch(resetNote(data.data))
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	// FETCH DATA ON PAGE LOAD
	useEffect(() => {
		fetchData(0, 10)
		// eslint-disable-next-line
	}, [])

	// ON ADDING NOTES
	const onFinish = async values => {
		setLoading(true)
		const dataObject = {
			association_id,
			note: values.note
		}
		try {
			const { data } = await createNote(token, dataObject)
			dispatch(addNote({ created_at: dayjs().unix(), id: data.data.id, ...dataObject }))
			setLoading(false)
			message.success('Note added successfully!')
			form.resetFields()
		} catch (error) {
			setLoading(false)
			message.error(error?.response?.data?.message || 'Something went wrong.')
		}
	}

	// ON DELETING NOTES
	const onDelete = async id => {
		try {
			await deleteNoteAPI(token, id)
			message.success('Note Deleted Successfully.')
			dispatch(deleteNote(id))
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong.')
		}
	}

	return (
		<div className='space-y-5'>
			<div>
				{/* FORM */}
				<Form
					name='control-hooks'
					onFinish={onFinish}
					form={form}
					layout='inline'
					style={{
						display: 'grid',
						gridTemplateColumns: '7fr 1fr',
						gap: '20px'
					}}
					hidden={accessType === 'Protect'}
				>
					<Form.Item
						name='note'
						rules={[
							{
								required: true,
								message: 'Please input note!'
							}
						]}
						style={{ width: '100%' }}
					>
						<Input placeholder='Add a quick note!' />
					</Form.Item>

					<Form.Item>
						<Button type='primary' htmlType='submit' loading={loading}>
							Save
						</Button>
					</Form.Item>
				</Form>
			</div>
			{/* Notes View */}
			<div className='grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-4 gap-5'>
				{notesData.map(note => (
					<SingleNoteView note={note} accessType={accessType} onDelete={onDelete} />
				))}
			</div>
		</div>
	)
}

export default Notes
