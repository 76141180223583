/* eslint-disable no-param-reassign */
import { persistStore, persistReducer } from 'redux-persist'

import { createStore, combineReducers, applyMiddleware } from 'redux'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import { refreshComponent, userSession } from './Reducers/userSession'
import { employeesData, rolesData } from './Reducers/employees'
import { leadsData, singleLeadsData } from './Reducers/leads'
import { notesData } from './Reducers/notes'
import { activitiesData } from './Reducers/activity'
import { LOGGED_OUT } from './types'
import { accountsData, singleAccountsData } from './Reducers/accounts'
import { tasksData } from './Reducers/tasks'
import { userProfile, userPreference } from './Reducers/userProfile'
import {
	attachmentsData,
	enquiriesData,
	enquirySourceData,
	enquiryStageData,
	revisionsData,
	singleEnquiriesData
} from './Reducers/enquiries'
import { productsServicesData } from './Reducers/products-services'
import { contactsData, singleContactsData } from './Reducers/contacts'
import { opportunitiesData, singleOpportunitiesData } from './Reducers/opportunities'
import { contractsData, singleContractsData, variationsData } from './Reducers/contracts'
import { invoicesData, singleInvoicesData } from './Reducers/invoices'
import { bankGuaranteesData } from './Reducers/bank-guarantees'
import { reportsData } from './Reducers/reports'
import { timeSheetsData, currentTimeEntry } from './Reducers/timesheets'
import { projectsData, singleProjectsData } from './Reducers/projects'
import { expensesData } from './Reducers/expenses'

const persistConfig = {
	key: 'root',
	storage
}

const appReducer = combineReducers({
	userSession,
	employeesData,
	leadsData,
	notesData,
	activitiesData,
	accountsData,
	tasksData,
	userProfile,
	singleLeadsData,
	enquiriesData,
	enquirySourceData,
	singleEnquiriesData,
	enquiryStageData,
	revisionsData,
	attachmentsData,
	productsServicesData,
	singleAccountsData,
	refreshComponent,
	rolesData,
	contactsData,
	singleContactsData,
	opportunitiesData,
	singleOpportunitiesData,
	contractsData,
	singleContractsData,
	variationsData,
	invoicesData,
	singleInvoicesData,
	bankGuaranteesData,
	timeSheetsData,
	currentTimeEntry,
	reportsData,
	projectsData,
	singleProjectsData,
	expensesData,
	userPreference
})

const rootReducer = (state, action) => {
	if (action.type === LOGGED_OUT) {
		storage.removeItem('persist:root')
		state = undefined
	}
	return appReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const middleware = applyMiddleware(thunk)

export const store = createStore(persistedReducer, middleware)
export const persistor = persistStore(store)
