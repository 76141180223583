/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import 'animate.css'
import { ConfigProvider } from 'antd'
import { persistor, store } from './Store/configureStore'
import { ONE_SIGNAL } from './config'

import PrivateRoute from './Utils/PrivateRoute'
import PublicRoute from './Utils/PublicRoute'
import Home from './Pages/Home'
import Page403 from './Pages/403'
import Page404 from './Pages/404'

import Login from './Pages/Login/Login'
import ResetPassword from './Pages/Login/ResetPassword'
import Employees from './Pages/Employees/Employees'
import Roles from './Pages/Employees/Roles'
import OrganizationHome from './Pages/Organization/OrganizationHome'

import Sales from './Pages/Sales/Sales'
import Enquiries from './Pages/Sales/Enquiries/Enquiries'
import Leads from './Pages/Sales/Leads/Leads'
import Tasks from './Pages/Tasks/Tasks'
import Opportunities from './Pages/Sales/Opportunities/Opportunities'
import Contacts from './Pages/Sales/Contacts/Contacts'
import PS from './Pages/Sales/PS/PS'
import Accounts from './Pages/Accounts/Accounts'

import Contracts from './Pages/MainContracts/Contracts/Contracts'
import ContractsHome from './Pages/MainContracts/Home'
import Invoices from './Pages/MainContracts/Invoices/Invoices'

import BankGuarantees from './Pages/Guarantees/BankGuarantees'

import Entities from './Pages/Organization/Entities'

import Projects from './Pages/Projects/Projects'
import MainProjects from './Pages/Projects/Home'

import Expenses from './Pages/Expenses/Expenses'

import TimeSheets from './Pages/TimeSheets/TimeSheets'

// reports
import Reports from './Pages/Reports/Reports'
import SalesReport from './Pages/Reports/SalesReport'
import LeadsAndAccountsReport from './Pages/Reports/LeadsAndAccountsReport'
import EnquiriesByAccountAndCountryReport from './Pages/Reports/EnquiriesByAccountAndCountryReport'
import ClientEngagementReport from './Pages/Reports/ClientEngagementReport'
import EnquiriesStatusReport from './Pages/Reports/EnquiriesStatusReport'
import TimesheetsReport from './Pages/Reports/TimesheetsReport'
import ProjectTimeUtilizationReport from './Pages/Reports/ProjectTimeUtilizationReport'

import Finance from './Pages/Finance/Finance'
import PettyCashManagement from './Pages/Finance/PettyCashManagement'
import WebsiteInquiries from './Pages/Sales/Website/WebsiteInquiries'
import ProjectDurationReport from './Pages/Reports/ProjectDurationReport'
import { BLUE } from './styles/colors'

window.OneSignal = window.OneSignal || []
const { OneSignal } = window

const App = () => {
	useEffect(() => {
		OneSignal.push(() => {
			OneSignal.init({
				appId: ONE_SIGNAL,
				safari_web_id: 'web.onesignal.auto.3707da58-ddb1-4483-bf9e-ed962105bbd1',
				notifyButton: {
					enable: true
				}
			})
		})
	}, [])
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<ConfigProvider
					theme={{
						token: { colorPrimary: BLUE, colorLink: BLUE, colorInfo: BLUE, fontFamily: 'Fira Sans' }
					}}
				>
					<div className='bg-bell-background min-h-screen'>
						<Router>
							<div>
								<Switch>
									<PublicRoute path='/login' exact component={Login} />
									<PublicRoute path='/reset-password' exact component={ResetPassword} />
									{/* home page */}
									<PrivateRoute path='/' name='Home' exact component={Home} />
									{/* sales modules */}
									<PrivateRoute path='/sales' name='Sales' exact component={Sales} />
									<PrivateRoute path='/sales/enquiries' name='Sales' exact component={Enquiries} />
									<PrivateRoute path='/sales/enquiries/kanban' name='Sales' exact component={Enquiries} />
									<PrivateRoute path='/sales/leads' name='Sales' exact component={Leads} />
									<PrivateRoute path='/sales/website' name='Sales' exact component={WebsiteInquiries} />
									<PrivateRoute path='/sales/opportunities' name='Sales' exact component={Opportunities} />
									<PrivateRoute path='/sales/tasks' name='Sales' exact component={() => <Tasks type='Sales' />} />
									<PrivateRoute path='/sales/accounts' name='Sales' exact component={Accounts} />
									<PrivateRoute path='/sales/contacts' name='Sales' exact component={Contacts} />
									<PrivateRoute path='/sales/products-services' name='Sales' exact component={PS} />
									<PrivateRoute
										path='/sales/tender-bonds'
										name='Sales'
										exact
										component={() => <BankGuarantees type='Tender Bond' />}
									/>
									{/* contracts module */}
									<PrivateRoute path='/contracts' name='Contracts' exact component={ContractsHome} />
									<PrivateRoute path='/contracts/contracts' name='Contracts' exact component={Contracts} />
									<PrivateRoute path='/contracts/invoices' name='Contracts' exact component={Invoices} />
									<PrivateRoute
										path='/contracts/bank-guarantees'
										name='Contracts'
										exact
										component={() => <BankGuarantees type='Bank Guarantee' />}
									/>
									<PrivateRoute
										path='/contracts/tasks'
										name='Contracts'
										exact
										component={() => <Tasks type='Contracts' />}
									/>
									{/* tasks module */}
									<PrivateRoute path='/tasks' name='Tasks' exact component={Tasks} />
									{/* employees module */}
									<PrivateRoute path='/employees' name='Employees' exact component={Employees} />
									<PrivateRoute path='/employees/resources' name='Employees' exact component={Roles} />

									{/* organization module */}
									<PrivateRoute path='/organization' name='Organization' exact component={OrganizationHome} />
									<PrivateRoute path='/organization/entities' name='Organization' exact component={Entities} />

									{/* timesheets module */}
									<PrivateRoute path='/timesheets' name='Timesheets' exact component={TimeSheets} />

									{/* projects module */}

									<PrivateRoute path='/projects' name='Projects' exact component={MainProjects} />
									<PrivateRoute path='/projects/projects' name='Projects' exact component={Projects} />

									{/* reports module */}
									<PrivateRoute path='/reports' name='Reports' exact component={Reports} />
									<PrivateRoute path='/reports/sales-report' name='Reports' exact component={SalesReport} />
									<PrivateRoute
										path='/reports/leads-and-accounts'
										name='Reports'
										exact
										component={LeadsAndAccountsReport}
									/>
									<PrivateRoute
										path='/reports/enquiries-by-country-and-account'
										name='Reports'
										exact
										component={EnquiriesByAccountAndCountryReport}
									/>
									<PrivateRoute
										path='/reports/enquiries-status-report'
										name='Reports'
										exact
										component={EnquiriesStatusReport}
									/>

									<PrivateRoute
										path='/reports/client-engagement-report'
										name='Reports'
										exact
										component={ClientEngagementReport}
									/>
									<PrivateRoute path='/reports/timesheet-report' name='Reports' exact component={TimesheetsReport} />
									<PrivateRoute
										path='/reports/project-utilization-report'
										name='Reports'
										exact
										component={ProjectTimeUtilizationReport}
									/>
									<PrivateRoute
										path='/reports/project-duration-report'
										name='Reports'
										exact
										component={ProjectDurationReport}
									/>

									{/* expenses module */}
									<PrivateRoute path='/expenses' name='Expenses' exact component={Expenses} />

									{/* Finance module */}
									<PrivateRoute path='/finance' name='Finance' exact component={Finance} />
									<PrivateRoute path='/finance/disbursement' name='Finance' exact component={PettyCashManagement} />

									{/* unauthorized access */}
									<PrivateRoute path='/403' exact component={Page403} />
									{/* page not found */}
									<PrivateRoute path='/' component={Page404} />
								</Switch>
							</div>
						</Router>
					</div>
				</ConfigProvider>
			</PersistGate>
		</Provider>
	)
}

export default App
