import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { message } from 'antd'
import { DataView, DrawerComponent, InternalHeader, Stats } from '../../../Components'
import SingleContact from './SingleContact'
import { getAllContacts } from '../../../Services/Sales/contacts'
import { resetContact } from '../../../Store/Actions/contacts'
import ContactForm from '../../../Components/Forms/ContactForm'
import { getContactStats } from '../../../Services/stats'

const Contacts = () => {
	const location = useLocation()
	const contactsData = useSelector(state => state.contactsData)
	const { token } = useSelector(state => state.userSession)

	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		sortOrder: 'desc',
		sortField: 'created_at',
		total: ''
	})
	const [addDrawer, setAddDrawer] = useState(false)
	const [search, setSearch] = useState('')
	const [stats, setStats] = useState(null)
	const handleSearch = e => {
		setSearch(e.target.value)
	}

	const dispatch = useDispatch()

	const fetchData = async (current, pageSize, sortOrder, sortField, query) => {
		try {
			const { data } = await getAllContacts(token, current, pageSize, sortOrder, sortField, query)
			if (!data.data) {
				dispatch(resetContact([]))
			} else {
				dispatch(resetContact(data.data))
			}
			setPagination({
				current: current + 1,
				pageSize,
				sortOrder,
				sortField,
				total: data.count
			})
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const getStats = async () => {
		try {
			const { data } = await getContactStats(token)
			setStats(data.data)
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	useEffect(() => {
		getStats()
		// eslint-disable-next-line
	}, [contactsData])

	const handleChange = (page, filters, sorter) => {
		const leadFilter = filters.contact_owner ? filters.contact_owner[0] : undefined
		const sortOrder = {
			ascend: 'asc',
			descend: 'desc'
		}
		fetchData(
			page.current - 1,
			page.pageSize,
			sortOrder[sorter.order] || pagination.sortOrder,
			sorter.field || pagination.sortField,
			leadFilter || search
		)
	}

	useEffect(() => {
		fetchData(0, pagination.pageSize, pagination.sortOrder, pagination.sortField, search)
		// eslint-disable-next-line
	}, [search])

	const columns = [
		{
			title: 'First Name',
			key: 'first_name',
			sorter: true,
			dataIndex: 'first_name'
		},
		{
			title: 'Last Name',
			key: 'last_name',
			sorter: true,
			dataIndex: 'last_name'
		},
		{
			title: 'Account',
			key: 'account',
			dataIndex: 'contact_account_name'
		},
		{
			title: 'Contact Owner',
			key: 'contact_owner_name',
			dataIndex: 'contact_owner_name'
		}
	]

	const contactsStats = {
		title: 'Contacts',
		total: stats?.contacts,
		data: []
	}

	return !location.search ? (
		<div className='bg-bell-background'>
			<InternalHeader title='Sales' selected='Contacts' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Contacts</div>
				<div>
					<DrawerComponent
						form={<ContactForm handleClose={() => setAddDrawer(false)} />}
						visible={addDrawer}
						onOpen={() => setAddDrawer(true)}
						onClose={() => setAddDrawer(false)}
						buttonTitle='Add Contact'
					/>
				</div>
			</div>
			<div className='grid grid-flow-col px-10'>
				<div className='col-span-2'>
					<Stats data={contactsStats} nograph />
				</div>
			</div>
			<div>
				<DataView
					columns={columns}
					data={contactsData}
					type='contacts'
					rowClickable
					pagination={pagination}
					handleChange={handleChange}
					handleSearch={handleSearch}
				/>
			</div>
		</div>
	) : (
		<SingleContact />
	)
}

export default Contacts
