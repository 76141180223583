import React, { useState } from 'react'
import { Button, Form, Input, Select, message, Space, DatePicker } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { form_options } from '../../Content/employees'
import { addEmployee, editEmployee } from '../../Store/Actions/employees'
import { createUser, getUserById, updateUser } from '../../Services/user'
import { objectDifference } from '../../Controllers/objectDifference'
import SelectDebounce from '../SelectDebounce'
import { fetchRoleList, fetchUserList } from '../../Controllers/fetchLists'
import { DrawerComponent } from '..'
import RolesForm from './RolesForm'
import { drawerMenu } from '../../Content/drawerMenu'
import dateFormat from '../../Content/dateFormat'

const EmployeeForm = ({ handleClose, edit, data, handleEmployeeValues }) => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false)
	const userSession = useSelector(state => state.userSession)
	const [roleDrawer, setRoleDrawer] = useState(false)
	const { Option } = Select
	const [form] = Form.useForm()

	const formData = data
		? {
				...data,
				designation_id: {
					key: data?.designation_id,
					value: data?.designation_id,
					label: data?.designation_name
				},
				reports_to: {
					key: data?.reports_to,
					value: data?.reports_to,
					label: data?.reports_to_name
				},
				dob: data?.dob ? dayjs.unix(data.dob) : null
		  }
		: null

	const dataObject = values => ({
		...values,
		designation_id: values?.designation_id?.value,
		reports_to: values?.reports_to?.value,
		dob: values.dob ? dayjs(values.dob).unix() : 0,
		account_status: 'Active'
	})

	const onAdd = async values => {
		setLoading(true)
		try {
			const { data } = await createUser(userSession.token, dataObject(values))
			const res = await getUserById(userSession.token, data.data.id)
			dispatch(addEmployee(res.data.data))
			if (handleEmployeeValues) {
				handleEmployeeValues({
					id: data.data.id,
					...dataObject(values)
				})
			}
			message.success('Employee Information saved successfully!')
			setLoading(false)
			form.resetFields()
			handleClose()
		} catch (error) {
			message.error(error.response?.data?.message || 'Something went wrong!')
			setLoading(false)
		}
	}
	const onEdit = async values => {
		setLoading(true)
		try {
			const updatedData = objectDifference(data, dataObject(values))
			await updateUser(userSession.token, { id: data.id, ...updatedData })
			const res = await getUserById(userSession.token, data.id)
			dispatch(
				editEmployee({
					id: data.id,
					data: res.data.data
				})
			)
			message.success('Employee Information updated successfully!')
			setLoading(false)
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error.response?.data?.message || 'Something went wrong')
		}
	}

	return (
		<Form
			name='control-hooks'
			form={form}
			onFinish={edit ? onEdit : onAdd}
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 16 }}
			initialValues={formData}
		>
			<div className='py-2 italic text-bell-gray'>Personal Information</div>
			<Form.Item
				label='First Name'
				name='first_name'
				rules={[
					{
						required: true,
						message: 'Please input first name!'
					}
				]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label='Last Name'
				name='last_name'
				rules={[
					{
						required: true,
						message: 'Please input last name!'
					}
				]}
			>
				<Input />
			</Form.Item>
			<Form.Item
				label='Date of Birth'
				name='dob'
				rules={[
					{
						transform: value => (value ? dayjs(value).unix() : null),
						type: 'number',
						max: dayjs().subtract(18, 'y').unix(),
						message: 'Age should be above 18.'
					},
					{
						transform: value => (value ? dayjs(value).unix() : null),
						type: 'number',
						min: dayjs().subtract(100, 'y').unix(),
						message: 'Should we contact Guinness World Records?'
					}
				]}
			>
				<DatePicker format={dateFormat} />
			</Form.Item>
			<Form.Item
				label='Email Address'
				name='email'
				rules={[
					{
						required: true,
						message: 'Please input email address!'
					},
					{
						type: 'email',
						message: 'Please input a valid email address!'
					}
				]}
			>
				<Input />
			</Form.Item>
			{/* <div className='py-2 italic text-bell-gray'>Region & Religion Information</div>
			<Form.Item label='Region' name='region'>
				<Select placeholder='Select Region' allowClear>
					{regions.map((opt, i) => (
						<Option value={opt} key={i}>
							{opt}
						</Option>
					))}
				</Select>
			</Form.Item>
			<Form.Item label='Religion' name='religion'>
				<Select placeholder='Select Religion' allowClear>
					{religions.map((opt, i) => (
						<Option value={opt} key={i}>
							{opt}
						</Option>
					))}
				</Select>
			</Form.Item> */}

			<div className='py-2 italic text-bell-gray'>Work Information</div>
			<Form.Item label='Department' name='department'>
				<Input />
			</Form.Item>
			<Form.Item label='Designation'>
				<Space>
					<Form.Item name='designation_id' noStyle>
						<SelectDebounce
							showSearch
							placeholder='Search Resource'
							fetchOptions={e => fetchRoleList(e, userSession.token)}
							style={{
								width: 300
							}}
						/>
					</Form.Item>
					<DrawerComponent
						visible={roleDrawer}
						onOpen={() => setRoleDrawer(true)}
						onClose={() => setRoleDrawer(false)}
						buttonTitle='Add Resource'
						drawerWidth='600'
						buttonType='link'
						form={
							<RolesForm
								handleClose={() => setRoleDrawer(false)}
								handleRoleValue={data => {
									form.setFieldsValue({
										designation_id: {
											label: data.name,
											value: data.id
										}
									})
								}}
							/>
						}
					/>
				</Space>
			</Form.Item>
			<Form.Item label='Reports To' name='reports_to'>
				<SelectDebounce
					showSearch
					placeholder='Select Employees'
					fetchOptions={e => fetchUserList(e, userSession.token)}
				/>
			</Form.Item>
			<Form.Item name='role' label='Access Role' rules={[{ required: true, message: 'Please select role!' }]}>
				<Select placeholder='Select Role' allowClear>
					{form_options.role.map((opt, i) => (
						<Option value={opt} key={i}>
							{opt}
						</Option>
					))}
				</Select>
			</Form.Item>
			<div className='py-2 italic text-bell-gray'>Access Information</div>
			<Form.Item name='modules' label='Choose Modules'>
				<Select placeholder='Accessible Modules' allowClear mode='multiple'>
					{drawerMenu.map((opt, i) => (
						<Option value={opt.title} key={i}>
							{opt.title}
						</Option>
					))}
				</Select>
			</Form.Item>

			<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
				<Button type='primary' htmlType='submit' loading={loading}>
					Save
				</Button>
			</Form.Item>
		</Form>
	)
}

export default EmployeeForm
