export const LIGHT_GREY = '#8E8E8E'
export const BLUE = '#005EAA'
export const RED = '#EC201C'
export const GREEN = '#2E7632'
export const YELLOW = '#F1BE42'
export const BACKGROUND = '#FAF9F8'

export const LIGHT_BLUE = '#7DACD1'
export const LIGHT_RED = '#F38C8A'
export const LIGHT_GREEN = '#94B895'
export const LIGHT_YELLOW = '#f5d68c'
