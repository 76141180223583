/* eslint-disable react/jsx-filename-extension */
// eslint-disable-next-line camelcase
import React from 'react'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import accounting from '../Controllers/accountingNumbers'
import * as Colors from '../styles/colors'

// eslint-disable-next-line import/prefer-default-export
export const contractFormOptions = {
	contractType: ['Positive Variation', 'Negative Variation']
}
export const invoiceFormOptions = {
	paymentTerms: [
		{ name: 'Net 15', type: 'days', increment: 15 },
		{ name: 'Net 30', type: 'days', increment: 30 },
		{ name: 'Net 45', type: 'days', increment: 45 },
		{ name: 'Net 60', type: 'days', increment: 60 },
		{ name: 'Net 120', type: 'days', increment: 120 },
		{ name: 'Due end of Month', type: 'months', increment: 0 },
		{ name: 'Due end of next Month', type: 'months', increment: 1 },
		{ name: 'Due on receipt', type: 'custom', increment: null },
		{ name: 'Custom', type: 'custom', increment: null }
	]
}
export const variationType = {
	Positive: <AiFillCaretUp color={Colors.GREEN} />,
	Negative: <AiFillCaretDown color={Colors.RED} />
}

export const statusReportColumns = [
	{
		title: 'Product / Service',
		key: 'ps_name',
		dataIndex: 'ps_name'
	},
	{
		title: 'Payments Received',
		key: 'payment_received',
		dataIndex: 'payment_received',
		render: (_, record) => `USD ${accounting(record.payment_received)}`
	},
	{
		title: 'Raised but not Paid',
		key: 'not_paid',
		dataIndex: 'not_paid',
		render: (_, record) => `USD ${accounting(record.not_paid)}`
	},
	{
		title: 'Rejected',
		key: 'rejected',
		dataIndex: 'rejected',
		render: (_, record) => `USD ${accounting(record.rejected)}`
	},
	{
		title: 'To be Invoiced',
		key: 'pending',
		dataIndex: 'pending',
		render: (_, record) => `USD ${accounting(record.pending)}`
	}
]
