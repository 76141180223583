/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import { SearchOutlined } from '@ant-design/icons'
import { Button, Checkbox, Divider } from 'antd'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { SelectDebounce } from '../Components'
import { fetchAccountList, fetchUserList } from './fetchLists'

const tableFilter = (name, id, setSearch) => {
	const [filtered, setFiltered] = useState(false)
	const [checked, setChecked] = useState(false)
	const [selectedId, setSelectedId] = useState(null)

	const { token } = useSelector(state => state.userSession)

	const fetchLists = e => {
		switch (name) {
			case 'Owner':
				return fetchUserList(e, token)
			case 'Account':
				return fetchAccountList(e, token)
			default:
				return null
		}
	}

	const handleFilter = confirm => {
		if (checked) {
			setSearch(id)
			setFiltered(true)
		} else if (selectedId) {
			setSearch(selectedId.value)
			setFiltered(true)
		} else {
			setSearch('')
			setFiltered(false)
		}
		confirm()
	}

	const handleReset = clearFilters => {
		setSelectedId(null)
		setChecked(false)
		clearFilters()
	}
	return {
		filterDropdown: ({ confirm, clearFilters }) => (
			<div className='p-2 space-y-2'>
				{name === 'Owner' && (
					<div className='space-y-2'>
						<div>
							<Checkbox onChange={e => setChecked(e.target.checked)} checked={checked} disabled={selectedId} /> Owned by
							Me
						</div>
						<div className='text-bell-gray text-center'>OR</div>
					</div>
				)}
				<div>
					<div>
						<SelectDebounce
							disabled={checked}
							showSearch
							placeholder={`Search ${name}`}
							fetchOptions={fetchLists}
							style={{ width: 200 }}
							onChange={e => setSelectedId(e)}
							value={selectedId}
						/>
					</div>
					<Divider style={{ margin: '10px 0px' }} />
					<div className='flex justify-between'>
						<Button
							onClick={() => handleReset(clearFilters)}
							size='small'
							type='link'
							disabled={!selectedId && !checked}
						>
							Reset
						</Button>
						<Button onClick={() => handleFilter(confirm)} size='small' type='primary'>
							OK
						</Button>
					</div>
				</div>
			</div>
		),

		filterIcon: () => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
	}
}

export default tableFilter
