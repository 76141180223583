/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useRef, useEffect } from 'react'
import { Table, Input, Button, Form, Divider, Select, InputNumber } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { v4 } from 'uuid'
import accounting from '../../../Controllers/accountingNumbers'

const { Option } = Select
const EditableContext = React.createContext(null)

const EditableRow = ({ index, ...props }) => {
	const [form] = Form.useForm()
	return (
		<Form form={form} component={false}>
			<EditableContext.Provider value={form}>
				<tr {...props} />
			</EditableContext.Provider>
		</Form>
	)
}

const initialItem = {
	key: v4(),
	description: '',
	percentage: '',
	quantity: '',
	rate: '',
	amount: '',
	tax: '',
	updated: false
}

const EditableCell = ({
	title,
	value,
	editable,
	children,
	dataIndex,
	record,
	handleSave,
	handleRemove,
	...restProps
}) => {
	const inputRef = useRef(null)
	const form = useContext(EditableContext)
	const save = async () => {
		const values = await form.validateFields()
		if (editable) {
			handleSave({ ...record, ...values })
		}
	}

	const renderChildren = {
		action: (
			<Button type='link' onClick={() => handleRemove(record)}>
				Remove
			</Button>
		),
		percentage: (
			<InputNumber
				// 0 <= percentage <= percentage invoiced so far.
				min={0}
				max={
					record?.history
						? Number(
								((record.history.total_amount - record.history.amount_invoiced) * 100) / record.history.total_amount
						  )
						: 100
				}
				ref={inputRef}
				onPressEnter={save}
				onChange={save}
				onBlur={save}
				disabled={
					// total amount can be lesser than amount invoiced when there are multiple drafts yet to be paid.
					record?.type === 'Goods' || (record?.history?.total_amount || 1) <= (record?.history?.amount_invoiced || 0)
				}
				formatter={d => d && `${d}%`}
				parser={d => d.replace('%', '')}
			/>
		),
		quantity: (
			<InputNumber
				// 0 <= quantity <= quantity invoiced so far.
				min={0}
				max={record?.history && Number(record.history.total_quantity - record.history.quantity_invoiced)}
				ref={inputRef}
				onPressEnter={save}
				onChange={save}
				onBlur={save}
				disabled={
					// total quantity can be lesser than quantity invoiced when there are multiple drafts yet to be paid.
					record?.type === 'Service' ||
					(record?.history?.total_quantity || 1) <= (record?.history?.quantity_invoiced || 0)
				}
			/>
		),
		// disable rate if added from contracts.
		rate: <Input ref={inputRef} onPressEnter={save} onChange={save} onBlur={save} disabled={record?.history} />
	}
	return (
		<td {...restProps}>
			{title === 'Action' ? (
				<Button type='link' onClick={() => handleRemove(record)}>
					Remove
				</Button>
			) : editable ? (
				<Form.Item
					style={{
						margin: 0
					}}
					name={dataIndex}
					initialValue={record.updated ? record[dataIndex] : ''}
				>
					{renderChildren[dataIndex] || <Input ref={inputRef} onPressEnter={save} onChange={save} onBlur={save} />}
				</Form.Item>
			) : (
				<div>{children}</div>
			)}
		</td>
	)
}

const ProductCalculationForm = ({ data, handleData, handleClose, entityData }) => {
	const [dataSource, setDataSource] = useState(data?.data || [initialItem])
	const [currentTax, setCurrentTax] = useState(data?.tax)

	const handleSave = row => {
		const amountCalculator = {
			Service: (Number(row.percentage) * Number(row.rate)) / 100,
			Goods: Number(row.rate) * Number(row.quantity)
		}
		const updatedRow = {
			...row,
			amount: amountCalculator[row?.type] || (Number(row.percentage) * row.rate * Number(row.quantity)) / 100
		}
		setDataSource(prev =>
			prev.map(x => {
				if (x.key === row.key) {
					return { ...x, ...updatedRow, updated: true }
				}
				return x
			})
		)
	}

	useEffect(() => {
		if (data?.data?.length) {
			setDataSource(data?.data)
		}
	}, [data])

	const handleAdd = async () => {
		setDataSource(prev => [
			...prev,
			{
				key: v4(),
				description: '',
				percentage: '',
				quantity: '',
				rate: '',
				amount: '',
				tax: '',
				updated: false
			}
		])
	}

	const handleRemove = async row => {
		if (dataSource?.length > 1) {
			setDataSource(prev => prev.filter(x => x.key !== row.key))
		} else {
			setDataSource([initialItem])
		}
	}
	const columns = [
		{
			title: 'Item Description',
			dataIndex: 'description',
			editable: true
		},
		{
			title: 'Product / Service',
			dataIndex: 'ps_group_name'
		},
		{
			title: 'Quantity',
			dataIndex: 'quantity',
			width: '10%',
			editable: true
		},
		{
			title: 'Percentage Completion',
			key: 'percentage',
			dataIndex: 'percentage',
			width: '10%',
			editable: true
		},
		{
			title: 'Rate',
			dataIndex: 'rate',
			width: '10%',
			editable: true
		},
		{
			title: 'Amount Submitted or Paid',
			key: 'amount_history',
			render: (_, record) => (
				<div>
					{record?.history?.total_quantity > 1 && (
						<div className='text-xs'>
							<span className='text-bell-gray'>{record?.history?.quantity_invoiced} No.</span> /
							{record?.history?.total_quantity} No.
						</div>
					)}
					<div>
						<span className='text-bell-gray'>{accounting(record?.history?.amount_invoiced)}</span>/
						{accounting(record?.history?.total_amount)}
					</div>
				</div>
			)
		},
		{
			title: 'Amount (USD)',
			key: 'amount',
			render: (_, record) => <div> {accounting(record.amount)}</div>
		},
		{
			title: 'Tax',
			key: 'tax',
			render: () => <div> {currentTax?.tax || 0}%</div>
		},
		{
			title: 'Net Amount (USD)',
			key: 'net_amount',
			render: (_, record) => <div>{accounting((record.amount * (currentTax?.tax || 0)) / 100 + record.amount)}</div>
		},

		{
			title: 'Action',
			dataIndex: 'action',
			width: '10%',
			editable: true
		}
	]
	const mergerColumns = columns.map(col => {
		if (!col.editable) {
			return col
		}

		return {
			...col,
			onCell: record => ({
				record,
				editable: col.editable,
				dataIndex: col.dataIndex,
				title: col.title,
				handleSave,
				handleRemove
			})
		}
	})

	const components = {
		body: {
			row: EditableRow,
			cell: EditableCell
		}
	}
	return (
		<div className='space-y-4'>
			<Form
				initialValues={{
					tax_item: {
						key: currentTax?.id,
						value: currentTax?.id,
						label: currentTax?.tax_name
					}
				}}
			>
				<Form.Item label='Select Applicable Tax' name='tax_item'>
					<Select
						style={{ width: 300 }}
						placeholder='Select Tax'
						onChange={e => {
							const selectedTax = entityData?.tax_details?.filter(x => x.id === e)[0]
							if (selectedTax) {
								setCurrentTax(selectedTax)
							} else {
								setCurrentTax(null)
							}
						}}
						allowClear
					>
						{entityData?.tax_details?.map(one => (
							<Option value={one.id}>{one.tax_name}</Option>
						))}
					</Select>
				</Form.Item>
			</Form>
			<Table
				components={components}
				rowClassName={() => 'editable-row'}
				bordered
				dataSource={dataSource}
				columns={mergerColumns}
				pagination={false}
			/>
			<Button type='dashed' onClick={handleAdd} block icon={<PlusOutlined />}>
				Add Item
			</Button>
			<Divider />
			<div className='grid place-items-center pt-10'>
				<Button
					onClick={() => {
						handleData({ tax: currentTax, data: dataSource })
						handleClose()
					}}
					type='primary'
				>
					Save & Close
				</Button>
			</div>
		</div>
	)
}

export default ProductCalculationForm
