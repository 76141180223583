import {
	ADD_ENQUIRY,
	ADD_ENQUIRY_ATTACHMENT,
	ADD_ENQUIRY_SOURCE,
	ADD_REVISION,
	DELETE_ENQUIRY,
	EDIT_ENQUIRY,
	ENQUIRY_COSTING,
	ENQUIRY_SUBMISSION,
	RESET_ENQUIRY,
	RESET_ENQUIRY_ATTACHMENT,
	RESET_REVISION,
	SCOPE_AND_EVALUATION,
	SINGLE_ENQUIRY,
	UPDATE_SINGLE_ENQUIRY,
	UPDATE_STAGE
} from '../types'

export const addEnquiry = payload => ({
	type: ADD_ENQUIRY,
	payload
})

export const editEnquiry = payload => ({
	type: EDIT_ENQUIRY,
	payload
})

export const deleteEnquiry = payload => ({
	type: DELETE_ENQUIRY,
	payload
})

export const resetEnquiry = payload => ({
	type: RESET_ENQUIRY,
	payload
})

export const updateStage = payload => ({
	type: UPDATE_STAGE,
	payload
})

export const addEnquirySource = payload => ({
	type: ADD_ENQUIRY_SOURCE,
	payload
})

export const addSingleEnquiry = payload => ({
	type: SINGLE_ENQUIRY,
	payload
})

export const updateSingleEnquiry = payload => ({
	type: UPDATE_SINGLE_ENQUIRY,
	payload
})
export const addScopeAndEvaluation = payload => ({
	type: SCOPE_AND_EVALUATION,
	payload
})

export const addEnquiryCosting = payload => ({
	type: ENQUIRY_COSTING,
	payload
})
export const addEnquirySubmission = payload => ({
	type: ENQUIRY_SUBMISSION,
	payload
})

export const addEnquiryAttachment = payload => ({
	type: ADD_ENQUIRY_ATTACHMENT,
	payload
})

export const resetEnquiryAttachment = payload => ({
	type: RESET_ENQUIRY_ATTACHMENT,
	payload
})

export const addRevision = payload => ({
	type: ADD_REVISION,
	payload
})
export const resetRevision = payload => ({
	type: RESET_REVISION,
	payload
})
