import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { message, Tag } from 'antd'
import { AiTwotoneEdit } from 'react-icons/ai'

import dayjs from 'dayjs'
import _ from 'lodash'
import * as Colors from '../../../styles/colors'

// COMPONENTS
import {
	ActivityTimeline,
	Details,
	Files,
	Information,
	LogInformation,
	Notes,
	Overview,
	TasksView,
	Title
} from '../../../Components/Views'
import { DrawerComponent, InternalHeader, Spinner } from '../../../Components'
import { addSingleContact, updateSingleContact } from '../../../Store/Actions/contacts'
import { getContactById, updateContact } from '../../../Services/Sales/contacts'
import ContactForm from '../../../Components/Forms/ContactForm'
import { TaskForm } from '../../../Components/Forms'
import renderShareTag from '../../../Controllers/shareTag'
import ShareModal from '../../../Components/ShareModal'
import ExpensesView from '../../../Components/Views/Details/ExpensesView'

const SingleContact = () => {
	const history = useHistory()
	const location = useLocation()
	const dispatch = useDispatch()
	const query = new URLSearchParams(location.search)
	const id = query.get('id')
	const { token, id: userId } = useSelector(state => state.userSession)
	const [editDrawer, setEditDrawer] = useState(false)
	const [shareDrawer, setShareDrawer] = useState(false)
	const [loading, setLoading] = useState(true)
	const singleContactsData = useSelector(state => state.singleContactsData)

	const accessType = singleContactsData?.access_specifier
	const shareType = singleContactsData?.access_type

	const fetchSingleContact = async () => {
		try {
			const { data } = await getContactById(token, id)
			if (!data.data) {
				history.push('/sales/contacts')
			}
			dispatch(addSingleContact(data.data))
		} catch (error) {
			message.error('The data could not be fetched.')
			history.push('/sales/contacts')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchSingleContact()
		// eslint-disable-next-line
	}, [editDrawer, shareDrawer])

	const handleFiles = async (currentAttachment, fileName) => {
		try {
			await updateContact(token, {
				id: singleContactsData.id,
				attachments: [...singleContactsData.attachments, currentAttachment]
			})
			dispatch(
				updateSingleContact({
					attachments: [...singleContactsData.attachments, currentAttachment]
				})
			)
			message.success(`${fileName} uploaded successfully`)
		} catch (error) {
			message.error(`Uploading ${fileName} failed. Try again.`)
		}
	}

	const handleRemoveAsset = async fileName => {
		try {
			await updateContact(token, {
				id: singleContactsData.id,
				attachments: singleContactsData.attachments.filter(x => x !== fileName)
			})
			dispatch(
				updateSingleContact({
					attachments: singleContactsData.attachments.filter(x => x !== fileName)
				})
			)
			message.success(`${fileName} removed successfully`)
		} catch (error) {
			message.error(`Deleting ${fileName} failed. Try again.`)
		}
	}

	const handleSharing = async sharingData => {
		try {
			const dataObject = {
				id: singleContactsData.id,
				...sharingData
			}
			await updateContact(token, dataObject)
			message.success(`Contact updated successfully`)
			setShareDrawer(false)
		} catch (error) {
			message.error(`Something went wrong!`)
		}
	}

	const overviewData = {
		..._.omit(singleContactsData, ['contact_owner_name', 'contact_account_name']),
		account_name: (
			<Link to={`./accounts?id=${singleContactsData?.account_name}`}>{singleContactsData?.contact_account_name}</Link>
		),
		contact_owner: singleContactsData?.contact_owner_name,
		date_of_birth: singleContactsData.date_of_birth
			? dayjs.unix(singleContactsData.date_of_birth).format('DD MMM YYYY')
			: undefined,
		mobile_phone: singleContactsData.mobile_phone ? (
			<a href={`tel:${singleContactsData.mobile_phone}`}>{singleContactsData.mobile_phone}</a>
		) : null,
		official_email_address: singleContactsData.official_email_address && (
			<a href={`mailto:${singleContactsData.official_email_address}`}> {singleContactsData.official_email_address}</a>
		),
		personal_email_address: singleContactsData.personal_email_address && (
			<a href={`mailto:${singleContactsData.personal_email_address}`}> {singleContactsData.personal_email_address}</a>
		),
		fax: singleContactsData.fax && <a href={`tel:${singleContactsData.fax}`}>{singleContactsData.fax}</a>,
		office_phone: singleContactsData.office_phone && (
			<a href={`tel:${singleContactsData.office_phone}`}>{singleContactsData.office_phone}</a>
		),
		other_phone: singleContactsData.other_phone && (
			<a href={`tel:${singleContactsData.other_phone}`}>{singleContactsData.other_phone}</a>
		),
		home_phone: singleContactsData.home_phone && (
			<a href={`tel:${singleContactsData.home_phone}`}>{singleContactsData.home_phone}</a>
		),
		linkedin: singleContactsData.linkedin && (
			<a href={`https://www.linkedin.com/in/${singleContactsData.linkedin}/`}>{singleContactsData.linkedin}</a>
		),
		twitter_id: singleContactsData.twitter_id && (
			<a href={`https://twitter.com/${singleContactsData.twitter_id}/`}>{singleContactsData.twitter_id}</a>
		)
	}
	const details = {
		leftColumn: [
			{
				name: 'Overview',
				content: <Overview data={overviewData} />
			},
			{
				name: 'Details',
				content: (
					<Details
						data={[
							{
								name: 'Expenses',
								content: <ExpensesView id={id} />
							},
							{
								name: 'Notes',
								content: <Notes association_id={id} accessType={accessType} />
							},
							{
								name: 'Files',
								content: (
									<Files
										id={id}
										attachments={singleContactsData?.attachments}
										handleFiles={handleFiles}
										handleRemove={handleRemoveAsset}
										accessType={accessType}
										tabView
									/>
								)
							}
						]}
					/>
				)
			},
			{
				name: 'Timeline',
				content: <ActivityTimeline association_id={id} />
			},
			{
				name: 'Tasks',
				content: <TasksView id={id} />
			}
		],
		rightColumn: [
			{
				name: 'Activity',
				content: (
					<Details
						data={[
							{
								name: 'Log Information',
								content: <LogInformation association_id={id} association_type='Contacts' />
							},
							{
								name: 'New Task',
								content: <TaskForm association_id={id} />
							}
						]}
					/>
				)
			}
		]
	}
	return (
		<div>
			<InternalHeader title='Sales' selected='Contacts' />
			{!loading ? (
				<div className='bg-bell-background px-10 py-6 min-h-screen space-y-10'>
					<Title
						title='Contact'
						name={`${singleContactsData.first_name} ${singleContactsData.last_name}`}
						hasSubtitle
						extraDetail={
							<div className='space-y-2'>
								<div className='text-bell-gray'>{singleContactsData.title}</div>
								<div>
									<Tag color={userId === singleContactsData?.contact_owner ? Colors.GREEN : Colors.LIGHT_GREY}>
										{renderShareTag({
											shareType,
											isOwner: userId === singleContactsData?.contact_owner,
											accessType
										})}
									</Tag>
								</div>
							</div>
						}
						editButton={
							<DrawerComponent
								form={
									<ContactForm
										edit
										handleClose={() => {
											setEditDrawer(false)
										}}
										data={singleContactsData}
									/>
								}
								visible={editDrawer}
								onOpen={() => setEditDrawer(true)}
								onClose={() => setEditDrawer(false)}
								buttonTitle={<AiTwotoneEdit color={Colors.BLUE} fontSize={20} />}
								buttonType='link'
								drawerTitle='Edit Contact'
								isHidden={accessType !== 'Public'}
							/>
						}
						shareButton={
							<ShareModal
								visible={shareDrawer}
								onOpen={() => setShareDrawer(true)}
								onClose={() => setShareDrawer(false)}
								modalTitle='Share Contact'
								isHidden={accessType !== 'Public'}
								handleValues={handleSharing}
								data={singleContactsData}
							/>
						}
						overview={[
							{
								title: 'Account Name',
								value: (
									<Link to={`./accounts?id=${singleContactsData?.account_name}`}>
										{singleContactsData?.contact_account_name}
									</Link>
								)
							},
							{
								title: 'Email Address',
								value: (
									<a href={`mailto:${singleContactsData?.official_email_address}`}>
										{singleContactsData?.official_email_address}
									</a>
								)
							},
							{
								title: 'Mobile',
								value: <a href={`tel:${singleContactsData?.mobile_phone}`}>{singleContactsData?.mobile_phone}</a>
							},
							{
								title: 'Contact Owner',
								value: singleContactsData?.contact_owner_name
							}
						]}
					/>
					<Information details={details} accessType={accessType} />
				</div>
			) : (
				<Spinner />
			)}
		</div>
	)
}

export default SingleContact
