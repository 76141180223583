/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { AiTwotoneEdit } from 'react-icons/ai'
import _ from 'lodash'
import { Tag } from 'antd'
import { Link } from 'react-router-dom'
import DrawerComponent from '../../../DrawerComponent'
import * as Colors from '../../../../styles/colors'
import WorkingLeadForm from '../../../Forms/Leads/WorkingLeadForm'
import accounting from '../../../../Controllers/accountingNumbers'
import colorSelector from '../../../../Content/tagColor'

const Working = ({ data, isFilled, editable }) => {
	const [form, setForm] = useState(false)

	const refactor = data
		? {
				..._.omit(data, [
					'stakeholders_data',
					'competitors_data',
					'decision_makers_data',
					'previous_summary',
					'opportunity',
					'opportunity_name'
				]),
				stakeholders:
					data.stakeholders_data?.length &&
					data.stakeholders_data.map(one => (
						<div className='mt-2'>
							<a href={`/sales/${one.type.toLowerCase()}?id=${one.id}`} className='pt-2'>
								<Tag color={Colors[colorSelector[one.type]]}>{one.type}</Tag>
								{one.name}
							</a>
						</div>
					)),
				competitors:
					data?.competitors_data?.length &&
					data.competitors_data.map(one => (
						<div>
							<a href={`/sales/accounts?id=${one.id}`} className='pt-2'>
								{one.name}
							</a>
						</div>
					)),
				decision_makers:
					data?.decision_makers_data?.length &&
					data.decision_makers_data.map(one => (
						<div>
							<a href={`/sales/contacts?id=${one.id}`} className='pt-2'>
								{one.name}
							</a>
						</div>
					)),
				estimated_budget: data.estimated_budget && `USD ${accounting(data.estimated_budget)}`,
				business_opportunity: (
					<Link to={`./opportunities?id=${data.opportunity}`}>{data.opportunity_name || data.opportunity}</Link>
				)
		  }
		: null

	return (
		<div>
			<div className='flex items-center'>
				<div className='font-bold text-base text-bell-text py-2'> Working</div>
				{isFilled && editable && (
					<DrawerComponent
						form={<WorkingLeadForm edit data={data} handleClose={() => setForm(false)} />}
						visible={form}
						onOpen={() => setForm(true)}
						onClose={() => setForm(false)}
						buttonTitle={<AiTwotoneEdit color={Colors.BLUE} fontSize={20} />}
						buttonType='link'
						drawerTitle='Working'
						drawerWidth='1000'
					/>
				)}
			</div>

			{isFilled ? (
				<div className='p-4 space-y-4'>
					{Object.keys(_.omit(refactor))
						.filter(item => refactor[item])
						.map((item, i) => (
							<div className='grid grid-cols-3 lg:grid-cols-5 gap-4' key={i}>
								<div className='capitalize col-span-1 lg:col-span-1 text-bell-gray'>{item.replaceAll('_', ' ')}</div>
								<div className='col-span-2 lg:col-span-2'> {refactor[item]}</div>
							</div>
						))}
				</div>
			) : (
				<div>
					<div className='text-bell-gray text-sm py-2'>No Information Available</div>
					{editable && (
						<DrawerComponent
							form={<WorkingLeadForm handleClose={() => setForm(false)} />}
							visible={form}
							onOpen={() => setForm(true)}
							onClose={() => setForm(false)}
							buttonTitle='Add Information'
							drawerTitle='Working'
							drawerWidth='1000'
						/>
					)}
				</div>
			)}
		</div>
	)
}

export default Working
