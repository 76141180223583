import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { message, Space, Tag } from 'antd'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { DataView, DrawerComponent, InternalHeader, Stats } from '../../Components'

import * as Colors from '../../styles/colors'

import { resetTask } from '../../Store/Actions/tasks'
import { getAllTasks } from '../../Services/task'
import { getTaskStats } from '../../Services/stats'
import TaskForm from '../../Components/Forms/TaskForm'
import dateFormat from '../../Content/dateFormat'
import tableFilter from '../../Controllers/tableFilter'
import TaskViewer from '../../Components/TaskViewer'
import { taskPriorityColor } from '../../Content/tasks'
import StartTask from '../../Components/StartTask'
import { timeSheetSync } from '../../Content/timetracker'
import MarkAsCompleted from '../../Components/MarkAsCompleted'

const Tasks = ({ type }) => {
	const tasksData = useSelector(state => state.tasksData)
	const [addTaskDrawer, setAddTaskDrawer] = useState(false)
	const [viewTaskDrawer, setViewTaskDrawer] = useState(false)

	const [stats, setStats] = useState(null)

	const { token, role, id } = useSelector(state => state.userSession)
	const dispatch = useDispatch()
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		sortOrder: 'desc',
		sortField: 'created_at',
		total: ''
	})
	const [search, setSearch] = useState('')

	const handleSearch = e => {
		setSearch(e.target.value)
	}

	const fetchData = async (current, pageSize, sortOrder, sortField, query) => {
		try {
			const { data } = await getAllTasks(token, current, pageSize, sortOrder, sortField, query, type)
			if (!data.data) {
				dispatch(resetTask([]))
			} else {
				dispatch(resetTask(data.data))
			}
			setPagination({
				current: current + 1,
				pageSize,
				sortOrder,
				sortField,
				total: data.count
			})
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const handleChange = (page, filters, sorter) => {
		const sortOrder = {
			ascend: 'asc',
			descend: 'desc'
		}
		fetchData(page.current - 1, page.pageSize, sortOrder[sorter.order], sorter.order ? sorter.columnKey : '', search)
	}

	useEffect(() => {
		fetchData(0, pagination.pageSize, pagination.sortOrder, pagination.sortField, search)
		// eslint-disable-next-line
	}, [search])

	const getStats = async () => {
		try {
			const { data } = await getTaskStats(token, type)
			setStats(data.data)
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}
	useEffect(() => {
		getStats(token)
		// eslint-disable-next-line
	}, [tasksData])

	const tasksStats = {
		title: 'Tasks',
		total: stats?.tasks,
		data: [
			{
				name: 'Not Started',
				color: Colors.RED,
				number: stats && stats.tasks - (stats.in_progress + stats.completed)
			},
			{
				name: 'In Progress',
				color: Colors.YELLOW,
				number: stats?.in_progress
			},
			{
				name: 'Completed',
				color: Colors.GREEN,
				number: stats?.completed
			}
		]
	}
	const columns = [
		{
			title: 'Action / Task',
			key: 'name',
			sorter: true,
			dataIndex: 'name'
		},
		{
			title: 'Assigned To',
			dataIndex: 'assignee_name',
			key: 'assignee_name',
			...tableFilter('Owner', id, setSearch)
		},
		{
			title: 'Associated With',
			key: 'association_name',
			render: (_, record) =>
				record.association_name ? (
					<div>
						<div className='text-xs text-bell-gray uppercase'>{record.association}</div>
						<Link
							to={`../${record?.related_to?.toLowerCase()}/${record?.association?.toLowerCase()}?id=${
								record.association_id
							}`}
						>
							{record.association_name}
						</Link>
					</div>
				) : (
					<div className='border border-solid border-bell-blue w-2 ml-6' />
				)
		},
		{
			title: 'Due Date',
			sorter: true,
			key: 'end_date',
			render: (_, record) => (
				<div>
					{' '}
					{typeof record.end_date !== 'string'
						? dayjs.unix(record.end_date).format(dateFormat)
						: dayjs(record.end_date).format(dateFormat)}
				</div>
			)
		},
		{
			title: 'Priority',
			dataIndex: 'priority',
			sorter: true,
			key: 'priority',
			render: priority => (
				<Tag color={taskPriorityColor[priority]} key={priority}>
					{priority?.toUpperCase()}
				</Tag>
			)
		},
		{
			title: 'Status',
			dataIndex: 'status',
			sorter: true,
			key: 'status',
			render: status => {
				let color
				if (status === 'Not Started') {
					color = Colors.RED
				}

				if (status === 'In Progress') {
					color = Colors.YELLOW
				}
				if (status === 'Completed') {
					color = Colors.GREEN
				}
				return (
					<Tag color={color} key={status}>
						{status?.toUpperCase()}
					</Tag>
				)
			}
		},

		{
			title: 'Actions',
			key: 'actions',
			render: (_, record) => (
				<Space size='middle'>
					<DrawerComponent
						form={
							<TaskViewer
								id={record.id}
								handleTaskDelete={() => {
									setViewTaskDrawer(false)
									dispatch(resetTask(tasksData.filter(x => x.id !== record.id)))
								}}
							/>
						}
						visible={viewTaskDrawer[record.id]}
						onOpen={() => setViewTaskDrawer({ [record.id]: true })}
						onClose={() => setViewTaskDrawer(false)}
						buttonTitle='View'
						buttonType='link'
					/>
					<StartTask
						description={record.name}
						category={timeSheetSync[record.related_to]}
						association={timeSheetSync[record.association]}
						association_id={record.association_id}
						task_id={record.id}
					/>
					<MarkAsCompleted task_id={record.id} status={record.status} />
					{/* <DrawerComponent
						form={<TaskForm edit handleClose={() => setEditTaskDrawer(false)} data={record} />}
						visible={editTaskDrawer[record.id]}
						onOpen={() => setEditTaskDrawer({ [record.id]: true })}
						onClose={() => setEditTaskDrawer(false)}
						buttonTitle='Edit'
						buttonType='link'
					/> */}
				</Space>
			)
		}
	]

	const columnRenderer = () => {
		if (role === 'Employee') {
			return columns.filter(x => ['assignee_name'].indexOf(x.key) === -1)
		}
		return columns
	}

	return (
		<div className='bg-bell-background'>
			<InternalHeader title={type || 'Tasks'} selected='Tasks' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium _-blue-text'> Tasks </div>
				<div>
					<DrawerComponent
						form={<TaskForm handleClose={() => setAddTaskDrawer(false)} />}
						visible={addTaskDrawer}
						onOpen={() => setAddTaskDrawer(true)}
						onClose={() => setAddTaskDrawer(false)}
						buttonTitle='Add Task'
					/>
				</div>
			</div>
			<div className='grid grid-flow-col px-10'>
				<div className='col-span-2'>
					<Stats data={tasksStats} />
				</div>
			</div>
			<div>
				<DataView
					columns={columnRenderer()}
					data={tasksData}
					type='tasks'
					pagination={pagination}
					handleChange={handleChange}
					handleSearch={handleSearch}
					// rowClickable
				/>
			</div>
		</div>
	)
}

export default Tasks
