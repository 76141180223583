// eslint-disable-next-line import/prefer-default-export
// for choosing project color
export const colorsArray = [
	'#f44336',
	'#e91e63',
	'#9c27b0',
	'#673ab7',
	'#3f51b5',
	'#2196f3',
	'#03a9f4',
	'#00bcd4',
	'#009688',
	'#4caf50',
	'#8bc34a',
	'#cddc39',
	'#ffeb3b',
	'#ffc107',
	'#ff9800',
	'#ff5722',
	'#795548',
	'#607d8b'
]

export const randomColorGenerator = (string, s = 30, l = 80) => {
	let hash = 0
	for (let i = 0; i < string.length; i++) {
		hash = string.charCodeAt(i) + ((hash << 5) - hash)
	}

	const h = hash % 360
	return `hsl(${h}, ${s}%, ${l}%)`
}

export const associationsList = [
	'Contract',
	'Lead',
	'Enquiry',
	'Opportunity',
	'Product / Service',
	'Account',
	'Contact'
]
