import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Space, Popconfirm, message } from 'antd'
import { DataView } from './index'
import { deletePaperAccessById, getPaperAccessList } from '../Services/Sales/websiteinquiries'

const WhitePaperListView = () => {
	const { token } = useSelector(state => state.userSession)
	const [data, setData] = useState([])
	const [dataLoader, setDataLoader] = useState(false)
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		total: ''
	})

	const fetchData = async (_, current, pageSize) => {
		try {
			const response = await getPaperAccessList(token, current, pageSize)
			setData(response.data.data)
			setPagination({
				current: current + 1,
				pageSize,
				total: response.data.count
			})
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong!')
		} finally {
			setDataLoader(false)
		}
	}

	const handleChange = page => {
		fetchData(token, page.current - 1, page.pageSize)
	}

	useEffect(() => {
		fetchData(token, 0, pagination.pageSize, pagination.sortOrder)
		// eslint-disable-next-line
	}, [])

	const columns = [
		{
			title: 'Name',
			key: 'name',
			dataIndex: 'name',
			render: x => <div className='font-semibold'>{x}</div>
		},
		{
			title: 'Email',
			key: 'email',
			dataIndex: 'email',
			render: email => (
				<a href={`mailto:${email}`} className='inline-block w-36'>
					{email}
				</a>
			)
		},
		{
			title: 'Company',
			key: 'company',
			dataIndex: 'company'
		},
		{
			title: 'Industry',
			key: 'industry',
			dataIndex: 'industry'
		},
		{
			title: 'Role',
			key: 'role',
			dataIndex: 'role'
		},
		{
			title: 'City / State / Country',
			key: 'city',
			dataIndex: 'city',
			render: (x, rec) => (
				<div>
					{rec.city} {rec.state} {rec.country}
				</div>
			)
		},

		{
			title: 'Actions',
			key: 'actions',
			render: (_, record) => (
				<Space>
					<Popconfirm
						title='Are you sure to delete this entry? This action cannot be undone.'
						showArrow
						icon={false}
						okText='Delete'
						okButtonProps={{ danger: true }}
						onConfirm={async () => {
							try {
								await deletePaperAccessById(token, record.id)
								setData(prev => prev.filter(q => q.id !== record.id))
							} catch (error) {
								message.error('Something wrong happened. Please try again!')
							}
						}}
					>
						<Button type='link' danger>
							Delete
						</Button>
					</Popconfirm>
				</Space>
			)
		}
	]

	return (
		<div>
			<DataView
				columns={columns}
				noHeader
				data={data}
				type='website'
				loading={dataLoader}
				pagination={pagination}
				handleChange={handleChange}
				handleSearch={() => {}}
			/>
		</div>
	)
}

export default WhitePaperListView
