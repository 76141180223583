import { ADD_ACTIVITY, DELETE_ACTIVITY, RESET_ACTIVITY } from '../types'

export const addActivity = payload => ({
	type: ADD_ACTIVITY,
	payload
})

export const deleteActivity = payload => ({
	type: DELETE_ACTIVITY,
	payload
})

export const resetActivity = payload => ({
	type: RESET_ACTIVITY,
	payload
})
