import axios from 'axios'
import { URL } from '../../config'

export const getAllAccounts = (token, offset = 0, limit = 10, sort_order = 'desc', sort_by = 'email', query = '') =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/account/all?offset=${offset}&limit=${limit}&sort_order=${sort_order}&sort_by=${sort_by}&query=${query}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const createAccount = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'post',
				url: `${URL}/account/add`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const updateAccount = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'put',
				url: `${URL}/account/edit`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getAccountById = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/account/get/${id}`,

				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getEnquiriesByAccountID = (
	token,
	id,
	offset = 0,
	limit = 10,
	sort_order = 'desc',
	sort_by = 'created_at',
	query = ''
) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/enquiry/get-by-account/${id}?offset=${offset}&limit=${limit}&sort_order=${sort_order}&sort_by=${sort_by}&query=${query}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getLeadsByAccountID = (
	token,
	id,
	offset = 0,
	limit = 10,
	sort_order = 'desc',
	sort_by = 'created_at',
	query = ''
) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/lead/get-by-account/${id}?offset=${offset}&limit=${limit}&sort_order=${sort_order}&sort_by=${sort_by}&query=${query}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getChildAccountByAccountID = (
	token,
	id,
	offset = 0,
	limit = 10,
	sort_order = 'desc',
	sort_by = 'created_at',
	query = ''
) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/account/get-child/${id}?offset=${offset}&limit=${limit}&sort_order=${sort_order}&sort_by=${sort_by}&query=${query}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getContactsByAccountID = (
	token,
	id,
	offset = 0,
	limit = 10,
	sort_order = 'desc',
	sort_by = 'created_at',
	query = ''
) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/contact/get-by-account/${id}?offset=${offset}&limit=${limit}&sort_order=${sort_order}&sort_by=${sort_by}&query=${query}`,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
