import { Spin } from 'antd'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import * as Colors from '../styles/colors'

const Stats = ({ data, nograph }) => {
	const graphData = {
		labels: data.data.map(x => x.name),
		datasets: [
			{
				label: data.title,
				data: data.data.map(x => x.number),
				backgroundColor: data.data.map(x => x.color)
			}
		]
	}

	const options = {
		plugins: {
			legend: {
				position: 'right',
				labels: {
					color: Colors.LIGHT_GREY,
					font: {
						family: 'Fira Sans'
					},
					padding: 20
				}
			}
		},
		layout: {
			padding: 0
		}
	}
	return data.total || data.total === 0 ? (
		<div>
			<div className='flex items-center space-x-4'>
				<div className='font-medium text-5xl'> {data.total}</div>
				<div className='text-bell-gray font-medium text-lg'> {data.title}</div>
			</div>
			{!nograph && data.total > 0 ? (
				<div className='flex'>
					<div>
						<Doughnut data={graphData} options={options} />
					</div>
				</div>
			) : null}
		</div>
	) : (
		<Spin size='large' />
	)
}

export default Stats
