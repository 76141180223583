import {
	ADD_EMPLOYEE,
	ADD_ROLE,
	DELETE_EMPLOYEE,
	DELETE_ROLE,
	EDIT_EMPLOYEE,
	EDIT_ROLE,
	RESET_EMPLOYEE,
	RESET_ROLE
} from '../types'

export const addEmployee = payload => ({
	type: ADD_EMPLOYEE,
	payload
})

export const resetEmployee = payload => ({
	type: RESET_EMPLOYEE,
	payload
})

export const editEmployee = payload => ({
	type: EDIT_EMPLOYEE,
	payload
})

export const deleteEmployee = payload => ({
	type: DELETE_EMPLOYEE,
	payload
})

export const addRole = payload => ({
	type: ADD_ROLE,
	payload
})

export const resetRole = payload => ({
	type: RESET_ROLE,
	payload
})

export const editRole = payload => ({
	type: EDIT_ROLE,
	payload
})

export const deleteRole = payload => ({
	type: DELETE_ROLE,
	payload
})
