import React, { useState } from 'react'
import { Button, message, Upload } from 'antd'
import * as XLSX from 'xlsx'
import { useSelector } from 'react-redux'

import { InboxOutlined, DownloadOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import * as Colors from '../../styles/colors'
import { bulkUploadUser } from '../../Services/user'

const BulkUpload = ({ handleClose }) => {
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const userSession = useSelector(state => state.userSession)

	const [data, setData] = useState([])

	const { Dragger } = Upload

	const onUpload = async () => {
		const dataObject = data.map(x => ({
			first_name: x['First Name'],
			last_name: x['Last Name'],
			email: x['Email Address'],
			department: x.Department,
			designation: x.Designation,
			role: x.Role,
			account_status: 'Active'
		}))
		setLoading(true)
		try {
			await bulkUploadUser(userSession.token, dataObject)
			message.success('Employee Information saved successfully!')
			history.go()
			setLoading(false)
		} catch (error) {
			handleClose()
			setLoading(false)
			message.error(error.response?.data?.message || 'Something went wrong')
		}
	}

	const props = {
		name: 'file',
		multiple: false,
		accept: '.xlsx',

		onChange(e) {
			const f = e.file.originFileObj

			const reader = new FileReader()
			reader.onload = function (e) {
				const data = e.target.result
				const read = XLSX.read(data, { type: 'binary', cellDates: true })
				const wsname = read.SheetNames[0]
				const ws = read.Sheets[wsname]
				const parsedData = XLSX.utils.sheet_to_json(ws)
				setData(parsedData)
			}
			reader.readAsBinaryString(f)
		}
	}

	return (
		<div className='w-full'>
			<div>Please download the template to ease the process of upload.</div>

			<a
				href={`${process.env.PUBLIC_URL}/excel-template.xlsx`}
				download='Paradigm Excel Template'
				target='_blank'
				rel='noreferrer'
				className='flex space-x-2 items-end my-4'
			>
				<div>
					<DownloadOutlined style={{ color: Colors.BLUE, fontSize: 14 }} />
				</div>
				<div className='text-bell-blue font-semibold text-sm'>Download Template</div>
			</a>
			<div>
				<Dragger {...props}>
					<p className='ant-upload-drag-icon'>
						<InboxOutlined />
					</p>
					<p className='ant-upload-text'>Click or drag file to this area to upload</p>
					<p className='ant-upload-hint'>We recommend not changing any column names in the template.</p>
				</Dragger>
			</div>
			<div className='mt-4'>
				<Button type='primary' htmlType='button' loading={loading} onClick={onUpload}>
					Upload
				</Button>
			</div>
		</div>
	)
}

export default BulkUpload
