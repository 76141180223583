/* eslint-disable react/no-unstable-nested-components */
import React, { useRef, useState, useEffect } from 'react'
import ReactToPrint from 'react-to-print'
import { Link } from 'react-router-dom'
import { PrinterOutlined } from '@ant-design/icons'
import { Button, Table, Spin, message, Select, Tag } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { InternalHeader } from '../../Components'
import { addEnquiryReportData } from '../../Store/Actions/reports'
import { DownloadExcel } from '../../Components/DownloadExcel'
import { BASE_URL } from '../../config'
import { getAccountsByCountry, getCountriesList, getEnquiriesByAccountReport } from '../../Services/reports'
import NoData from '../../Components/NoData'

const EnquiriesByAccountAndCountryReport = () => {
	const componentRef = useRef()
	const { token } = useSelector(state => state.userSession)
	const { enquiryReport } = useSelector(state => state.reportsData)
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(true)
	const [reportData, setReportData] = useState(null)
	const [countriesList, setCountriesList] = useState([])
	const [accountsList, setAccountsList] = useState([])

	const fetchCountriesList = async () => {
		try {
			const { data } = await getCountriesList(token)
			if (!data.data) {
				setCountriesList([])
			} else {
				setCountriesList(data.data)
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const fetchAccounts = async e => {
		try {
			const { data } = await getAccountsByCountry(token, e)
			if (!data.data) {
				setAccountsList([])
			} else {
				setAccountsList(data.data)
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const fetchReport = async () => {
		setLoading(true)
		try {
			const { data } = await getEnquiriesByAccountReport(token, enquiryReport?.account?.id)
			if (!data.data) {
				setReportData(null)
			} else {
				setReportData(data.data)
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchCountriesList()
		if (!enquiryReport) {
			dispatch(
				addEnquiryReportData({
					country: null,
					account: null
				})
			)
		}
	}, [])

	useEffect(() => {
		fetchReport()
	}, [enquiryReport?.country, enquiryReport?.account])

	const excelData = reportData?.length
		? {
				Enquiries: reportData.map(one => ({
					Name: one?.name,
					Amount: one?.amount,
					'Contact Person': `${one?.lead?.first_name || ''} ${one?.lead?.last_name || ''}`,
					'Mobile Phone': one?.lead?.mobile_phone,
					'Office Phone': one?.lead?.office_phone,
					'Personal Email': one?.lead?.personal_email_address,
					'Official Email': one?.lead?.official_email_address,
					Link: one.id && `${BASE_URL}/sales/enquiries?id=${one.id}`
				}))
		  }
		: null

	return (
		<div className='bg-bell-background'>
			<InternalHeader title='Reports' selected='All Reports' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Enquiries by Country and Account </div>
			</div>
			<div className='px-10'>
				<div className='flex flex-row-reverse justify-between items-center'>
					<div className='flex space-x-4'>
						<DownloadExcel
							data={excelData}
							fileName={`Enquiries Report for ${enquiryReport?.account?.name} in ${enquiryReport?.country}.xlsx`}
						/>
						<ReactToPrint
							trigger={() => (
								<Button type='primary' icon={<PrinterOutlined />}>
									Print Report
								</Button>
							)}
							content={() => componentRef.current}
						/>
					</div>
					<div className='flex space-x-4 text-xs text-gray-600'>
						<Select
							showSearch
							placeholder='Select Country'
							onChange={e => {
								dispatch(
									addEnquiryReportData({
										country: e,
										account: null
									})
								)
								fetchAccounts(e)
							}}
							options={countriesList.map(a => ({ value: a, label: a }))}
							value={enquiryReport?.country}
							style={{ width: 200 }}
						/>
						<Select
							showSearch
							placeholder='Identify Account'
							onChange={(e, q) => {
								dispatch(
									addEnquiryReportData({
										country: enquiryReport?.country,
										account: {
											id: q.value,
											name: q.label
										}
									})
								)
							}}
							options={accountsList.map(a => ({ value: a.id, label: a.name }))}
							value={enquiryReport?.account?.name}
							style={{ width: 200 }}
						/>
					</div>
				</div>
				<ComponentToPrint
					ref={componentRef}
					componentData={reportData}
					enquiryReport={enquiryReport}
					loading={loading}
				/>
			</div>
		</div>
	)
}

const ComponentToPrint = React.forwardRef(({ componentData, loading, enquiryReport }, ref) =>
	// eslint-disable-next-line no-nested-ternary
	loading ? (
		<div className='pt-32 text-center'>
			{' '}
			<Spin size='large' />
		</div>
	) : enquiryReport?.country && enquiryReport?.account ? (
		<div ref={ref} className='print:p-10 py-10'>
			<section className='text-2xl font-semibold py-2'>
				Enquiries Report:{' '}
				<span className='text-bell-blue'>
					{' '}
					{enquiryReport?.account?.name} ({enquiryReport?.country})
				</span>
			</section>
			<Table
				columns={[
					{ title: 'Name', dataIndex: 'name' },
					{ title: 'Amount (USD)', dataIndex: 'amount' },
					{
						title: 'Contact Person',
						render: (_, record) => (
							<Link to={`/sales/contacts?id=${record?.lead?.id}`}>
								{record?.lead?.first_name} {record?.lead?.last_name}
							</Link>
						)
					},
					{
						title: "Contact's Email",
						render: (_, record) => (
							<div className='space-y-2'>
								{record?.lead?.personal_email_address && (
									<div>
										<Tag> Personal Email </Tag>
										<div className=''>{record?.lead?.personal_email_address}</div>
									</div>
								)}
								{record?.lead?.official_email_address && (
									<div>
										<Tag> Official Email </Tag>
										<div className=''>{record?.lead?.official_email_address}</div>
									</div>
								)}
							</div>
						)
					},
					{
						title: "Contact's Phone",
						render: (_, record) => (
							<div className='space-y-2'>
								{record?.lead?.mobile_phone && (
									<div>
										<Tag> Mobile </Tag>
										<div className=''>{record?.lead?.mobile_phone}</div>
									</div>
								)}
								{record?.lead?.office_phone && (
									<div>
										<Tag> Office </Tag>
										<div className=''>{record?.lead?.office_phone}</div>
									</div>
								)}
							</div>
						)
					},
					{
						title: 'Actions',
						key: 'actions',
						render: (_, record) => (
							<div className='z-10'>
								<a href={record.id && `${BASE_URL}/sales/enquiries?id=${record.id}`}>View Enquiry</a>
							</div>
						)
					}
				]}
				dataSource={componentData}
				pagination={false}
			/>
		</div>
	) : (
		<NoData title='Please select country and account for reports!' />
	)
)

export default EnquiriesByAccountAndCountryReport
