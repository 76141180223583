export const getToken = () => sessionStorage.getItem('token') || null

export const getRole = () => sessionStorage.getItem('role') || null

// remove the email from the session storage
export const removeUserSession = () => {
	sessionStorage.removeItem('token')
	sessionStorage.removeItem('role')
}

// set the email from the session storage
export const setUserSession = (name, value) => {
	sessionStorage.setItem(name, value)
}
