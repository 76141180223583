import React, { useState } from 'react'
import { Collapse, Table, Tooltip } from 'antd'
import { HiBadgeCheck } from 'react-icons/hi'
import { FaCaretDown, FaCaretRight } from 'react-icons/fa'
import accounting from '../../Controllers/accountingNumbers'
import * as Colors from '../../styles/colors'

const { Panel } = Collapse

const CostingCollapsibleTable = ({ data, currency, submitted }) => {
	const [activeKeys, setActiveKeys] = useState([])

	function callback(key) {
		setActiveKeys(key)
	}

	const PanelName = ({ name, id, keyID }) => {
		const w = activeKeys.indexOf(String(keyID))
		return (
			<div className='flex items-center space-x-1'>
				<div>{w < 0 ? <FaCaretRight /> : <FaCaretDown />}</div>
				<div className={id === submitted ? 'font-semibold' : ''}>
					{id === submitted ? (
						<div className='flex items-center space-x-2'>
							<div>{name}</div>
							<Tooltip title='This schema was used on submission.' placement='topLeft'>
								<HiBadgeCheck fontSize={22} color={Colors.GREEN} />
							</Tooltip>
						</div>
					) : (
						<div>{name}</div>
					)}
				</div>
			</div>
		)
	}
	return (
		<Collapse onChange={callback}>
			{data.map((panel, i) => (
				<Panel header={<PanelName name={panel.name} id={panel.id} keyID={i} />} key={i} showArrow={false}>
					<div>
						<p> {panel.description} </p>
						<Table
							columns={[
								{
									title: 'Product Name',
									dataIndex: 'name',
									key: 'product_id'
								},
								{
									title: 'Billable Tasks Cost',
									render: (_, record) => (
										<div className='text-right'>
											{' '}
											{accounting(record.billable_tasks?.map(x => x.total_amount).reduce((a, b) => a + b, 0))}{' '}
										</div>
									)
								},
								{
									title: 'Subcontracting Cost',
									render: (_, record) => <div className='text-right'> {accounting(record.subcontracting?.value)} </div>
								},
								{
									title: 'Travel Cost',
									render: (_, record) => <div className='text-right'> {accounting(record.travel?.value)} </div>
								},
								{
									title: 'Accomodation Cost',
									render: (_, record) => <div className='text-right'> {accounting(record.accomodation?.value)} </div>
								},
								{
									title: 'Other Costs',
									render: (_, record) => (
										<div className='text-right'>
											{' '}
											{accounting(record.other_costs?.map(x => Number(x.value)).reduce((a, b) => a + b, 0))}{' '}
										</div>
									)
								},
								{
									title: 'Total Cost',
									render: (_, record) => <div className='text-right'> {accounting(record.total_cost)} </div>
								}
							]}
							dataSource={panel?.deliverables}
							footer={() => (
								<div className='flex justify-end space-x-2 items-center'>
									<div>Grand Total:</div>
									<div className='font-bold'>
										{currency} {accounting(panel?.deliverables?.map(x => x?.total_cost).reduce((a, b) => a + b, 0))}
									</div>
								</div>
							)}
						/>
					</div>
				</Panel>
			))}
		</Collapse>
	)
}

export default CostingCollapsibleTable
