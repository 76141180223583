// eslint-disable-next-line import/prefer-default-export
export const timeDuration = (duration, type) => {
	if (type === 'days') {
		return `${duration.days()}d ${duration.hours()}h`
	}
	if (type === 'table') {
		return `${duration.days() > 0 ? `${duration.days()}d` : ''} ${duration.hours()}h ${`0${duration.minutes()}`.slice(
			-2
		)}m`
	}
	return `${duration.hours()}h ${`0${duration.minutes()}`.slice(-2)}m ${`0${duration.seconds()}`.slice(-2)}s`
}
