/* eslint-disable default-param-last */
import { ADD_INVOICE, EDIT_INVOICE, RESET_INVOICE, SINGLE_INVOICE, UPDATE_SINGLE_INVOICE } from '../types'

const invoices = []
const singleInvoicesState = []

export const invoicesData = (state = invoices, { type, payload }) => {
	switch (type) {
		case ADD_INVOICE:
			return [payload, ...state]
		case EDIT_INVOICE:
			return state.map(x => {
				if (x.id === payload.id) {
					return payload.data
				}
				return x
			})
		case RESET_INVOICE:
			return payload

		default:
			return state
	}
}

export const singleInvoicesData = (state = singleInvoicesState, { type, payload }) => {
	switch (type) {
		case SINGLE_INVOICE:
			return payload
		case UPDATE_SINGLE_INVOICE:
			return {
				...state,
				...payload
			}
		default:
			return state
	}
}
