import React, { useState } from 'react'
import { Button, Checkbox, Form, Input, message, Select, Space } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

// COMPONENTS
import { form_options } from '../../Content/enquiries'
import { addAccount, editAccount } from '../../Store/Actions/accounts'
import { formItemLayout, tailFormItemLayout } from '../../Controllers/form'
import SelectDebounce from '../SelectDebounce'
import { createAccount, getAccountById, updateAccount } from '../../Services/Sales/account'
import { fetchAccountList } from '../../Controllers/fetchLists'
import { objectDifference } from '../../Controllers/objectDifference'
import { refreshComponent } from '../../Store/Actions/userSession'
import ReporteeSelect from '../ReporteeSelect'

const { Option } = Select

const AccountForm = ({ handleClose, handleAccountValues, edit, data }) => {
	const { token } = useSelector(state => state.userSession)

	const dispatch = useDispatch()

	const formData = data
		? {
				...data,
				owner: {
					label: data.owner_name,
					key: data.owner,
					value: data.owner
				}
		  }
		: null
	//   INSIDE FORMS VISIBILITY
	const [loading, setLoading] = useState(false)
	const [checked, setChecked] = useState(false)
	const [form] = Form.useForm()

	const handleChange = () => {
		if (checked) {
			form.setFieldsValue({
				shipping_address: form.getFieldValue('billing_address'),
				shipping_city: form.getFieldValue('billing_city'),
				shipping_state: form.getFieldValue('billing_state'),
				shipping_code: form.getFieldValue('billing_code'),
				shipping_country: form.getFieldValue('billing_country')
			})
		}
	}
	const handleCheck = e => {
		setChecked(e.target.checked)
		if (e.target.checked) {
			form.setFieldsValue({
				shipping_address: form.getFieldValue('billing_address'),
				shipping_city: form.getFieldValue('billing_city'),
				shipping_state: form.getFieldValue('billing_state'),
				shipping_code: form.getFieldValue('billing_code'),
				shipping_country: form.getFieldValue('billing_country')
			})
		}
	}
	const onAdd = async values => {
		setLoading(true)
		const dataObject = {
			...values,
			owner: values?.owner?.value,
			parent_account: values.parent_account?.value || undefined,
			attachments: []
		}
		try {
			const { data } = await createAccount(token, dataObject)
			const res = await getAccountById(token, data.data.id)
			dispatch(addAccount(res.data.data))
			if (handleAccountValues) {
				handleAccountValues({
					id: data.data.id,
					...dataObject
				})
			}
			setLoading(false)
			message.success('Account Information saved successfully!')
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error.response?.data?.message || 'Something went wrong!')
		}
	}

	const onEdit = async values => {
		setLoading(true)
		const dataObject = {
			...values,
			owner: values.owner.value || data.owner,
			parent_account: values.parent_account?.value || data.parent_account
		}
		try {
			const updatedData = objectDifference(data, dataObject)
			await updateAccount(token, { id: data.id, ...updatedData })
			const res = await getAccountById(token, data.id)
			dispatch(
				editAccount({
					id: data.id,
					data: res.data.data
				})
			)
			dispatch(refreshComponent())
			message.success('Account Information updated successfully!')
			setLoading(false)
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	return (
		<div className='space-y-20'>
			{/* REGISTER FORM */}
			<Form
				name='control-hooks'
				form={form}
				onFinish={edit ? onEdit : onAdd}
				{...formItemLayout}
				scrollToFirstError
				onChange={handleChange}
				initialValues={formData}
			>
				<div className='py-2 italic text-bell-gray'>Account Information</div>
				<Form.Item label='Account Owner'>
					<Space>
						<Form.Item name='owner' noStyle rules={[{ required: true, message: 'Please select account owner!' }]}>
							<ReporteeSelect form={form} fieldName='owner' currentValue={formData ? formData?.owner?.label : null} />
						</Form.Item>
					</Space>
				</Form.Item>
				<Form.Item
					label='Account Name'
					name='account_name'
					rules={[
						{
							required: true,
							message: 'Please input account name!'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item label='D & B DUNS Number' name='duns_number'>
					<Input />
				</Form.Item>

				<Form.Item label='Parent Account' name='parent_account'>
					<SelectDebounce
						showSearch
						placeholder='Search Account'
						fetchOptions={e => fetchAccountList(e, token)}
						currentSearchValue={form.getFieldValue('parent_account')?.label ? '' : data?.parent_account_name}
					/>
				</Form.Item>

				<Form.Item label='Specific Location/Site' name='location'>
					<Input />
				</Form.Item>
				<Form.Item label='Phone' name='phone'>
					<Input />
				</Form.Item>
				<Form.Item label='Fax' name='fax'>
					<Input />
				</Form.Item>
				<Form.Item
					label='Email'
					name='email'
					rules={[
						{
							type: 'email',
							message: 'Please input valid email address.'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item
					label='Website'
					name='website'
					rules={[
						{
							type: 'url',
							message: 'Please input valid web address. Example: https://www.example.com'
						}
					]}
				>
					<Input type='url' />
				</Form.Item>
				<Form.Item label='Ticker Symbol' name='ticker_symbol'>
					<Input />
				</Form.Item>
				<Form.Item label='Number of Employees' name='number_of_employees'>
					<Select placeholder='Select Employee Strength'>
						{form_options.number_of_employees.map((option, i) => (
							<Option value={option.name} key={i}>
								<p className='capitalize'>{option.name}</p>
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label='SIC Code' name='sic_code'>
					<Input />
				</Form.Item>
				<Form.Item label='Annual Revenue' name='annual_revenue'>
					<Input addonBefore='USD' />
				</Form.Item>
				<Form.Item label='Ownership' name='ownership'>
					<Select placeholder='Select Ownership'>
						{form_options.ownership.map((option, i) => (
							<Option value={option.name} key={i}>
								<p className='capitalize'>{option.name}</p>
							</Option>
						))}
					</Select>
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Address Information</div>
				<Form.Item label='Billing Address' name='billing_address'>
					<Input />
				</Form.Item>
				<Form.Item label='Billing City' name='billing_city'>
					<Input />
				</Form.Item>
				<Form.Item label='Billing State' name='billing_state'>
					<Input />
				</Form.Item>
				<Form.Item label='Billing Code' name='billing_code'>
					<Input />
				</Form.Item>
				<Form.Item label='Billing Country' name='billing_country'>
					<Input />
				</Form.Item>
				<div className='text-xs w-full text-bell-gray text-right pb-4'>
					<Checkbox onClick={handleCheck} /> Same as Billing Address
				</div>
				<Form.Item label='Shipping Address' name='shipping_address'>
					<Input />
				</Form.Item>
				<Form.Item label='Shipping City' name='shipping_city'>
					<Input />
				</Form.Item>
				<Form.Item label='Shipping State' name='shipping_state'>
					<Input />
				</Form.Item>
				<Form.Item label='Shipping Code' name='shipping_code'>
					<Input />
				</Form.Item>
				<Form.Item label='Shipping Country' name='shipping_country'>
					<Input />
				</Form.Item>
				<div className='py-2 italic text-bell-gray'>Descriptive Information</div>
				<Form.Item label='Description' name='description'>
					<Input.TextArea rows={4} />
				</Form.Item>

				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}

export default AccountForm
