import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Divider, Drawer, Form, Input, message, Select, Space } from 'antd'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { v4 } from 'uuid'
import _ from 'lodash'
import { formItemLayout, tailFormItemLayout } from '../../../Controllers/form'
import SelectDebounce from '../../SelectDebounce'
import {
	fetchAccountList,
	fetchContactsList,
	fetchContractsList,
	fetchEntitiesList
} from '../../../Controllers/fetchLists'
import { invoiceFormOptions } from '../../../Content/contracts'
import { AccountForm, ContactForm, PaymentTermFrom, ProductCalculationForm } from '..'
import { Files } from '../../Views'
import { getAccountById } from '../../../Services/Sales/account'
import { addInvoice } from '../../../Store/Actions/invoices'
import DrawerComponent from '../../DrawerComponent'
import {
	createInvoice,
	getInvoiceById,
	getInvoiceHistoryByContractId,
	updateInvoice
} from '../../../Services/Contracts/invoices'
import { objectDifference } from '../../../Controllers/objectDifference'
import { getContractById } from '../../../Services/Contracts/contracts'
import dateFormat from '../../../Content/dateFormat'

const { Option } = Select

const InvoiceForm = ({ edit, handleClose, data, contractData, handleValues }) => {
	const formData = data
		? {
				...data,
				entity_id: {
					key: data.entity_id,
					label: data?.entity_data?.name,
					value: data.entity_id
				},
				contract_id: {
					key: data.contract_id,
					label: data?.contract_data?.project_name,
					value: data.contract_id
				},
				company_id: {
					key: data.company_id,
					label: data.company_name,
					value: data.company_id
				},
				kind_attention: {
					key: data.kind_attention,
					label: data.kind_attention_name,
					value: data.kind_attention
				},
				due_date: data.due_date !== 0 ? dayjs.unix(data.due_date) : undefined,
				invoice_date: data.invoice_date !== 0 ? dayjs.unix(data.invoice_date) : undefined,
				bank_account: {
					key: data.bank_account?.account_name,
					label: data.bank_account?.account_name,
					value: data.bank_account?.account_name
				}
		  }
		: false
	const { token } = useSelector(state => state.userSession)
	const [form] = Form.useForm()
	const dispatch = useDispatch()
	const [visible, setVisible] = useState('')
	const [loading, setLoading] = useState(false)
	const [disableDueDate, setDisableDueDate] = useState(true)
	const [drawer, setDrawer] = useState(false)
	const [uploadedFiles, setUploadedFiles] = useState(data?.attachments || [])
	const [tableData, setTableData] = useState([])
	const [entityData, setEntityData] = useState(false)

	const selectWidth = 350
	const showDrawer = (e, type) => {
		setVisible(type)
	}
	const onClose = () => {
		setVisible('')
	}
	const dataObject = values => ({
		...values,
		entity_id: values?.entity_id?.value,
		company_id: values?.company_id?.value,
		contract_id: contractData ? contractData.data.id : values.contract_id?.value,
		due_date: values?.due_date ? dayjs(values.due_date).unix() : 0,
		invoice_date: values?.invoice_date ? dayjs(values.invoice_date).unix() : 0,
		kind_attention: values?.kind_attention?.value,
		attachments: uploadedFiles,
		payment_terms: values?.payment_terms?.value || values?.payment_terms,
		item_details: {
			...tableData,
			data:
				tableData?.data?.length &&
				tableData?.data
					?.filter(x => x?.updated)
					?.map(one => ({
						..._.omit(one, ['updated', 'cost', 'history']),
						// in case there is no quantity / percentage specified, we assume it to be 0.
						quantity: Number(one.quantity) || 0,
						percentage: Number(one.percentage) || 0,
						order_value: Number(one.order_value),
						rate: Number(one.rate),
						amount: Number(one.amount)
					}))
		},
		bank_account: values.bank_account && entityData?.bank_accounts?.filter(x => x.key === values.bank_account)[0],
		status: 'Draft'
	})
	const onAdd = async values => {
		setLoading(true)
		try {
			const { data: responseData } = await createInvoice(token, dataObject(values))
			const { id: invoiceId } = responseData.data
			const res = await getInvoiceById(token, invoiceId)
			if (handleValues) {
				handleValues(res.data.data)
			}
			dispatch(addInvoice(res.data.data))
			message.success('Invoice added successfully!')
			form.resetFields()
			setUploadedFiles([])
			setTableData({
				tax: null,
				data: [
					{
						key: v4(),
						description: '',
						percentage: '',
						quantity: '',
						type: '',
						rate: '',
						amount: '',
						updated: false
					}
				]
			})
			handleClose()
		} catch (error) {
			message.error(error.response?.data?.message || 'Something went wrong.')
		} finally {
			setLoading(false)
		}
	}

	const onEdit = async values => {
		setLoading(true)
		try {
			const updatedData = objectDifference(data, dataObject(values))
			await updateInvoice(token, { id: data.id, ...updatedData })
			const res = await getInvoiceById(token, data.id)
			if (handleValues) {
				handleValues(res.data.data)
			}
			message.success('Invoice updated successfully!')
			setLoading(false)
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	// on selecting payment terms, update due date
	const handlePaymentTerms = async (w, isNew) => {
		const { type, increment } = isNew ? w : invoiceFormOptions.paymentTerms.filter(x => x.name === w)[0]
		const invoiceDate = await form.getFieldValue('invoice_date')
		if (isNew) {
			form.setFieldsValue({
				payment_terms: {
					key: w.name,
					value: w.name,
					label: w.name
				}
			})
		}
		if (invoiceDate) {
			if (type === 'days') {
				setDisableDueDate(true)
				form.setFieldsValue({ due_date: dayjs(invoiceDate).add(increment, 'days') })
			} else if (type === 'months') {
				form.setFieldsValue({ due_date: dayjs(invoiceDate).add(increment, 'months').endOf('month') })
				setDisableDueDate(true)
			} else if (type === 'custom') {
				setDisableDueDate(false)
			}
		}
	}

	const getDrawerComponent = () => {
		switch (visible) {
			case 'Add Payment Term':
				return <PaymentTermFrom handleClose={onClose} handleValues={w => handlePaymentTerms(w, true)} />
			case 'Add Account':
				return (
					<AccountForm
						handleClose={onClose}
						handleAccountValues={one => {
							form.setFieldsValue({
								company_id: {
									label: one.account_name,
									value: one.id
								},
								address: `${one.billing_address}, ${one.billing_city}, ${one.billing_state}`,
								country: one.billing_country
							})
						}}
					/>
				)
			case 'Add Contact':
				return (
					<ContactForm
						handleClose={onClose}
						handleContactValues={(_, one) => {
							form.setFieldsValue({
								kind_attention: {
									label: `${one.first_name} ${one.last_name}`,
									value: one.id,
									key: one.id
								}
							})
						}}
					/>
				)
			default:
				return null
		}
	}
	const getDrawerWidth = () => {
		switch (visible) {
			case 'Add Employee':
				return 500
			case 'Add Payment Term':
				return 500
			default:
				return 700
		}
	}

	// on selecting account, fill account data in the form
	const handleAccountSelection = accountData => {
		const { data: account } = accountData
		form.setFieldsValue({
			address: `${account.billing_address}, ${account.billing_city}, ${account.billing_state}`,
			country: account.billing_country
		})
	}

	// on selecting contract, fill account details and product / services data in table
	const handleContractSelection = async contractInformation => {
		const { data: q } = contractInformation
		try {
			const {
				data: { data: contract }
			} = await getContractById(token, q.id)

			// if user has no write access, set relevant fields to empty
			if (contract.access_specifier !== 'Public') {
				setTableData({ tax: undefined, data: [] })
				setEntityData(false)
				form.setFieldsValue({
					company_id: undefined,
					address: undefined,
					country: undefined,
					entity_id: undefined
				})
			} else {
				// get account details by contract's account id
				if (contract?.account_id) {
					try {
						const {
							data: { data: account }
						} = await getAccountById(token, contract?.account_id)
						form.setFieldsValue({
							company_id: {
								key: account?.id,
								value: account?.id,
								label: account?.account_name
							},
							address: `${account.billing_address}, ${account.billing_city}, ${account.billing_state}`,
							country: account.billing_country
						})
					} catch (error) {
						console.log(error)
					}
				} else {
					form.setFieldsValue({
						company_id: null,
						address: null,
						country: null
					})
				}

				// if contract has entity data
				if (contract?.entity_data) {
					form.setFieldsValue({
						entity_id: {
							key: contract?.entity_data?.id,
							value: contract?.entity_data?.id,
							label: contract?.entity_data?.name
						}
					})
					setEntityData(contract?.entity_data)
				} else {
					form.setFieldsValue({
						entity_id: null
					})
					setEntityData(false)
				}

				// get invoice history by contract id
				try {
					const { data: invoiceHistory } = await getInvoiceHistoryByContractId(token, contract?.id)
					if (contract?.ps?.length > 0) {
						setTableData(prev => ({
							...prev,
							data: contract.ps.map(one => ({
								...one,
								description: one.display_name,
								history: invoiceHistory?.data[one.key],
								quantity: '',
								percentage: '',
								amount: '',
								updated: true
							}))
						}))
					} else {
						setTableData(prev => ({
							...prev,
							data: [
								{
									key: v4(),
									description: '',
									percentage: '',
									quantity: '',
									type: '',
									order_value: '',
									amount: '',
									updated: false
								}
							]
						}))
					}
				} catch (error) {
					console.log(error)
				}
			}
		} catch (e) {
			console.log(e)
		}
	}

	const handleFiles = async (currentAttachment, fileName) => {
		setUploadedFiles(prev => [...prev, currentAttachment])
		message.success(`${fileName} uploaded successfully`)
	}

	const handleRemove = async fileName => {
		try {
			setUploadedFiles(prev => prev.filter(x => x !== fileName))
			message.success(`${fileName} removed successfully`)
		} catch (error) {
			message.error(`Deleting ${fileName} failed. Try again.`)
		}
	}

	useEffect(() => {
		// handle contract selection if the invoice is opened from contract view
		if (contractData && !edit) {
			handleContractSelection(contractData)
		}
	}, [handleClose])

	// on edit form, fill table data details with invoice history
	useEffect(async () => {
		if (edit) {
			const contractId = data?.contract_data?.id
			// get invoice history by contract id
			try {
				const { data: invoiceHistory } = await getInvoiceHistoryByContractId(token, contractId)
				if (formData?.item_details?.data?.length) {
					setTableData({
						...formData?.item_details,
						data: formData?.item_details?.data?.map(one => ({
							...one,
							history: invoiceHistory.data[one.key],
							updated: true
						}))
					})
				} else {
					setTableData({
						...formData?.item_details,
						data: [
							{
								key: v4(),
								description: '',
								percentage: '',
								quantity: '',
								rate: '',
								type: '',
								amount: '',
								updated: false
							}
						]
					})
				}
			} catch (error) {
				console.log(error)
			}

			// if contract has entity data, on edit, set the data
			if (data?.entity_data) {
				setEntityData(data?.entity_data)
			}
		}
	}, [])
	return (
		<div className='space-y-20'>
			<Form
				name='control-hooks'
				form={form}
				onFinish={edit ? onEdit : onAdd}
				{...formItemLayout}
				initialValues={formData}
				scrollToFirstError
			>
				<div className='py-2 italic text-bell-gray'>Basic Information</div>
				<Form.Item label='Invoicing Entity' name='entity_id'>
					<SelectDebounce
						showSearch
						placeholder='Auto-generated from Contract Details'
						fetchOptions={e => fetchEntitiesList(e, token)}
						disabled
					/>
				</Form.Item>
				<Form.Item
					name='contract_id'
					label='Contract'
					requiredMark
					rules={
						!contractData
							? [
									{
										validator: async (_, value) => {
											if (!value) {
												return Promise.reject(new Error('Please select contract!'))
											}
											const {
												data: { data }
											} = await getContractById(token, value.value)
											if (data?.access_specifier !== 'Public') {
												return Promise.reject(new Error("You don't have write access to the selected contract!"))
											}
											return Promise.resolve()
										}
									}
							  ]
							: []
					}
					hidden={contractData}
				>
					<SelectDebounce
						showSearch
						placeholder='Search Contracts'
						fetchOptions={e => fetchContractsList(e, token)}
						onChange={(e, w) => handleContractSelection(w)}
					/>
				</Form.Item>
				<Form.Item label='Company Name'>
					<Space>
						<Form.Item name='company_id' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search For Accounts'
								fetchOptions={e => fetchAccountList(e, token)}
								style={{
									width: selectWidth
								}}
								onChange={(e, w) => handleAccountSelection(w)}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, 'Add Account')} type='link'>
							Add Account
						</Button>
					</Space>
				</Form.Item>
				<Form.Item label='Address' name='address'>
					<Input />
				</Form.Item>
				<Form.Item label='Country' name='country'>
					<Input />
				</Form.Item>
				<Form.Item label='Kind Attention'>
					<Space>
						<Form.Item name='kind_attention' noStyle>
							<SelectDebounce
								showSearch
								placeholder='Search Contacts'
								fetchOptions={e => fetchContactsList(e, token)}
								style={{
									width: selectWidth
								}}
							/>
						</Form.Item>
						<Button onClick={e => showDrawer(e, 'Add Contact')} type='link'>
							Add Contact
						</Button>
					</Space>
				</Form.Item>
				<Form.Item label='Invoice Date' name='invoice_date' initialValue={dayjs()}>
					<DatePicker format={dateFormat} />
				</Form.Item>
				<Form.Item label='Payment Terms'>
					<Space>
						<Form.Item noStyle name='payment_terms'>
							<Select
								placeholder='Select payment terms'
								style={{ width: selectWidth }}
								onChange={e => handlePaymentTerms(e)}
							>
								{invoiceFormOptions.paymentTerms.map(option => (
									<Option value={option.name}>
										<p className='capitalize'>{option.name}</p>
									</Option>
								))}
							</Select>
						</Form.Item>
						<Button onClick={e => showDrawer(e, 'Add Payment Term')} type='link'>
							Add Payment Term
						</Button>
					</Space>
				</Form.Item>
				<Form.Item label='Due Date' name='due_date'>
					<DatePicker disabled={disableDueDate} />
				</Form.Item>
				<Form.Item label='Subject' name='subject'>
					<Input />
				</Form.Item>
				<Divider />
				<Form.Item label='Item Details' name='item_details'>
					<Space>
						<DrawerComponent
							form={
								<ProductCalculationForm
									handleClose={() => setDrawer(false)}
									data={tableData}
									entityData={entityData}
									handleData={w => setTableData(w)}
								/>
							}
							visible={drawer}
							onOpen={() => setDrawer(true)}
							onClose={() => setDrawer(false)}
							buttonTitle='Item Details'
							drawerWidth={1200}
							buttonType='default'
						/>
						<Divider type='vertical' />
						<div className='text-gray-600 font-normal'>
							<span className='text-bell-blue text-2xl font-medium'>
								{tableData?.data?.filter(x => x.updated)?.length || 'No'}
							</span>{' '}
							Item(s) added.
						</div>
					</Space>
				</Form.Item>
				<Divider />
				<Form.Item label='Bank Account' name='bank_account'>
					<Select placeholder='Select Bank Account'>
						{entityData?.bank_accounts?.map(option => (
							<Option value={option?.key}>
								<div className='capitalize'>
									<div>{option?.account_name}</div>
									<div className='text-bell-gray text-xs'>
										{option?.bank_name} {option.currency && <span> ({option?.currency}) </span>}
										{option.account_number && <span> - {option?.account_number}</span>}
									</div>
								</div>
							</Option>
						))}
					</Select>
				</Form.Item>
				<Form.Item label='Notes' name='notes'>
					<Input.TextArea rows={4} />
				</Form.Item>
				<Form.Item label='Terms & Conditions' name='terms_and_conditions'>
					<Input.TextArea rows={4} />
				</Form.Item>
				<Form.Item label='Attach File to Invoice' name='attachments'>
					<Files attachments={uploadedFiles} handleFiles={handleFiles} handleRemove={handleRemove} />
				</Form.Item>
				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
			<Drawer
				title={visible}
				width={getDrawerWidth()}
				onClose={onClose}
				open={!!visible}
				bodyStyle={{ paddingBottom: 80 }}
				footer={null}
			>
				{getDrawerComponent(onClose)}
			</Drawer>
		</div>
	)
}
export default InvoiceForm
