// eslint-disable-next-line camelcase
// eslint-disable-next-line import/prefer-default-export
export const form_options = {
	salutation: ['Mr.', 'Mrs.', 'Ms.', 'Dr.', 'Prof.'],
	department: ['IT', 'Maintenance', 'Quality', 'Sales'],
	rating: ['Hot', 'Warm', 'Cold'],
	ownership: ['Private', 'Public', 'Subsidiary', 'Others']
}

export const leadStages = ['New', 'Working', 'Nurturing', 'Closed']

export const leadSources = [
	'Employee Referral',
	'Trade Show',
	'Seminar',
	'External Referral',
	'Advertisement',
	'Customer Event',
	'Partner',
	'Published List',
	'Webinar',
	'LinkedIn Ads',
	'Google Ads',
	'Facebook and Instagram Ads',
	'Cold Call',
	'LinkedIn Research',
	'Facebook Research',
	'Twitter Research'
]
