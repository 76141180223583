import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { message, Space } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { DataView, InternalHeader, DrawerComponent, Stats, TableComponent } from '../../../Components'
import { resetPS } from '../../../Store/Actions/products-services'
import { ProductServicesForm } from '../../../Components/Forms'
import { getAllPS } from '../../../Services/products-services'
import { getPSStats } from '../../../Services/stats'
import PSBulk from './PSBulk'
import ModalView from '../../../Components/ModalView'
import { MINIO } from '../../../config'
import MINIOLink from '../../../Controllers/minioLink'

const PS = () => {
	const productsServicesData = useSelector(state => state.productsServicesData)
	const { token, role } = useSelector(state => state.userSession)

	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		sortOrder: '',
		sortField: '',
		total: ''
	})
	const [addDrawer, setAddDrawer] = useState(false)
	const [editDrawer, setEditDrawer] = useState(false)
	const [viewDrawer, setViewDrawer] = useState(false)
	const [bulkUploadDrawer, setBulkUploadDrawer] = useState(false)
	const [search, setSearch] = useState('')
	const [stats, setStats] = useState(null)
	const handleSearch = e => {
		setSearch(e.target.value)
	}

	const dispatch = useDispatch()

	// eslint-disable-next-line no-shadow
	const fetchData = async (current, pageSize, sortOrder, sortField, query) => {
		try {
			const { data } = await getAllPS(token, current, pageSize, sortOrder, sortField, query)
			if (!data.data) {
				dispatch(resetPS([]))
			} else {
				dispatch(resetPS(data.data))
			}
			setPagination({
				current: current + 1,
				pageSize,
				sortOrder,
				sortField,
				total: data.count
			})
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}

	const getStats = async () => {
		try {
			const { data } = await getPSStats(token)
			setStats(data.data)
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		}
	}
	useEffect(() => {
		getStats(token)
		// eslint-disable-next-line
	}, [productsServicesData])

	const handleChange = (page, filters, sorter) => {
		const sortOrder = {
			ascend: 'asc',
			descend: 'desc'
		}
		fetchData(page.current - 1, page.pageSize, sortOrder[sorter.order], sorter.order ? sorter.columnKey : '', search)
	}

	useEffect(() => {
		fetchData(0, pagination.pageSize, pagination.sortOrder, pagination.sortField, search)
		// eslint-disable-next-line
	}, [search])

	const columns = [
		{
			title: 'Name',
			key: 'name',
			sorter: true,
			render: (_, record) => (
				<div>
					<div>{record.name}</div>
					<div className='text-xs text-gray-500'>{record.code}</div>
				</div>
			)
		},
		{
			title: 'Description',
			key: 'description',
			sorter: true,
			dataIndex: 'description'
		},
		{
			title: 'Type',
			key: 'type',
			sorter: true,
			dataIndex: 'type'
		},
		{
			title: 'Billable Tasks',
			key: 'description',
			render: (_, record) => record.billable_tasks.length
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => {
				const drawerData = {
					name: record.name,
					type: record.type,
					image: record.image && (
						<div className='flex items-center space-x-2'>
							<img src={`${MINIO}/${record.image}`} alt={record.image} className='w-52 rounded-md' />
							<a href={`${MINIO}/${record.image}`}>
								<DownloadOutlined />
							</a>
						</div>
					),
					code: record.code,
					sku: record.sku,
					unit: record.unit,
					description: record.description,
					'Billable Tasks': (
						<TableComponent
							columns={[
								{ title: 'Task Name', dataIndex: 'name' },
								{ title: 'Description', dataIndex: 'description' }
							]}
							data={record?.billable_tasks}
							pagination={false}
						/>
					),
					attachments:
						record?.attachments?.length &&
						record.attachments.map((file, i) => (
							<div key={i}>
								<MINIOLink item={file} />
							</div>
						)),
					created_at: record.created_at,
					updated_at: record.updated_at
				}
				return (
					<Space size='middle'>
						<DrawerComponent
							visible={viewDrawer[record.id]}
							onOpen={() => setViewDrawer({ [record.id]: true })}
							onClose={() => setViewDrawer(false)}
							drawerTitle='Products & Services'
							buttonTitle='View'
							drawerWidth={900}
							buttonType='link'
							form={<ModalView data={drawerData} />}
						/>
						<DrawerComponent
							form={<ProductServicesForm edit handleClose={() => setEditDrawer(false)} data={record} />}
							visible={editDrawer[record.id]}
							onOpen={() => setEditDrawer({ [record.id]: true })}
							onClose={() => setEditDrawer(false)}
							buttonTitle='Edit'
							buttonType='link'
							drawerWidth={1000}
							drawerTitle='Product / Service'
							isHidden={role !== 'Admin'}
						/>
					</Space>
				)
			}
		}
	]

	const contactsStats = {
		title: 'Products & Services',
		total: stats?.ps,
		data: []
	}

	return (
		<div className='bg-bell-background'>
			<InternalHeader title='Sales' selected='Products & Services' />
			<div className='flex justify-between px-10 py-6'>
				<div className='uppercase font-medium text-blue-text'> Products & Services </div>
				<div className='flex space-x-4'>
					<div>
						<DrawerComponent
							visible={bulkUploadDrawer}
							onOpen={() => setBulkUploadDrawer(true)}
							onClose={() => setBulkUploadDrawer(false)}
							buttonTitle='Bulk Upload'
							buttonType='default'
							drawerWidth='500'
							form={<PSBulk handleClose={() => setBulkUploadDrawer(false)} />}
							isHidden={role !== 'Admin'}
						/>
					</div>

					<div>
						<DrawerComponent
							form={<ProductServicesForm handleClose={() => setAddDrawer(false)} />}
							visible={addDrawer}
							onOpen={() => setAddDrawer(true)}
							onClose={() => setAddDrawer(false)}
							buttonTitle='Add Product / Service'
							drawerWidth={1000}
							isHidden={role !== 'Admin'}
						/>
					</div>
				</div>
			</div>
			<div className='grid grid-flow-col px-10'>
				<div className='col-span-2'>
					<Stats data={contactsStats} nograph />
				</div>
			</div>
			<div>
				<DataView
					columns={columns}
					data={productsServicesData}
					type='products-services'
					pagination={pagination}
					handleChange={handleChange}
					handleSearch={handleSearch}
				/>
			</div>
		</div>
	)
}

export default PS
