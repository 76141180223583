/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import { Tooltip } from 'antd'

const renderShareTag = ({ shareType, isOwner, accessType }) => {
	if (shareType === 'Share Only') {
		return (
			<Tooltip
				title='You are authorized by the owner to view this entity.'
				placement='right'
				className='cursor-pointer'
			>
				Shared: View Only
			</Tooltip>
		)
	}
	if (shareType === 'Share And Edit') {
		return (
			<Tooltip
				title='You are authorized by the owner to view and update this entity.'
				placement='right'
				className='cursor-pointer'
			>
				Shared: View and Edit
			</Tooltip>
		)
	}
	if (!isOwner && accessType === 'Public') {
		return (
			<Tooltip title='You have full access to this entity.' placement='right' className='cursor-pointer'>
				Full Access
			</Tooltip>
		)
	}

	if (!isOwner) {
		return (
			<Tooltip title='You have limited access to the this entity.' placement='right' className='cursor-pointer'>
				Limited Access
			</Tooltip>
		)
	}

	if (isOwner) {
		return (
			<Tooltip title='You own this entity.' placement='right' className='cursor-pointer'>
				Ownership
			</Tooltip>
		)
	}
	return null
}

export default renderShareTag
