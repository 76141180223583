import React, { useState } from 'react'
import { Button, Form, Input, message } from 'antd'
import { useSelector } from 'react-redux'

import { formItemLayout, tailFormItemLayout } from '../../Controllers/form'
import { createMerchant, getMerchantById } from '../../Services/merchant'

const MerchantForm = ({ handleClose, handleValues, data }) => {
	const { token } = useSelector(state => state.userSession)

	//   INSIDE FORMS VISIBILITY
	const [loading, setLoading] = useState(false)
	const [form] = Form.useForm()

	const onAdd = async values => {
		setLoading(true)
		try {
			const { data } = await createMerchant(token, values)
			const res = await getMerchantById(token, data.data.id)
			if (handleValues) {
				handleValues(res.data.data)
			}
			setLoading(false)
			message.success('Merchant added successfully!')
			handleClose()
		} catch (error) {
			setLoading(false)
			message.error(error.response?.data?.message || 'Something went wrong!')
		}
	}

	// const onEdit = async values => {
	// 	setLoading(true)
	// 	try {
	// 		const updatedData = objectDifference(data, dataObject)
	// 		await updateAccount(token, { id: data.id, ...updatedData })
	// 		const res = await getAccountById(token, data.id)
	// 		dispatch(refreshComponent())
	// 		message.success('Account Information updated successfully!')
	// 		setLoading(false)
	// 		handleClose()
	// 	} catch (error) {
	// 		setLoading(false)
	// 		message.error(error?.response?.data?.message || 'Something went wrong')
	// 	}
	// }

	return (
		<div className='space-y-20'>
			{/* REGISTER FORM */}
			<Form
				name='control-hooks'
				form={form}
				// onFinish={edit ? onEdit : onAdd}
				onFinish={onAdd}
				{...formItemLayout}
				initialValues={data || null}
			>
				<Form.Item
					label='Merchant Name'
					name='name'
					rules={[
						{
							required: true,
							message: 'Please input merchant name!'
						}
					]}
				>
					<Input />
				</Form.Item>
				<Form.Item {...tailFormItemLayout}>
					<Button type='primary' htmlType='submit' loading={loading}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</div>
	)
}

export default MerchantForm
