import { Select, Spin } from 'antd'
import debounce from 'lodash/debounce'
import React, { useEffect, useState } from 'react'

const SelectDebounce = ({ fetchOptions, debounceTimeout = 800, defaultValue, currentSearchValue, ...props }) => {
	const [fetching, setFetching] = useState(false)
	const [options, setOptions] = useState([])

	const [searchValue, setSearchValue] = useState('')

	useEffect(() => {
		setSearchValue(currentSearchValue)
		//  eslint-disable-next-line
	}, [currentSearchValue])

	useEffect(() => {
		if (props.resetOptions) {
			setOptions([])
		}
	}, [props?.resetOptions])

	const fetchRef = React.useRef(0)
	const debounceFetcher = React.useMemo(() => {
		const loadOptions = value => {
			fetchRef.current += 1
			const fetchId = fetchRef.current

			setOptions([])
			setFetching(true)
			fetchOptions(value).then(newOptions => {
				if (fetchId !== fetchRef.current) {
					// for fetch callback order
					return
				}
				setOptions(newOptions)
				setFetching(false)
			})
		}

		return debounce(loadOptions, debounceTimeout)
	}, [fetchOptions, debounceTimeout])
	return (
		<Select
			labelInValue
			searchValue={searchValue}
			mode={props?.isMultiple ? 'multiple' : false}
			filterOption={false}
			onSearch={e => {
				debounceFetcher(e)
				setSearchValue(e)
			}}
			onFocus={() => {
				if (!options.length) {
					debounceFetcher('')
					setSearchValue('')
				}
			}}
			notFoundContent={fetching ? <Spin size='small' /> : null}
			{...props}
			options={options}
			defaultValue={defaultValue}
			clearIcon
		/>
	)
} // Usage of DebounceSelect

export default SelectDebounce
