/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */
import { ADD_BG, EDIT_BG, RESET_BG } from '../types'

const bankGuarantees = []

export const bankGuaranteesData = (state = bankGuarantees, { type, payload }) => {
	switch (type) {
		case ADD_BG:
			return [payload, ...state]
		case EDIT_BG:
			return state.map(x => {
				if (x.id === payload.id) {
					return payload.data
				}
				return x
			})
		case RESET_BG:
			return payload

		default:
			return state
	}
}
