export const LOGGED_IN = 'LOGGED_IN'
export const LOGGED_OUT = 'LOGGED_OUT'

export const SET_PROFILE = 'SET_PROFILE'
export const SET_PREFERENCE = 'SET_PREFERENCE'

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE'
export const EDIT_EMPLOYEE = 'EDIT_EMPLOYEE'
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE'
export const RESET_EMPLOYEE = 'RESET_EMPLOYEE'

export const ADD_ROLE = 'ADD_ROLE'
export const EDIT_ROLE = 'EDIT_ROLE'
export const DELETE_ROLE = 'DELETE_ROLE'
export const RESET_ROLE = 'RESET_ROLE'

export const ADD_LEAD = 'ADD_LEAD'
export const EDIT_LEAD = 'EDIT_LEAD'
export const DELETE_LEAD = 'DELETE_LEAD'
export const RESET_LEAD = 'RESET_LEAD'
export const UPDATE_STAGE = 'UPDATE_STAGE'

export const SINGLE_LEAD = 'SINGLE_LEAD'
export const ADD_ATTACHMENT = 'ADD_ATTACHMENT'
export const UPDATE_SINGLE_LEAD = 'UPDATE_SINGLE_LEAD'
export const WORKING_LEAD = 'WORKING_LEAD'
export const NURTURING_LEAD = 'NURTURING_LEAD'
export const CLOSED_LEAD = 'CLOSED_LEAD'

// CONTACTS
export const ADD_CONTACT = 'ADD_CONTACT'
export const EDIT_CONTACT = 'EDIT_CONTACT'
export const RESET_CONTACT = 'RESET_CONTACT'
export const SINGLE_CONTACT = 'SINGLE_CONTACT'
export const UPDATE_SINGLE_CONTACT = 'UPDATE_SINGLE_CONTACT'

// CONTRACTS
export const ADD_CONTRACT = 'ADD_CONTRACT'
export const EDIT_CONTRACT = 'EDIT_CONTRACT'
export const RESET_CONTRACT = 'RESET_CONTRACT'
export const SINGLE_CONTRACT = 'SINGLE_CONTRACT'
export const UPDATE_SINGLE_CONTRACT = 'UPDATE_SINGLE_CONTRACT'
export const ADD_VARIATION = 'ADD_VARIATION'
export const RESET_VARIATION = 'RESET_VARIATION'

// PROJECTS
export const ADD_PROJECT = 'ADD_PROJECT'
export const EDIT_PROJECT = 'EDIT_PROJECT'
export const RESET_PROJECT = 'RESET_PROJECT'
export const SINGLE_PROJECT = 'SINGLE_PROJECT'
export const UPDATE_SINGLE_PROJECT = 'UPDATE_SINGLE_PROJECT'

// INVOICES
export const ADD_INVOICE = 'ADD_INVOICE'
export const EDIT_INVOICE = 'EDIT_INVOICE'
export const RESET_INVOICE = 'RESET_INVOICE'
export const SINGLE_INVOICE = 'SINGLE_INVOICE'
export const UPDATE_SINGLE_INVOICE = 'UPDATE_SINGLE_INVOICE'

// BANK GUARANTEES
export const ADD_BG = 'ADD_BG'
export const EDIT_BG = 'EDIT_BG'
export const RESET_BG = 'RESET_BG'

// ENQUIRIES
export const ADD_ENQUIRY = 'ADD_ENQUIRY'
export const EDIT_ENQUIRY = 'EDIT_ENQUIRY'
export const DELETE_ENQUIRY = 'DELETE_ENQUIRY'
export const RESET_ENQUIRY = 'RESET_ENQUIRY'
export const ADD_ENQUIRY_SOURCE = 'ADD_ENQUIRY_SOURCE'
export const SINGLE_ENQUIRY = 'SINGLE_ENQUIRY'
export const UPDATE_SINGLE_ENQUIRY = 'UPDATE_SINGLE_ENQUIRY'

export const SCOPE_AND_EVALUATION = 'SCOPE_AND_EVALUATION'
export const ENQUIRY_COSTING = 'ENQUIRY_COSTING'
export const ENQUIRY_SUBMISSION = 'ENQUIRY_SUBMISSION'
export const UPDATE_ENQUIRY_STAGE = 'UPDATE_ENQUIRY_STAGE'
export const ADD_ENQUIRY_ATTACHMENT = 'ADD_ENQUIRY_ATTACHMENT'
export const RESET_ENQUIRY_ATTACHMENT = 'RESET_ENQUIRY_ATTACHMENT'
export const ADD_REVISION = 'ADD_REVISION'
export const RESET_REVISION = 'RESET_REVISION'

export const ADD_NOTE = 'ADD_NOTE'
export const DELETE_NOTE = 'DELETE_NOTE'
export const RESET_NOTE = 'RESET_NOTE'

export const ADD_TASK = 'ADD_TASK'
export const EDIT_TASK = 'EDIT_TASK'
export const DELETE_TASK = 'DELETE_TASK'
export const RESET_TASK = 'RESET_TASK'

export const ADD_ACTIVITY = 'ADD_ACTIVITY'
export const RESET_ACTIVITY = 'RESET_ACTIVITY'
export const DELETE_ACTIVITY = 'DELETE_ACTIVITY'

export const ADD_ACCOUNT = 'ADD_ACCOUNT'
export const EDIT_ACCOUNT = 'EDIT_ACCOUNT'
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT'
export const RESET_ACCOUNT = 'RESET_ACCOUNT'
export const SINGLE_ACCOUNT = 'SINGLE_ACCOUNT'
export const UPDATE_SINGLE_ACCOUNT = 'UPDATE_SINGLE_ACCOUNT'

export const ADD_OPPORTUNITY = 'ADD_OPPORTUNITY'
export const EDIT_OPPORTUNITY = 'EDIT_OPPORTUNITY'
export const DELETE_OPPORTUNITY = 'DELETE_OPPORTUNITY'
export const RESET_OPPORTUNITY = 'RESET_OPPORTUNITY'
export const SINGLE_OPPORTUNITY = 'SINGLE_OPPORTUNITY'
export const UPDATE_SINGLE_OPPORTUNITY = 'UPDATE_SINGLE_OPPORTUNITY'

export const ADD_PS = 'ADD_PS'
export const EDIT_PS = 'EDIT_PS'
export const RESET_PS = 'RESET_PS'
export const BULK_ADD_PS = 'BULK_ADD_PS'

export const ADD_TIME = 'ADD_TIME'
export const EDIT_TIME = 'EDIT_TIME'
export const DELETE_TIME = 'DELETE_TIME'
export const RESET_TIME = 'RESET_TIME'
export const CURRENT_TIME = 'CURRENT_TIME'

export const REFRESH_COMPONENT = 'REFRESH_COMPONENT'

export const ADD_REPORT_USER = 'ADD_REPORT_USER'
export const ADD_REPORT_FILTER = 'ADD_REPORT_FILTER'
export const ADD_REPORT_COUNTRY = 'ADD_REPORT_COUNTRY'
export const ADD_ENQUIRY_REPORT_DATA = 'ADD_ENQUIRY_REPORT_DATA'
export const ADD_ACTIVE_AND_LOST_REPORT = 'ADD_ACTIVE_AND_LOST_REPORT'
export const ADD_CLIENT_ENGAGEMENT_REPORT = 'ADD_CLIENT_ENGAGEMENT_REPORT'
export const ADD_TIMESHEET_REPORT = 'ADD_TIMESHEET_REPORT'

export const ADD_EXPENSE = 'ADD_EXPENSE'
export const EDIT_EXPENSE = 'EDIT_EXPENSE'
export const DELETE_EXPENSE = 'DELETE_EXPENSE'
export const RESET_EXPENSE = 'RESET_EXPENSE'
