import axios from 'axios'
import { URL } from '../../config'

export const updateWebInquiryById = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'put',
				url: `${URL}/inquiry/edit`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getAllWebInquiries = (token, offset = 0, limit = 10, tag, replied, converted) => {
	const url = `${URL}/inquiry/all?offset=${offset}&limit=${limit}${tag !== undefined ? `&tag=${tag}` : ''}${
		replied !== undefined ? `&replied=${replied === 'Replied'}` : ''
	}${converted !== undefined ? `&converted=${converted === 'Converted'}` : ''}`

	// eslint-disable-next-line no-console
	console.log('requested url:', url)
	return new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
}

export const getWebInquiryById = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/inquiry/get/${id}`,

				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const deleteWebInquiryById = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/inquiry/delete/${id}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const getPaperAccessList = (token, offset = 0, limit = 10) => {
	const url = `${URL}/paper/all?offset=${offset}&limit=${limit}`
	return new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
}

export const deletePaperAccessById = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/paper/delete/${id}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

// this is the list of those who have filled the form on bell immersive website to get the product brief of alert sim
export const getAlertSimDownloadList = (token, offset = 0, limit = 10) => {
	const url = `${URL}/sim/all?offset=${offset}&limit=${limit}`
	return new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
}

export const getDICECONRegistrationList = (token, offset = 0, limit = 10) => {
	const url = `${URL}/dicecon/all?offset=${offset}&limit=${limit}`
	return new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url,
				headers: { Authorization: `Bearer ${token}` }
			})

			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
}

export const deleteDICECONRegistrationById = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/dicecon/delete/${id}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const deleteAlertSimDownloadById = (token, id) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'get',
				url: `${URL}/sim/delete/${id}`,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
