import axios from 'axios'
import { URL } from '../config'

export const uploadFile = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'post',
				url: `${URL}/assets/upload?file`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})

export const removeAsset = (token, data) =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await axios({
				method: 'delete',
				url: `${URL}/assets/delete`,
				data,
				headers: { Authorization: `Bearer ${token}` }
			})
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
