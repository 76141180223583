import { message, Table, Tag } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import * as Colors from '../../../styles/colors'
import dateFormat from '../../../Content/dateFormat'
import { timeCategory } from '../../../Content/timetracker'
import { timeDuration } from '../../../Controllers/timeDuration'
import { getTimesheetByAssociationID } from '../../../Services/timesheet'

const TimesheetView = ({ id }) => {
	const { token } = useSelector(state => state.userSession)
	const refreshComponent = useSelector(state => state.refreshComponent)

	const [loading, setLoading] = useState(true)
	const [data, setData] = useState([])

	const fetchData = async () => {
		try {
			const { data } = await getTimesheetByAssociationID(token, id)
			setData(data?.data)
			setLoading(false)
		} catch (error) {
			setData([])
			setLoading(false)
			message.error(error?.response?.data?.message || 'Something went wrong!')
		}
	}

	const columns = [
		{
			title: 'Description',
			key: 'description',
			dataIndex: 'description'
		},
		{
			title: 'Date',
			dataIndex: 'date',
			key: 'date',
			render: (_, rec) =>
				rec.start_time && !timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange ? (
					dayjs.unix(rec.start_time).format(dateFormat)
				) : (
					<Tag />
				)
		},
		{
			title: 'Start',
			dataIndex: 'start_time',
			key: 'start_time',
			render: (_, rec) =>
				rec.start_time
					? dayjs
							.unix(rec.start_time)
							.format(timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange ? dateFormat : 'hh:mm A')
					: null
		},
		{
			title: 'End',
			key: 'end_time',
			dataIndex: 'end_time',
			render: (_, rec) =>
				rec.end_time ? (
					dayjs
						.unix(rec.end_time)
						.format(timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange ? dateFormat : 'hh:mm A')
				) : (
					<Tag color={Colors.GREEN}> In Progress </Tag>
				)
		},
		{
			title: 'Duration',
			key: 'duration',
			dataIndex: 'duration',
			render: (_, rec) => {
				const duration = rec.end_time
					? moment.duration(dayjs.unix(rec.end_time).diff(dayjs.unix(rec.start_time)))
					: false

				if (duration) {
					if (timeCategory.filter(a => a.name === rec.category)[0]?.showDateRange) {
						return timeDuration(duration, 'days')
					}
					return timeDuration(duration, 'table')
				}
				return null
			}
		}
	]

	// FETCH DATA ON PAGE LOAD
	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, [refreshComponent])
	return <Table columns={columns} dataSource={data} loading={loading} />
}

export default TimesheetView
