import { useSelector } from 'react-redux'
import { drawerMenu } from '../Content/drawerMenu'

const renderFilteredMenu = () => {
	const { modules } = useSelector(state => state.userProfile)
	const { role } = useSelector(state => state.userSession)
	return role !== 'Admin' ? drawerMenu.filter(one => (modules || []).indexOf(one.title) !== -1) : drawerMenu
}

export default renderFilteredMenu
