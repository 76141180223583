import React from 'react'
import Lottie from 'react-lottie'
import animationData from '../Content/maintenance.json'

const Maintenance = ({ title, description }) => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice'
		}
	}
	return (
		<div>
			<Lottie options={defaultOptions} height={300} width={500} />
			<div className='text-center'>
				<div className='text-3xl text-bell-text font-bold'>
					{title || 'Paradigm is currently down for maintenance.'}
				</div>
				<div className='text-bell-gray'>
					{description || 'We expect to be back in a couple of hours. Thanks for your patience.'}
				</div>
			</div>
		</div>
	)
}
export default Maintenance
