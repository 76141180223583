export const reportsList = [
	{
		report_name: 'Individual Sales Activity Report',
		type: 'sales-report',
		description: 'Stats of the latest work in Sales',
		image:
			'https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1630&q=80',
		accessibleModules: ['Sales'],
		accessibleRoles: ['Employee', 'Manager']
	},
	{
		report_name: 'Leads & Accounts',
		type: 'leads-and-accounts',
		description: 'List of Leads & Accounts in a country',
		accessibleModules: ['Sales'],
		accessibleRoles: ['Employee', 'Manager'],
		image:
			'https://images.unsplash.com/photo-1524758870432-af57e54afa26?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80'
	},
	{
		report_name: 'Enquiries By Country & Account',
		type: 'enquiries-by-country-and-account',
		description: 'Details of enquiries for accounts in a country',
		accessibleModules: ['Sales'],
		accessibleRoles: ['Employee', 'Manager'],
		image:
			'https://images.unsplash.com/photo-1478860409698-8707f313ee8b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80'
	},
	{
		report_name: 'Enquiries Status Report',
		type: 'enquiries-status-report',
		description: 'Active, Won, Lost, and Cancelled Enquiries',
		accessibleModules: ['Sales'],
		accessibleRoles: ['Manager'],
		image:
			'https://images.unsplash.com/photo-1517245386807-bb43f82c33c4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80'
	},
	{
		report_name: 'Client Engagement Report',
		type: 'client-engagement-report',
		description: 'Details of calls and email filtered by time',
		accessibleModules: ['Sales'],
		accessibleRoles: ['Manager'],
		image:
			'https://images.unsplash.com/photo-1534073828943-f801091bb18c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=774&q=80'
	},
	{
		report_name: 'Timesheet Report',
		new: false,
		type: 'timesheet-report',
		description: 'Explore Timesheet Details Per User',
		accessibleModules: ['Sales', 'Projects', 'Timesheets'],
		accessibleRoles: ['Manager'],
		image:
			'https://images.unsplash.com/photo-1508962914676-134849a727f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80'
	},
	{
		report_name: 'Project Utilization Report ',
		new: false,
		type: 'project-utilization-report',
		description: "Details of users' time in a project",
		accessibleModules: ['Sales', 'Projects', 'Timesheets'],
		accessibleRoles: ['Manager'],
		image:
			'https://images.unsplash.com/photo-1542744173-05336fcc7ad4?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1604&q=80'
	},
	{
		report_name: 'Project Duration Report',
		new: true,
		type: 'project-duration-report',
		description: 'Total time in a project, deliverable, and activity',
		accessibleModules: ['Sales', 'Projects', 'Timesheets'],
		accessibleRoles: ['Manager'],
		image:
			'https://images.unsplash.com/photo-1620325867502-221cfb5faa5f?q=80&w=3257&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
	}
]

export const reportsTableColumns = [
	{
		title: 'Report Name',
		key: 'report_name',
		sorter: true,
		dataIndex: 'report_name'
	}
]
