/* eslint-disable camelcase */
import * as Colors from '../styles/colors'

export const steps = ['Register Proposal', 'Scope & Evaluation', 'Costing', 'Submission']

export const form_options = {
	communication_type: ['Fax', 'Telephone', 'Email'],
	department: [
		{
			id: 1,
			name: 'IT'
		},
		{
			id: 2,
			name: 'Maintenance'
		},
		{
			id: 3,
			name: 'Quality'
		},
		{
			id: 4,
			name: 'Sales'
		}
	],

	ownership: [
		{
			id: 1,
			name: 'Private'
		},
		{
			id: 2,
			name: 'Public'
		},
		{
			id: 3,
			name: 'Subsidiary'
		},
		{
			id: 4,
			name: 'Others'
		}
	],
	number_of_employees: [
		{
			id: 1,
			name: '<10'
		},
		{
			id: 2,
			name: '11 - 50'
		},
		{
			id: 3,
			name: '51 - 150'
		},
		{
			id: 4,
			name: '151 - 500'
		},
		{
			id: 5,
			name: '501 - 1000'
		},
		{
			id: 6,
			name: '> 1000'
		}
	],
	risk_factors: ['Geopolitical', 'Timely Payment', 'Reputation']
}

export const reminder = {
	frequency: ['Daily', 'Weekly', 'Monthly', 'Yearly'],
	repeats: ['Every Day', 'Every Other Day', 'Custom'],
	custom_repeat: () => {
		const arr = []
		let i = 0
		while (i < 30) {
			i += 1
			arr.push(i)
		}
		return arr
	}
}

export const proposalType = [
	'Techno-Commercial Proposal',
	'Commercial Proposal',
	'Technical Proposal',
	'Technical / Commercial Clarifications'
]

export const enquiry_stage_probability = [
	{
		name: 'Expression of Interest',
		percentage: 15
	},
	{
		name: 'Budgetary',
		percentage: 10
	},
	{
		name: 'Technical Only',
		percentage: 20
	},
	{
		name: 'Technical & Commercial',
		percentage: 50
	},
	{
		name: 'Technical Evaluation',
		percentage: 50
	},
	{
		name: 'Negotiation / Review',
		percentage: 50
	},
	{
		name: 'Closed (Won)',
		percentage: 100
	},
	{
		name: 'Closed (Lost)',
		percentage: 0
	},
	{
		name: 'Cancelled',
		percentage: 0
	}
]

export const ENQUIRIES_ENUM = {
	REGISTRATION: 'Registration',
	SCOPE_AND_EVALUATION: 'Scope and Evaluation',
	COSTING: 'Costing',
	SUBMISSION: 'Submission'
}

export const enquiryStages = [
	ENQUIRIES_ENUM.REGISTRATION,
	ENQUIRIES_ENUM.SCOPE_AND_EVALUATION,
	ENQUIRIES_ENUM.COSTING,
	ENQUIRIES_ENUM.SUBMISSION
]

export const revisionStageColor = {
	[ENQUIRIES_ENUM.REGISTRATION]: Colors.LIGHT_RED,
	[ENQUIRIES_ENUM.SCOPE_AND_EVALUATION]: Colors.LIGHT_YELLOW,
	[ENQUIRIES_ENUM.COSTING]: Colors.LIGHT_BLUE,
	[ENQUIRIES_ENUM.SUBMISSION]: Colors.LIGHT_GREEN
}

export const kanbanColumnColor = {
	'Expression of Interest': Colors.LIGHT_GREEN,
	Budgetary: Colors.LIGHT_BLUE,
	'Technical Only': Colors.LIGHT_GREEN,
	'Technical & Commercial': Colors.LIGHT_BLUE,
	'Technical Evaluation': Colors.LIGHT_YELLOW,
	'Negotiation / Review': Colors.LIGHT_BLUE,
	'Closed (Won)': Colors.LIGHT_GREEN,
	'Closed (Lost)': Colors.LIGHT_RED,
	Cancelled: Colors.LIGHT_RED
}
