/* eslint-disable default-param-last */
import { ADD_EXPENSE, DELETE_EXPENSE, EDIT_EXPENSE, RESET_EXPENSE } from '../types'

const expenses = []

// eslint-disable-next-line import/prefer-default-export
export const expensesData = (state = expenses, { type, payload }) => {
	switch (type) {
		case ADD_EXPENSE:
			return [payload, ...state]
		case EDIT_EXPENSE:
			return state.map(x => {
				if (x.id === payload.id) {
					return payload.data
				}
				return x
			})
		case RESET_EXPENSE:
			return payload
		case DELETE_EXPENSE:
			return state.filter(expense => expense.id !== payload)

		default:
			return state
	}
}
