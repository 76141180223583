/* eslint-disable import/prefer-default-export */
import * as Colors from '../styles/colors'

export const taskOptions = {
	task_type: [
		'Proposal',
		'Email',
		'Meeting',
		'Call',
		'Letter',
		'Demo',
		'Risk Control & Mitigation',
		'Payments',
		'Administrative',
		'Prequalification',
		'Others'
	],
	priority: ['High', 'Medium', 'Low'],
	status: ['Not Started', 'In Progress', 'Completed'],
	association: {
		Sales: ['Accounts', 'Contacts', 'Leads', 'Enquiries', 'Opportunities'],
		Contracts: ['Contracts'],
		Projects: ['Projects']
	}
}

export const taskPriorityColor = {
	High: Colors.RED,
	Medium: Colors.YELLOW,
	Low: Colors.GREEN
}
export const taskStatusColor = {
	'Not Started': Colors.RED,
	'In Progress': Colors.YELLOW,
	Completed: Colors.GREEN
}
