import {
	ADD_ENQUIRY,
	ADD_ENQUIRY_ATTACHMENT,
	ADD_ENQUIRY_SOURCE,
	ADD_REVISION,
	DELETE_ENQUIRY,
	EDIT_ENQUIRY,
	ENQUIRY_COSTING,
	ENQUIRY_SUBMISSION,
	RESET_ENQUIRY,
	RESET_ENQUIRY_ATTACHMENT,
	RESET_REVISION,
	SCOPE_AND_EVALUATION,
	SINGLE_ENQUIRY,
	UPDATE_SINGLE_ENQUIRY
} from '../types'

const enquiriesState = []
const singleEnquiryState = []
const enquirySourceState = [
	'Email',
	'Employee Referral',
	'Trade Show',
	'Seminar',
	'Website',
	'External Referral',
	'Advertisement',
	'Customer Event',
	'Partner',
	'Published List',
	'Webinar',
	'LinkedIn Ads',
	'Google Ads',
	'Facebook and Instagram Ads',
	'Cold Call',
	'LinkedIn Research',
	'Facebook Research',
	'Twitter Research'
]

const enquiryStage = [
	'Expression of Interest',
	'Budgetary',
	'Technical Only',
	'Technical & Commercial',
	'Technical Evaluation',
	'Negotiation / Review',
	'Closed (Won)',
	'Closed (Lost)',
	'Cancelled'
]
const revisionsState = []
export const enquiriesData = (state = enquiriesState, { type, payload }) => {
	switch (type) {
		case ADD_ENQUIRY:
			return [payload, ...state]
		case EDIT_ENQUIRY:
			return state.map(x => {
				if (x.id === payload.id) {
					return payload.data
				}
				return x
			})
		case RESET_ENQUIRY:
			return payload
		case DELETE_ENQUIRY:
			return state.filter(enquiry => enquiry.id !== payload)
		default:
			return state
	}
}

export const revisionsData = (state = revisionsState, { type, payload }) => {
	switch (type) {
		case ADD_REVISION:
			return [payload, ...state]

		case RESET_REVISION:
			return payload
		default:
			return state
	}
}

export const singleEnquiriesData = (state = singleEnquiryState, { type, payload }) => {
	switch (type) {
		case SINGLE_ENQUIRY:
			return payload
		case UPDATE_SINGLE_ENQUIRY:
			return { ...state, ...payload }
		case SCOPE_AND_EVALUATION:
			return {
				...state,
				current_revision_data: { ...state.current_revision_data, scope_and_evaluation: payload }
			}
		case ENQUIRY_COSTING:
			return {
				...state,
				current_revision_data: { ...state.current_revision_data, costing: payload }
			}
		case ENQUIRY_SUBMISSION:
			return {
				...state,
				current_revision_data: { ...state.current_revision_data, submission: payload }
			}
		default:
			return state
	}
}
const attachments = {
	commonAttachments: [],
	revisionAttachments: []
}

export const attachmentsData = (state = attachments, { type, payload }) => {
	switch (type) {
		case ADD_ENQUIRY_ATTACHMENT:
			return {
				...state,
				commonAttachments: state.commonAttachments ? [...state.commonAttachments, payload] : [payload]
			}
		case RESET_ENQUIRY_ATTACHMENT:
			return payload
		default:
			return state
	}
}

export const enquirySourceData = (state = enquirySourceState, { type, payload }) => {
	switch (type) {
		case ADD_ENQUIRY_SOURCE:
			return [payload, ...state]
		default:
			return state
	}
}

export const enquiryStageData = (state = enquiryStage, { type }) => {
	switch (type) {
		default:
			return state
	}
}
