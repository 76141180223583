import React from 'react'
import { Redirect } from 'react-router-dom'
// import { InternalHeader } from '../../Components'

const Sales = () => (
	// <div>
	//   <InternalHeader title="Sales" selected="Home" />
	//   This is a sales page
	// </div>
	<Redirect to='/sales/leads' />
)

export default Sales
