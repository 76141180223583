import { SET_PREFERENCE, SET_PROFILE } from '../types'

export const setProfile = payload => ({
	type: SET_PROFILE,
	payload
})

export const setPreferences = payload => ({
	type: SET_PREFERENCE,
	payload
})
