import React, { useEffect, useState } from 'react'
import { message, Space, Table, Tag } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

// COMPONENTS
import { DrawerComponent } from '../..'
import dateFormat from '../../../Content/dateFormat'
import InvoiceForm from '../../Forms/Contracts/InvoiceForm'
import InvoiceExport from '../../Internal/InvoiceExport'
import { getInvoiceByContractId } from '../../../Services/Contracts/invoices'
import accounting from '../../../Controllers/accountingNumbers'
import { renderStatus } from '../../../Content/renderTags'
import { refreshComponent } from '../../../Store/Actions/userSession'

const InvoicesView = ({ contractData, accessType }) => {
	const { token } = useSelector(state => state.userSession)
	const { id, role } = useSelector(state => state.userProfile)
	const dispatch = useDispatch()

	const [addDrawer, setAddDrawer] = useState(false)
	const [editDrawer, setEditDrawer] = useState(false)
	const [invoiceDrawer, setInvoiceDrawer] = useState(false)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true)

	const fetchData = async () => {
		setLoading(true)
		try {
			const { data: invoiceData } = await getInvoiceByContractId(token, contractData.id)
			if (invoiceData.data) {
				setData(invoiceData.data)
			}
		} catch (error) {
			message.error(error?.response?.data?.message || 'Something went wrong')
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		fetchData()
		// eslint-disable-next-line
	}, [])

	const columns = [
		{
			title: 'Invoice Number',
			key: 'invoice_number',
			dataIndex: 'invoice_number'
		},
		{
			title: 'Due Date',
			key: 'due_date',
			render: (_, record) => record.due_date !== 0 && dayjs.unix(record.due_date).format(dateFormat)
		},
		{
			title: 'Order Value',
			key: 'order_value',
			render: (_, record) =>
				`USD ${
					record?.item_details?.data?.length &&
					accounting(
						record?.item_details?.data
							?.map(one => (one?.amount || 0) + ((one?.amount || 0) * (record?.item_details?.tax?.tax || 0)) / 100)
							.reduce((a, b) => a + b, 0)
					)
				}`
		},
		{
			title: 'Status',
			key: 'status',
			render: (_, record) => (
				<Tag color={renderStatus(record, id, role)[record?.status]?.color}>
					{renderStatus(record, id, role)[record?.status]?.title || record.status}
				</Tag>
			)
		},
		{
			title: 'Actions',
			key: 'actions',
			render: (text, record) => (
				<Space size='middle'>
					{/* eslint-disable-next-line */}
					<DrawerComponent
						form={
							<InvoiceExport
								invoiceId={record.id}
								handleValues={w => {
									setData(prev =>
										prev.map(x => {
											if (x.id === w.id) {
												return w
											}
											return x
										})
									)
									dispatch(refreshComponent())
								}}
								closeDrawer={() => {
									setData(prev => prev.filter(x => x.id !== record.id))
									setInvoiceDrawer(false)
								}}
							/>
						}
						visible={invoiceDrawer[record.id]}
						onOpen={() => setInvoiceDrawer({ [record.id]: true })}
						onClose={() => setInvoiceDrawer(false)}
						buttonTitle='View'
						buttonType='link'
						drawerWidth={1100}
					/>
					{!record.submitted && (
						<DrawerComponent
							form={
								<InvoiceForm
									edit
									handleClose={() => setEditDrawer(false)}
									data={record}
									contractData={{
										data: {
											id: contractData.id,
											name: contractData.project_name,
											account_id: contractData.account_id,
											ps: contractData.ps,
											entity_data: contractData?.entity_data
										}
									}}
									handleValues={w => {
										setData(prev =>
											prev.map(x => {
												if (x.id === w.id) {
													return w
												}
												return x
											})
										)
										dispatch(refreshComponent())
									}}
								/>
							}
							visible={editDrawer[record.id]}
							onOpen={() => setEditDrawer({ [record.id]: true })}
							onClose={() => setEditDrawer(false)}
							buttonTitle='Edit'
							buttonType='link'
							drawerWidth={700}
							isHidden={accessType !== 'Public'}
						/>
					)}
				</Space>
			)
		}
	]

	return (
		<div className='space-y-5'>
			<div className='text-right'>
				<DrawerComponent
					form={
						<InvoiceForm
							contractData={{
								data: {
									id: contractData.id,
									name: contractData.project_name,
									account_id: contractData.account_id,
									ps: contractData.ps,
									entity_data: contractData?.entity_data
								}
							}}
							handleClose={() => {
								setAddDrawer(false)
							}}
							handleValues={w => {
								setData(prev => [w, ...prev])
								dispatch(refreshComponent())
							}}
						/>
					}
					visible={addDrawer}
					onOpen={() => setAddDrawer(true)}
					onClose={() => setAddDrawer(false)}
					buttonTitle='Add Invoice'
					isHidden={accessType !== 'Public'}
				/>
			</div>
			<Table columns={columns} dataSource={data} pagination={false} loading={loading} />
		</div>
	)
}

export default InvoicesView
