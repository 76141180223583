import { Button, Result, Table, Tag } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { InternalHeader } from '../../Components'
import { reportsList, reportsTableColumns } from '../../Content/reports'
import { addClientEngagementReport, addTimesheetReport } from '../../Store/Actions/reports'
import * as Colors from '../../styles/colors'

const Reports = () => {
	const history = useHistory()
	const dispatch = useDispatch()
	const { clientEngagementReport } = useSelector(state => state.reportsData)
	const { timesheetReport } = useSelector(state => state.reportsData)
	const { modules, role } = useSelector(state => state.userProfile)

	// initial state setup
	useEffect(() => {
		if (!clientEngagementReport?.duration) {
			dispatch(addClientEngagementReport({ duration: [604800] }))
		}

		if (!timesheetReport) {
			dispatch(addTimesheetReport({ user: { name: '', id: '' } }))
		}
	}, [])

	const filteredReportsList =
		role === 'Admin'
			? reportsList
			: reportsList
					// filter reports array by role
					.filter(a => a.accessibleRoles.indexOf(role) !== -1)
					// filter reports array if any of the modules in user's profile are in the accessibleModules array
					.filter(b => b.accessibleModules.filter(mod => (modules || []).indexOf(mod) !== -1).length > 0)

	return filteredReportsList?.length > 0 ? (
		<div className='bg-bell-background'>
			<InternalHeader title='Reports' selected='All Reports' />
			<section className='px-10 py-4'>
				<div className='text-lg py-2 text-bell-text font-medium'> Recent Reports</div>
				<div className='flex py-2 gap-6 flex-wrap'>
					{filteredReportsList?.map((report, i) => (
						<Link to={`../reports/${report.type}`} key={i}>
							<div className='cursor-pointer bg-white p-4 rounded-md shadow-md hover:bg-gray-50 w-72 h-64'>
								<img src={report.image} alt={report.report_name} className='rounded-md object-cover h-44 w-full' />
								<div className='pt-2 font-medium text-base text-gray-900'>
									{report.report_name}
									{report.new && (
										<span className='mx-2 font-light'>
											<Tag color={Colors.LIGHT_BLUE}> New</Tag>
										</span>
									)}
								</div>

								<div className='text-gray-600 text-xs pt-1'>{report.description}</div>
							</div>
						</Link>
					))}
				</div>
			</section>
			<section className='px-10'>
				<div className='text-lg py-2 text-bell-text font-medium'> All Reports</div>
				<div>
					<Table
						columns={reportsTableColumns}
						dataSource={filteredReportsList}
						onRow={record => ({
							onClick: () => {
								history.push(`./reports/${record.type}`)
							}
						})}
						pagination={false}
					/>
				</div>
			</section>
		</div>
	) : (
		<Result
			status='403'
			title='No reports available!'
			subTitle='There are currently no reports that are accessible to you, because of your role and access rights. The reports relevant to you will be shown here once developed.'
			extra={
				<Link to='/'>
					<Button type='primary'>Back Home</Button>
				</Link>
			}
		/>
	)
}

export default Reports
