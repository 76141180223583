import React, { useState } from 'react'
import { AiTwotoneEdit } from 'react-icons/ai'
import _ from 'lodash'
import { Button, Popover, Tag, Table } from 'antd'
import { Link } from 'react-router-dom'
import { MdArrowForward } from 'react-icons/md'
import { useSelector } from 'react-redux'
import * as Colors from '../../../../styles/colors'
import ScopeAndEvaluationForm from '../../../Forms/Enquiries/ScopeAndEvalForm'
import DrawerComponent from '../../../DrawerComponent'
import accounting from '../../../../Controllers/accountingNumbers'
import { enquiryStages } from '../../../../Content/enquiries'

const ScopeAndEvaluation = ({ data, isFilled, isCurrent, currentStage, showButton }) => {
	const [form, setForm] = useState(false)
	const singleEnquiriesData = useSelector(state => state.singleEnquiriesData)
	const editable = singleEnquiriesData.current_revision_data.revision_stage !== 'Submission'

	const refactor = isFilled
		? {
				...data,
				decision_makers_procurement: data?.decision_makers_procurement_data?.length
					? data?.decision_makers_procurement_data?.map(x => <div>{x.name}</div>)
					: undefined,
				decision_makers_registration: data?.decision_makers_registration_data?.length
					? data?.decision_makers_registration_data?.map(x => <div>{x.name}</div>)
					: undefined,
				decision_makers_technical: data?.decision_makers_technical_data?.length
					? data?.decision_makers_technical_data?.map(x => <div>{x.name}</div>)
					: undefined,
				competitors: data?.competitors_data?.length ? data?.competitors_data?.map(x => <div>{x.name}</div>) : undefined,
				products_and_services: data?.products_services_data?.length
					? data?.products_services_data?.map(x => <div>{x?.name}</div>)
					: undefined,
				bank_guarantee_required: data.bank_guarantee_required ? 'Yes' : 'No',
				insurance_required: data.insurance_required ? 'Yes' : 'No',
				tender_bond_required: data.tender_bond_required ? 'Yes' : 'No',
				tender_bond_value: data?.tender_bond_value && `${data?.currency} ${accounting(data?.tender_bond_value)}`,
				bank_guarantee_value:
					data?.bank_guarantee_value && `${data?.currency} ${accounting(data?.bank_guarantee_value)}`,
				regular_enquiry: data.regular_enquiry ? 'Yes' : 'No',
				bid:
					data.bid === 'No Bid' ? (
						<div>
							<Tag color={Colors.RED}> NO BID </Tag>
							<Popover
								placement='right'
								content={
									<>
										<Table
											columns={[
												{
													title: 'Action Name',
													key: 'name',
													dataIndex: 'name'
												},
												{
													title: 'Description',
													key: 'description',
													dataIndex: 'description'
												},
												{
													title: 'Status',
													key: 'status',
													dataIndex: 'status'
												}
											]}
											dataSource={data?.no_bid_action_data}
											pagination={false}
										/>
										<div className='pt-2 text-right flex flex-row-reverse'>
											<Link to='./tasks'>
												<div className='flex items-center'>
													<Button type='link'> Go to Tasks</Button>
													<MdArrowForward size='18' color={Colors.BLUE} />
												</div>
											</Link>
										</div>
									</>
								}
							>
								<Button type='link' size='small'>
									No Bid Actions
								</Button>
							</Popover>
						</div>
					) : (
						<Tag color={Colors.GREEN}> {data?.bid?.toUpperCase()} </Tag>
					)
		  }
		: null

	const risk = isFilled
		? {
				...data?.risk_management,
				risk_factors: data?.risk_management?.risk_factors?.map(x => <div> {x} </div>)
		  }
		: null
	return (
		<div>
			<div className='flex items-center'>
				<div className='font-bold text-base text-bell-text py-2'> Scope and Evaluation </div>
				{isFilled && isCurrent && editable && showButton && (
					<DrawerComponent
						form={
							<ScopeAndEvaluationForm
								edit
								data={data}
								handleClose={() => setForm(false)}
								updateStage={enquiryStages.indexOf(currentStage) < enquiryStages.indexOf('Scope and Evaluation')}
							/>
						}
						visible={form}
						onOpen={() => setForm(true)}
						onClose={() => setForm(false)}
						buttonTitle={<AiTwotoneEdit color={Colors.BLUE} fontSize={20} />}
						buttonType='link'
						drawerTitle='Scope and Evaluation'
						drawerWidth='1000'
					/>
				)}
			</div>

			{isFilled ? (
				<div className='p-4 space-y-4'>
					{Object.keys(
						_.omit(refactor, [
							'risk_management',
							'decision_makers_procurement_data',
							'decision_makers_registration_data',
							'decision_makers_technical_data',
							'competitors_data',
							'products_services_data',
							'no_bid_actions',
							'no_bid_action_data',
							'enquiry_stage',
							'proposal_engineer'
						])
					)
						.filter(item => refactor[item])
						.map((item, i) => (
							<div className='grid grid-cols-3 lg:grid-cols-5 gap-4' key={i}>
								<div className='capitalize col-span-1 lg:col-span-1 text-bell-gray'>{item.replaceAll('_', ' ')}</div>
								<div className='col-span-2 lg:col-span-2'> {refactor[item]}</div>
							</div>
						))}
					{data.risk_management.risk_factors && (
						<div>
							<div className='font-medium py-5'> Risk Management</div>
							<div className='space-y-4 '>
								{Object.keys(risk).map((item, i) => (
									<div className='grid grid-cols-3 lg:grid-cols-5 gap-4' key={i}>
										<div className='capitalize col-span-1 lg:col-span-1 text-bell-gray'>
											{item.replaceAll('_', ' ')}
										</div>
										<div className='col-span-2 lg:col-span-2'> {refactor[item]}</div>
									</div>
								))}
							</div>
						</div>
					)}
				</div>
			) : (
				<div>
					<div className='text-bell-gray text-sm py-2'>No Information Available</div>
					{isCurrent && showButton && (
						<DrawerComponent
							form={<ScopeAndEvaluationForm handleClose={() => setForm(false)} />}
							visible={form}
							onOpen={() => setForm(true)}
							onClose={() => setForm(false)}
							buttonTitle='Add Information'
							drawerTitle='Scope and Evaluation'
							drawerWidth='1000'
						/>
					)}
				</div>
			)}
		</div>
	)
}

export default ScopeAndEvaluation
