import React from 'react'
import { Redirect } from 'react-router-dom'
// import { InternalHeader } from '../../Components'

const ContractsHome = () => (
	// <div>
	//   <InternalHeader title="Sales" selected="Home" />
	//   This is a sales page
	// </div>
	<Redirect to='/contracts/contracts' />
)

export default ContractsHome
