import {
	ADD_CONTRACT,
	ADD_VARIATION,
	EDIT_CONTRACT,
	RESET_CONTRACT,
	RESET_VARIATION,
	SINGLE_CONTRACT,
	UPDATE_SINGLE_CONTRACT
} from '../types'

export const addContract = payload => ({
	type: ADD_CONTRACT,
	payload
})

export const editContract = payload => ({
	type: EDIT_CONTRACT,
	payload
})

export const resetContract = payload => ({
	type: RESET_CONTRACT,
	payload
})

export const addSingleContract = payload => ({
	type: SINGLE_CONTRACT,
	payload
})

export const updateSingleContract = payload => ({
	type: UPDATE_SINGLE_CONTRACT,
	payload
})

export const addVariation = payload => ({
	type: ADD_VARIATION,
	payload
})
export const resetVariation = payload => ({
	type: RESET_VARIATION,
	payload
})
