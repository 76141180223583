import { Alert, Button, Divider, Form, Modal } from 'antd'
import React from 'react'
import { IoIosShareAlt } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { fetchUserList } from '../Controllers/fetchLists'
import * as Colors from '../styles/colors'
import SelectDebounce from './SelectDebounce'

const ShareModal = ({ visible, onClose, onOpen, modalTitle, isHidden, handleValues, data }) => {
	const formData = {
		share_and_edit: data?.share_and_edit_data?.length
			? data?.share_and_edit_data.map(one => ({
					label: one.name,
					value: one.id,
					key: one.id
			  }))
			: undefined,
		share_only: data?.share_only_data?.length
			? data?.share_only_data.map(one => ({
					label: one.name,
					value: one.id,
					key: one.id
			  }))
			: undefined
	}
	const { token } = useSelector(state => state.userSession)
	const [form] = Form.useForm()
	const handleOk = async () => {
		const res = await form.validateFields()
		const dataObject = {
			share_only: res.share_only ? res.share_only.map(one => one.value) : [],
			share_and_edit: res.share_and_edit ? res.share_and_edit.map(one => one.value) : []
		}
		handleValues(dataObject)
	}
	return (
		<div>
			<Button type='link' onClick={onOpen} className={`${isHidden ? 'hidden' : ''}`} title='Share with Others'>
				<IoIosShareAlt color={Colors.BLUE} fontSize={20} />
			</Button>
			<Modal
				title={modalTitle}
				onCancel={onClose}
				onOk={handleOk}
				open={visible}
				bodyStyle={{ paddingBottom: 80 }}
				destroyOnClose
			>
				<Form
					name='control-hooks'
					form={form}
					labelCol={{ span: 4 }}
					wrapperCol={{ span: 20 }}
					initialValues={formData}
				>
					<Alert
						type='warning'
						message='Share with Others'
						description="You can share the item with others with 'View Only' or 'Full Access'."
					/>
					<Divider />
					<Form.Item label='View Only' name='share_only'>
						<SelectDebounce
							showSearch
							placeholder='Search Employees'
							fetchOptions={e => fetchUserList(e, token)}
							isMultiple
						/>
					</Form.Item>
					<Form.Item label='Full Access' name='share_and_edit'>
						<SelectDebounce
							showSearch
							placeholder='Search Employees'
							fetchOptions={e => fetchUserList(e, token)}
							isMultiple
						/>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	)
}
export default ShareModal
