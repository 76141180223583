import React, { useState } from 'react'
import { Badge, Popover, Tabs } from 'antd'
import { GoPrimitiveDot } from 'react-icons/go'
import * as Colors from '../../styles/colors'

const { TabPane } = Tabs

const Information = ({ details, accessType }) => {
	const factoredDetails = () => {
		if (accessType === 'Private') {
			return { leftColumn: [details.leftColumn[0]] }
		}
		if (accessType === 'Protect') {
			return { leftColumn: details.leftColumn }
		}
		return details
	}
	const [activeKey, setActiveKey] = useState(0)
	return (
		<div
			className={`grid ${accessType === 'Private' || accessType === 'Protect' ? 'grid-cols-3' : 'grid-cols-5'} gap-20`}
		>
			<div className='xl:col-span-3 col-span-5'>
				<Tabs defaultActiveKey='0' destroyInactiveTabPane onChange={e => setActiveKey(e)}>
					{factoredDetails().leftColumn.map((tab, i) => (
						<TabPane
							tab={
								tab.badge ? (
									<Popover content={tab.badge?.popoverText} placement='topLeft'>
										<Badge
											status={tab.badge?.status}
											count={
												tab?.badge?.count || (
													<GoPrimitiveDot
														color={tab.badge?.status === 'success' ? Colors.GREEN : Colors.RED}
														fontSize={20}
													/>
												)
											}
											title={false}
											size='default'
										>
											<div className={`mr-2 ${Number(activeKey) === i && 'text-bell-blue'}`}>{tab.name}</div>
										</Badge>
									</Popover>
								) : (
									tab.name
								)
							}
							key={i}
						>
							<div>{tab.content || `Content of ${tab.name}`}</div>
						</TabPane>
					))}
				</Tabs>
			</div>
			<div className='xl:col-span-2 col-span-5'>
				<Tabs defaultActiveKey='0' destroyInactiveTabPane>
					{factoredDetails().rightColumn &&
						factoredDetails()?.rightColumn?.map((tab, i) => (
							<TabPane tab={tab.name} key={i}>
								<div>{tab.content || `Content of ${tab.name}`}</div>
							</TabPane>
						))}
				</Tabs>
			</div>
		</div>
	)
}

export default Information
