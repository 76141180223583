import { ADD_PROJECT, EDIT_PROJECT, RESET_PROJECT, SINGLE_PROJECT, UPDATE_SINGLE_PROJECT } from '../types'

export const addProject = payload => ({
	type: ADD_PROJECT,
	payload
})

export const editProject = payload => ({
	type: EDIT_PROJECT,
	payload
})

export const resetProject = payload => ({
	type: RESET_PROJECT,
	payload
})

export const addSingleProject = payload => ({
	type: SINGLE_PROJECT,
	payload
})

export const updateSingleProject = payload => ({
	type: UPDATE_SINGLE_PROJECT,
	payload
})
